import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Section from "./section/Section";
import SectionList from "./section/SectionList";
import { MdOutlineDocumentScanner } from "react-icons/md";
import { BiDownArrow, BiRightArrow, BiArchiveIn, BiArchiveOut, BiCheck, BiPencil } from "react-icons/bi";
import { IoReturnUpBackSharp, IoReturnUpForwardSharp } from "react-icons/io5";
import LoadingCircle from "../utility/LoadingCircle";
import ScrollToTop from "react-scroll-to-top";
import { BsArrowUpCircleFill } from "react-icons/bs";
import NavigationSection from "./section/NavigationSection";
import FilterUpdateModal from "./filter/FilterUpdateModal";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";

const TemplateForm = (props) => {
	let params = useParams();
	const navigationSectionId = -1;
	const [sections, setSections] = useState([]);
	const [sectionWordBank, setSectionWordBank] = useState([]);
	const [fieldWordBank, setFieldWordBank] = useState([]);
	const fieldTypes = ["text", "photos", "long text", "number", "date", "selection", "yes/no", "currency"];
	const [idsOfOpenSections, setIdsOfOpenSections] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [template, setTemplate] = useState({});
	const [isArchived, setIsArchived] = useState(false);
	const [draft, setDraft] = useState([]);
	const [cloningTemplate, setCloningTemplate] = useState(false);
	const [odooModelMapping, setOdooModelMapping] = useState("");
	const [navMappings, setNavMappings] = useState([]);
	const [portfolioType, setPortfolioType] = useState("");
	const [showPortfolioModal, setShowPortfolioModal] = useState(false);
	const [disableSectionDrag, setDisableSectionDrag] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (props.api === "" || props.token === "") {
			return;
		}
		const controller = new AbortController();
		const signal = controller.signal;
		setIsLoading(true);
		fetch(props.api + "/template/" + params.id, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: props.token,
			},
			signal,
		})
			.then((res) => {
				if (!res.ok) {
					setIsLoading(false);
					// put an error here
				} else {
					return res.json();
				}
			})
			.then((res) => {
				if (res) {
					setIsLoading(false);
					setDraft(res.draft);
					setTemplate(res.template[0]);
					setOdooModelMapping(res.template[0].odoo_model);
					setIsArchived(res.template[0].is_archived);
					setPortfolioType(res.template[0].portfolio_type);
					document.title = "Template: " + res.template[0].name;
					setNavMappings(res.navMappings);

					if (!res.sections || res.sections.length <= 0) {
						return;
					}
					let unsortedSections = res.sections;
					let sortedSections = unsortedSections.sort((a, b) => a.position - b.position);
					setSections(sortedSections);
				}
			})
			.catch((err) => {});

		fetch(props.api + "/wordbank", {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: props.token,
			},
			signal,
		})
			.then((res) => {
				if (!res.ok) {
					// put an error here
				} else {
					return res.json();
				}
			})
			.then((res) => {
				if (res) {
					setSectionWordBank(res.sectionWords);
					setFieldWordBank(res.fieldWords);
				}
			})
			.catch((err) => {});

		return () => {
			controller.abort();
		};
	}, [props.api, props.token, params.id]);

	const deleteSection = (section_id) => {
		if (props.api === "" || props.token === "" || isNaN(section_id)) {
			return;
		}
		fetch(props.api + "/section/" + section_id, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: props.token,
			},
		}).then((res) => {
			if (!res.ok) {
				// put an error here
			} else {
				setSections(sections.filter((current_section) => current_section.id !== section_id));
			}
		});
	};

	const showAllSections = (e) => {
		e.preventDefault();
		setIdsOfOpenSections([...sections.map((mapData) => mapData.id), navigationSectionId]);
	};

	const closeAllSections = (e) => {
		e.preventDefault();
		setIdsOfOpenSections([]);
	};

	const publishDraft = (e) => {
		e.preventDefault();
		fetch(props.api + "/template/publish-draft/" + template.id, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: props.token,
			},
			body: JSON.stringify({ templateid: template.template_id }),
		}).then((res) => {
			if (!res.ok) {
				// put an error here
			} else {
				setTemplate((prevState) => {
					return {
						...prevState,
						template_status_name: "published",
					};
				});
				let filteredDraft = draft.filter((filterData) => filterData.id !== template.id);
				setDraft([...filteredDraft]);
			}
		});
	};

	const createRevision = (e) => {
		e.preventDefault();
		if (cloningTemplate) {
			return;
		}
		setCloningTemplate(true);
		fetch(props.api + "/template/create-revision/" + template.id, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: props.token,
			},
		})
			.then((res) => {
				setCloningTemplate(false);
				if (!res.ok) {
					// put an error here
				} else {
					return res.json();
					// probably navigate to the draft here
				}
			})
			.then((res) => {
				if (res) {
					navigate("/template/" + res.createdVersionId);
				}
			});
	};

	const setArchived = (e, newArchivedValue) => {
		e.preventDefault();

		fetch(props.api + "/template/" + template.template_id, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: props.token,
			},
			body: JSON.stringify({
				name: template.name,
				is_archived: newArchivedValue,
				template_type: template.template_type_id,
			}),
		}).then((res) => {
			if (!res.ok) {
				// put an error here
			} else {
				let templateClone = template;
				setIsArchived(newArchivedValue);
				templateClone.is_archived = newArchivedValue;
				setTemplate(templateClone);
			}
		});
	};

	const revertPublishToDraft = (e) => {
		e.preventDefault();
		fetch(props.api + "/template/revert-published/" + template.id, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: props.token,
			},
			body: JSON.stringify({ templateid: template.template_id }),
		}).then((res) => {
			if (!res.ok) {
				// put an error here
			} else {
				setTemplate((prevState) => {
					return {
						...prevState,
						template_status_name: "draft",
					};
				});
			}
		});
	};

	const openPortfolioEditModal = () => {
		setShowPortfolioModal(true);
	};

	const updateSectionInDB = (theSection, newPosition) => {
		fetch(props.api + "/section/" + theSection.id, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: props.token,
			},
			body: JSON.stringify({
				name: theSection.name,
				position: newPosition,
				template_id: theSection.template_id,
				condition_type: theSection.condition_type,
				condition_value: theSection.condition_value,
				field_condition_id: theSection.field_condition_id,
				section_condition_id: theSection.section_condition_id,
			}),
		}).then((res) => {
			if (!res.ok) {
				// put an error here
			} else {
			}
		});
	};

	const updateMultipleSectionsInDB = (updatedSections) => {
		fetch(props.api + "/section/move-multiple", {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: props.token,
			},
			body: JSON.stringify({ updatedSections }),
		}).then((res) => {
			if (!res.ok) {
				// put an error here
			} else {
				// success logic here
			}
		});
	};

	const handleOnDragEnd = (result) => {
		if (!result.destination) return;

		const items = Array.from(sections);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		items.forEach((item, index) => {
			item.position = index;
		});
		updateMultipleSectionsInDB(items);
		setSections(items);
	};

	const toggleProposal = (newState) => {
		setTemplate({
			...template,
			requires_proposal_permission: newState,
		});
		fetch(props.api + "/template/proposal-permission/" + template.template_id, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: props.token,
			},
			body: JSON.stringify({ newState }),
		});
	};

	return (
		<div>
			{showPortfolioModal && template ? (
				<FilterUpdateModal
					api={props.api}
					token={props.token}
					closeModal={() => setShowPortfolioModal(false)}
					portfolioType={portfolioType}
					setPortfolioType={setPortfolioType}
					templateid={template && template.template_id ? template.template_id : -1}
				/>
			) : null}
			<ScrollToTop
				smooth
				top={500}
				width="1"
				height="1"
				style={{ background: (76, 175, 80, 0.3) }}
				component={<BsArrowUpCircleFill className="w-full h-full" />}
			/>
			<div className="p-5 flex flex-col md:flex-row justify-between gap-4 md:items-center bg-gray-100">
				<div>
					{template && template.name ? (
						<p className="text-2xl font-bold flex gap-2">
							{template.name} {"- v" + template.version_number}
							<span
								className={`font-normal text-base p-2 ${
									template.template_status_name === "draft"
										? " bg-orange-300 "
										: template.template_status_name === "published"
										? " bg-green-300 "
										: " bg-gray-300 "
								} `}>{`${template.template_status_name}`}</span>
						</p>
					) : null}

					<p className="text-xl capitalize">{template.template_type_name}</p>
					<p>archived: {isArchived ? "Yes" : "No"}</p>
					<p>Odoo Model: {odooModelMapping && odooModelMapping !== "" ? odooModelMapping : "-"}</p>
					<p>Portfolio Type: {portfolioType && portfolioType !== "" ? (portfolioType === "misc" ? "misc." : portfolioType) : "-"}</p>
					<p>Requires Proposal Permissions: {template ? (template.requires_proposal_permission === 1 ? "Yes" : "No") : "-"}</p>
				</div>

				<div className="flex flex-col md:justify-center md:items-center gap-1 bg-gray-100 text-white">
					{template.template_status_name === "published" && props.isAdmin ? (
						<div
							className="flex items-center gap-1 cursor-pointer bg-purple-600 p-2 rounded-lg text-white w-full"
							onClick={(e) => revertPublishToDraft(e)}>
							<IoReturnUpBackSharp />
							<p>Change Back to Draft</p>
						</div>
					) : null}
					{template && isArchived ? (
						<div className="flex items-center gap-1 cursor-pointer bg-blue-600 p-2 rounded-lg w-full" onClick={(e) => setArchived(e, 0)}>
							<BiArchiveOut />
							<p>Unarchive Template</p>
						</div>
					) : null}
					{template && !isArchived ? (
						<div className="flex items-center gap-1 cursor-pointer bg-blue-600 p-2 rounded-lg w-full" onClick={(e) => setArchived(e, 1)}>
							<BiArchiveIn />
							<p>Archive Template</p>
						</div>
					) : null}
					{template.template_status_name === "draft" ? (
						<div className="flex items-center gap-1 cursor-pointer bg-blue-600 p-2 rounded-lg text-white w-full" onClick={(e) => publishDraft(e)}>
							<BiCheck />
							<p>Publish Draft</p>
						</div>
					) : null}

					{template.template_status_name === "published" && draft && draft.length > 0 ? (
						<div className="flex items-center gap-1 cursor-pointer bg-blue-600 p-2 rounded-lg text-white w-full">
							<IoReturnUpForwardSharp />
							<Link to={"/template/" + draft[0].id}>Visit Latest Revision</Link>
						</div>
					) : null}
					{template.template_status_name === "published" && draft && draft.length <= 0 ? (
						<div
							className={`flex items-center gap-1 cursor-pointer ${
								cloningTemplate ? "bg-gray-600" : "bg-blue-600"
							} p-2 rounded-lg text-white w-full`}>
							<BiPencil />
							{cloningTemplate ? (
								<p>Creating Revision...</p>
							) : (
								<p className="cursor-pointer" onClick={(e) => createRevision(e)}>
									Create revision
								</p>
							)}
						</div>
					) : null}
					{template.template_status_name === "draft" ? (
						<div className="flex items-center gap-1 cursor-pointer bg-blue-600 p-2 rounded-lg text-white w-full" onClick={openPortfolioEditModal}>
							<BiPencil />
							<p>Update Portfolio Type</p>
						</div>
					) : null}
					{props.proposalAccess ? (
						template.requires_proposal_permission === 1 ? (
							<div
								className="flex items-center gap-1 cursor-pointer bg-blue-600 p-2 rounded-lg text-white w-full"
								onClick={(e) => toggleProposal(0)}>
								<MdOutlineDocumentScanner />
								<p className="select-none">Proposal Toggle (Yes)</p>
							</div>
						) : (
							<div
								className="flex items-center gap-1 cursor-pointer bg-red-700 p-2 rounded-lg text-white w-full"
								onClick={(e) => toggleProposal(1)}>
								<MdOutlineDocumentScanner />
								<p className="select-none">Proposal Toggle (No)</p>
							</div>
						)
					) : null}
				</div>
			</div>

			<div className="flex flex-col md:flex-row">
				<div className="basis-1/4 p-2">
					<SectionList
						api={props.api}
						token={props.token}
						sections={sections}
						setSections={setSections}
						sectionWordBank={sectionWordBank}
						template_id={params.id}
						isLoading={isLoading}
						isDrafting={template.template_status_name === "draft"}
						updateSectionInDB={updateSectionInDB}
					/>
				</div>

				<div className="basis-3/4">
					<div className="flex gap-2 my-2">
						{idsOfOpenSections.length === sections.length + 1 ? (
							<button className="bg-gray-200 p-2 flex items-center" onClick={(e) => closeAllSections(e)}>
								<BiDownArrow className="text-xl" />
								Close All Sections
							</button>
						) : (
							<button className="bg-gray-200 p-2 flex items-center" onClick={(e) => showAllSections(e)}>
								<BiRightArrow className="text-xl" /> Show All Sections
							</button>
						)}
					</div>

					<DragDropContext onDragEnd={handleOnDragEnd}>
						<Droppable droppableId="sections">
							{(provided) => (
								<ul className="sections" {...provided.droppableProps} ref={provided.innerRef}>
									{sections && sections.length > 0 ? (
										sections.map((values, index) => {
											return (
												<Draggable
													key={values.id}
													draggableId={values.id.toString()}
													index={index}
													isDragDisabled={
														idsOfOpenSections.length === sections.length + 1 ||
														template.template_status_name === "published" ||
														disableSectionDrag
													}>
													{(provided) => (
														<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
															<Section
																deleteSection={() => deleteSection(values.id)}
																idsOfOpenSections={idsOfOpenSections}
																setIdsOfOpenSections={setIdsOfOpenSections}
																fieldTypes={fieldTypes}
																template_id={params.id}
																fieldWordBank={fieldWordBank}
																sectionWordBank={sectionWordBank}
																key={values.id}
																api={props.api}
																id={values.id}
																token={props.token}
																name={values.name}
																allSectionData={values}
																position={values.position}
																sections={sections}
																setSections={setSections}
																isDrafting={template.template_status_name === "draft"}
																disableSectionDrag={disableSectionDrag}
																setDisableSectionDrag={setDisableSectionDrag}
															/>
														</div>
													)}
												</Draggable>
											);
										})
									) : isLoading ? (
										<div className="flex flex-col items-center justify-center w-full my-2">
											<LoadingCircle />
											<p>Loading Sections</p>
										</div>
									) : (
										<div className="mb-2 bg-gray-50 text-xl p-4 flex">Add a section to get started</div>
									)}
									{provided.placeholder}
								</ul>
							)}
						</Droppable>
					</DragDropContext>
				</div>
			</div>
		</div>
	);
};

export default TemplateForm;
