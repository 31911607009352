import { useEffect } from "react";
import LoadingCircle from "../utility/LoadingCircle";
import { useNavigate } from "react-router-dom";
import { IoMdWarning } from "react-icons/io";
import { FaCheckCircle } from "react-icons/fa";

const ClockInModal = ({
	status,
	odooId,
	conflictingProject,
	isLoading,
	cancel,
	clockInWithTransfer,
	projectsRoute,
	conflictIdMatchesClockInId,
	loadingMessage,
	successMessage,
	errorMessage,
}) => {
	const navigate = useNavigate();

	const goToProject = () => {
		navigate(projectsRoute);
	};
	return (
		<div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black/[.3] z-[200]">
			<div className="p-4 bg-white rounded-lg w-full md:w-2/5 lg:w-1/5">
				{isLoading === true ? (
					<div className="flex flex-col justify-center items-center">
						<LoadingCircle />
						<p className="text-center mt-2">{loadingMessage}</p>
					</div>
				) : (
					<div>
						{status === 200 ? (
							<div className="flex flex-col justify-center items-center">
								<div className="flex justify-center items-center">
									<FaCheckCircle size={55} color="green" />
								</div>
								<p className="text-green-700">Success</p>
								<p className="text-center">{successMessage}</p>

								<div className="flex flex-col gap-1 w-full mt-4">
									{projectsRoute ? (
										<button className="bg-blue-500 hover:bg-blue-700 text-white w-full rounded-lg p-2" onClick={goToProject}>
											Go To Project
										</button>
									) : null}
									<button className="bg-gray-500 hover:bg-gray-700 text-white w-full rounded-lg p-2" onClick={cancel}>
										Close
									</button>
								</div>
							</div>
						) : null}
						{status === 409 ? (
							odooId ? (
								<div className="flex flex-col justify-center items-center">
									<div className="flex justify-center items-center">
										<IoMdWarning size={55} color="red" />
									</div>
									<p className="text-red-700">Warning</p>
									<p className="mt-1 text-center">
										{conflictIdMatchesClockInId
											? "You are already clocked in at this site"
											: `You are already clocked in ${conflictingProject && conflictingProject.name ? "at" : "via ADP"}`}
									</p>
									<p className="font-bold text-center">{conflictingProject && conflictingProject.name ? conflictingProject.name : null}</p>
									<div className="flex flex-col gap-1 w-full mt-4">
										{conflictIdMatchesClockInId ? (
											projectsRoute ? (
												<button className="bg-blue-500 hover:bg-blue-700 text-white w-full rounded-lg p-2" onClick={goToProject}>
													Go To Project
												</button>
											) : null
										) : (
											<button className="bg-blue-500 hover:bg-blue-700 text-white w-full rounded-lg p-2" onClick={clockInWithTransfer}>
												Clock Out + Transfer
											</button>
										)}
										<button className="bg-gray-500 hover:bg-gray-700 text-white w-full rounded-lg p-2" onClick={cancel}>
											Cancel
										</button>
									</div>
								</div>
							) : (
								<div>
									<div className="flex justify-center items-center">
										<IoMdWarning size={55} color="red" />
									</div>
									<p className="text-center">An Error Occurred. Please try again.</p>
									<button className="bg-gray-500 hover:bg-gray-700 text-white w-full rounded-lg p-2 mt-4" onClick={cancel}>
										Close
									</button>
								</div>
							)
						) : null}
						{status === 400 ? (
							<div>
								<div className="flex justify-center items-center">
									<IoMdWarning size={55} color="red" />
								</div>
								<p className="text-red-700 text-center">Warning</p>
								<p className="text-center">{errorMessage ? errorMessage : "An error occurred. Please try again."}</p>
								<button className="bg-gray-500 hover:bg-gray-700 text-white w-full rounded-lg p-2 mt-4" onClick={cancel}>
									Close
								</button>
							</div>
						) : null}
					</div>
				)}
			</div>
		</div>
	);
};

export default ClockInModal;
