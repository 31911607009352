import React, { useRef, useState, useEffect} from "react";
import FilerobotImageEditor, { TABS, TOOLS } from "react-filerobot-image-editor";
import { BsDownload, BsPencil, BsTrash, BsZoomIn, BsZoomOut, BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { saveAs } from "file-saver"; 
import YesNoPrompt from "../../utility/YesNoPrompt";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const ImageViewer = (props) => {
	const editorRef = useRef(null);
	const windowWidth = useRef(window.innerWidth);

	const [isImgEditorShown, setIsImgEditorShown] = useState(false);
	const [source, setSource] = useState("");
	const [selectedShapeId, setSelectedShapeId] = useState(null);

	useEffect(() => {
		if (windowWidth.current > 762) {
			document.body.style.overflow = 'hidden';
			return ()=> document.body.style.overflow = 'unset';
		}
			
	 }, []); 

	const closeViewer = () => {
		props.setShowImage(false);
		props.setSrcOfImageToShow("");
	}

	const openImgEditor = () => {
		setIsImgEditorShown(true);
	}
	
	const closeImgEditor = () => {
		setIsImgEditorShown(false)
		props.setShowImage(false);
		props.setSrcOfImageToShow("")
	}

	const closeImgEditorBeforeSaving = () => {
		if (window.confirm("Changes you made may not be saved.") === true) {
			setIsImgEditorShown(false)
		}
	}
	
	const handleShapeSelection = (shapeId) => {
		setSelectedShapeId(shapeId);
	}

	return (
		<div>
			{selectedShapeId !== null && (
			<div>
			Selected shape position: 
			{editorRef.current && 
				editorRef.current
				.getShapes()
				.find((shape) => shape.id === selectedShapeId).position.x}
			,
			{editorRef.current && 
				editorRef.current
				.getShapes()
				.find((shape) => shape.id === selectedShapeId).position.y}
			</div>
			)}
		
			<div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-black/50 z-50" onClick={() => isImgEditorShown ? closeImgEditorBeforeSaving() : closeViewer()}>
				<TransformWrapper>
					{({ zoomIn, zoomOut, resetTransform, ...rest}) => (
						!isImgEditorShown ? (
							<div className="bg-white p-4 flex flex-col justify-center gap-3 m-5 rounded-lg" onClick={(e) => e.stopPropagation()}> 
								<span className="flex flex-1 justify-between">
									<BsPencil size={25} className="hover:text-blue-700 cursor-pointer" onClick={openImgEditor} />
									<BsZoomIn size={25} className="hover:text-blue-700 cursor-pointer" onClick={() => zoomIn()} />
									<BsZoomOut size={25} className="hover:text-blue-700 cursor-pointer" onClick={() => zoomOut()} />
									<BsTrash size={25} className="hover:text-red-700 cursor-pointer" onClick={() => props.prepDeletePrompt(props.thumbnailData[props.clickedImageIndex].path_lower)}/>
								</span>
								<p className="text-xl text-center w-full">{props.fieldName}</p>
								<hr />
								<div className="flex items-center justify-between gap-2">
									{props.clickedImageIndex > 0 && (
										<BsArrowLeft
											size={25}
											className="cursor-pointer hover:text-blue-700"
											onClick={() => props.setClickedImageIndex((prevIndex) => (prevIndex - 1 + props.thumbnailData.length) % props.thumbnailData.length)}
										/>
									)}
									<div className="flex-1 flex justify-center items-center">
										{props.imgSrc && props.imgSrc !== ""
											? <TransformComponent><img src={props.imgSrc} alt="work" className="max-h-[80vh] w-auto" /></TransformComponent>
											: <p>Unable to get this image</p>
										}
									</div>
									{props.clickedImageIndex < props.thumbnailData.length - 1 && (
										<BsArrowRight
											size={25}
											className="cursor-pointer hover:text-blue-700"
											onClick={() => props.setClickedImageIndex((prevIndex) => (prevIndex + 1) % props.thumbnailData.length)}
										/>
									)}
								</div>
							</div> 
						) : null
					)}
				</TransformWrapper>
				
				<div className="h-screen w-screen md:h-screen md:w-max" onClick={(e) => isImgEditorShown ? e.stopPropagation() : null}> 
					<img style={{ width: "auto", height: "auto" }} src={source} alt="" />
					{isImgEditorShown && (
						<FilerobotImageEditor
							ref={editorRef}
							source={props.imgSrc}
							onSave={(editedImageObject) => {
								closeImgEditor();
								props.uploadEditedImage(editedImageObject.name, editedImageObject.imageBase64)
								setSource(editedImageObject.imageBase64);
							}}
							onShapeCreate={(shape) => handleShapeSelection(shape.id)}
							onClose={closeImgEditor}
							annotationsCommon={{ fill: "#ff0000"}}
							Text={{ text: "Text..." }}
							Pen={{stroke: "#ff0000", strokeWidth: 10}}
							tabsIds={[TABS.ADJUST, TABS.ANNOTATE]}
							defaultTabId={TABS.ANNOTATE}
							defaultToolId={TOOLS.PEN}
							defaultSavedImageType={"jpg"}
							defaultSavedImageName={props.fieldName}
							closeAfterSave={true}
							avoidChangesNotSavedAlertOnLeave={false}
							willReadFrequently={true}
							moreSaveOptions={[
								{
									label: "Download Edited Image",
									onClick: (triggerSaveModal, triggerSave) =>
										triggerSaveModal((...args) => {
											saveAs(args[0].imageBase64, props.fieldName)

										}),
									icon: BsDownload
								},
							]}
						/>
					)}
				</div>
			</div>			
				{props.showDeletePrompt ? (
					<YesNoPrompt
						type="image"
						name={props.deletePath.split("/")[props.deletePath.split("/").length - 1].split("-")[1]}
						hasImage={true}
						imgSrc={props.imgSrc}
						cancelAction={() => props.setShowDeletePrompt(false)}
						deleteAction={() => props.deleteImage()}
					/>
				) : null}	
		</div>
	);
};

export default ImageViewer;
