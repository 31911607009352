import { ImImages } from "react-icons/im";

const InventoryGrid = ({ inventory, setSelectedProduct, setViewProduct, loading, paginationComponent }) => {
	const handleClick = (item) => {
		setSelectedProduct(item);
		setViewProduct(true);
	};

	if (inventory.length === 0) {
		return (
			<div className="p-4 border border-black m-4">
				<p className="font-bold">{loading ? "Retrieving products..." : "No results found"}</p>
			</div>
		);
	}

	return (
		<div className="grid grid-cols-2 lg:grid-cols-4 gap-2 p-4">
			{inventory.map((item) => (
				<div
					key={item.id}
					className="border border-black p-4 flex flex-col justify-between bg-white items-center grow cursor-pointer"
					onClick={() => handleClick(item)}>
					{item.image_1920 ? (
						<img src={`data:image/jpeg;base64,${item.image_1920}`} alt={item.display_name} className="w-[200px] h-[200px] object-contain" />
					) : (
						<div className="w-auto h-[200px] flex justify-center items-center">
							<ImImages className="w-auto h-[50%]" />
						</div>
					)}

					<div className="w-full">
						<h3 className="font-bold">{item.display_name}</h3>
					</div>
				</div>
			))}
		</div>
	);
};

export default InventoryGrid;
