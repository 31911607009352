import { useState } from "react";
import { BsEraser } from "react-icons/bs";
import DeleteProjectModal from "../DeleteProjectModal";

const ClearRecordButton = ({ projectName, projectid, token, api }) => {
	const [showClearRecordModal, setShowClearRecordModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [clearComplete, setClearComplete] = useState(false);
	const clearFields = (clearData, clearPhotos) => {
		if (isLoading) {
			return;
		}
		setIsLoading(true);
		setClearComplete(false);
		fetch(api + "/project/clear/" + projectid, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: token,
			},
			body: JSON.stringify({ clearData, clearPhotos }),
		}).then((res) => {
			if (res.ok) {
				setIsLoading(false);
				setClearComplete(true);
				return res.json();
			}
		});
	};
	return (
		<>
			<div
				className="flex items-center gap-1 bg-red-600 rounded-lg hover:bg-red-700 p-2 cursor-pointer w-full select-none"
				onClick={() => {
					setShowClearRecordModal(true);
				}}>
				<BsEraser />
				<p>Clear Data</p>
			</div>
			{showClearRecordModal ? (
				<>
					<DeleteProjectModal
						setShowDeleteProjectModal={setShowClearRecordModal}
						projectName={projectName}
						deleteFunction={(clearData, clearPhotos) => {
							clearFields(clearData, clearPhotos);
						}}
						deleteFunctionCompleted={clearComplete}
						completedMessage={`${projectName} has successfully been cleared.`}
						actionVerb={"clear"}
						isClearing={true}
						isLoading={isLoading}
					/>
				</>
			) : null}
		</>
	);
};

export default ClearRecordButton;
