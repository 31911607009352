import { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import LoadingCircle from "../../utility/LoadingCircle";

const SectionInfoModal = ({
	sectionName,
	setShowSectionInfoModal,
	condition_type,
	condition_value,
	field_condition_id,
	section_condition_id,
	sections,
	api,
	token,
}) => {
	const [conditionalFieldName, setConditionalFieldName] = useState("");
	const [conditionalSectionName, setConditionalSectionName] = useState("");
	const [fields, setFields] = useState([]);
	const [checkingFields, setCheckingFields] = useState(true);
	const [checkingSections, setCheckingSections] = useState(true);

	useEffect(() => {
		if (api === "" || token === "" || section_condition_id === "" || section_condition_id === 0 || typeof section_condition_id === "undefined") {
			return;
		}
		const controller = new AbortController();
		const signal = controller.signal;
		fetch(api + "/section/" + section_condition_id, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: token,
			},
			signal,
		})
			.then((res) => {
				if (!res.ok) {
				} else {
					return res.json();
				}
			})
			.then((res) => {
				if (res) {
					setFields(res.fields);
				}
			})
			.catch((err) => {});

		return () => {
			controller.abort();
		};
	}, [api, token, section_condition_id]);

	useEffect(() => {
		findConditionalSectionName(section_condition_id, sections);
	}, [section_condition_id, sections]);

	const findConditionalSectionName = (sectionId, theSections) => {
		for (let i = 0; i < theSections.length; i++) {
			if (theSections[i].id === sectionId) {
				setCheckingSections(false);
				setConditionalSectionName(theSections[i].name);
				return;
			}
		}
		setCheckingSections(false);
		setConditionalSectionName("");
	};

	useEffect(() => {
		if (fields.length === 0) {
			if (isNaN(field_condition_id) || field_condition_id === 0) {
				setCheckingFields(false);
			}
			return;
		}
		findConditionalFieldName(field_condition_id, fields);
	}, [field_condition_id, fields]);

	const findConditionalFieldName = (fieldId, theFields) => {
		for (let i = 0; i < theFields.length; i++) {
			if (theFields[i].id === fieldId) {
				setCheckingFields(false);
				setConditionalFieldName(theFields[i].name);
				return;
			}
		}
		setCheckingFields(false);
		setConditionalFieldName("");
	};

	return (
		<div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black/50 z-50" onClick={() => setShowSectionInfoModal(false)}>
			<div className="bg-white w-full lg:w-1/2 m-2 rounded-lg border border-black" onClick={(e) => e.stopPropagation()}>
				<div className="p-4 text-left font-bold text-3xl flex justify-between items-center bg-gray-300 rounded-t-lg">
					{sectionName}
					<MdClose color="red" className="text-3xl cursor-pointer" onClick={() => setShowSectionInfoModal(false)} />
				</div>
				<div className="p-4">
					<p className="text-xl font-bold">Conditional: </p>
					{checkingFields || checkingSections ? (
						<div>
							<LoadingCircle />
						</div>
					) : conditionalSectionName === "" || conditionalFieldName === "" ? (
						<p>This section doesn't have a condition</p>
					) : (
						<p>{`Displays when section: ${conditionalSectionName} has field: ${conditionalFieldName} ${condition_type} ${condition_value}`}</p>
					)}
				</div>
			</div>
		</div>
	);
};

export default SectionInfoModal;
