import React, { useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";

const InventoryTree = ({ data, categoriesQuery, setCategoriesQuery }) => {
	const [expandedNodes, setExpandedNodes] = useState({});
	const selectedCategories = categoriesQuery === "all" ? ["all"] : categoriesQuery.split(",").map((item) => Number(item.trim()));

	const handleToggle = (id) => {
		setExpandedNodes((prev) => ({ ...prev, [id]: !prev[id] }));
	};

	const updateCategories = (updatedCategories) => {
		let queryParam = "";

		if (updatedCategories.length === 0 || updatedCategories.includes("all")) {
			queryParam = "all";
		} else {
			queryParam = updatedCategories.join(",");
		}

		if (categoriesQuery !== queryParam) {
			setCategoriesQuery(`${queryParam}`);
		}
	};

	const handleSelect = (id) => {
		const node = data.find((item) => item.id === id);
		let updatedCategories;

		if (selectedCategories.includes(id)) {
			updatedCategories = selectedCategories.filter((catId) => catId !== id);
		} else {
			if (node.child_id.length > 0) {
				updatedCategories = [...selectedCategories.filter((catId) => !node.child_id.includes(catId)), id];
			} else {
				const parent = data.find((parent) => parent.child_id.includes(node.id));
				updatedCategories = parent ? selectedCategories.filter((catId) => catId !== parent.id).concat(id) : [...selectedCategories, id];
			}
		}
		if (updatedCategories.includes("all")) {
			updatedCategories = updatedCategories.filter((filterData) => filterData !== "all");
		}
		updateCategories(updatedCategories);
	};

	const renderTree = (node) => {
		return (
			<div key={node.id}>
				<div className="flex items-center justify-between items-center">
					<label>
						<input
							type="checkbox"
							name="categoryCheckbox"
							value={node.id}
							checked={selectedCategories.includes(node.id)}
							onChange={() => handleSelect(node.id)}
						/>
						<span
							value={node.id}
							checked={selectedCategories.includes(node.id)}
							onChange={() => handleSelect(node.id)}
							className="cursor-pointer ml-2 select-none">
							{node.name}
						</span>
					</label>
					{node.child_id.length > 0 && (
						<button onClick={() => handleToggle(node.id)}>{expandedNodes[node.id] ? <IoIosArrowDown /> : <IoIosArrowForward />}</button>
					)}
				</div>
				{(expandedNodes[node.id] || selectedCategories.includes(node.id) || node.child_id.some((childId) => selectedCategories.includes(childId))) &&
					node.child_id.length > 0 && (
						<div>
							{node.child_id.map((childId) => {
								const childNode = data.find((item) => item.id === childId);
								return childNode ? (
									<div className="ml-8" key={childNode.id}>
										{renderTree(childNode)}
									</div>
								) : null;
							})}
						</div>
					)}
			</div>
		);
	};

	const rootNodes = data.filter((item) => !data.some((child) => child.child_id.includes(item.id)));

	return (
		<div>
			<p className="font-bold text-lg">Categories</p>
			<label>
				<input
					type="checkbox"
					name="categoryCheckbox"
					value="all"
					checked={selectedCategories.includes("all")}
					onChange={() => {
						selectedCategories.includes("all") ? updateCategories([]) : updateCategories(["all"]);
					}}
				/>
				<span className="cursor-pointer ml-2 select-none">All Products</span>
			</label>
			{rootNodes.map((node) => renderTree(node))}
		</div>
	);
};

export default InventoryTree;
