import { useEffect, useState, useMemo } from "react";
import FieldExclusionList from "./FieldExclusionList";

const SectionExclusionList = ({ api, token, templateData, dataToSave, setDataToSave, reportId }) => {
	const [selectedTemplate, setSelectedTemplate] = useState("");
	const [selectedTemplates, setSelectedTemplates] = useState([]);
	const [templateNames, setTemplateNames] = useState([]);
	const [sections, setSections] = useState([]);
	const [selectAllConst, setSelectAllConst] = useState(false);

	const collator = useMemo(() => {
		return new Intl.Collator(undefined, { numeric: true, sensitivity: "base" });
	}, []);

	const changeTemplateSelection = (e) => {
		e.preventDefault();
		setSelectedTemplate(e.target.value);

		if (e.target.value === "") {
			setSelectAllConst(false);
			return;
		}

		let selectedSectionsLength = dataToSave.templateData[e.target.value].includedSections.filter((filterData) => filterData.deleted === false).length;
		let totalSections = Object.keys(templateData[e.target.value].sections).length;
		if (selectedSectionsLength === totalSections) {
			setSelectAllConst(true);
		} else {
			setSelectAllConst(false);
		}
	};

	useEffect(() => {
		if (selectedTemplate === "" || typeof templateData[selectedTemplate] === "undefined") {
			setSections([]);
			return;
		}
		let newSections = Object.keys(templateData[selectedTemplate].sections);
		newSections.sort(collator.compare);
		setSections(newSections);
	}, [collator.compare, selectedTemplate, templateData]);

	useEffect(() => {
		if (templateData) {
			setTemplateNames(Object.keys(templateData));
		}
	}, [templateData]);

	useEffect(() => {
		if (!dataToSave || !dataToSave.templateData) {
			return;
		}
		if (Object.keys(dataToSave.templateData).includes(selectedTemplate) === false || dataToSave.templateData[selectedTemplate].deleted === true) {
			setSections([]);
			setSelectAllConst(false);
			setSelectedTemplate("");
		}
	}, [selectedTemplate, dataToSave]);

	useEffect(() => {
		if (dataToSave && dataToSave.templateData) {
			setSelectedTemplates(Object.keys(dataToSave.templateData));
		}
	}, [dataToSave]);

	const toggleSelectAllSections = (e) => {
		setSelectAllConst(e.target.checked ? 1 : 0);
		if (!selectAllConst) {
			for (let i = 0; i < sections.length; i++) {
				addSection(e, sections[i]);
			}
		} else {
			for (let i = 0; i < sections.length; i++) {
				removeSection(e, sections[i]);
			}
		}
	};

	const removeSection = (e, sectionName) => {
		e.preventDefault();
		let newTemplateDataToSave = { ...dataToSave.templateData };
		for (let i = 0; i < newTemplateDataToSave[selectedTemplate].includedSections.length; i++) {
			if (newTemplateDataToSave[selectedTemplate].includedSections[i].name === sectionName) {
				if (newTemplateDataToSave[selectedTemplate].includedSections[i].from_server) {
					newTemplateDataToSave[selectedTemplate].includedSections[i].deleted = true;
				} else {
					newTemplateDataToSave[selectedTemplate].includedSections.splice(i, 1);
				}

				let currentFields = newTemplateDataToSave[selectedTemplate]["includedFields"][sectionName];
				if (currentFields) {
					for (let j = currentFields.length - 1; j >= 0; j--) {
						if (currentFields[j].from_server) {
							newTemplateDataToSave[selectedTemplate]["includedFields"][sectionName][j].deleted = true;
						} else {
							newTemplateDataToSave[selectedTemplate]["includedFields"][sectionName].splice(j, 1);
						}
					}
				}

				if (
					newTemplateDataToSave[selectedTemplate]["includedFields"][sectionName] &&
					newTemplateDataToSave[selectedTemplate]["includedFields"][sectionName].length === 0
				) {
					delete newTemplateDataToSave[selectedTemplate]["includedFields"][sectionName];
				}

				break;
			}
		}

		let selectedSectionsLength = newTemplateDataToSave[selectedTemplate].includedSections.filter((filterData) => filterData.deleted === false).length;
		let totalSections = Object.keys(templateData[selectedTemplate].sections).length;
		if (selectedSectionsLength === totalSections) {
			setSelectAllConst(true);
		} else {
			setSelectAllConst(false);
		}

		setDataToSave((prev) => {
			return { ...prev, templateData: newTemplateDataToSave };
		});
	};

	const addSection = (e, sectionName) => {
		e.preventDefault();
		let newTemplateDataToSave = { ...dataToSave.templateData };
		let currentSelectedSectionNames = newTemplateDataToSave[selectedTemplate].includedSections.map((mapData) => mapData.name);
		if (currentSelectedSectionNames.includes(sectionName)) {
			for (let i = 0; i < newTemplateDataToSave[selectedTemplate].includedSections.length; i++) {
				if (newTemplateDataToSave[selectedTemplate].includedSections[i].name === sectionName) {
					newTemplateDataToSave[selectedTemplate].includedSections[i].deleted = false;
					break;
				}
			}
		} else {
			newTemplateDataToSave[selectedTemplate].includedSections.push({ id: -1, name: sectionName, from_server: false, deleted: false });
		}

		let selectedSectionsLength = newTemplateDataToSave[selectedTemplate].includedSections.filter((filterData) => filterData.deleted === false).length;
		let totalSections = Object.keys(templateData[selectedTemplate].sections).length;
		if (selectedSectionsLength === totalSections) {
			setSelectAllConst(true);
		} else {
			setSelectAllConst(false);
		}

		setDataToSave((prev) => {
			return { ...prev, templateData: newTemplateDataToSave };
		});
	};

	return (
		<>
			<div className="my-4  border-2 border-black p-2 rounded-lg bg-gray-50">
				<h3 className="text-xl font-bold">Section Inclusions</h3>
				<form onSubmit={(e) => e.preventDefault()} className="flex w-full flex-col md:flex-row gap-3">
					<div className="w-full">
						<p className="font-bold">Template:</p>
						<p className="text-sm">Select template to modify included sections.</p>
						<select
							id="templateNames"
							value={selectedTemplate}
							className="border  border-2 border-black rounded-lg p-1 w-full mt-2"
							onChange={changeTemplateSelection}>
							<option value=""></option>
							{selectedTemplates && selectedTemplates.length > 0
								? selectedTemplates.map((mapData, index) => {
										return (
											<option value={mapData} key={index}>
												{mapData}
											</option>
										);
								  })
								: null}
						</select>
					</div>
					<div className="w-full">
						<p className="font-bold">Sections:</p>
						<p className="text-sm">Click on sections to include or exclude them.</p>
						<div className="w-full border-2 border-black rounded-lg max-h-[33vh] h-[33vh] overflow-y-scroll mt-2 bg-white">
							{sections &&
							sections.length > 0 &&
							dataToSave &&
							templateNames.includes(selectedTemplate) &&
							typeof dataToSave.templateData[selectedTemplate] !== "undefined" ? (
								<div className="p-4 flex items-center gap-2 border-b border-black select-none cursor-pointer" onClick={toggleSelectAllSections}>
									<input type="checkbox" className="border border-black" readOnly checked={selectAllConst ? 1 : 0} />

									<p>Select All</p>
								</div>
							) : null}

							{sections &&
							sections.length > 0 &&
							dataToSave &&
							templateNames.includes(selectedTemplate) &&
							typeof dataToSave.templateData[selectedTemplate] !== "undefined" ? (
								sections.map((mapData, index) => {
									return dataToSave &&
										Object.keys(dataToSave.templateData).includes(selectedTemplate) &&
										dataToSave.templateData[selectedTemplate] &&
										dataToSave.templateData[selectedTemplate]["includedSections"].map((mapData) => mapData.name).includes(mapData) &&
										dataToSave.templateData[selectedTemplate]["includedSections"][
											dataToSave.templateData[selectedTemplate]["includedSections"].map((mapData) => mapData.name).indexOf(mapData)
										].deleted === false ? (
										<div
											key={index}
											className={` cursor-pointer select-none  ${index !== 0 ? "  border-t border-black" : ""}`}
											onClick={(e) => removeSection(e, mapData)}>
											<div className="p-4 flex items-center gap-2">
												<input type="checkbox" className="border border-black" readOnly checked={true} value={mapData} />
												<p>{mapData}</p>
											</div>
										</div>
									) : (
										<div
											key={index}
											className={` cursor-pointer select-none ${index !== 0 ? "  border-t border-black" : ""}`}
											onClick={(e) => addSection(e, mapData)}>
											<div className="p-4 flex items-center gap-2">
												<input type="checkbox" className="border border-black" readOnly checked={false} value={mapData} />
												<p>{mapData}</p>
											</div>
										</div>
									);
								})
							) : (
								<p className="text-center p-4">Select a template to get started</p>
							)}
						</div>
					</div>
				</form>
			</div>
			<FieldExclusionList
				api={api}
				token={token}
				reportId={reportId}
				dataToSave={dataToSave}
				setDataToSave={setDataToSave}
				sections={
					sections &&
					sections.length > 0 &&
					selectedTemplate !== "" &&
					dataToSave &&
					templateNames.includes(selectedTemplate) &&
					typeof dataToSave.templateData[selectedTemplate] !== "undefined"
						? sections.filter(
								(filterData) =>
									dataToSave.templateData[selectedTemplate]["includedSections"].map((mapData) => mapData.name).includes(filterData) &&
									dataToSave.templateData[selectedTemplate]["includedSections"][
										dataToSave.templateData[selectedTemplate]["includedSections"].map((mapData) => mapData.name).indexOf(filterData)
									].deleted === false
						  )
						: []
				}
				templateData={templateData}
				selectedTemplate={selectedTemplate}
			/>
		</>
	);
};

export default SectionExclusionList;
