import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsExclamationCircleFill } from "react-icons/bs";

const TemplateModal = (props) => {
	const [templateName, setTemplateName] = useState("");
	const [templateType, setTemplateType] = useState("");
	const [selectedModel, setSelectedModel] = useState("");
	const [selectedPortfolio, setSelectedPortfolio] = useState("all");
	const [isArchived, setIsArchived] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
	const navigate = useNavigate();

	const getTemplateIdByName = (typeName) => {
		let templateTypeId = -1;
		for (let i = 0; i < props.templateTypes.length; i++) {
			if (props.templateTypes[i].name === templateType) {
				templateTypeId = props.templateTypes[i].id;
				break;
			}
		}
		templateTypeId = templateTypeId === -1 ? 1 : templateTypeId;
		return templateTypeId;
	};

	const postTemplate = () => {
		if (props.api === "" || props.token === "") {
			return;
		}
		let templateTypeId = getTemplateIdByName(templateType);
		let filteredModels = props.odooModels.filter((filterData) => filterData.name === selectedModel);
		let selectedModelIsValid = filteredModels.length > 0;
		if (!selectedModelIsValid) {
			setErrorMsg("Selected Odoo Model is not valid.");
			return;
		}
		fetch(props.api + "/template", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: props.token,
			},
			body: JSON.stringify({
				name: templateName,
				template_type: templateTypeId,
				odoo_model: filteredModels[0].model,
				portfolio_type: selectedPortfolio,
			}),
		})
			.then((res) => {
				return res.json();
			})
			.then((res) => {
				if (res) {
					if (res.errorMsg) {
						setErrorMsg(res.errorMsg);
					} else {
						navigate("/template/" + res.template_id);
						props.closeModal();
					}
				}
			});
	};

	const putTemplate = () => {
		let templateTypeId = getTemplateIdByName(templateType);

		let filteredModels = props.odooModels.filter((filterData) => filterData.name === selectedModel);
		let selectedModelIsValid = filteredModels.length > 0;
		if (!selectedModelIsValid) {
			setErrorMsg("Selected Odoo Model is not valid.");
			return;
		}
		fetch(props.api + "/template/" + props.templateId, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: props.token,
			},
			body: JSON.stringify({
				name: templateName,
				is_archived: isArchived ? 1 : 0,
				template_type: templateTypeId,
				odoo_model: filteredModels[0].model,
			}),
		})
			.then((res) => {
				if (!res.ok) {
					// put an error here
				} else {
					return res.json();
				}
			})
			.then((res) => {
				if (res) {
					props.setIsArchived(isArchived ? 1 : 0);
					props.setTemplateName(templateName);
					props.setTemplateType(templateType);
					props.closeModal();
				}
			});
	};

	useEffect(() => {
		if (props.templateName) {
			setTemplateName(props.templateName);
		}
		if (props.isArchived) {
			setIsArchived(props.isArchived);
		}
		if (props.templateType) {
			setTemplateType(props.templateType);
		}
	}, [props.isArchived, props.templateName, props.templateType]);

	const processForm = (e) => {
		e.preventDefault(e);
		if (props.isCreating) {
			postTemplate();
		} else {
			putTemplate();
		}
	};

	return (
		<div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-black/50 z-50">
			<div className="bg-white  m-2 rounded-lg">
				<p className="p-5 text-3xl">{props.isCreating ? "Create a Template" : "Update Template"}</p>
				<hr></hr>
				<form className="p-5" onSubmit={(e) => processForm(e)}>
					<label>
						Name:
						<input
							type="text"
							className="border border-rounded border-2 p-2 w-full mb-5"
							value={templateName}
							onChange={(e) => {
								setErrorMsg("");
								setTemplateName(e.target.value);
							}}
							required></input>
					</label>
					<label>
						Template Type:
						<select
							className="border border-rounded border-2 p-1 w-full mb-5"
							required
							value={templateType}
							onChange={(e) => {
								setTemplateType(e.target.value);
							}}>
							{props.templateTypes && props.templateTypes.length > 0
								? props.templateTypes.map((mapData) => {
										return (
											<option value={mapData.name} key={mapData.id}>
												{mapData.name}
											</option>
										);
								  })
								: null}
						</select>
					</label>
					{props.odooModels && props.odooModels.length > 0 ? (
						<label>
							Odoo Model:
							<input
								list="models"
								name="modelselect"
								className="border border-rounded border-2 p-1 w-full mb-5"
								required
								onChange={(e) => {
									setSelectedModel(e.target.value);
									setErrorMsg("");
								}}
							/>
							<datalist id="models">
								<option value="" />
								{props.odooModels.map((mapData, key) => (
									<option value={mapData.name} key={key}>
										{mapData.name}
									</option>
								))}
							</datalist>
						</label>
					) : null}
					<label>
						Portfolio
						<select
							className="border border-rounded border-2 p-1 w-full mb-5"
							required
							value={selectedPortfolio}
							onChange={(e) => {
								setSelectedPortfolio(e.target.value);
							}}>
							<option value="all">All</option>
							<option value="ps">Public Storage</option>
							<option value="ess">Extra Space Storage</option>
							<option value="misc">Misc.</option>
						</select>
					</label>
					{props.isCreating ? null : (
						<label>
							Archived? <input type="checkbox" checked={isArchived} onChange={(e) => setIsArchived(e.target.checked)} />
						</label>
					)}
					<div className="flex gap-2 justify-end">
						<button type="button" className="rounded-none bg-gray-500 p-2 text-white" onClick={() => props.closeModal()}>
							Cancel
						</button>
						<button type="submit" className="rounded-none bg-blue-500 p-2 text-white">
							{props.isCreating ? "Create" : "Update"}
						</button>
					</div>
					{errorMsg && errorMsg !== "" ? (
						<div className="flex items-center gap-2 bg-rose-100 p-2 mt-2 rounded-lg">
							<BsExclamationCircleFill color="red" className="text-3xl" />
							<p>{errorMsg}</p>
						</div>
					) : null}
				</form>
			</div>
		</div>
	);
};

export default TemplateModal;
