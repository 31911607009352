const ColorPicker = ({ name, values, value, onChange, productId }) => (
	<div className="mt-1 flex flex-col">
		<div className="flex items-center">
			<label className="font-bold text-lg mb-1">{name}</label>
			<div className="flex-grow border-b border-gray-400 ml-2"></div>
		</div>
		<div className="flex gap-2 flex-wrap">
			{values.map((val) => (
				<div key={val.id} className="inline-block">
					<input
						type="radio"
						id={`${name}-${val.id}-${productId}`}
						name={name + "-" + productId}
						value={val.id}
						checked={value === val.id}
						className="hidden"
						onChange={onChange}
					/>
					<label
						htmlFor={`${name}-${val.id}-${productId}`}
						className={`w-[30px] h-[30px] inline-block cursor-pointer rounded-full border border-black ${
							value.toString() === val.id.toString() ? "ring-4 ring-offset-2 ring-green-500" : ""
						}`}
						style={{
							backgroundColor: val.html_color ? val.html_color : val.name.toLowerCase(),
						}}
					/>
				</div>
			))}
		</div>
	</div>
);

export default ColorPicker;
