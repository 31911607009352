import { useEffect, useState } from "react";

const ExportManyModal = ({ projects, templates, setSelectedProjects, setShowAddProjectModal, selectedProjects }) => {
	const [currentProjects, setCurrentProjects] = useState([]);
	const [projectFilter, setProjectFilter] = useState("");
	const [templateFilter, setTemplateFilter] = useState("");

	useEffect(() => {
		if (!projects) {
			return;
		}
		if (projectFilter === "" && templateFilter === "") {
			setCurrentProjects([...projects]);
		} else {
			let arrayCopy = [...projects];
			arrayCopy = arrayCopy.filter((projectData) => {
				let templateNameWithVersion = `${projectData.template_name.toLowerCase()} - v${projectData.template_version}`;
				return (
					projectData.project_name.toLowerCase().includes(projectFilter.toLowerCase()) &&
					templateNameWithVersion.includes(templateFilter.toLowerCase())
				);
			});
			setCurrentProjects([...arrayCopy]);
		}
	}, [projectFilter, projects, templateFilter]);

	useEffect(() => {
		if (projects) {
			setCurrentProjects([...projects]);
		}
	}, [projects]);

	const addProject = (e, addedProject) => {
		if (selectedProjects.includes(addedProject) === false) {
			setSelectedProjects((prev) => [...prev, addedProject]);
		}
	};

	const addAllCurrentFilteredProjects = () => {
		for (let i = 0; i < currentProjects.length; i++) {
			if (selectedProjects.includes(currentProjects[i]) === false) {
				setSelectedProjects((prev) => [...prev, currentProjects[i]]);
			}
		}
	};

	return (
		<div className="fixed top-0 left-0 w-full h-screen flex justify-center items-center bg-black/50 z-30" onClick={(e) => setShowAddProjectModal(false)}>
			<div className="bg-white m-2 rounded-lg w-full md:w-auto" onClick={(e) => e.stopPropagation()}>
				<div className="border border-black border-2 rounded-lg w-full md:basis-1/4 ">
					<div className="flex justify-between bg-gray-300 rounded-t-lg p-4 items-center">
						<p className="text-2xl font-bold text-center ">Projects</p>
					</div>
					<hr className="border-black" />
					<form onSubmit={(e) => e.preventDefault()} className="p-4 flex flex-col gap-4">
						<label>
							Name Filter
							<input
								type="text"
								value={projectFilter}
								onChange={(e) => setProjectFilter(e.target.value)}
								className="border border-1 p-2 border-black w-full "
							/>
						</label>{" "}
						<label>
							Template Filter
							<input
								type="text"
								value={templateFilter}
								onChange={(e) => setTemplateFilter(e.target.value)}
								className="border border-1 p-2 border-black w-full "
							/>
						</label>
						<div className="max-h-[40vh] overflow-y-scroll border border-1  border-black flex flex-col">
							{currentProjects && currentProjects.length > 0 ? (
								currentProjects.map((mapData, index) => {
									return (
										<div key={mapData.project_id} className="select-none" onClick={(e) => addProject(e, mapData)}>
											<div className="cursor-pointer p-2">
												<p className=" font-bold">{mapData.project_name}</p>
												<p>
													{mapData.template_name} - v{mapData.template_version}
												</p>
											</div>
											{index !== currentProjects.length - 1 ? <hr className="border-black" /> : null}
										</div>
									);
								})
							) : (
								<p className="p-2">No projects found</p>
							)}
						</div>
						<div className="flex gap-4 justify-center">
							<button type="button" className="rounded bg-blue-600 p-2 text-white" onClick={() => addAllCurrentFilteredProjects()}>
								Add All
							</button>
							<button type="button" className="rounded bg-gray-500 p-2 text-white" onClick={() => setShowAddProjectModal(false)}>
								Close
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default ExportManyModal;
