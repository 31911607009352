import { BiCommentAdd, BiDownArrow, BiUpArrow } from "react-icons/bi";
import { FiEdit3 } from "react-icons/fi";
import { BsTrash } from "react-icons/bs";
import { useEffect, useState } from "react";
import YesNoPrompt from "../../utility/YesNoPrompt";
import FieldModal from "./FieldModal";
import { AiFillStar, AiFillLock, AiOutlineInfoCircle } from "react-icons/ai";
import { IoChatbubbleOutline, IoChatbubblesOutline } from "react-icons/io5";
import { BiCommentCheck } from "react-icons/bi";
import { MdOutlineSchema } from "react-icons/md";
import { BsFillCameraFill, BsFolderCheck } from "react-icons/bs";
import { CgShapeCircle } from "react-icons/cg";
import FieldInfoModal from "./FieldInfoModal";

const Field = (props) => {
	const [showDeletePrompt, setShowDeletePrompt] = useState(false);
	const [showUpdateModal, setShowUpdateModal] = useState(false);
	const [showFieldInfoModal, setShowFieldInfoModal] = useState(false);

	const deleteField = () => {
		if (props.api === "" || props.token === "") {
			return;
		}
		fetch(props.api + "/field/" + props.values.id, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: props.token,
			},
		}).then((res) => {
			if (!res.ok) {
				// put an error here
			} else {
				props.setFields(props.fields.filter((current_field) => current_field.id !== props.values.id));
			}
		});
	};

	const moveFieldUp = async (indexToSwitch) => {
		let destination = indexToSwitch - 1;
		if (destination < 0) {
			return;
		}
		let arrayCopy = [...props.fields];
		let swappedPosition = arrayCopy[destination].position;
		await props.updateFieldInDB(arrayCopy[destination], arrayCopy[indexToSwitch].position);
		await props.updateFieldInDB(arrayCopy[indexToSwitch], arrayCopy[destination].position);
		arrayCopy[destination].position = arrayCopy[indexToSwitch].position;
		arrayCopy[indexToSwitch].position = swappedPosition;

		arrayCopy = arrayCopy.sort((a, b) => a.position - b.position);
		props.setFields(arrayCopy);
	};

	const moveFieldDown = async (indexToSwitch) => {
		let destination = indexToSwitch + 1;
		if (destination >= props.fields.length) {
			return;
		}
		let arrayCopy = [...props.fields];
		let swappedPosition = arrayCopy[destination].position;
		await props.updateFieldInDB(arrayCopy[destination], arrayCopy[indexToSwitch].position);
		await props.updateFieldInDB(arrayCopy[indexToSwitch], swappedPosition);
		arrayCopy[destination].position = arrayCopy[indexToSwitch].position;
		arrayCopy[indexToSwitch].position = swappedPosition;

		arrayCopy = arrayCopy.sort((a, b) => a.position - b.position);
		props.setFields(arrayCopy);
	};

	return (
		<div className="p-4 flex flex-col gap-2 md:flex-row justify-between md:items-center w-full hover:bg-gray-100 border border-black">
			{showFieldInfoModal ? <FieldInfoModal fields={props.fields} setShowFieldInfoModal={setShowFieldInfoModal} fieldData={props.values} /> : null}
			{showUpdateModal ? (
				<FieldModal
					fieldId={props.values.id}
					setFields={props.setFields}
					fieldTypes={props.fieldTypes}
					fieldWordBank={props.fieldWordBank}
					isCreatingField={false}
					api={props.api}
					token={props.token}
					fields={props.fields}
					setShowCreateFieldModal={setShowUpdateModal}
					fieldValues={props.values}
				/>
			) : null}
			{showDeletePrompt ? (
				<YesNoPrompt type="field" name={props.values.name} deleteAction={() => deleteField()} cancelAction={() => setShowDeletePrompt(false)} />
			) : null}
			<p className="basis-1/4">{props.values.name}</p>
			<p className="basis-1/4">{props.values.field_type}</p>
			<div className="basis-1/4 flex gap-3 text-xl ">
				<AiFillStar color={props.values.required ? "black" : "lightGray"} />
				<AiFillLock color={props.values.read_only ? "black" : "lightGray"} />
				<BsFillCameraFill color={props.values.allow_photos || props.values.field_type === "photos" ? "black" : "lightGray"} />
				<BsFolderCheck color={props.values.require_photos && props.values.require_photos !== "" ? "black" : "lightGray"} />
				<IoChatbubbleOutline color={props.values.allow_comment ? "black" : "lightGray"} />
				<BiCommentAdd color={props.values.require_comment ? "black" : "lightGray"} />
				<BiCommentCheck color={props.values.comment_has_validation ? "black" : "lightGray"} />
				<MdOutlineSchema color={props.values.conditional_list && props.values.conditional_list !== "" ? "black" : "lightGray"} />
				<CgShapeCircle color={props.values.odoo_mapping && props.values.odoo_mapping !== "" ? "purple" : "lightGray"} />
			</div>
			{props.isDrafting ? (
				<div className="flex flex-row-reverse md:flex-row md:justify-center items-center text-xl gap-4">
					<BsTrash color="red" className="cursor-pointer" onClick={() => setShowDeletePrompt(true)} />
					<FiEdit3 className="cursor-pointer" onClick={() => setShowUpdateModal(true)} />
					<BiDownArrow className="cursor-pointer select-none" onClick={() => moveFieldDown(parseInt(props.arrayIndex))} />
					<BiUpArrow className="cursor-pointer select-none" onClick={() => moveFieldUp(parseInt(props.arrayIndex))} />
				</div>
			) : (
				<div className="flex flex-row-reverse md:flex-row md:justify-center items-center text-xl gap-4">
					<AiOutlineInfoCircle className="cursor-pointer select-none" onClick={() => setShowFieldInfoModal(true)} />
				</div>
			)}
		</div>
	);
};

export default Field;
