import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

const TemplateDesktopFilter = ({
	showArchived,
	setShowArchived,
	odooModelToFilterBy,
	setOdooModelToFilterBy,
	odooModels,
	selectedTemplateType,
	setSelectedTemplateType,
	portfolioType,
	setPortfolioType,
}) => {
	return (
		<div className="border border-black p-4 rounded-lg select-none w-full  ">
			<div className="mb-4">
				<div className="text-xl">
					<FontAwesomeIcon icon={faFilter} size="sm" /> Filter
				</div>
			</div>
			<div className="grid grid-cols-2 md:grid-cols-4 gap-3 lg:gap-10">
				<div className="">
					<p>Status</p>
					<hr className="mb-2" />
					<div className="flex flex-col gap-1">
						<p className={`${showArchived.toString() === "1" ? "font-bold" : " "} `}>
							<span className="cursor-pointer hover:underline" onClick={(e) => setShowArchived(1)}>
								Archived
							</span>
						</p>
						<p className={`${showArchived.toString() === "0" ? "font-bold" : ""} cursor-pointer`}>
							<span className="cursor-pointer hover:underline" onClick={(e) => setShowArchived(0)}>
								{" "}
								Not Archived
							</span>
						</p>
					</div>
				</div>
				<div>
					<p>Type</p>
					<hr className="mb-2" />
					<div className="flex flex-col gap-1">
						<p>
							<span
								className={`${selectedTemplateType === "all" ? "font-bold" : ""} cursor-pointer hover:underline`}
								onClick={() => setSelectedTemplateType("all")}>
								All
							</span>
						</p>
						<p>
							<span
								className={`${selectedTemplateType.toString() === "1" ? "font-bold" : ""} cursor-pointer hover:underline`}
								onClick={() => setSelectedTemplateType(1)}>
								Residential
							</span>
						</p>
						<p>
							<span
								className={`${selectedTemplateType.toString() === "2" ? "font-bold" : ""} cursor-pointer hover:underline`}
								onClick={() => setSelectedTemplateType(2)}>
								Commercial
							</span>
						</p>
					</div>
				</div>
				<div>
					<p>Model</p>
					<hr className="mb-2" />
					<div className="flex flex-col gap-1">
						<p>
							<span
								className={`cursor-pointer ${odooModelToFilterBy === "all" ? "font-bold" : ""} hover:underline`}
								onClick={() => setOdooModelToFilterBy("all")}>
								All
							</span>
						</p>
						{odooModels && odooModels.length > 0
							? odooModels.map((mapData, key) => {
									return (
										<p key={key}>
											<span
												className={`cursor-pointer ${odooModelToFilterBy === mapData.model ? "font-bold" : ""} hover:underline`}
												onClick={() => setOdooModelToFilterBy(mapData.model)}>
												{mapData.name === "Task" ? "Installations" : mapData.name}
											</span>
										</p>
									);
							  })
							: null}
					</div>
				</div>
				<div>
					<p>Portfolio</p>
					<hr className="mb-2" />
					<div className="flex flex-col gap-1">
						<p>
							<span
								className={`${portfolioType === "all" ? "font-bold" : ""} cursor-pointer hover:underline`}
								onClick={() => setPortfolioType("all")}>
								All
							</span>
						</p>
						<p>
							<span
								className={`${portfolioType === "ps" ? "font-bold" : ""} cursor-pointer hover:underline`}
								onClick={() => setPortfolioType("ps")}>
								Public Storage
							</span>
						</p>
						<p>
							<span
								className={`${portfolioType === "ess" ? "font-bold" : ""} cursor-pointer hover:underline`}
								onClick={() => setPortfolioType("ess")}>
								Extra Space Storage
							</span>
						</p>
						<p>
							<span
								className={`${portfolioType === "misc" ? "font-bold" : ""} cursor-pointer hover:underline`}
								onClick={() => setPortfolioType("misc")}>
								Misc.
							</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TemplateDesktopFilter;
