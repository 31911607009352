import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingCircle from "./utility/LoadingCircle";
import { useAuth0 } from "@auth0/auth0-react";

const Login = ({}) => {
	let navigate = useNavigate();
	let location = useLocation();
	const { loginWithRedirect, isAuthenticated, user, isLoading } = useAuth0();

	useEffect(() => {
		if (!isLoading) {
			if (!isAuthenticated) {
				loginWithRedirect();
			} else {
				if (location && location.state && location.state.newPath) {
					let newPath = location.state.newPath;
					if (newPath && newPath !== "") {
						navigate(newPath + location.state.newSearch);
					} else {
						navigate("/dashboard");
					}
				} else {
					navigate("/dashboard");
				}
			}
		}
	}, [isAuthenticated, isLoading, location, loginWithRedirect, navigate, user]);

	useEffect(() => {
		document.title = "Login";
	}, []);

	return (
		<div className="fixed top-0 left-0 right-0 bottom-0 bg-black">
			<div className="flex-col flex w-full h-full justify-center items-center">
				<LoadingCircle />
				<div className="my-4 text-white">
					<p>If login verification is taking longer than usual, please try refreshing the page or pressing the refresh button.</p>
				</div>
				<button
					style={{ backgroundColor: "#93C840" }}
					onClick={() => window.location.reload()}
					className=" px-4 py-2 rounded shadow hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50">
					Refresh
				</button>
			</div>
		</div>
	);
};

export default Login;
