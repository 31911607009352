import { Link, useNavigate } from "react-router-dom";
import { BsCalendar3, BsSearch, BsCalendarPlusFill } from "react-icons/bs";
import { useEffect, useState, useCallback, useRef } from "react";
import DownloadModal from "./utility/DownloadModal";
import pdfToDownload from "../assets/placeholder.pdf";
import FileSaver from "file-saver";
import ProjectList from "./project/ProjectList";
import RequestStatus from "./request/RequestStatus";
import ScheduledProjectCard from "./dashboard/ScheduledProjectCard";
import { useAuth0 } from "@auth0/auth0-react";
import { FaArrowLeft, FaArrowRight, FaInfoCircle } from "react-icons/fa";
import LoadingCircle from "./utility/LoadingCircle";
import TimeSinceDate from "./Clocking/TimeSinceDate";
import ClockOutButton from "./Clocking/ClockOutButton";
import ClockInButton from "./Clocking/ClockInButton";
import LateClockOutBanner from "./Clocking/LateClockoutBanner";
import NearingLateClockoutBanner from "./Clocking/NearingLateClockoutBanner";

const DAYS = ["Sun", "Mon", "Tu", "Wed", "Th", "Fri", "Sat"];

const Dashboard = ({ api, wordBankAccess, token, isAdmin, templateAccess, requestAccess, isPublicStorage }) => {
	const navigate = useNavigate();
	const [searchQuery, setSearchQuery] = useState("");
	const [projects, setProjects] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [hasDownloadedPdf, setHasDownloadedPdf] = useState(true);
	const [requests, setRequests] = useState([]);
	const [odooModels, setOdooModels] = useState([]);
	const [odooModelToFilterBy, setOdooModelToFilterBy] = useState("project.task");
	const { user, isLoading: authIsLoading } = useAuth0();
	const [tasksByDate, setTasksByDate] = useState({});
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [fetchingSchedule, setFetchingSchedule] = useState(false);
	const dateInputRef = useRef(null);
	const [collectedDaysStart, setCollectedDaysStart] = useState(new Date());
	const [collectedDaysEnd, setCollectedDaysEnd] = useState(new Date());
	const [clockingData, setClockingData] = useState(null);
	const [clockBannerToShow, setClockBannerToShow] = useState("");
	const [lateClockedOutEmployees, setLateClockedOutEmployees] = useState([]);
	const [loadingClock, setLoadingClock] = useState(false);
	const [clockingNotes, setClockingNotes] = useState([]);

	useEffect(() => {
		document.title = "Dashboard";
	}, []);

	function formatDateKey(date) {
		return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
	}

	useEffect(() => {
		if (api === "" || token === "") {
			return;
		}

		const controller = new AbortController();
		const signal = controller.signal;
		setIsLoading(true);
		fetch(api + "/dashboard", {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			mode: "cors",
			signal,
		})
			.then((res) => {
				setIsLoading(false);
				if (res.ok) {
					return res.json();
				} else {
				}
			})
			.then((res) => {
				if (res && typeof res.projects != "undefined") {
					setProjects(res.projects);
					setHasDownloadedPdf(res.downloadedPdf);
					setRequests(res.requests);
					setOdooModels(res.odooData);
					setSelectedDate(new Date());

					if (user["https://claim.krummycapture/claim"]["is_employee"]) {
						let unfilteredScheduledProjects = [...res.scheduledProjects];
						let dateBuckets = {};

						const today = new Date();
						const startOfWeek = new Date(today);
						startOfWeek.setDate(today.getDate() - today.getUTCDay());
						const endOfWeek = new Date(startOfWeek);
						endOfWeek.setDate(endOfWeek.getDate() + 6);
						setCollectedDaysStart(startOfWeek);
						setCollectedDaysEnd(endOfWeek);

						for (let i = 0; i < 7; i++) {
							const currentDate = new Date(startOfWeek);
							currentDate.setDate(startOfWeek.getDate() + i);

							const dateKey = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, "0")}-${String(
								currentDate.getDate()
							).padStart(2, "0")}`;
							dateBuckets[dateKey] = [];
						}

						unfilteredScheduledProjects
							.map((mapData) => {
								const utcStartDate = mapData.start_datetime;
								const localStartDate = new Date(utcStartDate + "Z");

								const utcEndDate = mapData.end_datetime;
								const localEndDate = new Date(utcEndDate + "Z");

								return {
									...mapData,
									start_datetime: localStartDate,
									end_datetime: localEndDate,
								};
							})
							.forEach((project) => {
								for (let d = new Date(project.start_datetime); d <= project.end_datetime; d.setDate(d.getDate() + 1)) {
									const projectDayKey = `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, "0")}-${String(d.getDate()).padStart(
										2,
										"0"
									)}`;
									if (!dateBuckets[projectDayKey]) {
										dateBuckets[projectDayKey] = [];
									}
									const isDuplicate = dateBuckets[projectDayKey].some((existingProject) => existingProject.id === project.id);

									if (!isDuplicate) {
										dateBuckets[projectDayKey].push(project);
									}
								}
							});

						for (let date in dateBuckets) {
							dateBuckets[date].sort((a, b) => a.start_datetime - b.start_datetime);
						}
						setTasksByDate(dateBuckets);
					}
				}
			})
			.catch((err) => {});

		return () => {
			controller.abort();
		};
	}, [api, token, user]);

	useEffect(() => {
		if (api === "" || token === "") {
			return;
		}
		const currentDate = new Date();

		const year = currentDate.getFullYear();
		const month = String(currentDate.getMonth() + 1).padStart(2, "0");
		const day = String(currentDate.getDate()).padStart(2, "0");
		const formattedDate = `${year}-${month}-${day}`;

		const controller = new AbortController();
		const signal = controller.signal;
		setLoadingClock(true);
		fetch(api + "/dashboard/clocking?date=" + formattedDate, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			mode: "cors",
			signal,
		})
			.then((res) => {
				setLoadingClock(false);
				if (res.ok) {
					return res.json();
				} else {
				}
			})
			.then((res) => {
				if (res) {
					setClockingData({
						clockingInfo: res.clockingInfo,
						specialCode: res.specialCode,
						lastClocked: res.lastClocked ? new Date(res.lastClocked) : "",
					});
					setLateClockedOutEmployees(res.lateInfo);
					setClockingNotes(res.notes);
				}
			})
			.catch((err) => {});

		return () => {
			controller.abort();
		};
	}, [api, token, user]);

	function processFetchedData(fetchedData, dateInFetchedWeek) {
		const startOfWeek = new Date(dateInFetchedWeek);
		startOfWeek.setHours(0, 0, 0, 0);
		startOfWeek.setDate(dateInFetchedWeek.getDate() - dateInFetchedWeek.getUTCDay());
		const endOfWeek = new Date(startOfWeek);
		endOfWeek.setDate(endOfWeek.getDate() + 6);
		endOfWeek.setHours(23, 59, 59, 999);
		if (startOfWeek < collectedDaysStart) {
			setCollectedDaysStart(startOfWeek);
		}
		if (endOfWeek > collectedDaysEnd) {
			setCollectedDaysEnd(endOfWeek);
		}

		let newTasksByDate = { ...tasksByDate };

		for (let i = 0; i < 7; i++) {
			const currentDate = new Date(startOfWeek);
			currentDate.setDate(startOfWeek.getDate() + i);

			const dateKey = formatDateKey(currentDate);
			if (!newTasksByDate[dateKey]) {
				newTasksByDate[dateKey] = [];
			}
		}

		fetchedData.forEach((task) => {
			const taskStartDate = new Date(task.start_datetime);
			const taskEndDate = task.end_datetime ? new Date(task.end_datetime) : new Date(task.start_datetime);
			taskEndDate.setHours(23, 59, 59, 999); // Set to end of the day for end date

			for (let d = new Date(taskStartDate); d <= taskEndDate; d.setDate(d.getDate() + 1)) {
				const dateKey = formatDateKey(d);

				if (!newTasksByDate[dateKey]) {
					newTasksByDate[dateKey] = [];
				}

				const isDuplicate = newTasksByDate[dateKey].some((existingTask) => existingTask.id === task.id);
				if (!isDuplicate) {
					newTasksByDate[dateKey].push(task);
				}
			}
		});
		setTasksByDate(newTasksByDate);
	}

	const startSearch = (e) => {
		e.preventDefault();

		const uriEncodedString = encodeURIComponent(encodeURIComponent(searchQuery));
		navigate(`/projects/search?query=${uriEncodedString === "" ? " " : uriEncodedString}&page=1&contract=&stage=&template=&model=${odooModelToFilterBy}`);
	};

	const userDownloadedPdf = useCallback(
		async (e) => {
			e.preventDefault();

			await fetch(api + "/dashboard/pdf", {
				method: "POST",
				headers: {
					Authorization: token,
				},
				mode: "cors",
			}).then((res) => {
				if (res.ok) {
					setHasDownloadedPdf(true);
					//await new Promise((r) => setTimeout(r, 200));
					FileSaver.saveAs(pdfToDownload, "template.pdf");
				}
			});
		},
		[api, token]
	);

	const fetchNewWeekData = (targetDate) => {
		if (fetchingSchedule) {
			return;
		}
		setFetchingSchedule(true);
		fetch(api + "/dashboard/calendar", {
			method: "POST",
			headers: {
				Authorization: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ selectedDate: targetDate }),
			mode: "cors",
		})
			.then((res) => {
				setFetchingSchedule(false);
				if (res.ok) {
					return res.json();
				}
			})
			.then((data) => {
				if (data) {
					processFetchedData(data.projects, targetDate);
				}
			});
		// need an is loading variable here to denote if getting data
	};

	function goBackOneDay() {
		setSelectedDate((prevDate) => {
			const newDate = new Date(prevDate);
			newDate.setDate(prevDate.getDate() - 1);
			if (newDate < collectedDaysStart) {
				fetchNewWeekData(newDate);
			}
			return newDate;
		});
	}

	function goForwardOneDay() {
		setSelectedDate((prevDate) => {
			const newDate = new Date(prevDate);
			newDate.setDate(prevDate.getDate() + 1);
			newDate.setHours(0, 0, 0, 0);
			if (collectedDaysEnd < newDate) {
				fetchNewWeekData(newDate);
			}
			return newDate;
		});
	}

	const changeDateViaInput = (e) => {
		e.preventDefault();
		const [year, month, day] = e.target.value.split("-").map(Number);
		let newSelectedDate = new Date(year, month - 1, day);
		setSelectedDate(newSelectedDate);
		if (newSelectedDate > collectedDaysEnd || newSelectedDate < collectedDaysStart) {
			fetchNewWeekData(newSelectedDate);
		}
	};

	const removeAutonums = (s) => {
		const regex = /(-\s?\d+)+$/;
		return s.replace(regex, "");
	};

	return (
		<>
			{clockBannerToShow === "warning" ? <NearingLateClockoutBanner /> : null}

			{clockBannerToShow === "over" ? <LateClockOutBanner /> : null}

			<div className="py-4 flex flex-col justify-center items-center">
				{lateClockedOutEmployees && lateClockedOutEmployees.length > 0 ? (
					<div className="w-full md:w-4/5 lg:w-3/5  mb-4 border border-black rounded-lg">
						<div className="flex gap-4 items-center p-2 text-lg">
							<FaInfoCircle size={30} />
							<p>
								{`Your subordinates have worked 6 or more hours in a row without a break ${lateClockedOutEmployees.length} times in the last 14 days.`}
								<br />
								<Link to="/attendance" className="text-blue-500 hover:text-blue-700 underline">
									view them
								</Link>
							</p>
						</div>
					</div>
				) : null}
				{clockingNotes && clockingNotes.length > 0 ? (
					<div className="w-full md:w-4/5 lg:w-3/5  mb-4 border border-black rounded-lg">
						<div className="flex gap-4 items-center p-2 text-lg">
							<FaInfoCircle size={30} />
							<p>
								{`Your subordinates left ${clockingNotes.length} notes in the last 14 days.`}
								<br />
								<Link to="/attendance" className="text-blue-500 hover:text-blue-700 underline">
									view them
								</Link>
							</p>
						</div>
					</div>
				) : null}
				{clockingData && clockingData.lastClocked && clockingData.clockingInfo && !isLoading ? (
					<div className="w-full md:w-4/5 lg:w-3/5  mb-4 border border-black rounded-lg ">
						<div className="bg-gray-300 p-4 rounded-t-lg border border-b-black">
							<p className="text-center text-2xl">My Time</p>
						</div>
						<div className="p-4 flex flex-col justify-center items-center">
							{clockingData.clockingInfo && clockingData.clockingInfo.name ? (
								<p className="text-xl mb-3">{removeAutonums(clockingData.clockingInfo.name)}</p>
							) : null}

							<div>
								{clockingData.specialCode === "clock-out" ? (
									<ClockOutButton
										api={api}
										token={token}
										odooId={clockingData.clockingInfo.allInstallations.parent.id}
										odooModel={"project.task"}
										projectsRoute=""
										clockingData={clockingData}
										setClockingData={setClockingData}
										clockedIntoThis={true}
										showClock={true}
										clockBannerToShow={clockBannerToShow}
										setClockBannerToShow={setClockBannerToShow}
									/>
								) : null}
								{clockingData.specialCode === "clock-in" || clockingData.specialCode === "meal-in" ? (
									<ClockInButton
										api={api}
										token={token}
										odooId={clockingData.clockingInfo.allInstallations.parent.id}
										odooModel={"project.task"}
										projectsRoute=""
										clockingData={clockingData}
										setClockingData={setClockingData}
										clockedIntoThis={true}
										showClock={true}
										clockBannerToShow={clockBannerToShow}
										setClockBannerToShow={setClockBannerToShow}
									/>
								) : null}
							</div>
						</div>
					</div>
				) : null}
				{hasDownloadedPdf === false ? (
					<DownloadModal
						toDownload={pdfToDownload}
						downloadText="Click here to download"
						messages={
							<>
								<p>Download the templates to get started.</p>
								<p>
									{"("}This is a one-time message{")"}
								</p>
							</>
						}
						onDownloadAction={(e) => userDownloadedPdf(e)}
					/>
				) : null}
				<div className="flex flex-col w-full md:w-4/5 lg:w-3/5 justify-between border border-lg border-black rounded-lg">
					<div className=" bg-gray-300 flex flex-col gap-4 md:flex-row justify-between items-center rounded-t-lg p-4">
						<div className="basis-1/3 flex flex-col  ">
							<div className="flex items-center gap-1">
								<button className="text-white bg-black p-2" onClick={goBackOneDay}>
									<FaArrowLeft color="white" size={15} />
								</button>
								<div className="flex justify-center items-center">
									<button className="">
										<BsCalendar3
											color="black"
											size={31}
											onClick={() => {
												if (dateInputRef) {
													dateInputRef.current.showPicker();
												}
											}}
										/>
									</button>
									<form>
										<input
											ref={dateInputRef}
											id="dateinput"
											type="date"
											value={formatDateKey(selectedDate)}
											onChange={changeDateViaInput}
											className=" w-0"
										/>
									</form>
								</div>
								<button className="text-white bg-black p-1" onClick={() => setSelectedDate(new Date())}>
									Today
								</button>
								<button className="bg-black p-2" onClick={goForwardOneDay}>
									<FaArrowRight color="white" size={15} />
								</button>
							</div>
						</div>
						<div className="text-center text-2xl basis-1/3">
							<p className="mb-2">Schedule</p>
							<p>{`${selectedDate.toLocaleDateString("en-US")} (${DAYS[selectedDate.getDay()]})`}</p>
						</div>
						<div className="basis-1/3 flex justify-end items-center">
							{!isLoading && clockingData ? (
								<ClockInButton
									api={api}
									token={token}
									odooId={"000000SHOP"}
									odooModel={"project.task"}
									projectsRoute=""
									clockingData={clockingData}
									setClockingData={setClockingData}
									clockedIntoThis={true}
									showClock={true}
									clockBannerToShow={clockBannerToShow}
									setClockBannerToShow={setClockBannerToShow}
								/>
							) : null}
						</div>
					</div>
					<div className="flex flex-col w-full justify-between">
						{selectedDate &&
						Object.keys(tasksByDate).includes(formatDateKey(selectedDate)) &&
						tasksByDate[formatDateKey(selectedDate)].length > 0 &&
						isLoading === false &&
						fetchingSchedule === false ? (
							tasksByDate[formatDateKey(selectedDate)].map((mapData, index) => (
								<ScheduledProjectCard
									project={mapData}
									key={mapData.id}
									isEven={index % 2 === 0}
									isLast={index === tasksByDate[formatDateKey(selectedDate)].length - 1}
									api={api}
									token={token}
									clockingData={clockingData}
									setClockingData={setClockingData}
								/>
							))
						) : isLoading || fetchingSchedule || authIsLoading || loadingClock ? (
							<div className="p-4 flex flex-col items-center justify-center border border-transparent border-t-black">
								<LoadingCircle />
								<p>Downloading Schedule...</p>
							</div>
						) : (
							<div className="p-4 border border-transparent border-t-black">
								<p className="text-center">Nothing Scheduled</p>
							</div>
						)}
					</div>
				</div>
				<form onSubmit={(e) => startSearch(e)} className="mt-4 mb-4 flex w-full justify-center items-center md:w-4/5 lg:w-3/5">
					<input
						type="text"
						className="border border-black p-2 w-full rounded-l-lg"
						placeholder="Search for a project..."
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
					/>
					{odooModels && odooModels.length > 0 && isPublicStorage === false ? (
						<label htmlFor="usedOdooModels">
							<select
								name="usedOdooModels"
								id="usedOdooModels"
								className="border border-black p-2"
								value={odooModelToFilterBy}
								onChange={(e) => setOdooModelToFilterBy(e.target.value)}>
								{odooModels && odooModels.length > 0
									? odooModels.map((mapData, key) => {
											return (
												<option value={mapData.model} key={key}>
													{mapData.name === "Task" ? "Installations" : mapData.name}
												</option>
											);
									  })
									: null}
							</select>
						</label>
					) : null}
					<button type="submit" className="border  border-black p-3 rounded-r-lg   ">
						<BsSearch className="" />
					</button>
				</form>
				{isPublicStorage ? (
					<div className="mb-4" />
				) : requests.length > 0 ? (
					<div className="mb-4 flex flex-col w-full justify-center items-center md:w-4/5 lg:w-3/5 text-center">
						<p className="text-2xl w-full bg-red-600 text-white text-center p-3 border border-black p-4 rounded-t-lg">Requests</p>
						<div className="w-full border border-black p-2 md:flex justify-between hidden bg-black text-white gap-3">
							<p className="md:basis-1/3">Request</p>
							<p className="md:basis-1/3">Last modified on</p>
							<p className="md:basis-1/3">Status</p>
						</div>
						{requests.map((mapData, index) => {
							return (
								<div
									key={"" + mapData.id + index}
									className={
										"gap-3 w-full border border-black p-3 flex flex-col md:flex-row justify-between items-center " +
										(index % 2 === 0 ? "bg-white" : "bg-gray-200") +
										(index === requests.length - 1 ? " rounded-b-lg " : "")
									}>
									<div className="md:basis-1/3">
										<Link to={"/request/" + mapData.id} className="text-blue-600 underline ">
											{mapData.name}
										</Link>
									</div>
									<p className="md:basis-1/3">
										<span className="md:hidden">
											Last Modified On:
											<br />
										</span>
										{new Date(mapData.last_modified_on).toLocaleString("en-US")}
									</p>
									<div className="md:basis-1/3">
										<RequestStatus status={mapData.status} />
									</div>
								</div>
							);
						})}
					</div>
				) : null}
				<div className="flex flex-col md:flex-row gap-3 w-full md:w-4/5 lg:w-3/5 justify-between">
					<ProjectList
						title={"Recent Projects"}
						isLoading={isLoading || authIsLoading}
						projectList={projects}
						isFullWidth={true}
						setProjectList={setProjects}
						isPublicStorage={isPublicStorage}
					/>
				</div>
			</div>
		</>
	);
};

export default Dashboard;
