import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoadingCircle from "../utility/LoadingCircle";
import TemplateSelectionModal from "./TemplateSelectionModal";
import { PaginationNav1Presentation } from "../project/Pagination";
import { allowedOdooModels } from "../utility/FilterData";

const TemplateList = ({ templates, isLoading }) => {
	const [combinedTemplates, setCombinedTemplates] = useState([]);
	const [showTemplateSelectionModal, setShowTemplateSelectionModal] = useState(false);
	const [selectedTemplateVersions, setSelectedTemplateVersions] = useState([]);
	const [selectedTemplateName, setSelectedTemplateName] = useState("");
	const [pageCount, setPageCount] = useState(0);
	const [firstIndex, setFirstIndex] = useState(0);
	const [lastIndex, setLastIndex] = useState(0);
	const [globalPage, setGlobalPage] = useState(0);

	useEffect(() => {
		let tempCombinedTemplates = templates.map((mapData) => [...mapData.published, ...mapData.draft, ...mapData.archived]);
		let sortedTemplates = [...tempCombinedTemplates];
		for (let i = 0; i < sortedTemplates.length; i++) {
			sortedTemplates[i] = sortedTemplates[i].sort((a, b) => b.version - a.version);
		}
		setCombinedTemplates([...sortedTemplates]);
	}, [templates]);

	useEffect(() => {
		setPageCount(Math.ceil(templates.length / 10));
		setFirstIndex(globalPage * 10);
		setLastIndex(10 * (globalPage + 1) < templates.length ? 10 * (globalPage + 1) - 1 : templates.length - 1);
	}, [templates, globalPage, firstIndex, lastIndex]);

	const selectThisTemplate = (e, theTemplateId, theTemplateName) => {
		e.preventDefault();
		let templateData = [];
		for (let i = 0; i < combinedTemplates.length; i++) {
			if (combinedTemplates[i].length > 0) {
				if (combinedTemplates[i][0].template_id === theTemplateId) {
					templateData = combinedTemplates[i];
					break;
				}
			}
		}
		if (templateData.length <= 0) {
			setSelectedTemplateVersions([]);
		} else {
			setSelectedTemplateVersions(templateData);
		}
		setSelectedTemplateName(theTemplateName);
		setShowTemplateSelectionModal(true);
	};

	return (
		<>
			{showTemplateSelectionModal ? (
				<TemplateSelectionModal
					templateData={selectedTemplateVersions}
					setShowTemplateSelectionModal={setShowTemplateSelectionModal}
					templateName={selectedTemplateName}
				/>
			) : null}

			{templates.length > 0 ? (
				templates.map((value, index) => {
					if (index <= lastIndex && index >= firstIndex) {
						return (
							<div
								key={value.template.id}
								className={`w-full md:w-3/5 p-3 flex flex-col lg:flex-row justify-between border border-black border-1 ${
									index % 2 === 0 ? "bg-gray-200" : "bg-white"
								} ${index === 0 ? "rounded-t-lg" : ""} ${index === templates.length - 1 ? "rounded-b-lg" : ""}`}>
								<div className="md:basis-3/4">
									<p className="text-center lg:text-left font-bold">{value.template.name}</p>
									<p className="text-center lg:text-left">{value.template.template_type === 1 ? "Residential" : "Commercial"}</p>
									<p className="text-center lg:text-left ">
										{allowedOdooModels[value.template.odoo_model]
											? allowedOdooModels[value.template.odoo_model].label
											: value.template.odoo_model}
									</p>
									<p className="text-center lg:text-left ">{value.template.portfolio_type + " portfolios"}</p>
								</div>
								<div className="flex flex-col gap-2 justify-between md:basis-1/4">
									{value.draft && value.draft.length > 0
										? value.draft.map((mapData) => {
												return (
													<div className="flex justify-between " key={mapData.id}>
														<p>
															v{mapData.version}{" "}
															<span className="font-normal text-base p-1 bg-orange-300">{mapData.status_name}</span>
														</p>
														<p className="text-blue-600">
															<Link to={"/template/" + mapData.id}>Edit</Link>
														</p>
													</div>
												);
										  })
										: null}
									{value.published && value.published.length > 0
										? value.published.map((mapData) => {
												return (
													<div className="flex justify-between " key={mapData.id}>
														<p>
															v{mapData.version}{" "}
															<span className="font-normal text-base p-1 bg-green-300">{mapData.status_name}</span>
														</p>
														<p className="text-blue-600">
															<Link to={"/template/" + mapData.id}>View</Link>
														</p>
													</div>
												);
										  })
										: null}
									<div className="flex justify-center ">
										<p
											className="cursor-pointer text-blue-600"
											onClick={(e) => selectThisTemplate(e, value.template.id, value.template.name)}>
											View All Versions
										</p>
									</div>
								</div>
							</div>
						);
					}
				})
			) : isLoading ? (
				<div className="flex flex-col items-center flex-center gap-3 bg-white p-4">
					<LoadingCircle />
					<p>Loading...</p>
				</div>
			) : (
				<p className="text-center text-3xl">No results found</p>
			)}
			<div className="text-center">
				<span className="text-sm text-gray-700">
					Showing
					<span className="font-semibold text-gray-900"> {globalPage * 10 + 1}</span> to
					<span className="font-semibold text-gray-900">
						{" "}
						{10 * (globalPage + 1) < templates.length ? 10 * (globalPage + 1) : templates.length}
					</span>{" "}
					of
					<span className="font-semibold text-gray-900"> {templates.length}</span> Entries
				</span>

				<nav aria-label="Template Navigation">
					<ul className="inline-flex items-center -space-x-px h-16">{PaginationNav1Presentation(pageCount, "", globalPage, setGlobalPage)}</ul>
				</nav>
			</div>
		</>
	);
};

export default TemplateList;
