import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoadingCircle from "../utility/LoadingCircle";
import { BiCaretDown, BiCaretUp } from "react-icons/bi";

const NavigationArea = ({
	api,
	token,
	templateNavMappings,
	selectedOdooModel,
	odooId,
	contractType,
	portfolioType,
	parentRecord,
	childRecords,
	tickets,
	installationRecord,
	isLoading,
}) => {
	const [isParentOpen, setParentOpen] = useState(false);
	const [isChildrenOpen, setChildrenOpen] = useState(false);
	const [isTicketsOpen, setTicketsOpen] = useState(false);
	const [isInstallationOpen, setInstallationOpen] = useState(false);

	const toggleParentOpen = () => setParentOpen(!isParentOpen);
	const toggleChildrenOpen = () => setChildrenOpen(!isChildrenOpen);
	const toggleTicketsOpen = () => setTicketsOpen(!isTicketsOpen);
	const toggleInstallationOpen = () => setInstallationOpen(!isInstallationOpen);

	if ((parentRecord === false || parentRecord.length === 0) && childRecords.length === 0 && tickets.length === 0) {
		if (selectedOdooModel === "project.task") {
			return null;
		}
		if (installationRecord.length === 0 && selectedOdooModel !== "project.task") {
			return null;
		}
	}
	return (
		<div className="">
			<p className="font-bold text-xl px-4 bg-gray-300 ">Related Odoo Records</p>
			{parentRecord ? (
				<div>
					<div className="flex justify-between items-center bg-gray-300 px-4 py-1 z-10">
						<p className="font-bold ">Parent Task</p>
						<button onClick={toggleParentOpen}>{isParentOpen ? <BiCaretUp size={25} /> : <BiCaretDown size={25} />}</button>
					</div>

					{isParentOpen ? (
						<div className={`px-5 z-1`}>
							<p className="border-l-4 p-1 pl-2">
								<Link
									className="text-blue-500 hover:text-blue-700 underline"
									to={`/odoo-project/${parentRecord[0]}/template-select?model=project.task&contract=${contractType}&portfolio=${portfolioType}`}>
									{parentRecord[1]}
								</Link>
							</p>
						</div>
					) : null}
				</div>
			) : null}
			{installationRecord && selectedOdooModel !== "project.task" && installationRecord.length > 0 ? (
				<div>
					<div className="flex justify-between items-center bg-gray-300 px-4 py-1 z-10">
						<p className="font-bold ">Installation</p>
						<button onClick={toggleInstallationOpen}>{isInstallationOpen ? <BiCaretUp size={25} /> : <BiCaretDown size={25} />}</button>
					</div>

					{isInstallationOpen ? (
						<div className={`px-5 z-1`}>
							<p className="border-l-4 p-1 pl-2">
								<Link
									className="text-blue-500 hover:text-blue-700 underline"
									to={`/odoo-project/${installationRecord[0]}/template-select?model=project.task&contract=${contractType}&portfolio=${portfolioType}`}>
									{installationRecord[1]}
								</Link>
							</p>
						</div>
					) : null}
				</div>
			) : null}
			{childRecords && childRecords.length > 0 ? (
				<div>
					<div className="flex justify-between items-center bg-gray-300 px-4 py-1 ">
						<p className="font-bold ">{`${`Subtasks (${childRecords.length})`}`}</p>
						<button onClick={toggleChildrenOpen}>{isChildrenOpen ? <BiCaretUp size={25} /> : <BiCaretDown size={25} />}</button>
					</div>
					{!isChildrenOpen ? null : (
						<div className={`px-5 `}>
							{childRecords.map((mapData) => (
								<p className="border-l-4 p-1 pl-2">
									<Link
										className="text-blue-500 hover:text-blue-700 underline"
										to={`/odoo-project/${mapData.id}/template-select?model=project.task&contract=${contractType}&portfolio=${portfolioType}`}>
										{mapData.name}
									</Link>
								</p>
							))}
						</div>
					)}
				</div>
			) : null}
			{tickets && tickets.length > 0 ? (
				<div>
					<div className="flex justify-between items-center bg-gray-300 px-4 py-1 ">
						<p className="font-bold ">{`${`Tickets (${tickets.length})`}`}</p>
						<button onClick={toggleTicketsOpen}>{isTicketsOpen ? <BiCaretUp size={25} /> : <BiCaretDown size={25} />}</button>
					</div>
					{!isTicketsOpen ? null : (
						<div className="px-5">
							{tickets.map((mapData) => (
								<p className="border-l-4 p-1 pl-2">
									<Link
										className="text-blue-500 hover:text-blue-700 underline"
										to={`/odoo-project/${mapData.id}/template-select?model=helpdesk.ticket&contract=${contractType}&portfolio=${portfolioType}`}>
										{mapData.name + " (#" + mapData.id + ")"}
									</Link>
								</p>
							))}
						</div>
					)}
				</div>
			) : null}
		</div>
	);
};

export default NavigationArea;
