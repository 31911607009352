import { Link } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
import ClockInButton from "../Clocking/ClockInButton";
import { getADPFormattedTimestamp, getIdAndModel } from "../Clocking/ClockinUtils";
import { useEffect, useState } from "react";
import ClockOutButton from "../Clocking/ClockOutButton";

const ScheduledProjectCard = ({ project, isEven, isLast, api, token, clockingData, setClockingData }) => {
	const [clockedIntoThis, setClockedIntoThis] = useState(false);
	const [odooId, setOdooId] = useState(-1);
	const [odooModel, setOdooModel] = useState("");
	const [clockBannerToShow, setClockBannerToShow] = useState("");

	useEffect(() => {
		let odooInfo = getIdAndModel(project);
		setOdooId(odooInfo[0]);
		setOdooModel(odooInfo[1]);
	}, [project]);
	const months = {
		1: "January",
		2: "February",
		3: "March",
		4: "April",
		5: "May",
		6: "June",
		7: "July",
		8: "August",
		9: "September",
		10: "October",
		11: "November",
		12: "December",
	};

	useEffect(() => {
		if (!clockingData || !clockingData.clockingInfo) {
			setClockedIntoThis(false);
			return;
		}
		let clockedInRecord = clockingData.clockingInfo;
		let foundId = false;
		if (project.x_studio_module === "Installation" && project.task_id) {
			if (clockedInRecord.installationIds && clockedInRecord.installationIds.includes(project.task_id[0])) {
				foundId = true;
			}
		} else if (project.x_studio_module === "Roofing" && project.x_studio_many2one_field_3UTue) {
			if (clockedInRecord.roofing && clockedInRecord.roofing[0] === project.x_studio_many2one_field_3UTue[0]) {
				foundId = true;
			}
		} else if (project.x_studio_module === "Helpdesk" && project.x_studio_many2one_field_8NwQn) {
			if (clockedInRecord.tickets && clockedInRecord.tickets.includes(project.x_studio_many2one_field_8NwQn[0])) {
				foundId = true;
			}
		} else if (project.x_studio_module === "Krummy Capture" && project.x_studio_many2one_field_JaG0B) {
			if (clockedInRecord.krummy && clockedInRecord.krummy[0] === project.x_studio_many2one_field_JaG0B[0]) {
				foundId = true;
			}
		}
		setClockedIntoThis(foundId);
	}, [clockingData, project]);

	function extractHref(str) {
		const anchorRegex = /<a [^>]*href="([^"]+)"/;
		const match = str.match(anchorRegex);
		return match && match[1] ? match[1] : null;
	}
	function extractAfterDotCom(url) {
		const regex = /\.com(\/.*)/;
		const match = url.match(regex);
		return match && match[1] ? match[1] : null;
	}
	const getTemplateSelect = () => {
		let url = "";
		if (project.x_studio_module === "Installation" && project.x_studio_related_field_utgAt) {
			url = project.x_studio_related_field_utgAt;
		} else if (project.x_studio_module === "Roofing" && project.x_roofing_krummy_link) {
			url = project.x_roofing_krummy_link;
		} else if (project.x_studio_module === "Helpdesk" && project.x_ticket_krummy_link) {
			url = project.x_ticket_krummy_link;
		} else if (project.x_studio_module === "Krummy Capture" && project.x_krummy_link) {
			url = project.x_krummy_link;
		}
		url = url.replace(/amp;/g, "&");
		url = url.replace(/&&/g, "&");
		if (url) {
			if (extractHref(url)) {
				let splicedUrl = extractAfterDotCom(extractHref(url));
				if (splicedUrl.startsWith("/odoo-project")) {
					splicedUrl = splicedUrl + `&schedulingid=${project.id}`;
				}
				return splicedUrl;
			}
		}
		return url;
	};

	const getAddress = () => {
		if (project.x_studio_module === "Installation" && project.x_studio_address) {
			return project.x_studio_address;
		} else if (project.x_studio_module === "Roofing" && project.x_studio_roofing_address) {
			return project.x_studio_roofing_address;
		} else if (project.x_studio_module === "Helpdesk" && project.x_studio_google_map_link) {
			return project.x_studio_google_map_link;
		} else if (project.x_studio_module === "Krummy Capture" && project.x_studio_krummy_address) {
			return project.x_studio_krummy_address;
		} else {
			return "-";
		}
	};

	const getName = () => {
		if (project.x_studio_module === "Installation" && project.task_id) {
			return project.task_id[1];
		} else if (project.x_studio_module === "Roofing" && project.x_studio_many2one_field_3UTue) {
			return project.x_studio_many2one_field_3UTue[1];
		} else if (project.x_studio_module === "Helpdesk" && project.x_studio_many2one_field_8NwQn) {
			return project.x_studio_many2one_field_8NwQn[1];
		} else if (project.x_studio_module === "Krummy Capture" && project.x_studio_many2one_field_JaG0B) {
			return project.x_studio_many2one_field_JaG0B[1];
		} else {
			return "-";
		}
	};

	function isSameDay(date1, date2) {
		if (!date1 || !date2) {
			return false;
		}
		return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
	}

	function format12HourTime(date) {
		if (!(date instanceof Date)) {
			date = new Date(date);
		}

		const day = date.getDate();
		const monthIndex = date.getMonth() + 1;

		let hours = date.getHours();
		const minutes = date.getMinutes();
		const ampm = hours >= 12 ? "PM" : "AM";
		hours = hours % 12;
		hours = hours || 12;
		const minutesStr = minutes < 10 ? "0" + minutes : minutes;

		return (
			<div>
				<p>{`${months[monthIndex]} ${String(day).padStart(2, "0")}, ${hours}:${minutesStr} ${ampm}`}</p>
			</div>
		);
	}

	function injectTailwindClassesToLink(str, classes) {
		const anchorRegex = /<a [^>]+>/;

		const anchorMatch = str.match(anchorRegex);
		if (!anchorMatch) return str; // If no anchor tag found, return the original string

		const originalAnchor = anchorMatch[0];
		const newAnchor = originalAnchor.replace("<a ", `<a class="${classes}" `);

		return str.replace(originalAnchor, newAnchor);
	}

	return project.x_misc_options ? (
		<div className="p-3 border-t border-1 border-black ">
			<p className="font-bold">{`Scheduled as ${project.x_misc_options}`}</p>

			<div className="flex gap-2 items-center text-sm">
				{format12HourTime(project.start_datetime)}
				<FaLongArrowAltRight />
				{format12HourTime(project.end_datetime)}
			</div>
			<p className="text-sm mt-4">
				<span className="font-bold">Notes:</span> {project.name ? project.name : " - "}
			</p>
		</div>
	) : !getTemplateSelect() ? null : (
		<div
			className={`border-t border-1 border-black p-3 flex flex-col-reverse md:flex-row justify-between items-center ${isEven ? "" : "bg-gray-200"} ${
				isLast ? "rounded-b-lg" : ""
			}`}>
			<div className="w-full">
				<div className="md:flex justify-between">
					<div>
						<p>
							{getTemplateSelect() ? (
								<Link
									className="text-blue-500 underline hover:text-blue-700 font-bold"
									to={`${getTemplateSelect()}${project.x_pre_project_ticket ? "?ppc=" + project.x_pre_project_ticket[0] : ""}`}>
									{getName()}
								</Link>
							) : null}
						</p>

						<div className="flex gap-2 items-center text-sm">
							{format12HourTime(project.start_datetime)}
							<FaLongArrowAltRight />
							{format12HourTime(project.end_datetime)}
						</div>
					</div>
					<div>
						<div
							dangerouslySetInnerHTML={{
								__html: injectTailwindClassesToLink(getAddress(), "text-blue-500 underline hover:text-blue-700 text-sm"),
							}}
						/>

						{project.x_dropbox_construction_link ? (
							<>
								<p className=" text-end">
									<a
										href={extractHref(project.x_dropbox_construction_link)}
										target="_blank"
										rel="noopener noreferrer"
										className="text-blue-500 text-sm underline hover:text-blue-700">
										Dropbox Construction Resources
									</a>
								</p>
							</>
						) : null}
					</div>
				</div>

				<p className="text-sm mt-4">
					<span className="font-bold">Phone:</span> {project.x_phone ? project.x_phone : " - "}
				</p>
				<p className="text-sm">
					<span className="font-bold">Notes:</span> {project.name ? project.name : " - "}
				</p>
				{project.workingWith && project.workingWith.length > 0 ? (
					<div className="w-full text-sm">
						<p>
							<span className="font-bold">Working with: </span>
							{project.workingWith.map((mapData, index, array) => {
								return (
									<span key={mapData.id}>
										{mapData.name}
										{index < array.length - 1 ? ", " : " "}
									</span>
								);
							})}
						</p>
					</div>
				) : null}

				<div className="flex gap-2 mt-1 justify-end">
					<ClockOutButton
						api={api}
						token={token}
						projectsRoute={getTemplateSelect()}
						odooId={odooId}
						odooModel={odooModel}
						clockingData={clockingData}
						setClockingData={setClockingData}
						clockedIntoThis={clockedIntoThis}
						clockBannerToShow={clockBannerToShow}
						setClockBannerToShow={setClockBannerToShow}
					/>
					<ClockInButton
						api={api}
						token={token}
						projectsRoute={getTemplateSelect()}
						odooId={odooId}
						odooModel={odooModel}
						clockingData={clockingData}
						setClockingData={setClockingData}
						clockedIntoThis={clockedIntoThis}
						clockBannerToShow={clockBannerToShow}
						setClockBannerToShow={setClockBannerToShow}
					/>
				</div>
			</div>
		</div>
	);
};

export default ScheduledProjectCard;
