import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

const TemplateMobileFilter = ({
	showArchived,
	setShowArchived,
	odooModelToFilterBy,
	setOdooModelToFilterBy,
	odooModels,
	selectedTemplateType,
	setSelectedTemplateType,
	portfolioType,
	setPortfolioType,
}) => {
	return (
		<div className="border border-black p-4 rounded-lg select-none w-full flex flex-col gap-3">
			<div className="mb-4">
				<div className="text-xl">
					<FontAwesomeIcon icon={faFilter} size="sm" /> Filter
				</div>
			</div>
			<div className="flex flex-col">
				<label htmlFor="templateStatus">Status:</label>
				<select
					name="templateStatus"
					id="templateStatus"
					className="border border-black p-1 "
					value={showArchived}
					onChange={(e) => setShowArchived(e.target.value)}>
					<option value={0}>Not Archived</option>
					<option value={1}>Archived</option>
				</select>
			</div>
			<div className="flex flex-col">
				<label htmlFor="templateType">Type:</label>
				<select
					name="templateType"
					id="templateType"
					className="border border-black p-1"
					value={selectedTemplateType}
					onChange={(e) => setSelectedTemplateType(e.target.value)}>
					<option value={"all"}>All</option>
					<option value={1}>Residential</option>
					<option value={2}>Commercial</option>
				</select>
			</div>
			<div className="flex flex-col">
				<label htmlFor="usedOdooModels">Model:</label>
				<select
					name="usedOdooModels"
					id="usedOdooModels"
					className="border border-black p-1"
					value={odooModelToFilterBy}
					onChange={(e) => setOdooModelToFilterBy(e.target.value)}>
					<option value={"all"}>All</option>
					{odooModels && odooModels.length > 0
						? odooModels.map((mapData, key) => {
								return (
									<option value={mapData.model} key={key}>
										{mapData.name === "Task" ? "Installations" : mapData.name}
									</option>
								);
						  })
						: null}
				</select>
			</div>
			<div className="flex flex-col">
				<label htmlFor="portfolioType">Portfolio</label>
				<select
					name="portfolioType"
					id="portfolioType"
					className="border border-black p-1"
					value={portfolioType}
					onChange={(e) => setPortfolioType(e.target.value)}>
					<option value={"all"}>All</option>
					<option value={"ps"}>Public Storage</option>
					<option value={"ess"}>Extra Space Storage</option>
					<option value={"misc"}>Misc.</option>
				</select>
			</div>
		</div>
	);
};

export default TemplateMobileFilter;
