import { useContext, useEffect, useState } from "react";
import { CartContext } from "./CartProvider";
import { ImImages } from "react-icons/im";
import ShopButton from "../ShopButton";
import { IoMdTrash } from "react-icons/io";
import { IoReceipt } from "react-icons/io5";
import LoadingCircle from "../../utility/LoadingCircle";
import { FaCheckCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

const Cart = ({ api, token }) => {
	const { cart, setCart, removeFromCart, incrementItem, decrementItem } = useContext(CartContext);
	const [loading, setLoading] = useState(false);
	const [loadingImages, setLoadingImages] = useState(true);
	const [imagesByProductId, setImagesByProductId] = useState({});
	const [salesOrderId, setSalesOrderId] = useState(-1);
	const navigate = useNavigate();

	useEffect(() => {
		document.title = "Cart";
	}, []);

	const groupedCart = cart.reduce((acc, item) => {
		const groupKey = `${item.odooId}-${item.odooModel}-${item.projectName}`;
		if (!acc[groupKey]) {
			acc[groupKey] = [];
		}
		acc[groupKey].push(item);
		return acc;
	}, {});

	useEffect(() => {
		let productIds = cart.map((mapData) => mapData.id);
		if (!api || !token || !productIds || productIds.length === 0) {
			return;
		}

		const controller = new AbortController();
		const signal = controller.signal;
		setLoadingImages(true);
		fetch(api + `/shop/cart`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			mode: "cors",
			body: JSON.stringify({ productIds }),
			signal,
		})
			.then((res) => {
				if (res.ok) {
					return res.json();
				} else {
					setLoadingImages(false);
				}
			})
			.then((data) => {
				if (data) {
					if (data.records) {
						let newImageDict = {};
						data.records.forEach((product) => {
							newImageDict[product.id] = product.image_1920;
						});
						setImagesByProductId(newImageDict);
					}
				}
				setLoadingImages(false);
			});

		return () => {
			controller.abort();
		};
	}, [api, cart, token]);

	const submitOrder = (odooId, odooModel, projectName, groupKey) => {
		let cartWithoutImages = groupedCart[groupKey].map((mapData) => {
			let newData = { ...mapData };
			delete newData["image_1920"];
			return newData;
		});

		const controller = new AbortController();
		const signal = controller.signal;
		setLoading(true);
		fetch(api + `/shop/order`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			mode: "cors",
			body: JSON.stringify({
				odooInfo: {
					odooId,
					odooModel,
					projectName,
				},
				cart: cartWithoutImages,
			}),
			signal,
		})
			.then((res) => {
				if (res.ok) {
					let tempCart = [...cart];
					tempCart = tempCart.filter((item) => groupKey !== `${item.odooId}-${item.odooModel}-${item.projectName}`);
					setCart(tempCart);
					return res.json();
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data) {
					if (data.orderId) {
						setSalesOrderId(data.orderId);
					}
					if (data.error) {
						alert("Order failed: " + data.error);
					}
				}
				setLoading(false);
			})
			.catch((err) => {});

		return () => {
			controller.abort();
		};
	};

	const goToOrders = () => {
		navigate("/orders");
	};

	const resetOrderId = () => {
		setSalesOrderId(-1);
	};

	if (cart && cart.length === 0) {
		return (
			<div className="p-4 flex justify-center">
				<div className="w-full md:w-3/5">
					{salesOrderId !== -1 ? (
						<div className="fixed top-0 right-0 left-0 bottom-0 bg-black/50 z-[100] flex justify-center items-center">
							<div className="flex flex-col items-center justify-center border border-black p-4 bg-white">
								<FaCheckCircle size={48} color="green" />
								<p className="font-bold text-2xl mt-1">Order Submitted!</p>
								<p className="mb-4">
									{`Order Id: `}{" "}
									<Link to={`/order/${salesOrderId}`} className="text-blue-500 hover:text-blue-600 underline">
										{salesOrderId}
									</Link>
								</p>
								<div className="mt-2 flex flex-col w-full gap-2">
									<button className="text-white bg-blue-500 hover:bg-blue-600 w-full rounded-lg p-2" onClick={goToOrders}>
										Go To Orders History
									</button>
									<button className="text-white bg-gray-500 hover:bg-gray-600 w-full rounded-lg p-2" onClick={resetOrderId}>
										Close
									</button>
								</div>
							</div>
						</div>
					) : null}
					<div className="m-4 p-4">
						<h2 className="text-center text-3xl font-bold">Cart</h2>
						<div className="border border-black p-4 mt-4">
							<p className="text-center font-bold">Your cart is empty. </p>
						</div>
					</div>
				</div>
			</div>
		);
	}

	if (loadingImages) {
		return (
			<div className="fixed top-0 right-0 left-0 bottom-0 bg-black/50 z-[100] flex justify-center items-center">
				<div className="flex flex-col items-center justify-center border border-black p-4 bg-white">
					<LoadingCircle />
					<p>Loading Cart...</p>
				</div>
			</div>
		);
	}

	return (
		<div className="p-4 flex justify-center">
			{salesOrderId !== -1 ? (
				<div className="fixed top-0 right-0 left-0 bottom-0 bg-black/50 z-[100] flex justify-center items-center">
					<div className="flex flex-col items-center justify-center border border-black p-4 bg-white">
						<FaCheckCircle size={48} color="green" />
						<p className="font-bold text-2xl mt-1">Order Submitted!</p>
						<p className="mb-4">
							{`Order Id: `}{" "}
							<Link to={`/order/${salesOrderId}`} className="text-blue-500 hover:text-blue-600 underline">
								{salesOrderId}
							</Link>
						</p>
						<div className="mt-2 flex flex-col w-full gap-2">
							<button className="text-white bg-blue-500 hover:bg-blue-600 w-full rounded-lg p-2" onClick={goToOrders}>
								Go To Orders History
							</button>
							<button className="text-white bg-gray-500 hover:bg-gray-600 w-full rounded-lg p-2" onClick={resetOrderId}>
								Close
							</button>
						</div>
					</div>
				</div>
			) : null}

			{loading ? (
				<div className="fixed top-0 right-0 left-0 bottom-0 bg-black/50 z-[100] flex justify-center items-center">
					<div className="flex flex-col items-center justify-center border border-black p-4 bg-white">
						<LoadingCircle />
						<p>Procesing Order...</p>
					</div>
				</div>
			) : null}
			<div className="w-full md:w-4/5 lg:w-3/5">
				<h1 className="text-center mb-4 text-3xl font-bold">Cart</h1>
				<div className="flex flex-col gap-4">
					{Object.keys(groupedCart).map((groupKey) => {
						const groupItems = groupedCart[groupKey];
						// Extract the group details from the first item
						const { odooId, odooModel, projectName } = groupItems[0];

						return (
							<div key={groupKey} className="grouped-cart p-4 border border-black flex flex-col">
								<h2 className="font-bold text-2xl mb-4">
									{`Project: ${projectName}`}
									<span className="text-base ml-2">{`(Odoo: ${odooModel} - ID: ${odooId})`}</span>
								</h2>
								{groupItems.map((item) => {
									const uniqueKey = `${item.id}-${item.attributes.map((attr) => `${attr.id}-${attr.selectedValue.id}`).join("-")}-${
										item.amount
									}`;

									return (
										<div key={uniqueKey} className="flex flex-col md:flex-row gap-2 grow border-b p-4">
											{imagesByProductId[item.id] ? (
												<img
													src={`data:image/jpeg;base64,${imagesByProductId[item.id]}`}
													alt={item.display_name}
													className="w-[200px] h-[200px] object-contain"
												/>
											) : (
												<div className="w-[200px] h-auto flex justify-center items-center">
													<ImImages className="w-auto h-[50%]" />
												</div>
											)}
											<div className="flex flex-col justify-between w-full">
												<div>
													<p className="font-bold text-xl">{item.name}</p>
													<p className="text-sm">
														{item.attributes && item.attributes.length > 0 ? (
															<>
																{item.attributes.map(
																	(attribute, index) =>
																		`${attribute.name} - ${attribute.selectedValue.name}${
																			index < item.attributes.length - 1 ? ", " : ""
																		}`
																)}
															</>
														) : null}
													</p>
													{item.notes ? (
														<p className="mt-4 whitespace-pre-wrap">
															<span className="font-bold">Notes: </span>
															{item.notes}
														</p>
													) : null}
												</div>
												<div className="flex gap-4 justify-end items-center mt-2">
													<button
														onClick={() => removeFromCart(item.id, item.attributes, item.odooId, item.odooModel, item.projectName)}
														className="flex text-white p-2 bg-red-700 items-center gap-1 rounded-lg">
														<IoMdTrash /> Delete
													</button>

													<div className="flex items-center">
														<button
															className="p-2 px-3 border border-black rounded-l bg-gray-200"
															onClick={() => {
																if (item.amount === 1) {
																	removeFromCart(item.id, item.attributes, item.odooId, item.odooModel, item.projectName);
																} else {
																	decrementItem(item.id, item.attributes, item.odooId, item.odooModel, item.projectName);
																}
															}}>
															-
														</button>
														<p className="p-2 border-t border-b border-black text-center w-16 bg-gray-100 text-center">
															{item.amount}
														</p>
														<button
															className="p-2 px-3 border border-black rounded-r bg-gray-200"
															onClick={() =>
																incrementItem(item.id, item.attributes, item.odooId, item.odooModel, item.projectName)
															}>
															+
														</button>
													</div>
												</div>
											</div>
										</div>
									);
								})}
								<div className="mt-4 flex justify-end gap-2 items-center">
									<div>
										<ShopButton
											api={api}
											token={token}
											odooId={odooId}
											odooModel={odooModel}
											projectName={projectName}
											openedFromCart={true}
											buttonText="Add Items"
										/>
									</div>
									<div>
										<button
											className="bg-blue-600 hover:bg-blue-700 p-2 text-white rounded-lg flex gap-1 items-center"
											onClick={() => submitOrder(odooId, odooModel, projectName, groupKey)}>
											<IoReceipt /> Submit Order
										</button>
									</div>
								</div>
							</div>
						);
					})}
				</div>

				{/* <p className="whitespace-pre-wrap">{JSON.stringify(cart, null, 2)}</p> */}
			</div>
		</div>
	);
};

export default Cart;
