export const contractTypes = [
	{ value: "Residential", label: "Residential" },
	{ value: "Commercial", label: "Commercial" },
];

export const stages = [
	{ value: "Assign", label: "Assign" },
	{ value: "In-Progress", label: "In Progress" },
	{ value: "Finalize", label: "Finalize" },
	{ value: "Completed", label: "Completed" },
];

export const templateNames = [
	{ value: "Commercial PSA", label: "Commercial PSA" },
	{ value: "Commercial QC Inspection", label: "Commercial QC Inspection" },
	{ value: "PS Roof PSA", label: "PS Roof PSA" },
	{ value: "System Check", label: "System Check" },
	{ value: "PS Remedial Work", label: "PS Remedial Work" },
	{ value: "Commercial Installation", label: "Commercial Installation" },
	{ value: "Material Requisition Form", label: "Material Requisition Form" },
	{ value: "Commisioning Report", label: "Commisioning Report" },
	{ value: "Panel Cleaning", label: "Panel Cleaning" },
	{ value: "PS Punch Walks", label: "PS Punch Walks" },
	{ value: "Change Order Items", label: "Change Order Items" },
];

export const allowedOdooModels = {
	"project.task": { value: "project.task", label: "Installations" },
	"helpdesk.ticket": { value: "helpdesk.ticket", label: "Helpdesk Ticket" },
	x_roofing: { value: "x_roofing", label: "Roofing" },
	x_krummy_capture: { value: "x_krummy_capture", label: "Krummy Capture" },
};
