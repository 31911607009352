import { useEffect, useState } from "react";

const TextInput = ({ initialFieldData, fieldDataExists, postToDB, putToDB, readOnly, odooData, hasOdooMapping }) => {
	const [currentValue, setCurrentValue] = useState("");

	useEffect(() => {
		let newValue = "";
		if (fieldDataExists) {
			newValue = initialFieldData.value;
		}

		if (hasOdooMapping) {
			if (odooData && odooData.odoo_value !== "failedtogetdataforpmscode400") {
				newValue = odooData.odoo_value;
			} else if (odooData && odooData.odoo_value === "failedtogetdataforpmscode400") {
				if (fieldDataExists) {
					newValue = initialFieldData.value;
				}
			} else {
				newValue = "";
			}
		}
		setCurrentValue(newValue);
	}, [initialFieldData, fieldDataExists, odooData, hasOdooMapping]);

	const sendToDb = () => {
		if (fieldDataExists === false && currentValue !== "") {
			postToDB(currentValue);
		} else if (fieldDataExists) {
			putToDB(currentValue);
		}
	};

	return (
		<form onSubmit={(e) => e.preventDefault()}>
			<input
				type="text"
				value={currentValue}
				onChange={(e) => setCurrentValue(e.target.value)}
				onBlur={() => sendToDb()}
				className="border border-2 p-2 border-gray w-full rounded-lg"
				readOnly={readOnly}
			/>
		</form>
	);
};

export default TextInput;
