import { useEffect, useState } from "react";
import LoadingCircle from "../../utility/LoadingCircle";

const FilterUpdateModal = ({ api, token, closeModal, templateid, portfolioType, setPortfolioType }) => {
	const [newType, setNewType] = useState("");
	const [loading, setLoading] = useState(false);
	const preventPropagation = (e) => {
		e.stopPropagation();
	};

	useEffect(() => {});

	const putPortfolioType = () => {
		if (!api || !token || newType === "") {
			return;
		}
		setLoading(true);
		fetch(api + "/template/portfolio/" + templateid, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: token,
			},
			body: JSON.stringify({
				portfolio_type: newType,
			}),
		})
			.then((res) => {
				setLoading(false);
				if (res.ok) {
					return res.json();
				} else {
				}
			})
			.then((res) => {
				if (res) {
					setPortfolioType(newType);
					closeModal();
				}
			});
	};

	return (
		<div className="text-black fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black/50 z-[100]" onClick={closeModal}>
			<div className="bg-white w-full lg:w-1/4 m-2 rounded-lg " onClick={preventPropagation}>
				<div className="m-4 flex justify-between items-center">
					<p className="text-2xl">Update Portfolio Type</p>
				</div>
				<div className="mx-4">
					<div className="flex flex-col">
						<label htmlFor="portfolioType">Portfolio</label>
						<select
							name="portfolioType"
							id="portfolioType"
							className="border border-black p-1"
							value={newType}
							onChange={(e) => setNewType(e.target.value)}
							disabled={loading ? true : false}
							required>
							<option value={""} style={{ display: "none" }}>
								Please select a new portfolio
							</option>
							{portfolioType !== "all" ? <option value={"all"}>All</option> : null}
							{portfolioType !== "ps" ? <option value={"ps"}>Public Storage</option> : null}
							{portfolioType !== "ess" ? <option value={"ess"}>Extra Space Storage</option> : null}
							{portfolioType !== "misc" ? <option value={"misc"}>Misc.</option> : null}
						</select>
					</div>
				</div>
				{loading ? (
					<div className="flex flex-col justify-center items-center m-4">
						<LoadingCircle />
						<p>Updating...</p>
					</div>
				) : (
					<div className="flex gap-2 justify-end m-4">
						<button type="button" className=" bg-gray-500 p-2 text-white rounded-lg" onClick={closeModal}>
							Cancel
						</button>
						{newType === "" ? (
							<button type="button" className=" bg-gray-500 p-2 text-white rounded-lg" disabled={true} onClick={(e) => e.preventDefault()}>
								Update
							</button>
						) : (
							<button type="button" className=" bg-blue-500 p-2 text-white rounded-lg" onClick={putPortfolioType}>
								Update
							</button>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default FilterUpdateModal;
