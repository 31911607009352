import { useEffect, useState } from "react";
import { BsExclamationCircleFill, BsFillCameraFill } from "react-icons/bs";
import CameraContainer from "../project/camera/cameracontainer";

const RequestField = ({
	api,
	token,
	requestFieldData,
	requestData,
	assigneeName,
	isAssignee,
	isRequester,
	updateLastModified,
	requestFields,
	setRequestFields,
	status,
}) => {
	const [currentValue, setCurrentValue] = useState("");
	const [hasPhotos, setHasPhotos] = useState(false);
	const [comment, setComment] = useState("");

	useEffect(() => {
		if (requestFieldData) {
			setCurrentValue(requestFieldData.data);
			setComment(requestFieldData.requester_comment);
		}
	}, [requestFieldData]);

	useEffect(() => {
		let arrayCopy = [...requestFields];
		for (let i = 0; i < arrayCopy.length; i++) {
			if (arrayCopy[i].id === requestFieldData.id) {
				if (arrayCopy[i].hasPhotos !== hasPhotos) {
					arrayCopy[i].hasPhotos = hasPhotos;
					setRequestFields(arrayCopy);
				}
				break;
			}
		}
	}, [hasPhotos, requestFields, requestFieldData.id, setRequestFields]);

	const updateData = (e) => {
		e.preventDefault();
		if (!isAssignee) {
			return;
		}
		fetch(api + "/request/field/" + requestFieldData.id, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: token,
			},
			body: JSON.stringify({
				data: currentValue,
				request_id: requestFieldData.request_id,
			}),
		}).then((res) => {
			if (res.ok) {
				let arrayCopy = [...requestFields];
				for (let i = 0; i < arrayCopy.length; i++) {
					if (arrayCopy[i].id === requestFieldData.id) {
						arrayCopy[i].data = currentValue;
						break;
					}
				}
				setRequestFields(arrayCopy);
			}
		});
	};

	const updateComment = (e) => {
		e.preventDefault();
		if (!isRequester) {
			return;
		}
		fetch(api + "/request/comment/" + requestFieldData.id, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: token,
			},
			body: JSON.stringify({
				comment: comment,
				request_id: requestFieldData.request_id,
			}),
		}).then((res) => {
			if (res.ok) {
				let arrayCopy = [...requestFields];
				for (let i = 0; i < arrayCopy.length; i++) {
					if (arrayCopy[i].id === requestFieldData.id) {
						arrayCopy[i].comment = comment;
						break;
					}
				}
				setRequestFields(arrayCopy);
			}
		});
	};

	return (
		<>
			<p className="text-2xl font-bold">{requestFieldData.name}</p>
			{requestFieldData.directions && requestFieldData.directions !== "" ? (
				<>
					<p className="font-bold">Directions:</p>
					<p className="whitespace-pre-wrap mx-1">{requestFieldData.directions}</p>
				</>
			) : null}
			{/* <p>Has Photos: {requestFieldData.hasPhotos.toString()}</p>
			<p>Has Photos: {hasPhotos.toString()}</p> */}
			<div>
				<p className="font-bold">Data:</p>
				{isAssignee && (status === "active" || status === "requesting retakes") ? (
					<form onSubmit={(e) => e.preventDefault()}>
						<textarea
							type="text"
							rows={4}
							value={currentValue}
							onChange={(e) => setCurrentValue(e.target.value)}
							onBlur={(e) => updateData(e)}
							readOnly={!isAssignee}
							className="rounded-lg border border-2 p-2 border-gray w-full whitespace-pre-wrap"
						/>
					</form>
				) : currentValue !== "" ? (
					<p className="mx-1">{currentValue}</p>
				) : (
					<p className="text-sm mx-1">waiting on data</p>
				)}
			</div>

			<CameraContainer
				sectionName={assigneeName}
				cameraid={requestFieldData.id}
				api={api}
				token={token}
				fieldName={requestFieldData.name + " - request#" + requestFieldData.request_id}
				projectName={requestData.name}
				templateName={"Requests"}
				stage={isAssignee && (status === "active" || status === "requesting retakes") ? "finalize" : ""}
				readOnly={!isAssignee || (isAssignee && status !== "active" && status !== "requesting retakes")}
				templateTypeId={requestData.template_type}
				setHasPhotos={setHasPhotos}
				updateLastModified={updateLastModified}
			/>

			{currentValue === "" && isAssignee && status !== "completed" ? (
				<div className="flex items-center gap-2 bg-rose-100 p-2 rounded-lg">
					<BsExclamationCircleFill color="red" className="text-3xl" />
					<p>Please enter some data.</p>
				</div>
			) : null}
			{hasPhotos === false && isAssignee && status !== "completed" ? (
				<div className="flex items-center gap-2 bg-yellow-300 p-2 rounded-lg">
					<BsFillCameraFill color="black" className="text-3xl" />
					<p>Photos are required. Take at least one photo.</p>
				</div>
			) : null}

			<div>
				{isRequester && status !== "completed" ? (
					<>
						<p className="font-bold">Comments:</p>
						<form onSubmit={(e) => e.preventDefault()}>
							<textarea
								type="text"
								rows={4}
								value={comment}
								onChange={(e) => setComment(e.target.value)}
								onBlur={(e) => updateComment(e)}
								className="rounded-lg border border-2 p-2 border-gray w-full whitespace-pre-wrap"
							/>
						</form>
					</>
				) : comment !== "" ? (
					<div className="mt-2">
						<hr className="border-black" />
						<p className="font-bold">Comment from Requester:</p>
						<p className="whitespace-pre-wrap mx-1">{comment}</p>
					</div>
				) : null}
			</div>
		</>
	);
};

export default RequestField;
