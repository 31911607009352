import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ReportImage from "./ReportImage";
import SectionExclusionList from "./SectionExclusionList";
import SelectedTemplateList from "./SelectedTemplateList";
import LoadingCircle from "../utility/LoadingCircle";
import { cloneDeep, isEqual } from "lodash";

const Report = ({ api, token, isAdmin, isPublicStorage, hasUnsavedFormData, setHasUnsavedFormData }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const useLocalStorage = queryParams.get("unsaved") === "true";
	const { reportId } = useParams();
	const [templateData, setTemplateData] = useState({});
	const [dataToSave, setDataToSave] = useState({});
	const [initialData, setInitialData] = useState({});
	const [reportName, setReportName] = useState("");
	const [selectedImgName, setSelectedImgName] = useState("");
	const [saveName, setSaveName] = useState("");
	const [saving, setSaving] = useState(false);

	useEffect(() => {
		const handleBeforeUnload = (event) => {
			if (!hasUnsavedFormData) {
				return;
			}
			event.preventDefault();
			event.returnValue = "";
		};

		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [hasUnsavedFormData]);

	useEffect(() => {
		if (Object.keys(dataToSave).length === 4) {
			localStorage.setItem("lastReportFormData", JSON.stringify(dataToSave));
		}
	}, [dataToSave]);

	useEffect(() => {
		if (!setHasUnsavedFormData) {
			return;
		}
		if (!isEqual(dataToSave, initialData)) {
			setHasUnsavedFormData(true);
		} else {
			setHasUnsavedFormData(false);
		}
	}, [initialData, dataToSave, setHasUnsavedFormData]);

	useEffect(() => {
		document.title = "Report Builder";
	}, []);

	useEffect(() => {
		if (!api || !token) {
			return;
		}
		setDataToSave({});
		setTemplateData([]);
		if (isNaN(reportId)) {
			return;
		}
		fetch(api + "/report/" + reportId, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: token,
			},
			mode: "cors",
		})
			.then((res) => {
				if (res.ok) {
					return res.json();
				} else {
					if (isPublicStorage) {
						navigate("/dashboard");
					} else {
						navigate("/reports");
					}
				}
			})
			.then((data) => {
				if (data) {
					setReportName(data.reportName);
					document.title = `Report Builder: ${data.reportName}`;
					setSaveName(data.saveName);
					setSelectedImgName(data.selectedImgName);
					let newDataToSave = {
						reportName: data.reportName,
						saveName: data.saveName,
						coverImgName: data.selectedImgName,
						templateData: data.templateData,
					};

					const initialFormState = JSON.parse(localStorage.getItem("lastReportFormData")) || {};

					if (useLocalStorage) {
						setReportName(initialFormState.reportName);
						document.title = `Report Builder: ${initialFormState.reportName}`;
						setSaveName(initialFormState.saveName);
						setSelectedImgName(initialFormState.coverImgName);
						setDataToSave(cloneDeep(initialFormState));
					} else {
						setReportName(data.reportName);
						document.title = `Report Builder: ${data.reportName}`;
						setSaveName(data.saveName);
						setSelectedImgName(data.selectedImgName);
						setDataToSave(cloneDeep(newDataToSave));
					}
					setInitialData(cloneDeep(newDataToSave));
				}
			});
	}, [reportId, api, token, isPublicStorage, navigate, useLocalStorage]);

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		fetch(api + "/report/project-templates", {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: token,
			},
			mode: "cors",
			signal,
		})
			.then((res) => {
				if (res.ok) {
					return res.json();
				}
			})
			.then((res) => {
				if (res) {
					setTemplateData(res.formattedTemplateData);
				}
			});

		return () => {
			controller.abort();
		};
	}, [api, token]);

	const changeSaveName = (e) => {
		e.preventDefault();
		setSaveName(e.target.value);
		setDataToSave((prev) => {
			return { ...prev, saveName: e.target.value };
		});
	};

	const changeReportName = (e) => {
		e.preventDefault();
		setReportName(e.target.value);
		setDataToSave((prev) => {
			return { ...prev, reportName: e.target.value };
		});
		document.title = `Report Builder: ${e.target.value}`;
	};

	const saveData = (e) => {
		e.preventDefault();
		if (saving) {
			return;
		}
		setSaving(true);
		fetch(api + "/report/" + reportId, {
			method: "put",
			headers: {
				Authorization: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(dataToSave),
		}).then((res) => {
			if (res.ok) {
				setHasUnsavedFormData(false);
				navigate("/reports");
			} else {
				alert("Unable to save. Please try again");
				setSaving(false);
			}
		});
	};

	useEffect(() => {
		setDataToSave((prev) => {
			return { ...prev, coverImgName: selectedImgName };
		});
	}, [selectedImgName]);

	return (
		<div className="flex flex-col">
			{saving ? (
				<div className="fixed top-0 left-0 w-full h-screen flex justify-center items-center bg-black/50 z-30">
					<div className="bg-white m-2 rounded-lg w-full md:w-auto" onClick={(e) => e.stopPropagation()}>
						<div className="border border-black border-2 rounded-lg w-full flex flex-col jusify-center items-center p-4">
							<LoadingCircle />
							<p>Saving the report... </p>
							<p>Will navigate to the reports list once complete.</p>
						</div>
					</div>
				</div>
			) : null}

			<div className="sticky top-0">
				<div className="flex bg-gray-100 p-4 ">
					<div className="flex items-center justify-between w-full">
						<p className="text-lg md:text-2xl font-bold">Report Builder</p>

						{hasUnsavedFormData ? (
							<button className="bg-blue-500 hover:bg-blue-700 p-2 text-lg  text-white" onClick={saveData}>
								Save
							</button>
						) : (
							<button className="bg-gray-500 p-2 text-lg  text-white" onClick={(e) => e.preventDefault} disabled={true}>
								Save
							</button>
						)}
					</div>
				</div>
			</div>
			<div className="flex w-full justify-center p-4">
				<div className="w-full md:w-4/5">
					<h3 className="text-xl font-bold">Settings</h3>
					<form onSubmit={(e) => e.preventDefault()} className="flex flex-col mb-4">
						<label>Report Name:</label>
						<input type="text" value={reportName} onChange={changeReportName} className="border border-2 rounded-lg p-1 border-black" />
						<label>Save Name:</label>
						<input type="text" value={saveName} onChange={changeSaveName} className="border border-2 rounded-lg p-1 border-black" />
					</form>

					<ReportImage api={api} token={token} reportId={reportId} setSelectedImgName={setSelectedImgName} selectedImgName={selectedImgName} />

					<SelectedTemplateList
						templateData={templateData}
						dataToSave={dataToSave}
						setDataToSave={setDataToSave}
						reportId={reportId}
						api={api}
						token={token}
					/>
					<SectionExclusionList
						api={api}
						token={token}
						templateData={templateData}
						dataToSave={dataToSave}
						setDataToSave={setDataToSave}
						reportId={reportId}
					/>
				</div>
			</div>
		</div>
	);
};

export default Report;
