import React, { useState, useEffect } from "react";

const getElapsedTime = (date) => {
	const now = new Date();
	const difference = now - new Date(date);

	const seconds = Math.floor((difference / 1000) % 60);
	const minutes = Math.floor((difference / 1000 / 60) % 60);
	const hours = Math.floor((difference / 1000 / 60 / 60) % 24);
	const days = Math.floor(difference / (1000 * 60 * 60 * 24));

	return { days, hours, minutes, seconds };
};

const TimeSinceDate = ({ date, preText, setClockBannerToShow, specialCode }) => {
	const [elapsedTime, setElapsedTime] = useState(getElapsedTime(date));

	useEffect(() => {
		if (preText === "Ended Work at" || specialCode === "clock-in" || specialCode === "meal-in") {
			return;
		}
		const interval = setInterval(() => {
			let tempElapsedTime = getElapsedTime(date);
			if (tempElapsedTime.hours >= 6) {
				setClockBannerToShow("over");
			} else if (tempElapsedTime.hours === 5 && tempElapsedTime.minutes >= 30) {
				setClockBannerToShow("warning");
			} else {
				setClockBannerToShow("");
			}
			setElapsedTime(tempElapsedTime);
		}, 1000);

		return () => clearInterval(interval);
	}, [date, preText, setClockBannerToShow, specialCode]);

	const padWithZero = (number) => {
		return number.toString().padStart(2, "0");
	};

	const formatDate = (date) => {
		let hours = date.getHours();
		let minutes = date.getMinutes();
		const ampm = hours >= 12 ? "PM" : "AM";
		hours = hours % 12;
		hours = hours ? hours : 12;
		minutes = minutes < 10 ? "0" + minutes : minutes;
		return `${hours}:${minutes} ${ampm}`;
	};

	return (
		<div className="flex flex-col justify-center items-center mb-1">
			<p>{preText + " " + formatDate(date)}</p>
			<p
				className={`${elapsedTime.hours === 5 && elapsedTime.minutes >= 30 ? "text-red-500" : ""} ${
					elapsedTime.hours >= 6 ? "text-red-500 font-bold" : ""
				}`}>
				{preText !== "Ended Work at" ? (
					<>
						({padWithZero(elapsedTime.hours)}:{padWithZero(elapsedTime.minutes)}:{padWithZero(elapsedTime.seconds)})
					</>
				) : (
					"(00:00:00)"
				)}
			</p>
		</div>
	);
};

export default TimeSinceDate;
