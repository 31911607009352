import { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";

const FieldInfoModal = ({ fieldData, setShowFieldInfoModal, fields }) => {
	const [procesedConditions, setProcessedCondition] = useState([]);
	const [processedSelection, setProcessedSelection] = useState([]);

	useEffect(() => {
		if (fieldData.field_settings) {
			let breakSeparated = fieldData.field_settings.split("[break]");
			setProcessedSelection(breakSeparated);
		}
	}, [fieldData]);

	useEffect(() => {
		if (fieldData.conditional_list) {
			let commaSeparated = fieldData.conditional_list.split(",");
			let currentProcessedConditions = [];
			for (let i = 0; i < commaSeparated.length; i += 2) {
				let foundField = "";
				let isYesNoField = false;
				for (let j = 0; j < fields.length; j++) {
					if (fields[j].id === parseInt(commaSeparated[i])) {
						if (fields[j].field_type === "yes/no") {
							isYesNoField = true;
						}
						foundField = fields[j].name;
						break;
					}
				}
				if (foundField === "") {
					currentProcessedConditions.push({ field: "This condition", condition: "broken" });
				} else {
					let conditionValue = commaSeparated[i + 1].replaceAll("[comma1]", ",");
					if (isYesNoField) {
						if (`${conditionValue}` === `${1}`) {
							conditionValue = "yes";
						} else if (`${conditionValue}` === `${0}`) {
							conditionValue = "no";
						}
					}
					currentProcessedConditions.push({ field: foundField, condition: conditionValue });
				}
			}
			setProcessedCondition(currentProcessedConditions);
		}
	}, [fieldData, fields]);

	return (
		<div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black/50 z-50" onClick={() => setShowFieldInfoModal(false)}>
			<div className="bg-white w-full lg:w-3/4 m-2 rounded-lg border border-black" onClick={(e) => e.stopPropagation()}>
				<div className="p-4 text-left font-bold text-3xl flex justify-between items-center bg-gray-300 rounded-t-lg">
					{fieldData.name}
					<MdClose color="red" className="text-3xl cursor-pointer" onClick={() => setShowFieldInfoModal(false)} />
				</div>
				<hr className="border-black" />
				<div className="p-4 ">
					<div className="max-h-[60vh] overflow-y-scroll">
						{fieldData.description === "" ? null : <p className="text-xl font-bold">Description:</p>}
						<p> {fieldData.description}</p>
						<p className="text-xl font-bold">Type: </p>
						<p>{fieldData.field_type}</p>
						{fieldData.field_type === "selection" ? (
							<>
								<p className="text-xl font-bold">Selections: </p>
								<div>
									{processedSelection.map((mapData, index) => {
										return <p key={index}>{mapData}</p>;
									})}
								</div>
							</>
						) : null}
						{fieldData.odoo_mapping ? (
							<>
								<p className="text-xl font-bold">Odoo Mapping: </p>
								<p>{fieldData.odoo_mapping}</p>
							</>
						) : null}
						{procesedConditions.length > 0 ? (
							<>
								<p className="text-xl font-bold">Conditionals: </p>
								{procesedConditions.length > 0 ? (
									procesedConditions.map((mapData, index) => {
										return (
											<div key="index">
												<p>
													{mapData.field} is {mapData.condition}
												</p>
											</div>
										);
									})
								) : (
									<p>No conditionals</p>
								)}
							</>
						) : null}
						<div className="flex flex-col">
							<p className="text-xl font-bold">Misc.</p>
							<label>
								<input
									type="checkbox"
									id="is-required"
									className="border border-rounded border-2 p-2 "
									checked={fieldData.required}
									disabled="disabled"></input>{" "}
								Required
							</label>
							<label>
								<input
									type="checkbox"
									id="is-required"
									className="border border-rounded border-2 p-2 "
									checked={fieldData.read_only}
									disabled="disabled"></input>{" "}
								Read Only
							</label>
							<label>
								<input
									type="checkbox"
									id="is-required"
									className="border border-rounded border-2 p-2 "
									checked={fieldData.allow_photos}
									disabled="disabled"></input>{" "}
								Allow Photos
							</label>
							<label>
								<input
									type="checkbox"
									id="is-required"
									className="border border-rounded border-2 p-2 "
									checked={fieldData.require_photos ? 1 : 0}
									disabled="disabled"></input>{" "}
								Require Photos
							</label>
							<label>
								<input
									type="checkbox"
									id="is-required"
									className="border border-rounded border-2 p-2 "
									checked={fieldData.allow_comment}
									disabled="disabled"></input>{" "}
								Allow Comment
							</label>
							<label>
								<input
									type="checkbox"
									id="is-required"
									className="border border-rounded border-2 p-2 "
									checked={fieldData.require_comment ? 1 : 0}
									disabled="disabled"></input>{" "}
								Require Comment
							</label>
							<label>
								<input
									type="checkbox"
									id="is-required"
									className="border border-rounded border-2 p-2 "
									checked={fieldData.comment_has_validation}
									disabled="disabled"></input>{" "}
								Validate Comment
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FieldInfoModal;
