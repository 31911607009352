import { useEffect, useState } from "react";
import { BsTrash } from "react-icons/bs";
import ConditionalModal from "./ConditionalModal";
import ConditionalRender from "./ConditionalRenderer";
import UpdateConditionalModal from "./UpdateConditionalModal";

const FieldModal = (props) => {
	const [selectedFieldName, setSelectedFieldName] = useState("");
	const [description, setDescription] = useState("");
	const [selectedType, setSelectedType] = useState("");
	const [selectionSettings, setSelectionSettings] = useState("");
	const [selectedConditionals, setSelectedConditionals] = useState([]);
	const [required, setRequired] = useState(false);
	const [readOnly, setReadOnly] = useState(false);
	const [allowComment, setAllowComment] = useState(false);
	const [validateComment, setValidateComment] = useState(false);
	const [fieldNameNotInList, setFieldNameNotInList] = useState(false);
	const [allowPhotos, setAllowPhotos] = useState(false);
	const [selectionsList, setSelectionList] = useState([]);
	const [odooMapping, setOdooMapping] = useState("");
	const [defaultName, setDefaultName] = useState(null);
	const [showConditionalModal, setShowConditionalModal] = useState(false);
	const [requirePhotos, setRequirePhotos] = useState(false);
	const [requireComment, setRequireComment] = useState(false);
	const [showUpdateConditionalModal, setShowUpdateConditionalModal] = useState(false);

	const postField = () => {
		if (props.api === "" || props.token === "") {
			return;
		}
		let processedSelectionList = "";
		if (selectionsList && selectionsList.length > 0) {
			for (let i = 0; i < selectionsList.length; i++) {
				processedSelectionList += selectionsList[i];
				if (i !== selectionsList.length - 1) {
					processedSelectionList += "[break]";
				}
			}
		}
		let processedCondition = [];
		for (let i = 0; i < selectedConditionals.length; i++) {
			processedCondition.push(selectedConditionals[i].selectedConditionField, selectedConditionals[i].conditionalFieldValue.replaceAll(",", "[comma1]"));
		}
		processedCondition = processedCondition.toString();
		let newPosition = props.fields.length > 0 ? props.fields[props.fields.length - 1].position + 1 : 1;

		let newField = {
			section_id: props.section_id,
			name: selectedFieldName,
			field_type: selectedType,
			field_settings: processedSelectionList,
			position: newPosition,
			conditional_list: processedCondition && processedCondition.length > 0 ? processedCondition : "",
			description: description,
			required: required ? 1 : 0,
			read_only: readOnly ? 1 : 0,
			allow_comment: allowComment ? 1 : 0,
			comment_has_validation: validateComment ? 1 : 0,
			allow_photos: allowPhotos ? 1 : 0,
			require_photos: requirePhotos ? 1 : 0,
			require_comment: requireComment ? 1 : 0,
			odoo_mapping: odooMapping,
		};

		fetch(props.api + "/field", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: props.token,
			},
			body: JSON.stringify(newField),
		})
			.then((res) => {
				if (!res.ok) {
					// put an error here
				} else {
					return res.json();
				}
			})
			.then((res) => {
				if (res) {
					newField.id = res.insertId;
					props.setFields([...props.fields, newField]);
					props.setShowCreateFieldModal(false);
					setAllowComment(false);
					setRequired(false);
					setReadOnly(false);
					setValidateComment(false);
					setAllowPhotos(false);
					setDescription("");
					setRequirePhotos(false);
					setRequireComment(false);
				}
			});
	};

	const putField = () => {
		if (props.api === "" || props.token === "") {
			return;
		}
		let processedSelectionList = "";
		if (selectionsList && selectionsList.length > 0) {
			for (let i = 0; i < selectionsList.length; i++) {
				processedSelectionList += selectionsList[i];
				if (i !== selectionsList.length - 1) {
					processedSelectionList += "[break]";
				}
			}
		}
		let processedCondition = [];
		for (let i = 0; i < selectedConditionals.length; i++) {
			processedCondition.push(selectedConditionals[i].selectedConditionField, selectedConditionals[i].conditionalFieldValue.replaceAll(",", "[comma1]"));
		}
		processedCondition = processedCondition.toString();

		let newField = {
			section_id: props.section_id,
			name: selectedFieldName,
			field_type: selectedType,
			field_settings: processedSelectionList,
			position: props.fieldValues.position,
			conditional_list: processedCondition && processedCondition.length > 0 ? processedCondition : "",
			description: description,
			required: required ? 1 : 0,
			read_only: readOnly ? 1 : 0,
			allow_comment: allowComment ? 1 : 0,
			comment_has_validation: validateComment ? 1 : 0,
			allow_photos: allowPhotos ? 1 : 0,
			require_photos: requirePhotos ? 1 : 0,
			require_comment: requireComment ? 1 : 0,
			odoo_mapping: odooMapping,
		};

		fetch(props.api + "/field/" + props.fieldId, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: props.token,
			},
			body: JSON.stringify(newField),
		})
			.then((res) => {
				if (!res.ok) {
					// put an error here
				} else {
					return res.json();
				}
			})
			.then((res) => {
				if (res) {
					let arrayCopy = [...props.fields];
					for (let i = 0; i < props.fields.length; i++) {
						if (props.fields[i].id === props.fieldId) {
							newField.id = props.fieldId;
							arrayCopy[i] = newField;
							break;
						}
					}
					props.setFields(arrayCopy);
					props.setShowCreateFieldModal(false);
					setAllowComment(false);
					setRequired(false);
					setReadOnly(false);
					setValidateComment(false);
					setAllowPhotos(false);
					setDescription("");
					setRequirePhotos(false);
					setRequireComment(false);
				}
			});
	};

	const sendFieldData = (e) => {
		e.preventDefault();
		if (props.isCreatingField) {
			postField();
		} else {
			putField();
		}
	};

	useEffect(() => {
		if (!props.fieldValues || !props.fieldWordBank) {
			return;
		}

		setDefaultName(props.fieldValues.name);
		setSelectedFieldName(props.fieldValues.name);
		setDescription(props.fieldValues.description);
		setRequired(props.fieldValues.required);
		setReadOnly(props.fieldValues.read_only);
		setAllowComment(props.fieldValues.allow_comment ? props.fieldValues.allow_comment : 0);
		setValidateComment(props.fieldValues.comment_has_validation ? props.fieldValues.comment_has_validation : 0);
		setRequirePhotos(props.fieldValues.require_photos ? props.fieldValues.require_photos : 0);
		setRequireComment(props.fieldValues.require_comment ? props.fieldValues.require_comment : 0);
		setSelectedType(props.fieldValues.field_type);
		if (props.fieldValues.field_type === "photos") {
			setAllowPhotos(true);
		} else {
			setAllowPhotos(props.fieldValues.allow_photos);
		}
		if (props.fieldValues.conditional_list) {
			let conditionalData = props.fieldValues.conditional_list.split(",");
			if (conditionalData.length >= 2) {
				let tempConditionalArr = [];
				for (let i = 0; i < conditionalData.length; i += 2) {
					let foundName = "";
					let foundType = "";
					for (let j = 0; j < props.fields.length; j++) {
						if (props.fields[j].id.toString() === conditionalData[i].toString()) {
							foundName = props.fields[j].name;
							foundType = props.fields[j].field_type;
							break;
						}
					}
					tempConditionalArr.push({
						selectedConditionFieldName: foundName,
						selectedFieldType: foundType,
						selectedConditionField: conditionalData[i],
						conditionalFieldValue: conditionalData[i + 1].replaceAll("[comma1]", ","),
					});
				}
				setSelectedConditionals(tempConditionalArr);
			}
		}
		setSelectionList(props.fieldValues.field_settings.split("[break]"));
		if (props.fieldValues.odoo_mapping) {
			setOdooMapping(props.fieldValues.odoo_mapping);
		}
	}, [props.fieldValues, props.fieldWordBank, props.fields]);

	const verifyFieldNameInList = () => {
		for (let i = 0; i < props.fieldWordBank.length; i++) {
			if (props.fieldWordBank[i].name === selectedFieldName || selectedFieldName === defaultName) {
				return;
			}
		}

		setSelectedFieldName("");
		setFieldNameNotInList(true);
	};

	const setToSelection = (e) => {
		e.preventDefault();
		let arrayClone = selectionsList;
		if (selectionSettings !== "" && arrayClone.includes(selectionSettings) === false) {
			arrayClone.push(selectionSettings);
			setSelectionList([...arrayClone]);
		} else {
		}
		setSelectionSettings("");
	};

	return (
		<div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black/50 z-50">
			<div className="bg-white w-full lg:w-3/4 m-2 rounded-lg">
				{showConditionalModal ? (
					<ConditionalModal
						fields={props.fields}
						selectedFieldName={selectedFieldName}
						selectedConditionals={selectedConditionals}
						setSelectedConditionals={setSelectedConditionals}
						setShowConditionalModal={setShowConditionalModal}
					/>
				) : null}
				{showUpdateConditionalModal ? (
					<UpdateConditionalModal
						fields={props.fields}
						selectedFieldName={selectedFieldName}
						selectedConditionals={selectedConditionals}
						setSelectedConditionals={setSelectedConditionals}
						setShowUpdateConditionalModal={setShowUpdateConditionalModal}
					/>
				) : null}
				<p className="p-4 text-left font-bold text-3xl">{props.isCreatingField ? "Add New Field" : "Update Field"}</p>
				<hr></hr>

				<form onSubmit={(e) => sendFieldData(e)} className="w-full max-w-full ">
					<div className="flex flex-col md:flex-row gap-5 md:gap-10 p-4 max-h-[70vh] overflow-y-scroll">
						<div className="md:basis-1/2">
							<div className="flex items-center justify-between flex-wrap">
								<label className="text-xl font-bold">Field Name</label>
								<input
									list="nameWordBank"
									onBlur={() => verifyFieldNameInList()}
									className="border border-rounded border-2 p-1 w-full"
									value={selectedFieldName}
									onChange={(e) => {
										setSelectedFieldName(e.target.value);
										setFieldNameNotInList(false);
									}}
									required
								/>
								<datalist id="nameWordBank">
									{props.fieldWordBank && props.fieldWordBank.length > 0
										? props.fieldWordBank.map((values) => {
												if (props.fields && props.fields.length > 0) {
													for (let i = 0; i < props.fields.length; i++) {
														if (props.fields[i].name === values.name) {
															return null;
														}
													}
												}
												return (
													<option value={values.name} key={values.id}>
														{values.name}
													</option>
												);
										  })
										: null}
								</datalist>
								{fieldNameNotInList ? <p className="text-red-500">Please select a value from the list</p> : null}
							</div>

							<div className="flex items-center justify-between flex-wrap">
								<label className="text-xl font-bold">Description </label>
								<input
									type="text"
									className="border border-rounded border-2 p-1 w-full"
									value={description}
									onChange={(e) => setDescription(e.target.value)}></input>
							</div>

							{selectedType === "selection" ? <hr className="" /> : null}

							<div className="flex items-center justify-between flex-wrap">
								<label className="text-xl font-bold">Type </label>
								<select
									className="border border-rounded border-2 p-1 w-full"
									required
									value={selectedType}
									onChange={(e) => {
										if (e.target.value === "photos") {
											setAllowPhotos(true);
										}
										setSelectedType(e.target.value);
										setSelectionSettings("");
										setSelectionList([]);
									}}>
									<option value=""></option>
									{props.fieldTypes && props.fieldTypes.length > 0
										? props.fieldTypes.map((values, index) => {
												return (
													<option value={values} key={index}>
														{values}
													</option>
												);
										  })
										: null}
								</select>
							</div>

							{selectedType === "selection" ? (
								<div className="w-full">
									<div className="flex justify-between flex-wrap flex-col w-full gap-2">
										<label className="text-xl font-bold">Selections</label>
										{selectionsList && selectionsList.length > 0 ? (
											<div className="max-h-[10vh] overflow-y-scroll border border-2 p-2">
												{selectionsList && selectionsList.length > 0
													? selectionsList.map((value, index) => {
															return (
																<span key={index} className="flex items-center gap-2">
																	<BsTrash
																		color="red"
																		onClick={() =>
																			setSelectionList(selectionsList.filter((selection) => selection !== value))
																		}
																		className="cursor-pointer text-lg"
																	/>
																	{value}
																</span>
															);
													  })
													: null}
											</div>
										) : null}
										<div className="flex w-full items-center justify-center">
											<input
												type="text"
												className="border border-rounded border border-2 p-2 w-full"
												value={selectionSettings}
												onChange={(e) => {
													e.preventDefault();
													setSelectionSettings(e.target.value);
												}}></input>
										</div>
										<button
											type="button"
											onClick={(e) => {
												e.preventDefault();
												setToSelection(e);
											}}
											className={` p-3 text-white mt-2 ${
												selectionSettings && selectionSettings !== "" ? "bg-green-700" : "bg-gray-500"
											}`}>
											Add Selection
										</button>
									</div>
									<hr className="mt-4 md:hidden" />
								</div>
							) : null}
						</div>

						<div className="md:basis-1/2">
							{selectedType !== "photos" ? (
								<div className="flex items-center justify-between flex-wrap mb-1">
									<label className="text-xl font-bold">Odoo Mapping </label>
									<input
										type="text"
										className="border border-rounded border-2 p-1 w-full"
										value={odooMapping}
										onChange={(e) => setOdooMapping(e.target.value)}></input>
								</div>
							) : null}
							{props.fields && props.fields.length >= 1 ? (
								<div className="flex flex-col flex-wrap gap-1 justify-between">
									<p className="text-xl font-bold">Conditionals</p>
									{selectedConditionals && selectedConditionals.length > 0 ? (
										<div className="max-h-[10vh] overflow-y-scroll border border-2 p-2">
											{selectedConditionals && selectedConditionals.length > 0
												? selectedConditionals.map((mapData, index) => {
														return (
															<div className="flex items-center gap-2" key={index}>
																<BsTrash
																	color="red"
																	onClick={(e) => {
																		e.preventDefault();
																		setSelectedConditionals(
																			selectedConditionals.filter((selection) => selection !== mapData)
																		);
																	}}
																	className="cursor-pointer"
																/>
																<p>
																	{mapData.selectedConditionFieldName} is{" "}
																	{mapData.selectedFieldType === "yes/no"
																		? mapData.conditionalFieldValue === "1" ||
																		  mapData.conditionalFieldValue === 1 ||
																		  (typeof mapData.conditionalFieldValue === "string" &&
																				mapData.conditionalFieldValue.trim().toLowerCase() === "yes")
																			? "yes"
																			: "no"
																		: mapData.conditionalFieldValue}
																</p>
															</div>
														);
												  })
												: null}
										</div>
									) : null}

									<button
										type="button"
										className="bg-green-700 p-3 text-white mt-2"
										onClick={(e) => {
											e.preventDefault();
											setShowConditionalModal(true);
										}}>
										Add Conditional
									</button>
									{selectedConditionals.length > 0 ? (
										<button
											type="button"
											className="bg-blue-700 p-3 text-white mt-2"
											onClick={(e) => {
												e.preventDefault();
												setShowUpdateConditionalModal(true);									
											}}>
											Update Conditional
										</button>
									) : null}
								</div>
							) : null}
							<div className="my-2 break-words">
								<p className="text-xl font-bold">Misc.</p>
								<div className="flex items-center justify-between ">
									<label className="basis-1/2 select-none" htmlFor="is-required">
										Required:
									</label>
									<input
										type="checkbox"
										id="is-required"
										className="border border-rounded border-2 p-2 basis-1/2"
										checked={required}
										onChange={(e) => setRequired(e.target.checked)}></input>
									<label className="basis-1/2 select-none" htmlFor="read-only">
										Read-Only:
									</label>
									<input
										type="checkbox"
										id="read-only"
										className="border border-rounded border-2 p-2 basis-1/2"
										checked={readOnly}
										onChange={(e) => setReadOnly(e.target.checked)}></input>
								</div>
								<div className="flex items-center justify-between">
									<label className="basis-1/2 select-none" htmlFor="allow-comment">
										Allow Comment:
									</label>
									<input
										type="checkbox"
										className="border border-rounded border-2 p-2 basis-1/2"
										id="allow-comment"
										checked={allowComment}
										onChange={(e) => {
											if (validateComment) {
												setAllowComment(true);
											} else {
												setAllowComment(e.target.checked);
											}
										}}></input>

									<label className="basis-1/2 select-none" htmlFor="require-comment">
										Require Comment:
									</label>
									<input
										id="require-comment"
										type="checkbox"
										className="border border-rounded border-2 p-2 basis-1/2"
										checked={requireComment}
										onChange={(e) => {
											if (e.target.checked === true) {
												setAllowComment(true);
											}
											setRequireComment(e.target.checked);
										}}></input>
								</div>
								<div className="flex items-center justify-between">

									<label className="basis-1/4 select-none" htmlFor="validate-comment">
										Validate Comment:
									</label>
									<input
										id="validate-comment"
										type="checkbox"
										className="border border-rounded border-2 p-2 basis-1/4"
										checked={validateComment}
										onChange={(e) => {
											setAllowComment(true);
											setValidateComment(e.target.checked);
										}}></input>
									{/**/}
									<label className="basis-1/2 select-none">
									</label>


								</div>
								<div className="flex items-center justify-between">
									<label className="basis-1/2 select-none" htmlFor="allow-photos">
										Allow Photos:
									</label>
									<input
										type="checkbox"
										id="allow-photos"
										className="border border-rounded border-2 p-2 basis-1/2"
										checked={allowPhotos}
										onChange={(e) => {
											selectedType === "photos" || requirePhotos === 1 || requirePhotos === true
												? setAllowPhotos(true)
												: setAllowPhotos(e.target.checked);
										}}
										readOnly={selectedType === "photos"}></input>
									<label className="basis-1/2 select-none" htmlFor="require-photos">
										Require Photos:
									</label>
									<input
										id="require-photos"
										type="checkbox"
										className="border border-rounded border-2 p-2 basis-1/2"
										checked={requirePhotos}
										onChange={(e) => {
											if (e.target.checked === true) {
												setAllowPhotos(true);
											}
											setRequirePhotos(e.target.checked);
										}}></input>
								</div>
							</div>
						</div>
					</div>
					<hr />
					<div className="flex gap-2 justify-end p-4">
						<button type="button" className="rounded-none bg-gray-500 p-2 text-white" onClick={() => props.setShowCreateFieldModal(false)}>
							Cancel
						</button>
						<button type="submit" className="rounded-none bg-blue-500 p-2 text-white">
							{props.isCreatingField ? "Create" : "Update"}
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default FieldModal;
