import { useEffect, useState } from "react";

const RequestStatus = ({ status }) => {
	const [color, setColor] = useState("");

	useEffect(() => {
		if (status === "active") {
			setColor("bg-blue-600");
		} else if (status === "completed") {
			setColor("bg-green-600");
		} else if (status === "awaiting approval") {
			setColor("bg-gray-600");
		} else if (status === "requesting retakes") {
			setColor("bg-red-600");
		}
	}, [status]);
	return (
		<>
			<div className={` rounded-lg ${color} text-white p-2 text-center`}>
				<p>{status}</p>
			</div>
		</>
	);
};

export default RequestStatus;
