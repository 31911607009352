import { useEffect, useState } from "react";
import LoadingCircle from "../utility/LoadingCircle";
import FieldConverter from "./inputtypes/FieldConverter";
import { AiFillWarning } from "react-icons/ai";

const ProjectSection = ({
	api,
	token,
	data,
	projectId,
	conditionalData,
	setConditionalData,
	projectName,
	templateId,
	templateName,
	sectionId,
	stage,
	templateTypeId,
	projectHtmlId,
	requiredFieldsBySection,
	setRequiredFieldsBySection,
	showThisSection,
	initialThumbnailPathes,
	setLastModifiedOn,
	showElement,
	selectedOdooModel,
	setLastUpdatedBy,
	odooInstallationName,
	odooParentName,
}) => {
	const [fields, setFields] = useState([]);
	const [fieldData, setFieldData] = useState([]);
	const [odooMappings, setOdooMappings] = useState([]);
	const [odooId, setOdooId] = useState(-1);
	const [isLoading, setIsLoading] = useState(false);
	const [requiredFieldsRemaining, setRequiredFieldsRemaining] = useState([]);
	const [showOnlyRequiredRemaining, setShowOnlyRequiredRemaining] = useState(false);

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		setIsLoading(true);
		fetch(api + "/project/section/" + data.id + "?project_id=" + projectId, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			signal,
		})
			.then((res) => {
				if (!res.ok) {
					setIsLoading(false);
					// put an error here
				} else {
					return res.json();
				}
			})
			.then((res) => {
				if (res) {
					setIsLoading(false);
					const unsortedFields = res.fields;
					const sortedFields = unsortedFields.sort((a, b) => a.position - b.position);

					setFields(sortedFields);
					setFieldData(res.projectData);
					setOdooMappings(res.odooMappings);
					setOdooId(res.odooId);
				} else {
					setIsLoading(false);
				}
			})
			.catch((err) => {});

		return () => {
			controller.abort();
		};
	}, [api, token, data.id, projectId]);
	useEffect(() => {
		for (let i = 0; i < conditionalData.length; i++) {
			if (data.id === conditionalData[i].section_condition_id && conditionalData[i].conditionMet === false) {
				for (let j = 0; j < fieldData.length; j++) {
					if (fieldData[j].field_id === conditionalData[i].field_condition_id) {
						// rather than using the field data id, we first check if there is an odoo mapping
						let currentFieldvalue = fieldData[j].value;
						let filteredOdoOMappings = odooMappings.filter((filterData) => filterData.templatefieldid === fieldData[j].field_id);
						if (filteredOdoOMappings.length > 0) {
							if (filteredOdoOMappings[0].odoo_value !== "failedtogetdataforpmscode400") {
								currentFieldvalue = filteredOdoOMappings[0].odoo_value;
							}
						}

						let conditionMet = false;
						if (conditionalData[i].condition_type === ">=") {
							if (parseInt(currentFieldvalue) >= parseInt(conditionalData[i].condition_value)) {
								conditionMet = true;
							}
						} else if (conditionalData[i].condition_type === ">") {
							if (parseInt(currentFieldvalue) > parseInt(conditionalData[i].condition_value)) {
								conditionMet = true;
							}
						} else if (conditionalData[i].condition_type === "=") {
							if (currentFieldvalue == conditionalData[i].condition_value) {
								conditionMet = true;
							}
						} else if (conditionalData[i].condition_type === "<") {
							if (parseInt(currentFieldvalue) < parseInt(conditionalData[i].condition_value)) {
								conditionMet = true;
							}
						} else if (conditionalData[i].condition_type === "<=") {
							if (parseInt(currentFieldvalue) <= parseInt(conditionalData[i].condition_value)) {
								conditionMet = true;
							}
						}
						if (conditionMet) {
							let copyOfData = conditionalData;
							copyOfData[i].conditionMet = true;
							setConditionalData([...copyOfData]);
						}
					}
				}
			}
		}
	}, [conditionalData, data.id, fieldData, odooMappings, setConditionalData]);

	useEffect(() => {
		if (requiredFieldsRemaining.length === 0) {
			setShowOnlyRequiredRemaining(false);
		}
	}, [requiredFieldsRemaining.length]);

	useEffect(() => {
		if (requiredFieldsRemaining.length !== requiredFieldsBySection[sectionId].length) {
			let tempRequiredFieldsBySection = { ...requiredFieldsBySection };
			tempRequiredFieldsBySection[sectionId] = requiredFieldsRemaining;
			setRequiredFieldsBySection(tempRequiredFieldsBySection);
		}
	}, [requiredFieldsBySection, requiredFieldsRemaining, sectionId, setRequiredFieldsBySection]);

	const toggleShowOnlyRequired = (e) => {
		e.preventDefault();
		setShowOnlyRequiredRemaining((prev) => !prev);
		const sectionHTML = document.getElementById(projectHtmlId);
		sectionHTML.scrollIntoView({ behavior: "smooth", block: "start" });
	};

	return (
		<div id={projectHtmlId} className={`${showThisSection ? "" : "hidden"}`}>
			{
				<div className="flex flex-col gap-3 mb-2 md:p-1" id={data.name.replaceAll(" ", "-")}>
					<div className="text-2xl font-bold sticky -top-1 bg-gray-200 p-1 flex flex-col md:flex-row gap-2">
						<p>{data.name}</p>
						<div className="flex gap-2">
							{showElement ? (
								requiredFieldsRemaining.length > 0 ? (
									<div
										className="flex items-center text-sm bg-yellow-300 p-1 rounded-lg gap-1 cursor-pointer select-none border border-black"
										title="show only the required fields"
										onClick={toggleShowOnlyRequired}>
										<AiFillWarning />
										<p>{requiredFieldsRemaining.length} </p>
									</div>
								) : null
							) : null}
							{showElement ? (
								requiredFieldsRemaining.length > 0 ? (
									showOnlyRequiredRemaining && requiredFieldsRemaining.length ? (
										<div
											className="flex items-center text-sm bg-gray-50 p-1 rounded-lg cursor-pointer select-none border border-black text-center"
											title="show only the required fields"
											onClick={toggleShowOnlyRequired}>
											<p>Show All</p>
										</div>
									) : (
										<div
											className="flex items-center text-xs bg-gray-50 p-1 rounded-lg cursor-pointer select-none border border-black text-center"
											title="show only the required fields"
											onClick={toggleShowOnlyRequired}>
											<p>Show Required Only</p>
										</div>
									)
								) : null
							) : null}
						</div>
					</div>
					{fields && fields.length > 0 ? (
						fields.map((mapData, index) => {
							return (
								<FieldConverter
									conditionalData={conditionalData}
									setConditionalData={setConditionalData}
									templateName={templateName}
									allFields={fields}
									sectionId={sectionId}
									projectId={projectId}
									templateId={templateId}
									fieldData={fieldData}
									setFieldData={setFieldData}
									projectName={projectName}
									key={mapData.id}
									data={mapData}
									api={api}
									token={token}
									sectionName={data.name}
									stage={stage}
									templateTypeId={templateTypeId}
									odooMappings={odooMappings}
									odooId={odooId}
									setRequiredFieldsRemaining={setRequiredFieldsRemaining}
									showThisField={!showOnlyRequiredRemaining ? true : requiredFieldsRemaining.includes(mapData.id.toString()) ? true : false}
									showThisSection={showThisSection}
									initialThumbnailPathes={initialThumbnailPathes}
									setLastModifiedOn={setLastModifiedOn}
									selectedOdooModel={selectedOdooModel}
									setLastUpdatedBy={setLastUpdatedBy}
									odooInstallationName={odooInstallationName}
									odooParentName={odooParentName}
								/>
							);
						})
					) : isLoading ? (
						<div className="flex flex-col items-center justify-center w-full my-2">
							<LoadingCircle />
							<p>Loading Fields</p>
						</div>
					) : (
						<p>This section does not have fields</p>
					)}
				</div>
			}
		</div>
	);
};

export default ProjectSection;
