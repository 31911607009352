import { BiDownArrow, BiUpArrow } from "react-icons/bi";
import { GrAdd } from "react-icons/gr";
import { useState } from "react";
import SectionModal from "./SectionModal";
import LoadingCircle from "../../utility/LoadingCircle";

const SectionList = (props) => {
	const [showSectionModal, setShowSectionModal] = useState(false);

	const moveSectionUp = async (indexToSwitch) => {
		let destination = indexToSwitch - 1;
		if (destination < 0) {
			return;
		}
		let arrayCopy = [...props.sections];
		let swappedPosition = arrayCopy[destination].position;
		await props.updateSectionInDB(arrayCopy[destination], arrayCopy[indexToSwitch].position);
		await props.updateSectionInDB(arrayCopy[indexToSwitch], arrayCopy[destination].position);
		arrayCopy[destination].position = arrayCopy[indexToSwitch].position;
		arrayCopy[indexToSwitch].position = swappedPosition;

		arrayCopy = arrayCopy.sort((a, b) => a.position - b.position);
		props.setSections(arrayCopy);
	};

	const moveSectionDown = async (indexToSwitch) => {
		let destination = indexToSwitch + 1;
		if (destination >= props.sections.length) {
			return;
		}
		let arrayCopy = [...props.sections];
		let swappedPosition = arrayCopy[destination].position;
		await props.updateSectionInDB(arrayCopy[destination], arrayCopy[indexToSwitch].position);
		await props.updateSectionInDB(arrayCopy[indexToSwitch], arrayCopy[destination].position);
		arrayCopy[destination].position = arrayCopy[indexToSwitch].position;
		arrayCopy[indexToSwitch].position = swappedPosition;

		arrayCopy = arrayCopy.sort((a, b) => a.position - b.position);
		props.setSections(arrayCopy);
	};

	return (
		<div className="mb-4  md:sticky md:top-0 z-50">
			{showSectionModal ? (
				<SectionModal
					isCreatingSection={true}
					setShowSectionModal={setShowSectionModal}
					sectionWordBank={props.sectionWordBank}
					api={props.api}
					template_id={props.template_id}
					token={props.token}
					sections={props.sections}
					setSections={props.setSections}
				/>
			) : null}

			<div className="flex justify-between items-center text-2xl">
				<p>Sections</p>
				{props.sectionWordBank && props.sectionWordBank.length > 0 && props.isDrafting ? (
					<GrAdd
						className="text-3xl cursor-pointer"
						onClick={() => {
							setShowSectionModal(true);
						}}
					/>
				) : null}
			</div>
			<div className="md:overflow-y-scroll md:h-[60vh]">
				{props.sections && props.sections.length > 0 ? (
					props.sections.map((vals, index) => {
						return (
							<div key={vals.id} className="border-l-4 p-1 pl-2 flex justify-between items-center">
								<a className="text-blue-600 underline" href={"#" + vals.name.replaceAll(" ", "-")}>
									{vals.name}
								</a>
								<div className="flex text-2xl">
									{props.isDrafting ? (
										<>
											<BiDownArrow className="cursor-pointer select-none" onClick={() => moveSectionDown(parseInt(index))} />
											<BiUpArrow className="cursor-pointer select-none" onClick={() => moveSectionUp(parseInt(index))} />
										</>
									) : null}
								</div>
							</div>
						);
					})
				) : props.isLoading ? (
					<div className="flex flex-col items-center justify-center w-full my-2">
						<LoadingCircle />
						<p>Loading List</p>
					</div>
				) : (
					<p>There are no sections</p>
				)}
			</div>
		</div>
	);
};

export default SectionList;
