import { useState } from "react";
import FileSaver from "file-saver";
import { IoDocumentTextOutline } from "react-icons/io5";
import { VscJson } from "react-icons/vsc";
import { FaFileCsv } from "react-icons/fa";
import { BsExclamationCircleFill } from "react-icons/bs";
import LoadingCircle from "../utility/LoadingCircle";

const ExportModal = ({ setShowExportMenu, projectid, api, token, projectName, templateName }) => {
	const [failedToFetch, setFailedToFetch] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const downloadProjectToJson = (e) => {
		e.preventDefault();
		if (isLoading) {
			return;
		}
		setIsLoading(true);
		fetch(api + "/project/all-data/" + projectid, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: token,
			},
		})
			.then((res) => {
				setIsLoading(false);
				if (res.ok) {
					return res.json();
				} else {
					setFailedToFetch(true);
				}
			})
			.then((jsonFile) => {
				if (jsonFile) {
					let data = jsonFile.data[0];
					let formattedData = {
						project_id: data[0].project_id,
						odoo_id: data[0].odoo_id,
						project_name: data[0].name,
						template_name: data[0].tempalte_name,
						version: data[0].version,
					};
					const sections = data.map((mapData) => mapData.section_name);
					const formattedSections = {};
					for (let i = 0; i < sections.length; i++) {
						formattedSections[sections[i]] = data
							.filter((filterData) => filterData.section_name === sections[i])
							.map((mapData) => {
								let dbValue = mapData.db_value;
								if (mapData.field_type === "yes/no") {
									if (mapData.db_value === 1 || mapData.db_value === "1") {
										dbValue = "yes";
									} else if (mapData.db_value === 0 || mapData.db_value === "0") {
										dbValue = "no";
									}
								}
								return {
									field_name: mapData.field_name,
									field_value_db: dbValue,
									field_value_odoo: mapData.odoo_value === "failedtogetdataforpmscode400" ? "" : mapData.odoo_value,
								};
							});
					}
					formattedData["sections"] = formattedSections;
					const blob = new Blob([JSON.stringify(formattedData)], {
						type: "application/json",
					});

					const url = URL.createObjectURL(blob, { type: "application/json" });
					const link = document.createElement("a");
					link.download = projectName + " -- template " + templateName + ".json";
					link.href = url;
					link.click();
					URL.revokeObjectURL(url);
				}
			});
	};

	const formatCSVHeader = (theString) => {
		return `"${theString.replaceAll('"', "'").replaceAll(" ", "_")}"`;
	};

	const formatCSVData = (theData) => {
		let formattedData = typeof theData === "string" ? `${theData.replaceAll('"', "'")}` : theData;
		formattedData = typeof formattedData === "undefined" || formattedData === null ? "" : formattedData;
		return `"${formattedData}"`;
	};

	const downloadProjectToCSV = (e) => {
		e.preventDefault();
		if (isLoading) {
			return;
		}
		setIsLoading(true);
		fetch(api + "/project/all-data/" + projectid, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: token,
			},
		})
			.then((res) => {
				setIsLoading(false);
				if (res.ok) {
					return res.json();
				} else {
					setFailedToFetch(true);
				}
			})
			.then((jsonFile) => {
				if (jsonFile) {
					let data = jsonFile.data;
					let allTemplates = {};

					for (let i = 0; i < data.length; i++) {
						let currentFields = [];
						for (let j = 0; j < data[i].length; j++) {
							currentFields.push(formatCSVHeader(data[i][j].field_name + "_PMS"));
							if (data[i][j].odoo_mapping !== "") {
								currentFields.push(formatCSVHeader(data[i][j].field_name + "_Odoo"));
							}
						}
						let currentSections = data[i].map((mapData) => formatCSVData(mapData.section_name));
						let templateNames = data[i].map((mapData) => mapData.template_name);
						templateNames = [...new Set(templateNames)];
						if (templateNames.length > 1) {
						} else {
							templateNames = templateNames[0];
						}
						if (!(templateNames in allTemplates)) {
							allTemplates[templateNames] = {
								sections: [...currentSections],
								fields: [...currentFields],
							};
						} else {
							allTemplates[templateNames].sections.push(...currentSections);
							allTemplates[templateNames].fields.push(...currentFields);
						}
					}
					let headers = [];
					for (const template in allTemplates) {
						allTemplates[template].fields = [...new Set(allTemplates[template].fields)];
						allTemplates[template].sections = [...new Set(allTemplates[template].sections)];
						headers.push(...allTemplates[template].fields);
					}

					headers = ["project_name_PMS", "section_name_PMS", ...new Set(headers)];
					let formattedData = [];
					for (let i = 0; i < data.length; i++) {
						formattedData.push({});
						for (let j = 0; j < data[i].length; j++) {
							let sectionName = formatCSVData(data[i][j].section_name);
							let fieldName = formatCSVHeader(data[i][j].field_name + "_PMS");
							let odooFieldName = formatCSVHeader(data[i][j].field_name + "_Odoo");
							let projectName = formatCSVData(data[i][j].name);
							let odooValue = formatCSVData(data[i][j].odoo_value === "failedtogetdataforpmscode400" ? "" : data[i][j].odoo_value);
							let dbValue = formatCSVData(data[i][j].db_value);
							if (data[i][j].field_type === "yes/no") {
								if (data[i][j].db_value === 1 || data[i][j].db_value === "1") {
									dbValue = formatCSVData("yes");
								} else if (data[i][j].db_value === 0 || data[i][j].db_value === "0") {
									dbValue = formatCSVData("no");
								}
							}
							if (!(sectionName in formattedData[i])) {
								let rowLength = headers.length;
								let defaultRow = Array(rowLength).join(".").split(".");
								formattedData[i][sectionName] = [projectName, sectionName, ...defaultRow];
							}
							let fieldPos = headers.indexOf(fieldName);
							formattedData[i][sectionName][fieldPos] = dbValue;
							fieldPos = headers.indexOf(odooFieldName);
							formattedData[i][sectionName][fieldPos] = odooValue;
						}
					}

					let rows = [[headers]];
					for (const index in formattedData) {
						for (const sections in formattedData[index]) {
							rows.push(formattedData[index][sections]);
						}
					}
					let csvContent = "";
					rows.forEach((rowArray) => {
						let row = rowArray.join(",");
						csvContent += row + "\r\n";
					});

					const fileData = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
					FileSaver.saveAs(fileData, projectName + " -- template " + templateName + ".csv");
				}
			});
	};

	const generatePDF = (e) => {
		e.preventDefault();

		if (isLoading) {
			return;
		}
		const controller = new AbortController();
		const signal = controller.signal;
		setIsLoading(true);
		fetch(api + "/project/generate-pdf/" + projectid, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: token,
			},
			signal,
		})
			.then((res) => {
				setIsLoading(false);
				if (res.ok) {
					return res.blob();
				} else {
					setFailedToFetch(true);
				}
			})
			.then((pdfFile) => {
				if (pdfFile) {
					const blob = new Blob([pdfFile], {
						type: "application/octet-stream",
					});
					const fileURL = window.URL.createObjectURL(blob, { type: "application/octet-stream" });
					let currentDate = new Date();
					let formattedDate = `${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`;

					const link = document.createElement("a");
					link.download = projectName + " - " + templateName + " " + formattedDate + ".pdf";
					link.href = fileURL;
					link.click();
					URL.revokeObjectURL(fileURL);
				}
			})
			.catch((err) => {});

		return () => {
			controller.abort();
		};
	};

	return (
		<div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black/50 z-50">
			<div className="bg-white w-full lg:w-1/4 m-2 rounded-lg">
				<div className="m-4 flex justify-between">
					<p className="text-3xl">Export Project</p>
					<button type="button" className="rounded-lg bg-gray-500 p-2 text-white" onClick={() => setShowExportMenu(false)}>
						Close
					</button>
				</div>
				{isLoading ? (
					<div className="m-4 flex flex-col items-center justify-center">
						<LoadingCircle />
						<p>Exporting...</p>
					</div>
				) : null}
				<div className="m-4 text-white flex flex-wrap gap-3 justify-center">
					<div
						className={`flex items-center ${isLoading ? " bg-gray-600 " : " bg-blue-600 "} p-4 rounded-lg cursor-pointer`}
						onClick={(e) => generatePDF(e)}>
						<IoDocumentTextOutline color="white" size={32} />
						<p>PDF</p>
					</div>
					<div
						className={`flex items-center ${isLoading ? " bg-gray-600 " : " bg-blue-600 "} p-4 rounded-lg cursor-pointer`}
						onClick={(e) => downloadProjectToJson(e)}>
						<VscJson size={32} />
						<p>JSON</p>
					</div>
					<div
						className={`flex items-center ${isLoading ? " bg-gray-600 " : " bg-blue-600 "} p-4 rounded-lg cursor-pointer`}
						onClick={(e) => downloadProjectToCSV(e)}>
						<FaFileCsv size={32} />
						<p>CSV</p>
					</div>
				</div>
				{failedToFetch ? (
					<div className="m-4">
						<div className="flex items-center gap-2 bg-rose-100 p-2 mt-2 rounded-lg">
							<BsExclamationCircleFill color="red" className="text-3xl" />
							<p>Failed to export.</p>
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default ExportModal;
