const SaveImagePrompt = (props) => {
	const saveAndUpload = (e) => {
		props.uploadImage(e, props.saveName + ".jpg");
	};

	const cancelUpload = () => {
		props.setImgSrc(null);
		props.uploadSingleRef.current.value = null;
	};

	return (
		<div className={`fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black/50 z-50`}>
			<div className="bg-white p-4 flex flex-col items-center justify-center gap-3 overflow-y-scroll">
				<p className="font-bold text-2xl">Save this image?</p>
				<a className="hidden" href={props.imgSrc} download={props.saveName + ".jpg"}>
					Save and Upload
				</a>
				{props.imgSrc && props.imgSrc !== "" ? <img src={props.imgSrc} alt="to upload" className="max-h-96 w-auto max-w-screen" /> : null}
				<div className="flex gap-3">
					<div className="flex items-center justify-center bg-blue-600 text-white rounded-lg p-2">
						<p className="cursor-pointer" onClick={(e) => saveAndUpload(e)}>
							Save and Upload
						</p>
					</div>
					<div className="flex items-center justify-center bg-blue-600 text-white rounded-lg p-2">
						<a className="" href={props.imgSrc} onClick={() => props.setImgSrc(false)} download={props.saveName + ".jpg"}>
							Save
						</a>
					</div>
					<div className="flex items-center justify-center bg-blue-600 text-white rounded-lg p-2">
						<p className="cursor-pointer" onClick={(e) => props.uploadImage(e)}>
							Upload
						</p>
					</div>

					<div className="flex items-center justify-center bg-gray-600 text-white rounded-lg p-2">
						<p className="cursor-pointer" onClick={() => cancelUpload()}>
							Cancel
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SaveImagePrompt;
