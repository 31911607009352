import { useEffect, useState } from "react";
import LoadingCircle from "../../utility/LoadingCircle";

const SectionModal = (props) => {
	const [name, setName] = useState("");
	const [sectionNameNotInList, setSectionNameNotInList] = useState(false);
	const [conditionalSectionId, setConditionalSectionId] = useState("");
	const [conditionalFieldId, setConditionalFieldId] = useState("");
	const [conditionalType, setConditionalType] = useState("");
	const [conditionalValue, setConditionalValue] = useState("");
	const [preUpdateName, setPreUpdateName] = useState("");
	const [fields, setFields] = useState([]);
	const [loadingFields, setLoadingFields] = useState(false);
	const [hasConditionalRender, setHasConditionalRender] = useState(false);

	useEffect(() => {
		if (conditionalSectionId === "") {
			setFields([]);
			setConditionalType("");
			setConditionalValue("");
			setConditionalSectionId(0);
			setConditionalFieldId(0);
		}
	}, [conditionalSectionId]);

	useEffect(() => {
		if (
			props.api === "" ||
			props.token === "" ||
			conditionalSectionId === "" ||
			conditionalSectionId === 0 ||
			typeof conditionalSectionId === "undefined"
		) {
			return;
		}
		const controller = new AbortController();
		const signal = controller.signal;
		setLoadingFields(true);
		fetch(props.api + "/section/" + conditionalSectionId, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: props.token,
			},
			signal,
		})
			.then((res) => {
				if (!res.ok) {
					// put an error here
					setLoadingFields(false);
				} else {
					return res.json();
				}
			})
			.then((res) => {
				if (res) {
					setLoadingFields(false);
					setFields(res.fields);
				}
			})
			.catch((err) => {});

		return () => {
			controller.abort();
		};
	}, [props.api, props.token, conditionalSectionId]);

	const createSection = () => {
		if (props.api === "" || props.token === "") {
			return;
		}

		let newPosition = props.sections.length > 0 ? props.sections[props.sections.length - 1].position + 1 : 1;

		fetch(props.api + "/section", {
			method: "post",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: props.token,
			},
			body: JSON.stringify({
				name: name,
				position: newPosition,
				template_id: props.template_id,
				condition_type: conditionalType,
				condition_value: conditionalValue,
				field_condition_id: conditionalFieldId,
				section_condition_id: conditionalSectionId,
			}),
		})
			.then((res) => {
				if (!res.ok) {
					// put an error here
				} else {
					return res.json();
				}
			})
			.then((res) => {
				if (res) {
					props.setSections([
						...props.sections,
						{
							id: res.section_id,
							name: name,
							position: newPosition,
							template_id: props.template_id,
							condition_type: conditionalType,
							condition_value: conditionalValue,
							field_condition_id: conditionalFieldId,
							section_condition_id: conditionalSectionId,
						},
					]);
				}
			});
	};

	useEffect(() => {
		if (props.currentName && props.currentName !== "") {
			setName(props.currentName);
			setPreUpdateName(props.currentName);
		}
		if (props.field_condition_id && props.section_condition_id) {
			setHasConditionalRender(true);
		}
	}, [props.currentName, props.section_condition_id, props.field_condition_id]);

	useEffect(() => {
		if (props.field_condition_id && props.field_condition_id !== 0) {
			setConditionalFieldId(props.field_condition_id);
		}
		if (props.section_condition_id && props.section_condition_id !== 0) {
			setConditionalSectionId(props.section_condition_id);
		}
		if (props.condition_type && props.condition_type !== `''`) {
			setConditionalType(props.condition_type);
		}
		if (props.condition_value && props.condition_value !== `''`) {
			setConditionalValue(props.condition_value);
		}
	}, [props.condition_type, props.condition_value, props.field_condition_id, props.section_condition_id]);

	const closeModal = (e) => {
		e.preventDefault();
		props.setShowSectionModal(false);
	};

	const updateSection = () => {
		fetch(props.api + "/section/" + props.section_id, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: props.token,
			},
			body: JSON.stringify({
				name: name,
				position: props.position,
				condition_type: conditionalType,
				condition_value: conditionalValue,
				field_condition_id: conditionalFieldId,
				section_condition_id: conditionalSectionId,
			}),
		}).then((res) => {
			if (!res.ok) {
				// put an error here
			} else {
				let sectionCopy = [...props.sections];
				for (let i = 0; i < sectionCopy.length; i++) {
					if (sectionCopy[i].id === props.section_id) {
						sectionCopy[i].name = name;
						sectionCopy[i].condition_type = conditionalType;
						sectionCopy[i].condition_value = conditionalValue;
						sectionCopy[i].field_condition_id = conditionalFieldId;
						sectionCopy[i].section_condition_id = conditionalSectionId;
						props.setSections(sectionCopy);
						return;
					}
				}
			}
		});
	};

	const decidedCreateOrUpdate = (e) => {
		e.preventDefault();

		let foundSelection = false;
		if (name === preUpdateName && preUpdateName !== "") {
			foundSelection = true;
		} else {
			for (let i = 0; i < props.sectionWordBank.length; i++) {
				if (props.sectionWordBank[i].name === name) {
					foundSelection = true;
					break;
				}
			}
		}

		if (!foundSelection) {
			setName("");
			setSectionNameNotInList(true);
		} else {
			if (props.isCreatingSection) {
				createSection();
			} else {
				updateSection();
			}
			props.setShowSectionModal(false);
		}
	};

	const handleConditionalToggle = (e) => {
		setHasConditionalRender(e.target.checked);
		if (e.target.checked === false) {
			setConditionalFieldId(0);
			setConditionalSectionId(0);
			setConditionalValue("");
		}
	};

	return (
		<div className="fixed top-0 left-0 w-full h-screen flex justify-center items-center bg-black/50 z-50">
			<div className="bg-white m-2 rounded-lg w-full md:w-2/5">
				<p className="p-5 text-3xl">{props.isCreatingSection ? "Create a Section" : "Update Section Name"}</p>
				<hr></hr>
				<form className="p-8" onSubmit={(e) => e.preventDefault()}>
					<label>
						Name:
						<input
							list="nameWordBank"
							className="border border-rounded border-2 p-2 w-full mb-4"
							value={name}
							onChange={(e) => {
								setName(e.target.value);
								setSectionNameNotInList(false);
							}}
							required
						/>
						<datalist id="nameWordBank">
							{props.sectionWordBank && props.sectionWordBank.length > 0
								? props.sectionWordBank.map((value) => {
										if (props.sections && props.sections.length > 0) {
											for (let i = 0; i < props.sections.length; i++) {
												if (props.sections[i].name === value.name) {
													return null;
												}
											}
										}
										return (
											<option key={value.id} value={value.name}>
												{value.name}
											</option>
										);
								  })
								: null}
						</datalist>
						{sectionNameNotInList ? (
							<div className="w-full">
								<p className="text-red-500 mb-4">Please select a value from the list</p>
							</div>
						) : null}
					</label>
					<label>
						<input type="checkbox" checked={hasConditionalRender} onChange={handleConditionalToggle} />
						Display section conditionally?
					</label>
					<br />
					<br />
					{hasConditionalRender ? (
						<>
							<label>
								Display when section:
								<select
									className="border border-rounded border-2 p-2 w-full mb-4"
									value={conditionalSectionId}
									onChange={(e) => {
										setConditionalSectionId(e.target.value);
									}}
									required>
									<option value=""></option>
									{props.sections && props.sections.length > 0
										? props.sections.map((value) => {
												return (
													<option key={value.id} value={value.id}>
														{value.name}
													</option>
												);
										  })
										: null}
								</select>
							</label>
							{conditionalSectionId !== "" && conditionalSectionId !== 0 && fields && fields.length > 0 ? (
								<>
									{" "}
									<label>
										has field:
										<select
											list="fieldsList"
											className="border border-rounded border-2 p-2 w-full mb-4"
											value={conditionalFieldId}
											onChange={(e) => {
												setConditionalFieldId(e.target.value);
											}}
											required>
											<option value=""></option>
											{fields && fields.length > 0
												? fields.map((value) => {
														return (
															<option key={value.id} value={value.id}>
																{value.name}
															</option>
														);
												  })
												: null}
										</select>
									</label>
									<label>
										whose value is:{" "}
										<select
											className="border border-rounded border-2 p-2 mb-4"
											value={conditionalType}
											onChange={(e) => {
												setConditionalType(e.target.value);
											}}
											required>
											<option value=""></option>
											<option value="<">{"<"}</option>
											<option value="<=">{"<="}</option>
											<option value="=">{"="}</option>
											<option value=">=">{">="}</option>
											<option value=">">{">"}</option>
										</select>
										<input
											type="text"
											className="border border-rounded border-2 p-2 mb-4 w-full"
											value={conditionalValue}
											onChange={(e) => setConditionalValue(e.target.value)}
										/>
									</label>
								</>
							) : conditionalSectionId !== "" && conditionalSectionId !== 0 && loadingFields ? (
								<div className="flex flex-col items-center justify-center w-full my-2">
									<LoadingCircle />
									<p>Loading fields</p>
								</div>
							) : null}
						</>
					) : null}

					<div className="flex gap-2 justify-end">
						<button type="button" onClick={(e) => closeModal(e)} className="rounded-none bg-gray-500 p-2 text-white">
							Cancel
						</button>
						<button type="button" onClick={(e) => decidedCreateOrUpdate(e)} className="rounded-none bg-blue-500 p-2 text-white">
							{props.isCreatingSection ? "Create" : "Update"}
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default SectionModal;
