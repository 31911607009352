import { useState } from "react";
import { useNavigate } from "react-router-dom";

const CreateReportModal = ({ setShowCreateReportModal, api, token }) => {
	const navigate = useNavigate();
	const [reportName, setReportName] = useState("");
	const [saveName, setSaveName] = useState("");
	const [creating, setCreating] = useState(false);
	const [error, setError] = useState("");

	const changeReportNameViaEvent = (e) => {
		setReportName(e.target.value);
	};

	const changeSaveNameViaEvent = (e) => {
		setSaveName(e.target.value);
	};

	const createReport = (e) => {
		e.preventDefault();
		if (reportName === "" || saveName === "") {
			setError("Please make sure all values are filled out");
			return;
		}
		if (creating) {
			return;
		}
		setCreating(true);
		fetch(api + "/report/create", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: token,
			},
			body: JSON.stringify({ name: reportName, save_name: saveName }),
		})
			.then((res) => {
				if (res.ok) {
					return res.json();
				} else {
					setCreating(false);
					setError("Something went wrong. Please check your connection and try again.");
				}
			})
			.then((data) => {
				if (data) {
					setCreating(false);
					navigate("/report/" + data.reportId);
				}
			});
	};

	const closeModal = (e) => {
		e.preventDefault();
		setShowCreateReportModal(false);
	};

	return (
		<div className="fixed top-0 left-0 w-full h-screen flex justify-center items-center bg-black/50 z-30" onClick={closeModal}>
			<div className="bg-white m-2 rounded-lg w-full md:w-auto" onClick={(e) => e.stopPropagation()}>
				<div className="border border-black border-2 rounded-lg w-full md:basis-1/4 ">
					<div className="flex justify-between bg-gray-300 rounded-t-lg p-4 items-center">
						<p className="text-2xl font-bold text-center ">Create Report Template</p>
					</div>
					<form className="flex flex-col gap-4 p-4" onSubmit={createReport}>
						<div className="flex flex-col">
							<label htmlFor="report-name">Report Name:</label>
							<input
								type="text"
								name="name"
								className="border border-black p-2"
								id="report-name"
								placeholder="ex: report name"
								value={reportName}
								onChange={changeReportNameViaEvent}
								required></input>
						</div>
						<div className="flex flex-col">
							<label htmlFor="save-name">Save Name:</label>
							<input
								type="text"
								name="savename"
								className="border border-black p-2"
								id="save-name"
								value={saveName}
								placeholder="ex: file name"
								onChange={changeSaveNameViaEvent}
								required></input>
						</div>
						<div className="flex gap-4 justify-center">
							<button type="submit" className="p-2 bg-blue-600 text-white rounded-lg">
								Create
							</button>
							<button type="button" onClick={closeModal} className="p-2 bg-gray-500 text-white rounded-lg">
								Cancel
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default CreateReportModal;
