import React, { createContext, useState, useEffect } from "react";

const loadCartFromLocalStorage = () => {
	try {
		const serializedCart = localStorage.getItem("cart");
		return serializedCart ? JSON.parse(serializedCart) : [];
	} catch (e) {
		console.warn("Could not load cart from local storage", e);
		return [];
	}
};

const saveCartToLocalStorage = (cart) => {
	try {
		const serializedCart = JSON.stringify(cart);
		localStorage.setItem("cart", serializedCart);
	} catch (e) {
		console.warn("Could not save cart to local storage", e);
	}
};

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
	const [cart, setCart] = useState(loadCartFromLocalStorage());

	useEffect(() => {
		saveCartToLocalStorage(cart);
	}, [cart]);

	const addToCart = (newItem) => {
		setCart((prevCart) => {
			const existingItemIndex = prevCart.findIndex((item) => {
				if (
					item.id !== newItem.id ||
					item.odooId !== newItem.odooId ||
					item.odooModel !== newItem.odooModel ||
					item.projectName !== newItem.projectName
				) {
					return false;
				}

				if (item.attributes.length !== newItem.attributes.length) return false;

				const attributesMatch = item.attributes.every((attribute, index) => {
					const newAttribute = newItem.attributes[index];
					return attribute.id === newAttribute.id && attribute.selectedValue?.id === newAttribute.selectedValue?.id;
				});

				return attributesMatch;
			});

			if (existingItemIndex !== -1) {
				const updatedCart = [...prevCart];
				const validAmount = newItem.amount > 0 ? newItem.amount : 1;
				updatedCart[existingItemIndex] = {
					...updatedCart[existingItemIndex],
					amount: parseInt(updatedCart[existingItemIndex].amount) + parseInt(validAmount),
					notes: updatedCart[existingItemIndex].notes + "\n\n" + newItem.notes,
				};
				return updatedCart;
			} else {
				const validAmount = newItem.amount > 0 ? newItem.amount : 1;
				return [...prevCart, { ...newItem, amount: validAmount }];
			}
		});
	};

	const removeFromCart = (id, attributes, odooId, odooModel, projectName) => {
		setCart((prevCart) => {
			return prevCart
				.map((item) => {
					const attributesMatch = attributes
						? item.attributes.length === attributes.length &&
						  item.attributes.every((attribute, index) => {
								return attribute.id === attributes[index].id && attribute.selectedValue.id === attributes[index].selectedValue.id;
						  })
						: true;

					if (item.id === id && item.odooId === odooId && item.odooModel === odooModel && item.projectName === projectName && attributesMatch) {
						return null;
					} else {
						return item;
					}
				})
				.filter(Boolean);
		});
	};

	const incrementItem = (id, attributes, odooId, odooModel, projectName) => {
		setCart((prevCart) => {
			return prevCart.map((item) => {
				const attributesMatch = attributes
					? item.attributes.length === attributes.length &&
					  item.attributes.every((attribute, index) => {
							return attribute.id === attributes[index].id && attribute.selectedValue.id === attributes[index].selectedValue.id;
					  })
					: true;

				if (item.id === id && item.odooId === odooId && item.odooModel === odooModel && item.projectName === projectName && attributesMatch) {
					return { ...item, amount: item.amount + 1 };
				} else {
					return item;
				}
			});
		});
	};

	const decrementItem = (id, attributes, odooId, odooModel, projectName) => {
		setCart((prevCart) => {
			return prevCart
				.map((item) => {
					const attributesMatch = attributes
						? item.attributes.length === attributes.length &&
						  item.attributes.every((attribute, index) => {
								return attribute.id === attributes[index].id && attribute.selectedValue.id === attributes[index].selectedValue.id;
						  })
						: true;

					if (item.id === id && item.odooId === odooId && item.odooModel === odooModel && item.projectName === projectName && attributesMatch) {
						if (item.amount > 1) {
							return { ...item, amount: item.amount - 1 };
						} else {
							return null;
						}
					} else {
						return item;
					}
				})
				.filter(Boolean);
		});
	};

	const clearCart = () => {
		setCart([]);
	};

	return (
		<CartContext.Provider value={{ cart, setCart, addToCart, removeFromCart, clearCart, incrementItem, decrementItem }}>{children}</CartContext.Provider>
	);
};
