import { useEffect, useState } from "react";
import Login from "./components/Login";
import Project from "./components/project/Project";
import TemplateForm from "./components/template/TemplateForm";
import Templates from "./components/template/Templates";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import WordBank from "./components/wordbank/WordBank";
import Dashboard from "./components/Dashboard";
import WrapperRoute from "./components/WrapperRoute";
import NotFound from "./components/NotFound";
import SearchProjects from "./components/project/SearchProjects";
import Request from "./components/request/Request";
import { Navigate } from "react-router-dom";
import TemplateSelection from "./components/templateselection/TemplateSelection";
import ExportForm from "./components/project/Export/ExportForm";
import Report from "./components/reports/Report";
import Reports from "./components/reports/Reports";
import AppDownloadPage from "./components/AppDownloadPage";
import { Auth0ProviderWithNavigate } from "./components/auth0/Auth0ProviderWithNavigate";
import Test from "./components/auth0/Test";
import Unverified from "./components/auth0/Unverified";
import Fleet from "./components/fleet/Fleet";
import Attendance from "./components/Clocking/Attendance/Attendance";
import ClockingNotesAdminPage from "./components/Clocking/ClockingNotesAdminPage";
import Cart from "./components/shop/cart/Cart";
import Orders from "./components/shop/orders/Orders";
import Order from "./components/shop/orders/Order";

function App() {
	const [token, setToken] = useState("");
	const [isAdmin, setIsAdmin] = useState(false);
	const [wordBankAccess, setWordBankAccess] = useState(false);
	const [templateAccess, setTemplateAccess] = useState(false);
	const [requestAccess, setRequestAccess] = useState(false);
	const [userId, setUserId] = useState(-1);
	const [isPublicStorage, setIsPublicStorage] = useState(false);
	const [reportAccess, setReportAccess] = useState(false);
	const [hasUnsavedFormData, setHasUnsavedFormData] = useState(false);
	const [proposalAccess, setProposalAccess] = useState(false);
	const [exportAccess, setExportAccess] = useState(false);
	const [clearRecordAccess, setClearRecordAccess] = useState(false);

	let api = process.env.REACT_APP_API_URL;

	useEffect(() => {
		if (localStorage.getItem("token")) {
			setToken(localStorage.getItem("token"));
		}
		if (localStorage.getItem("admin")) {
			setIsAdmin(localStorage.getItem("admin") === "true");
		}
		if (localStorage.getItem("hasWordBankAccess")) {
			setWordBankAccess(localStorage.getItem("hasWordBankAccess") === "true");
		}
		if (localStorage.getItem("templateAccess")) {
			setTemplateAccess(localStorage.getItem("templateAccess") === "true");
		}
		if (localStorage.getItem("requestAccess")) {
			setRequestAccess(localStorage.getItem("requestAccess") === "true");
		}
		if (localStorage.getItem("userId")) {
			setUserId(localStorage.getItem("userId"));
		}
		if (localStorage.getItem("isPublicStorage")) {
			setIsPublicStorage(localStorage.getItem("isPublicStorage") === "true");
		}
		if (localStorage.getItem("reportAccess")) {
			setReportAccess(localStorage.getItem("reportAccess") === "true");
		}
		if (localStorage.getItem("proposalAccess")) {
			setProposalAccess(localStorage.getItem("proposalAccess") === "true");
		}
		if (localStorage.getItem("clearRecordAccess")) {
			setClearRecordAccess(localStorage.getItem("clearRecordAccess"));
		}
	}, []);

	return (
		<div className="App">
			<BrowserRouter>
				<Auth0ProviderWithNavigate>
					<Routes>
						<Route path="test" element={<Test />} />
						<Route path="unverified-user" element={<Unverified />} />
						<Route path="/fleet/:id" element={<Fleet api={api} token={token} title={"Fleet Dropbox Folder"} type="fleet" />} />
						<Route path="/design/:id" element={<Fleet api={api} token={token} title={"Design Dropbox Folder"} type="design" />} />
						<Route path="/construction/:id" element={<Fleet api={api} token={token} title={"Construction Dropbox Folder"} type="construction" />} />
						<Route path="/service/:id" element={<Fleet api={api} token={token} title={"Service Dropbox Access"} type="service" />} />
						<Route path="/login" element={<Login />} />

						<Route
							element={
								// routes require just login dont need wordbank or admin access
								<WrapperRoute
									isAdmin={isAdmin}
									wordBankAccess={wordBankAccess}
									wordBankAccessRequired={false}
									adminRequired={false}
									setWordBankAccess={setWordBankAccess}
									setToken={setToken}
									setIsAdmin={setIsAdmin}
									token={token}
									api={api}
									setTemplateAccess={setTemplateAccess}
									setRequestAccess={setRequestAccess}
									templateAccess={templateAccess}
									requestAccess={requestAccess}
									userId={userId}
									setUserId={setUserId}
									isPublicStorage={isPublicStorage}
									setIsPublicStorage={setIsPublicStorage}
									reportAccess={reportAccess}
									setReportAccess={setReportAccess}
									reportAccessRequired={false}
									proposalAccess={proposalAccess}
									setProposalAccess={setProposalAccess}
									exportAccess={exportAccess}
									setExportAccess={setExportAccess}
									clearRecordAccess={clearRecordAccess}
									setClearRecordAccess={setClearRecordAccess}
								/>
							}>
							<Route path="/attendance" element={<Attendance token={token} api={api} />} />
							<Route path="/clocking-notes" element={<ClockingNotesAdminPage token={token} api={api} />} />
							<Route path="/cart" element={<Cart token={token} api={api} />} />
							<Route path="/orders" element={<Orders token={token} api={api} />} />
							<Route path="/order/:id" element={<Order token={token} api={api} />} />
							<Route path="/" element={<Navigate to="/dashboard" />}></Route>
							<Route
								path="/export-form"
								element={
									<ExportForm
										api={api}
										wordBankAccess={wordBankAccess}
										token={token}
										isAdmin={isAdmin}
										templateAccess={templateAccess}
										requestAccess={requestAccess}
									/>
								}
							/>

							<Route path="/mobile/download" element={<AppDownloadPage />} />
							<Route
								path="/dashboard"
								element={
									<Dashboard
										api={api}
										wordBankAccess={wordBankAccess}
										token={token}
										isAdmin={isAdmin}
										templateAccess={templateAccess}
										requestAccess={requestAccess}
										isPublicStorage={isPublicStorage}
									/>
								}
							/>
							<Route
								path="/request/:requestid"
								element={<Request api={api} token={token} isAdmin={isAdmin} isPublicStorage={isPublicStorage} />}></Route>
							<Route
								path="/projects/search"
								element={<SearchProjects api={api} token={token} isAdmin={isAdmin} isPublicStorage={isPublicStorage} />}
							/>
							<Route path="/odoo-project/:odooid/template-select" element={<TemplateSelection api={api} token={token} isAdmin={isAdmin} />} />
							<Route path="/projects" element={<SearchProjects api={api} token={token} isAdmin={isAdmin} />} />
							<Route
								path="/project/:id"
								element={
									<Project
										api={api}
										token={token}
										isAdmin={isAdmin}
										userId={userId}
										isPublicStorage={isPublicStorage}
										reportAccess={reportAccess}
										templateAccess={templateAccess}
										exportAccess={exportAccess}
										clearRecordAccess={clearRecordAccess}
										setClearRecordAccess={setClearRecordAccess}
									/>
								}
							/>
							<Route path="*" element={<NotFound />} />
						</Route>
						{/* <Route
						// route requires request acess
						element={
							<WrapperRoute
								wordBankAccessRequired={false}
								adminRequired={false}
								requestAccessRequired={true}
								templateAccessRequired={false}
								isAdmin={isAdmin}
								wordBankAccess={wordBankAccess}
								setWordBankAccess={setWordBankAccess}
								setToken={setToken}
								setIsAdmin={setIsAdmin}
								token={token}
								api={api}
								setTemplateAccess={setTemplateAccess}
								setRequestAccess={setRequestAccess}
								templateAccess={templateAccess}
								requestAccess={requestAccess}
								userId={userId}
								setUserId={setUserId}
								isPublicStorage={isPublicStorage}
								setIsPublicStorage={setIsPublicStorage}
								reportAccess={reportAccess}
								setReportAccess={setReportAccess}
								reportAccessRequired={false}
							/>
						}>
						<Route
							path="/request/create/:projectid"
							element={<CreateRequest api={api} token={token} isAdmin={isAdmin} isEditing={false} />}></Route>
						<Route path="/request/edit/:requestid" element={<EditRequest api={api} token={token} isAdmin={isAdmin} />}></Route>

						<Route path="/request-list" element={<RequestList api={api} token={token} isAdmin={isAdmin} />}></Route>
						<Route path="/request/search" element={<RequestSearch api={api} token={token} isAdmin={isAdmin} />}></Route>
					</Route> */}

						<Route
							element={
								// routes requires report access
								<WrapperRoute
									isAdmin={isAdmin}
									wordBankAccess={wordBankAccess}
									wordBankAccessRequired={false}
									adminRequired={false}
									setWordBankAccess={setWordBankAccess}
									setToken={setToken}
									setIsAdmin={setIsAdmin}
									token={token}
									api={api}
									setTemplateAccess={setTemplateAccess}
									setRequestAccess={setRequestAccess}
									templateAccess={templateAccess}
									requestAccess={requestAccess}
									userId={userId}
									setUserId={setUserId}
									isPublicStorage={isPublicStorage}
									setIsPublicStorage={setIsPublicStorage}
									reportAccess={reportAccess}
									setReportAccess={setReportAccess}
									reportAccessRequired={true}
									hasUnsavedFormData={hasUnsavedFormData}
									setHasUnsavedFormData={setHasUnsavedFormData}
									proposalAccess={proposalAccess}
									setProposalAccess={setProposalAccess}
									exportAccess={exportAccess}
									setExportAccess={setExportAccess}
									clearRecordAccess={clearRecordAccess}
									setClearRecordAccess={setClearRecordAccess}
								/>
							}>
							<Route
								path="/reports"
								element={
									<Reports
										api={api}
										wordBankAccess={wordBankAccess}
										token={token}
										isAdmin={isAdmin}
										templateAccess={templateAccess}
										requestAccess={requestAccess}
										reportAccess={reportAccess}
									/>
								}
							/>

							<Route
								path="/report/:reportId"
								element={
									<Report
										api={api}
										token={token}
										isAdmin={isAdmin}
										isPublicStorage={isPublicStorage}
										reportAccess={reportAccess}
										hasUnsavedFormData={hasUnsavedFormData}
										setHasUnsavedFormData={setHasUnsavedFormData}
									/>
								}
							/>
						</Route>

						<Route
							element={
								<WrapperRoute
									wordBankAccessRequired={false}
									adminRequired={false}
									requestAccessRequired={false}
									templateAccessRequired={true}
									isAdmin={isAdmin}
									wordBankAccess={wordBankAccess}
									setWordBankAccess={setWordBankAccess}
									setToken={setToken}
									setIsAdmin={setIsAdmin}
									token={token}
									api={api}
									setTemplateAccess={setTemplateAccess}
									setRequestAccess={setRequestAccess}
									templateAccess={templateAccess}
									requestAccess={requestAccess}
									userId={userId}
									setUserId={setUserId}
									isPublicStorage={isPublicStorage}
									setIsPublicStorage={setIsPublicStorage}
									reportAccess={reportAccess}
									setReportAccess={setReportAccess}
									reportAccessRequired={false}
									proposalAccess={proposalAccess}
									setProposalAccess={setProposalAccess}
									exportAccess={exportAccess}
									setExportAccess={setExportAccess}
									clearRecordAccess={clearRecordAccess}
									setClearRecordAccess={setClearRecordAccess}
								/>
							}>
							<Route path="/templates" element={<Templates api={api} token={token} isAdmin={isAdmin} proposalAccess={proposalAccess} />} />
							<Route path="/template/:id" element={<TemplateForm api={api} token={token} isAdmin={isAdmin} proposalAccess={proposalAccess} />} />
						</Route>
						<Route
							element={
								<WrapperRoute
									wordBankAccessRequired={true}
									isAdmin={isAdmin}
									adminRequired={false}
									wordBankAccess={wordBankAccess}
									setWordBankAccess={setWordBankAccess}
									setToken={setToken}
									setIsAdmin={setIsAdmin}
									token={token}
									api={api}
									setTemplateAccess={setTemplateAccess}
									setRequestAccess={setRequestAccess}
									templateAccess={templateAccess}
									requestAccess={requestAccess}
									userId={userId}
									setUserId={setUserId}
									isPublicStorage={isPublicStorage}
									setIsPublicStorage={setIsPublicStorage}
									reportAccess={reportAccess}
									setReportAccess={setReportAccess}
									reportAccessRequired={false}
									exportAccess={exportAccess}
									setExportAccess={setExportAccess}
									proposalAccess={proposalAccess}
									setProposalAccess={setProposalAccess}
									clearRecordAccess={clearRecordAccess}
									setClearRecordAccess={setClearRecordAccess}
								/>
							}>
							<Route path="/wordbank" element={<WordBank api={api} token={token} isAdmin={isAdmin} isPublicStorage={isPublicStorage} />} />
						</Route>
					</Routes>
				</Auth0ProviderWithNavigate>
			</BrowserRouter>
		</div>
	);
}

export default App;
