import { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ImageDropArea from "./ImageDropArea";
import { FaDropbox, FaFolder, FaHome } from "react-icons/fa";
import LoadingCircle from "../utility/LoadingCircle";
import MobileImageDropArea from "./MobileImageDropArea";
import { MdHideImage } from "react-icons/md";
import PdfViewer from "./PDFViewer";
import DocsViewer from "./DocViewer";
import LoadingDots from "./LoadingDots";

const Fleet = ({ api, title, type }) => {
	const { id } = useParams();
	const [uploadedImages, setUploadedImages] = useState([]);
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const query = searchParams.get("key");
	const ticketPath = searchParams.get("path");
	const [verifyingKey, setVerifyingKey] = useState(true);
	const navigation = useNavigate();
	const [loadingFiles, setLoadingFiles] = useState(false);
	const [clickedImage, setClickedImage] = useState(null);
	const [serviceBasePath, setServiceBasePath] = useState("");
	const [valid404Triggered, setValid404Triggered] = useState(false);
	const [valid403Triggered, setValid403Triggered] = useState(false);
	function checkFileType(fileName) {
		if (!fileName) {
			return "unknown";
		}
		const extension = fileName.split(".").pop().toLowerCase();
		if (extension === "csv") {
			return "csv";
		} else if (extension === "pdf") {
			return "pdf";
		} else if (extension === "doc") {
			return "doc";
		} else if (extension === "docx") {
			return "docx";
		} else {
			return "unknown";
		}
	}

	useEffect(() => {
		document.title = title;
	}, [title]);

	const isMobile = () => {
		// User agent string via navigator
		const userAgent = typeof window.navigator === "undefined" ? "" : navigator.userAgent;

		// Regular expressions to detect mobile devices
		const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

		// Return true if the user agent matches a mobile device
		return mobileRegex.test(userAgent);
	};

	useEffect(() => {
		if (!api) {
			return;
		}
		if (!query) {
			navigation("/");
		}

		const controller = new AbortController();
		const signal = controller.signal;
		fetch(`${api}/dropbox/${type}/verify`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ query, id, type }),
			mode: "cors",
			signal,
		})
			.then((res) => {
				if (res.ok) {
					setVerifyingKey(false);
				} else {
					if (res.status === 404) {
						setValid404Triggered(true);
					}
					if (res.status === 403) {
						setValid403Triggered(true);
					}
					// navigation("/");
				}
			})
			.catch((err) => {});
		return () => {
			controller.abort();
		};
	}, [api, navigation, query, id, type]);

	function renderLinksFromString(inputString) {
		const parts = inputString.split("/").filter((part) => part);

		return (
			<div className="flex gap-2">
				<div className="flex gap-2">
					{parts.map((part, index) => {
						const encodedHref =
							"/" +
							parts
								.slice(0, index + 1)
								.map(encodeURIComponent)
								.join("/");

						if (index === 0) {
							return null;
						}

						return (
							<Fragment key={index}>
								<Link to={`/service/${id}?path=${encodedHref}&key=${query}`} className="text-blue-500 underline hover:text-blue-700">
									{part}
								</Link>
								{index !== parts.length - 1 ? <span>/</span> : null}
							</Fragment>
						);
					})}
				</div>
			</div>
		);
	}

	const getFirstTwoFoldersOfPath = (pathToTest) => {
		let splitServicePath = pathToTest.toLowerCase().split("/");
		let testSplitServicePath = "";
		if (splitServicePath.length >= 3) {
			testSplitServicePath = "/" + pathToTest.split("/").slice(1, 3).join("/");
		}
		return testSplitServicePath;
	};

	if (valid403Triggered) {
		return (
			<div className="p-4">
				<p className="font-bold">Forbidden</p>
				<p>You do not have access to this page. Please ensure your URL is correct.</p>
			</div>
		);
	}

	if (valid404Triggered) {
		return (
			<div className="p-4">
				<p className="font-bold">Not Found</p>
				<p>
					Unable to find anything using the id <span className="font-bold">{id}</span>.
				</p>
			</div>
		);
	}

	if (verifyingKey) {
		return (
			<div className="h-screen flex flex-col justify-center items-center">
				<LoadingCircle />
				<p>Loading...</p>
			</div>
		);
	}
	return (
		<div className="w-full">
			{clickedImage ? (
				<div
					className="fixed top-0 bottom-0 left-0 right-0 bg-black/50"
					onClick={() => {
						setClickedImage(null);
					}}>
					<div className="flex justify-center items-center h-full ">
						<img
							src={clickedImage.local ? clickedImage.thumbnail : "data:image/jpeg;base64, " + clickedImage.thumbnail}
							alt="clicked"
							className="max-h-[90vh] w-auto"
						/>
					</div>
				</div>
			) : null}
			<div className="bg-gray-100 flex justify-center items-center  gap-2 p-4">
				<FaDropbox size={50} color="#0061FE" />
				<p className="text-2xl font-bold">{title}</p>
			</div>
			<div className="p-4 w-full flex justify-center items-center">
				<div className="w-full md:w-3/5 ">
					<div>
						<p className="font-bold text-2xl mb-2">{type === "fleet" ? "Chassis Id: " + id : "Installation Id: " + id}</p>

						{type === "service" && serviceBasePath !== "" ? (
							getFirstTwoFoldersOfPath(ticketPath.toLowerCase()) === getFirstTwoFoldersOfPath(serviceBasePath.toLowerCase()) ? (
								<>{renderLinksFromString(ticketPath)}</>
							) : (
								<div>
									<p>Using the default path. Path {ticketPath} is invalid.</p>
									{renderLinksFromString(serviceBasePath)}
								</div>
							)
						) : null}
						{isMobile() ? (
							<MobileImageDropArea
								id={id}
								api={api}
								uploadedImages={uploadedImages}
								setUploadedImages={setUploadedImages}
								setLoadingFiles={setLoadingFiles}
								type={type}
								servicePath={ticketPath}
								setServiceBasePath={setServiceBasePath}
								setValid404Triggered={setValid404Triggered}
								serverKey={query}
							/>
						) : (
							<ImageDropArea
								id={id}
								api={api}
								uploadedImages={uploadedImages}
								setUploadedImages={setUploadedImages}
								setLoadingFiles={setLoadingFiles}
								type={type}
								servicePath={ticketPath}
								setServiceBasePath={setServiceBasePath}
								setValid404Triggered={setValid404Triggered}
								serverKey={query}
							/>
						)}

						<div className="mt-4 flex flex-col gap-1 mb-12">
							{loadingFiles ? (
								<div className="flex gap-2 font-bold text-xl">
									<p>Files</p>
									<span className="flex">
										<LoadingDots />
									</span>
								</div>
							) : (
								<p className="font-bold text-xl">Files ({uploadedImages ? uploadedImages.length : 0})</p>
							)}
							{loadingFiles ? (
								<div className="h-[30vh] flex justify-center items-center">
									<div className="flex flex-col justify-center items-center">
										<LoadingCircle />
										<p>Loading...</p>
									</div>
								</div>
							) : uploadedImages && uploadedImages.length > 0 ? (
								uploadedImages.map((mapData, index) => {
									return (
										<div className="flex gap-2 items-center" key={index}>
											{mapData.type && mapData.type === "file" && type !== "fleet" ? (
												checkFileType(mapData.metadata.name) === "pdf" ? (
													<PdfViewer dropboxUrl={mapData.link} title={mapData.metadata.name} />
												) : checkFileType(mapData.metadata.name) === "doc" || checkFileType(mapData.metadata.name) === "docx" ? (
													<DocsViewer docUrl={mapData.link} title={mapData.metadata.name} />
												) : (
													<div className="flex gap-2 items-center">
														<MdHideImage className="h-10 w-10" />
														<p>Corrupt or non-supported file</p>
													</div>
												)
											) : mapData.type && mapData.type === "folder" && type === "service" ? (
												<div className="flex gap-2 items-center">
													<FaFolder className="h-10 w-10" color="#0061FE" />
													<Link
														to={`/service/${id}?path=${encodeURIComponent(mapData.metadata.path_display)}&key=${query}`}
														className="text-blue-500 underline hover:text-blue-700">
														{mapData.metadata.name}
													</Link>
												</div>
											) : mapData.failure || (type === "fleet" && mapData.type && mapData.type === "file") ? (
												<div className="flex gap-2 items-center">
													<MdHideImage className="h-10 w-10" />
													<p>Corrupt or non-image file</p>
												</div>
											) : (
												<>
													<img
														className="h-10 w-10 cursor-pointer"
														src={mapData.local ? mapData.thumbnail : "data:image/jpeg;base64, " + mapData.thumbnail}
														alt={mapData.local ? mapData.metadata.name + ".png" : mapData.metadata.name}
														onClick={() => setClickedImage(mapData)}
													/>
													<p onClick={() => setClickedImage(mapData)} className="cursor-pointer">
														{mapData.metadata.name}
													</p>
												</>
											)}
										</div>
									);
								})
							) : (
								<div>
									<p>No files in this directory</p>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Fleet;
