import { useEffect, useState } from "react";

const BooleanInput = ({ postToDB, putToDB, initialFieldData, fieldDataExists, readOnly, hasOdooMapping, odooData }) => {
	const [currentSelection, setCurrentSelection] = useState(3);

	useEffect(() => {
		let newValue = "";
		if (fieldDataExists) {
			newValue = initialFieldData.value;
		}
		if (hasOdooMapping) {
			if (odooData && odooData.odoo_value !== "failedtogetdataforpmscode400") {
				newValue = odooData.odoo_value;
			} else if (odooData && odooData.odoo_value === "failedtogetdataforpmscode400") {
				if (fieldDataExists) {
					newValue = initialFieldData.value;
				}
			} else {
				newValue = "";
			}
		}
		if (newValue === 1 || newValue === "1" || (typeof newValue === "string" && newValue.toLowerCase() === "yes")) {
			newValue = "yes";
		}
		if (newValue === 0 || newValue === "0" || (typeof newValue === "string" && newValue.toLowerCase() === "no")) {
			newValue = "no";
		}
		setCurrentSelection(newValue);
	}, [initialFieldData, fieldDataExists, hasOdooMapping, odooData]);

	const processClick = (value) => {
		if (readOnly) {
			return;
		}
		if (value === currentSelection) {
			setCurrentSelection("");

			if (fieldDataExists === false) {
				postToDB("");
			} else {
				putToDB("");
			}
		} else {
			setCurrentSelection(value);
			if (fieldDataExists === false) {
				postToDB(value);
			} else {
				putToDB(value);
			}
		}
	};

	return (
		<div className="flex gap-3 text-white">
			<button className={`rounded-lg px-4 py-1 ${currentSelection === "yes" ? "bg-blue-700" : "bg-gray-200"}`} onClick={() => processClick("yes")}>
				Yes
			</button>
			<button className={`rounded-lg px-4 py-1 ${currentSelection === "no" ? "bg-blue-700" : "bg-gray-200"}`} onClick={() => processClick("no")}>
				No
			</button>
		</div>
	);
};

export default BooleanInput;
