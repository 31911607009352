import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { PaginationNav1Presentation } from "./Pagination";
import Select from "react-select";
import LoadingCircle from "../utility/LoadingCircle";
import { contractTypes, stages, allowedOdooModels } from "../utility/FilterData";
import { RiErrorWarningLine } from "react-icons/ri";

const SearchProject = (props) => {
	const [projects, setProjects] = useState([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const [searchQuery, setSearchQuery] = useState("");
	const [searchContractType, setSearchContractType] = useState("");
	const [searchProjectStage, setSearchProjectStage] = useState("");
	const [searchTemplateType, setSearchTemplateType] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [start, setStart] = useState(false);
	const navigate = useNavigate();

	const [pageCount, setPageCount] = useState(0);
	const [firstIndex, setFirstIndex] = useState(0);
	const [lastIndex, setLastIndex] = useState(0);
	const [globalPage, setGlobalPage] = useState(0);
	const [prevSearchQuery, setPrevSearchQuery] = useState("");

	const contractInput = useRef();
	const stageInput = useRef();
	const templateInput = useRef();
	const isClearable = useRef();

	const [odooModel, setOdooModel] = useState();
	const [initialOdooModel, setInitialOdooModel] = useState();

	const [allTemplates, setAllTemplates] = useState([]);
	const [filteredTemplates, setFilteredTemplates] = useState([]);

	const [creatingProject, setCreatingProject] = useState(false);
	const [errorCreatingProject, setErrorCreatingProject] = useState(false);

	useEffect(() => {
		document.title = "Search: " + searchParams.get("query") + " | Page " + searchParams.get("page");
	}, [searchParams]);

	useEffect(() => {
		if (props.api === "" || props.token === "" || (allTemplates.length === 0 && props.isPublicStorage === false)) {
			return;
		}
		let queryString = "?";
		if (searchParams.get("query")) {
			queryString += "query=" + searchParams.get("query") + "&";
			let decodedQuery = decodeURIComponent(searchParams.get("query"));
			setSearchQuery(decodedQuery === " " ? "" : decodedQuery);
			setPrevSearchQuery(decodedQuery === " " ? "" : decodedQuery);
		}
		if (searchParams.get("page")) {
			queryString += "page=" + searchParams.get("page") + "&";
		}

		if (searchParams.get("contract")) {
			queryString += "contract=" + searchParams.get("contract") + "&";
			setSearchContractType(searchParams.get("contract"));
		}

		if (searchParams.get("stage")) {
			queryString += "stage=" + searchParams.get("stage") + "&";
			setSearchProjectStage(searchParams.get("stage"));
		}

		if (searchParams.get("template")) {
			queryString += "template=" + searchParams.get("template") + "&";
			setSearchTemplateType({ value: searchParams.get("template"), label: searchParams.get("template") });
		}
		if (searchParams.get("model")) {
			queryString += "model=" + searchParams.get("model");
			let selectedModel = searchParams.get("model");
			let modelInMapping = Object.keys(allowedOdooModels).includes(selectedModel);
			setOdooModel(modelInMapping ? allowedOdooModels[searchParams.get("model")] : allowedOdooModels["project.task"]);
			setInitialOdooModel(modelInMapping ? allowedOdooModels[searchParams.get("model")] : allowedOdooModels["project.task"]);
		} else {
			setOdooModel(allowedOdooModels["project.task"]);
			setInitialOdooModel(allowedOdooModels["project.task"]);
			let newParams = new URLSearchParams(searchParams.toString());
			newParams.set("model", "project.task");
			setSearchParams(newParams);
			queryString += "model=project.task";
		}
		setStart(true);

		const controller = new AbortController();
		const signal = controller.signal;
		setIsLoading(true);

		fetch(props.api + "/project/odoo/search" + queryString, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: props.token,
			},
			mode: "cors",
			signal,
		})
			.then((res) => {
				setIsLoading(false);
				if (res.ok) {
					return res.json();
				} else {
					setProjects([]);
				}
			})
			.then((res) => {
				if (res && typeof res.projects != "undefined") {
					setProjects(res.projects);
				}
			})
			.catch(function (error) {
				//Handle error
				//console.log(error);
			});

		return () => {
			controller.abort();
		};
	}, [props.api, props.token, searchParams, allTemplates, setSearchParams, props.isPublicStorage]);

	useEffect(() => {
		if (props.isPublicStorage) {
			return;
		}
		setStart(true);
		setIsLoading(true);
		const controller = new AbortController();
		const signal = controller.signal;
		fetch(props.api + "/project/search-templates?page=1&archived=0", {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: props.token,
			},
			signal,
		})
			.then((res) => {
				if (res.ok) {
					return res.json();
				} else {
					setStart(false);
					setIsLoading(false);
				}
				// need to add error message here
			})
			.then((res) => {
				if (res) {
					setAllTemplates(
						res.templates.map((mapData) => {
							return {
								value: mapData.template.name,
								id: mapData.template.id,
								label: mapData.template.name,
								model: mapData.template.odoo_model,
							};
						})
					);
				}
			});
		return () => {
			controller.abort();
		};
	}, [props.api, props.isPublicStorage, props.token]);

	useEffect(() => {
		if (odooModel && allTemplates) {
			setFilteredTemplates(allTemplates.filter((filterData) => filterData.model === odooModel.value));
		}
	}, [odooModel, allTemplates]);

	useEffect(() => {
		if (searchTemplateType && filteredTemplates && filteredTemplates.length > 0) {
			if (filteredTemplates.filter((filterData) => filterData.label === searchTemplateType.label).length === 0) {
				setSearchTemplateType("");
			}
		}
	}, [searchTemplateType, filteredTemplates]);

	useEffect(() => {
		setPageCount(Math.ceil(projects.length / 20));
		setFirstIndex(globalPage * 20);
		setLastIndex(20 * (globalPage + 1) < projects.length ? 20 * (globalPage + 1) - 1 : projects.length - 1);
	}, [projects, globalPage]);

	const startSearch = (e) => {
		e.preventDefault();
		let emptyProjects = [];
		if (
			prevSearchQuery !== searchQuery ||
			initialOdooModel.value !== odooModel.value ||
			searchContractType !== searchParams.get("contract") ||
			searchProjectStage !== searchParams.get("stage") ||
			searchTemplateType !== searchParams.get("template")
		) {
			setProjects(emptyProjects);
		}
		const uriEncodedString = encodeURIComponent(encodeURIComponent(searchQuery));
		let newTemplateQuery = searchTemplateType ? searchTemplateType.value : "";
		if (searchQuery !== "") {
			navigate(
				`/projects/search?query=${uriEncodedString}&page=1&contract=${searchContractType}&stage=${searchProjectStage}&template=${newTemplateQuery}&model=${odooModel.value}`
			);
		} else {
			navigate(
				`/projects/search?query=${encodeURIComponent(
					" "
				)}&page=1&contract=${searchContractType}&stage=${searchProjectStage}&template=${newTemplateQuery}&model=${odooModel.value}`
			);
		}
	};

	const clearAll = () => {
		contractInput.current.clearValue();
		stageInput.current.clearValue();
		templateInput.current.clearValue();
		setOdooModel({ value: "project.task", label: "Task" });
	};

	const convertPortfolio = (portfolioOdooName) => {
		if (!portfolioOdooName) {
			return "all";
		}
		if (portfolioOdooName.trim().toLowerCase() === "public storage") {
			return "ps";
		} else if (portfolioOdooName.trim().toLowerCase() === "extra space storage") {
			return "ess";
		} else if (portfolioOdooName) {
			return "misc";
		} else {
			return "all";
		}
	};
	const createPunchwalkForPS = async (odooData) => {
		if (creatingProject) {
			return;
		}
		setCreatingProject(true);
		// hardcoded ps punchwalk id
		let templateId = -1;
		try {
			let response = await fetch(props.api + "/project/latest-template/" + 32, {
				method: "post",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: props.token,
				},
			});
			if (response.ok) {
				let jsonData = await response.json();
				templateId = jsonData.templateId;
			}
		} catch (err) {
			setCreatingProject(false);
			setErrorCreatingProject(true);
		}

		if (templateId === -1) {
			setCreatingProject(false);
			setErrorCreatingProject(true);
			return;
		}
		try {
			let projectRes = await fetch(props.api + "/project", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: props.token,
				},
				body: JSON.stringify({
					odoo_id: odooData.id,
					template_version_id: templateId,
					name: odooData.name ? odooData.name : odooData.x_name,
					model: "project.task",
				}),
			});

			if (projectRes.ok) {
				let project = await projectRes.json();
				navigate("/project/" + project.id);
			} else {
				setCreatingProject(false);
				setErrorCreatingProject(true);
			}
		} catch (err) {
			setCreatingProject(false);
			setErrorCreatingProject(true);
		}
	};

	return (
		<div className="flex flex-col md:flex-row">
			{creatingProject ? (
				<div className="text-black fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black/50 z-50">
					<div className="bg-white flex flex-col items-center justify-center p-4 rounded-lg ">
						<LoadingCircle />
						<p>Creating project...</p>
					</div>
				</div>
			) : null}
			{errorCreatingProject ? (
				<div className="text-black fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black/50 z-50">
					<div className="bg-white flex flex-col items-center justify-center p-4 rounded-lg w-3/5 md:w-2/5 text-center">
						<RiErrorWarningLine size={40} />
						<p>Something went wrong. Please contact IT for assistance or refresh and try again.</p>
						<button className="p-2 mt-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600" onClick={() => window.location.reload()}>
							Refresh
						</button>
					</div>
				</div>
			) : null}
			<div className="md:w-1/5 md:h-100 md:m-2 justify-center md:pt-24 md:mt-6 pt-7">
				{props.isPublicStorage ? null : (
					<>
						<div className="border border-black p-1 rounded-lg md:w-45 md:h-30 md:m-1.5 justify-center pb-7">
							<h1>
								<div className="flex md:flex-row px-1">
									<div className="w-2/5 text-left text-xl">
										<FontAwesomeIcon icon={faFilter} size="sm" /> Filter
									</div>
									<div className="w-3/5 text-right text-md pt-1">
										<span>
											<button className="text-center text-md text-blue-600 hover:text-red-600" onClick={clearAll}>
												Clear All
											</button>
										</span>
									</div>
								</div>
							</h1>
							<div className="p-1 pr-3 mt-3">
								<div className="md:basis-1/4 flex justify-start">
									<p className="display:inline text-left">Contract Type</p>
								</div>
								<div>
									<Select
										styles={{
											control: (baseStyles, state) => ({
												...baseStyles,
												borderColor: state.isFocused ? "blue" : "black",
												"&:hover": {
													cursor: "pointer",
													boxShadow:
														"rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;",
												},
											}),
										}}
										defaultValue={
											searchParams.get("contract") === "Residential"
												? { label: contractTypes[0].label, value: contractTypes[0].value }
												: searchParams.get("contract") === "Commercial"
												? { label: contractTypes[1].label, value: contractTypes[1].value }
												: null
										}
										options={contractTypes}
										onChange={(contractTypes) => (!contractTypes ? setSearchContractType("") : setSearchContractType(contractTypes.value))}
										ref={contractInput}
										isClearable={isClearable}
									/>
								</div>
							</div>

							<div className="p-1 pr-3 mt-3">
								<div className="md:basis-1/4 flex justify-start">
									<p className="display:inline text-left">Project Stage</p>
								</div>
								<div>
									<Select
										styles={{
											control: (baseStyles, state) => ({
												...baseStyles,
												borderColor: state.isFocused ? "blue" : "black",
												"&:hover": {
													cursor: "pointer",
													boxShadow:
														"rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;",
												},
											}),
										}}
										defaultValue={
											searchParams.get("stage") === "Assign"
												? { label: stages[0].label, value: stages[0].value }
												: searchParams.get("stage") === "In-Progress"
												? { label: stages[1].label, value: stages[1].value }
												: searchParams.get("stage") === "Finalize"
												? { label: stages[2].label, value: stages[2].value }
												: searchParams.get("stage") === "Completed"
												? { label: stages[3].label, value: stages[3].value }
												: null
										}
										options={stages}
										onChange={(stages) => (!stages ? setSearchProjectStage("") : setSearchProjectStage(stages.value))}
										ref={stageInput}
										isClearable={isClearable}
									/>
								</div>
							</div>

							<div className="p-1 pr-3 mt-3">
								<div className="md:basis-1/4 flex justify-start">
									<p className="display:inline text-left">Odoo Model</p>
								</div>
								<div>
									<Select
										styles={{
											control: (baseStyles, state) => ({
												...baseStyles,
												borderColor: state.isFocused ? "blue" : "black",
												"&:hover": {
													cursor: "pointer",
													boxShadow:
														"rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;",
												},
											}),
										}}
										value={odooModel}
										options={[
											{ value: "project.task", label: "Installations" },
											{ value: "helpdesk.ticket", label: "Helpdesk Ticket" },
											{ value: "x_roofing", label: "Roofing" },
											{ value: "x_krummy_capture", label: "Krummy Capture" },
										]}
										onChange={(models) =>
											!models ? setOdooModel({ value: "project.task", label: "Installations" }) : setOdooModel(models)
										}
										ref={stageInput}
										isClearable={isClearable}
									/>
								</div>
							</div>

							<div className="p-1 pr-3 mt-3">
								<div className="md:basis-1/4 flex justify-start">
									<p className="display:inline text-left">Template Name</p>
								</div>
								<div>
									<Select
										styles={{
											control: (baseStyles, state) => ({
												...baseStyles,
												borderColor: state.isFocused ? "blue" : "black",
												"&:hover": {
													cursor: "pointer",
													boxShadow:
														"rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;",
												},
											}),
										}}
										value={searchTemplateType}
										options={filteredTemplates}
										onChange={(templateNames) => (!templateNames ? setSearchTemplateType("") : setSearchTemplateType(templateNames))}
										ref={templateInput}
										isClearable={isClearable}
									/>
								</div>
							</div>
						</div>
					</>
				)}
			</div>

			<div className="py-4 md:pr-6 flex flex-col justify-center items-left md:w-3/5">
				<div className="w-full md:w-5/5">
					{props.isPublicStorage ? (
						<p className="text-center text-3xl mb-4">Projects</p>
					) : (
						<p className="text-center text-3xl mb-4">{`${initialOdooModel ? initialOdooModel.label + " Projects" : "Searching..."} `}</p>
					)}
					<form onSubmit={(e) => startSearch(e)} className="my-4 flex w-full justify-center items-center">
						<input
							type="text"
							className="border border-black p-2 w-full rounded-l-lg"
							placeholder="Search for a project..."
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
						/>
						<button type="submit" className="border rounded-r-lg border-black p-3">
							<BsSearch className="" />
						</button>
					</form>
					<div>
						<p className="text-2xl bg-gray-300 text-center p-3 border border-black p-4 rounded-t-lg"></p>
						<div className="gap-3 w-full border border-black p-2 hidden md:flex justify-between bg-black text-white md:text-center">
							{/* <p className="md:basis-1/2">Project <button className="" onClick={sortByProject}> <img src={IoArrowUp} className="w-auto h-5" /> </button></p> */}

							{searchParams.get("model") === "helpdesk.ticket" ? (
								<div className="md:basis-1/4 flex justify-center">
									<p className="display:inline text-right">Ticket #</p>
								</div>
							) : null}

							<div className="md:basis-1/4 flex justify-center">
								<p className="display:inline text-right">Project</p>
							</div>

							<p className="md:basis-1/4">Contract Type</p>

							{searchParams.get("model") === "helpdesk.ticket" ? (
								<p className="md:basis-1/4">Status</p>
							) : null}

							<p className="md:basis-1/4">Project Stage</p>
							{/* <p className="md:basis-1/2">Template Name <button onClick={sortByTemplate}> <img src={sortButton} className="w-auto h-5" /> </button></p> */}

							<div className="md:basis-1/4 flex justify-center">
								<p className="display:inline text-right">Template Name</p>
							</div>
							<div className="md:basis-1/4 flex justify-center">
								<p className="display:inline text-right">Last Updated</p>
							</div>
						</div>
						{projects.length > 0 ? (
							projects.map((value, index) => {
								if (index <= lastIndex && index >= firstIndex) {
									return (
										<div
											key={`${value.project_id}${index}`}
											className={
												"gap-3 border border-black p-3 flex flex-col md:flex-row md:text-center justify-between " +
												(index % 2 === 0 ? "bg-white" : "bg-gray-200") +
												(index === projects.length - 1 ? " rounded-b-lg " : "")
											}>
											{searchParams.get("model") === "helpdesk.ticket" ? (
												<div className="md:basis-1/2">
													<span className="md:hidden">Ticket #: </span>
													{value.id}
												</div>
											) : null}

											<div className="md:basis-1/2">
												{value.project_id ? (
													<Link to={"/project/" + value.project_id} className="text-blue-600 underline ">
														{value.name || value.x_name}
													</Link>
												) : props.isPublicStorage ? (
													<p className="text-blue-600 underline ">
														<span className="cursor-pointer hover:text-blue-800" onClick={() => createPunchwalkForPS(value)}>
															{value.name || value.x_name}
														</span>
													</p>
												) : (
													<Link
														to={
															"/odoo-project/" +
															value.id +
															`/template-select?model=${odooModel.value}&contract=${
																value.x_studio_contract_type_3 ? value.x_studio_contract_type_3.toLowerCase() : "all"
															}&portfolio=${convertPortfolio(value.x_studio_portfolio_1)}`
														}
														className="text-blue-600 underline ">
														{value.name || value.x_name}
													</Link>
												)}
											</div>

											<p className="md:basis-1/2">
												<span className="md:hidden">Contract Type: </span>
												{value.x_studio_contract_type_3 || value.contract || value.x_studio_installation_contract_type}
											</p>
											{searchParams.get("model") === "helpdesk.ticket" ? (
												<p className="md:basis-1/2">
													<span className="md:hidden">Status: </span>
													{value.stage_id ? value.stage_id[1] : ""}
												</p>
											) : null}
											<p className="md:basis-1/2">
												<span className="md:hidden">Project Stage: </span>
												{value.stage}
											</p>
											<p className="md:basis-1/2">
												<span className="md:hidden">Template Name: </span>
												{value.template_name}
											</p>
											<p className="md:basis-1/2">
												<span className="md:hidden">Last Updated: </span>
												{value.last_modified_on ? new Date(value.last_modified_on).toLocaleDateString("en-US") : "-"}
											</p>
										</div>
									);
								} else {
									return null;
								}
							})
						) : isLoading ? (
							<div className="flex flex-col items-center justify-center w-full my-2">
								<LoadingCircle />
								<p>Loading projects</p>
							</div>
						) : (
							<p className="text-center text-xl m-4">0 Results found</p>
						)}
					</div>

					<div className="text-center">
						<span className="text-sm text-gray-700">
							Showing
							<span className="font-semibold text-gray-900"> {globalPage * 20 + 1}</span> to
							<span className="font-semibold text-gray-900">
								{" "}
								{20 * (globalPage + 1) < projects.length ? 20 * (globalPage + 1) : projects.length}
							</span>{" "}
							of
							<span className="font-semibold text-gray-900"> {projects.length}</span> Entries
						</span>

						<nav aria-label="Page navigation example">
							<ul className="inline-flex items-center -space-x-px h-16">
								{PaginationNav1Presentation(pageCount, searchQuery, globalPage, setGlobalPage)}
							</ul>
						</nav>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SearchProject;
