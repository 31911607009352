import { useState } from "react";
import { BsFillFileArrowUpFill } from "react-icons/bs";
import UpdateTemplateModal from "../UpdateTemplateModal";

const UpdateTemplateButton = ({ updateTemplateToLatestVersion }) => {
	const [showUpdateModal, setShowUpdateModal] = useState(false);

	const showModal = (e) => {
		e.preventDefault();
		setShowUpdateModal(true);
	};
	return (
		<>
			{showUpdateModal ? (
				<UpdateTemplateModal setShowUpdateModal={setShowUpdateModal} updateTemplateToLatestVersion={updateTemplateToLatestVersion} />
			) : null}
			<div className="flex items-center gap-1  bg-blue-600 p-2 rounded-lg w-full cursor-pointer" onClick={showModal}>
				<BsFillFileArrowUpFill />
				<p>Update Template</p>
			</div>
		</>
	);
};

export default UpdateTemplateButton;
