import { useRef, useEffect, useState, useCallback } from "react";
import SaveImagePrompt from "./SaveImagePrompt";
import UploadedImages from "./UploadedImages";
import { BsFillCameraFill, BsFillCloudArrowUpFill } from "react-icons/bs";
import SaveMultiplePrompt from "./SaveMultiplePrompt";
import FileSaver from "file-saver";

const CameraContainer = ({
	sectionName,
	data,
	setInitialFieldData,
	cameraid,
	api,
	token,
	fieldName,
	projectName,
	templateName,
	stage,
	readOnly,
	templateTypeId,
	setHasPhotos,
	initialThumbnailPathes,
	setLastModifiedOn,
	projectId,
	odooId,
	selectedOdooModel,
	odooInstallationName,
	odooParentName,
}) => {
	const uploadMulitpleRef = useRef();
	const uploadSingleRef = useRef();
	const thumbnailBoxRef = useRef();
	const windowWidth = useRef(window.innerWidth);
	const [imgSrc, setImgSrc] = useState(null);
	const [uploadedImages, setUploadedImages] = useState([]);
	const [uploadPreviews, setUploadPreviews] = useState([]);
	const [recentlyAddedPaths, setRecentlyAddedPaths] = useState([]);
	const [numberOfUploaded, setNumberOfUploaded] = useState(0);
	const [viewedThumbnailList, setViewedThumbnailList] = useState(false);
	const [dragActive, setDragActive] = useState(false);
	// const [isLoading, setIsLoading] = useState();
	// const [uploadingImages, setUploadingImages] = useState();

	useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			const entry = entries[0];
			if (viewedThumbnailList === false && entry.isIntersecting) {
				setViewedThumbnailList(true);
			}
		});
		if (thumbnailBoxRef !== null) {
			observer.observe(thumbnailBoxRef.current);
		}
		return () => {
			observer.disconnect();
		};
	}, [viewedThumbnailList]);

	useEffect(() => {
		if (setHasPhotos) {
			if (numberOfUploaded > 0) {
				setHasPhotos(true);
			} else {
				setHasPhotos(false);
			}
		}
	}, [numberOfUploaded, setHasPhotos]);

	useEffect(() => {
		let previews = [];
		for (let i = 0; i < uploadedImages.length; i++) {
			previews.push(URL.createObjectURL(uploadedImages[i]));
		}
		setUploadPreviews(previews);
	}, [uploadedImages]);

	const uploadImage = useCallback(
		async (e, downloadFileName) => {
			if (e) {
				e.preventDefault();
			}
			// setIsLoading(true);
			let tempImgSrc = imgSrc;
			setImgSrc(null);
			const formData = new FormData();
			if (downloadFileName) {
				FileSaver.saveAs(tempImgSrc, downloadFileName);
				await new Promise((r) => setTimeout(r, 100));
			}

			fetch(tempImgSrc)
				.then((res) => {
					// setIsLoading(false);
					if (res) {
						return res.blob();
					}
				})
				.then((resBlob) => {
					if (resBlob) {
						formData.append("newImage", resBlob, "newImage");
						formData.append("name", fieldName);
						formData.append("odoo_name", projectName);
						formData.append("template_type_id", templateTypeId);
						formData.append("image_name", sectionName.replaceAll("/", "-") + " - " + fieldName);
						formData.append("template_name", templateName);
						formData.append("project_id", projectId);
						formData.append("odooId", odooId);
						formData.append("odooModel", selectedOdooModel);
						formData.append("odooInstallationName", odooInstallationName);
						formData.append("odooParentName", odooParentName);
						fetch(api + "/project/image", {
							method: "POST",
							headers: {
								enctype: "multipart/form-data",
								Authorization: token,
							},
							body: formData,
							mode: "cors",
						})
							.then((res2) => {
								// setIsLoading(false);
								if (res2) {
									return res2.json();
								}
							})
							.then((res3) => {
								setLastModifiedOn(new Date());
								setRecentlyAddedPaths([res3.pathToImage]);
							})
							.catch((err) => {});
					} else {
					}
				})
				.catch((err) => {});
		},
		[
			imgSrc,
			fieldName,
			projectName,
			templateTypeId,
			sectionName,
			templateName,
			projectId,
			odooId,
			selectedOdooModel,
			odooInstallationName,
			odooParentName,
			api,
			token,
			setLastModifiedOn,
		]
	);

	const uploadEditedImage = useCallback(
		async (downloadFileName, editedImage) => {
			// setIsLoading(true);
			let tempImgSrc = editedImage;
			setImgSrc(null);
			const formData = new FormData();

			fetch(tempImgSrc)
				.then((res) => {
					// setIsLoading(false);
					if (res) {
						return res.blob();
					}
				})
				.then((resBlob) => {
					if (resBlob) {
						formData.append("newImage", resBlob, "newImage");
						formData.append("name", fieldName);
						formData.append("odoo_name", projectName);
						formData.append("template_type_id", templateTypeId);
						formData.append("image_name", sectionName.replaceAll("/", "-") + " - " + fieldName);
						formData.append("template_name", templateName);
						formData.append("project_id", projectId);
						formData.append("odooId", odooId);
						formData.append("odooModel", selectedOdooModel);
						formData.append("odooInstallationName", odooInstallationName);
						formData.append("odooParentName", odooParentName);
						fetch(api + "/project/image", {
							method: "POST",
							headers: {
								enctype: "multipart/form-data",
								Authorization: token,
							},
							body: formData,
							mode: "cors",
						})
							.then((res2) => {
								// setIsLoading(false);
								if (res2) {
									return res2.json();
								}
							})
							.then((res3) => {
								setLastModifiedOn(new Date());
								setRecentlyAddedPaths([res3.pathToImage]);
							})
							.catch((err) => {});
					} else {
					}
				})
				.catch((err) => {});
		},
		[
			fieldName,
			projectName,
			templateTypeId,
			sectionName,
			templateName,
			projectId,
			odooId,
			selectedOdooModel,
			odooInstallationName,
			odooParentName,
			api,
			token,
			setLastModifiedOn,
		]
	);

	const uploadImages = useCallback(
		async (e) => {
			if (e) {
				e.preventDefault();
			}
			if (uploadPreviews.length <= 0) {
				return;
			}

			const copyOfUploadPreviews = uploadPreviews;
			setUploadPreviews([]);

			let newPathes = [];
			for (let i = 0; i < copyOfUploadPreviews.length; i++) {
				let uploadImage = await fetch(copyOfUploadPreviews[i]);
				let imageBlob = await uploadImage.blob();
				const formData = new FormData();
				formData.append("newImage", imageBlob, "newImage");
				formData.append("name", fieldName);
				formData.append("odoo_name", projectName);
				formData.append("image_name", sectionName.replaceAll("/", "-") + " - " + fieldName);
				formData.append("template_type_id", templateTypeId);
				formData.append("template_name", templateName);
				formData.append("project_id", projectId);
				formData.append("odooId", odooId);
				formData.append("odooModel", selectedOdooModel);
				formData.append("odooInstallationName", odooInstallationName);
				formData.append("odooParentName", odooParentName);
				// setUploadingImages(true);
				let requestBlob = await fetch(api + "/project/image", {
					method: "POST",
					headers: {
						enctype: "multipart/form-data",
						Accept: "application/json",
						Authorization: token,
					},
					body: formData,
					mode: "cors",
				});
				let resJson = await requestBlob.json();
				// if (i === uploadPreviews.length - 1) {
				// 	setUploadingImages(false);
				// }
				setLastModifiedOn(new Date());
				newPathes.push(resJson.pathToImage);
			}

			setRecentlyAddedPaths(newPathes);
			setUploadedImages([]);
			uploadMulitpleRef.current.value = null;
		},

		[
			uploadPreviews,
			fieldName,
			projectName,
			sectionName,
			templateTypeId,
			templateName,
			projectId,
			odooId,
			selectedOdooModel,
			odooInstallationName,
			odooParentName,
			api,
			token,
			setLastModifiedOn,
		]
	);

	const handleDrag = (e) => {
		e.preventDefault();
		e.stopPropagation();

		if (e.type === "dragenter" || e.type === "dragover") {
			setDragActive(true);
		} else if (e.type === "dragleave") {
			setDragActive(false);
		}
	};

	const handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setDragActive(false);

		const files = e.dataTransfer.files;
		const imagesArray = [];
		for (let i = 0; i < files.length; i++) {
			if (!files[i].type.match("image")) {
				continue;
			} else {
				imagesArray.push(files[i]);
				setUploadedImages(imagesArray);
			}
		}
	};

	return (
		<div>
			{readOnly === false ? (
				<div className={dragActive ? "text-blue-500 bg-blue-100 animate-bounce" : null} onDragEnter={handleDrag}>
					<div className="flex gap-3 items-center">
						{windowWidth.current < 762 ? (
							<form className="flex">
								<label htmlFor={"pictureButton" + cameraid} className="cursor-pointer">
									<BsFillCameraFill className="text-5xl" />
								</label>
								<input
									ref={uploadSingleRef}
									className="hidden"
									type="file"
									id={"pictureButton" + cameraid}
									accept="image/*"
									onChange={(e) => {
										if (imgSrc) {
											URL.revokeObjectURL(imgSrc);
										}
										setImgSrc(
											URL.createObjectURL(
												new Blob([e.target.files[0]], {
													type: "image/jpg",
													"Content-Disposition": "attachment",
												})
											)
										);
									}}
								/>
							</form>
						) : null}
						{imgSrc ? (
							<SaveImagePrompt
								cameraid={cameraid}
								fieldName={fieldName}
								saveName={sectionName.replaceAll(("/", "-") + " - " + fieldName + `(${numberOfUploaded})`)}
								uploadSingleRef={uploadSingleRef}
								imgSrc={imgSrc}
								uploadImage={uploadImage}
								setImgSrc={setImgSrc}
							/>
						) : null}
						{uploadPreviews && uploadPreviews.length > 0 ? (
							<SaveMultiplePrompt
								cameraid={cameraid}
								uploadPreviews={uploadPreviews}
								setUploadedImages={setUploadedImages}
								uploadImages={uploadImages}
								uploadMulitpleRef={uploadMulitpleRef}
							/>
						) : null}

						<form className="flex">
							<label htmlFor={"uploadButton" + cameraid}>
								<BsFillCloudArrowUpFill className="text-5xl cursor-pointer" />
							</label>
							<input
								ref={uploadMulitpleRef}
								type="file"
								id={"uploadButton" + cameraid}
								className="hidden"
								accept="image/*"
								onChange={(e) => setUploadedImages(e.target.files)}
								multiple
							/>
						</form>
						{dragActive && (
							<div
								className="absolute w-full h-full rounded-2xl"
								onDragEnter={handleDrag}
								onDragLeave={handleDrag}
								onDragOver={handleDrag}
								onDrop={handleDrop}>
							</div>
						)}
					</div>
				</div>
			) : null}
			<div ref={thumbnailBoxRef}>
				<UploadedImages
					setInitialFieldData={setInitialFieldData}
					viewedThumbnailList={viewedThumbnailList}
					fieldName={fieldName}
					setNumberOfUploaded={setNumberOfUploaded}
					recentlyAddedPaths={recentlyAddedPaths}
					setRecentlyAddedPaths={setRecentlyAddedPaths}
					api={api}
					token={token}
					query={sectionName.replaceAll("/", "-") + " - " + fieldName}
					path={
						templateTypeId === 1
							? "/In-Progress2/" +
							  projectName
									.trim()
									.split("/")
									.reduce((accumulator, curretValue, currentIndex, array) => accumulator.trim() + "/" + curretValue.trim()) +
							  "/Installation/Photos/" +
							  templateName
							: "/commercial/" +
							  projectName
									.split("-")
									.reduce((accumulator, currentValue, currentIndex, array) =>
										currentIndex !== projectName.split("-").length - 1 ? accumulator + "-" + currentValue : accumulator
									)
									.trim()
									.split("/")
									.reduce((accumulator, curretValue, currentIndex, array) => accumulator.trim() + "/" + curretValue.trim()) +
							  "/Installation/Photos/" +
							  templateName
					}
					stage={stage}
					initialThumbnailPathes={initialThumbnailPathes}
					projectId={projectId}
					setLastModifiedOn={setLastModifiedOn}
					uploadEditedImage={uploadEditedImage}
				/>
			</div>
		</div>
	);
};

export default CameraContainer;
