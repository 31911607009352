import { useEffect, useState } from "react";
import { BsExclamationCircleFill } from "react-icons/bs";

const ConditionalRender = (props) => {
	const [conditionalField, setConditionalField] = useState(null);

	useEffect(() => {
		if (
			props.fields &&
			props.fields.length > 0 &&
			props.conditionalFieldId !== -1
		) {
			for (let i = 0; i < props.fields.length; i++) {
				if (props.fields[i].id === parseInt(props.conditionalFieldId)) {
					setConditionalField(props.fields[i]);
					return;
				}
			}
		}
	}, [props.conditionalFieldId, props.fields]);

	return (
		<>
			<p className="='w-full"> is</p>
			{conditionalField && conditionalField.field_type === "text" ? (
				<input
					type="text"
					className="border border-rounded border-2 p-2 w-full"
					value={props.conditionalFieldValue}
					onChange={(e) =>
						props.setConditionalFieldValue(e.target.value)
					}></input>
			) : null}
			{conditionalField && conditionalField.field_type === "photos" ? (
				<div className="flex items-center gap-3 bg-rose-100 p-3">
					<BsExclamationCircleFill color="red" className="text-3xl" />
					<p className="">
						{" "}
						Photos cannot be used for conditional rendering
					</p>
				</div>
			) : null}
			{conditionalField && conditionalField.field_type === "long text" ? (
				<textarea
					type="text"
					rows={4}
					value={props.conditionalFieldValue}
					onChange={(e) =>
						props.setConditionalFieldValue(e.target.value)
					}
					className="border border-2 p-2 border-gray w-full whitespace-pre-wrap"
				/>
			) : null}
			{conditionalField && conditionalField.field_type === "number" ? (
				<input
					type="text"
					className="border border-rounded border-2 p-2 w-full"
					value={props.conditionalFieldValue}
					onChange={(e) =>
						props.setConditionalFieldValue(e.target.value)
					}></input>
			) : null}
			{conditionalField && conditionalField.field_type === "date" ? (
				<input
					type="date"
					className="border border-rounded border-2 p-2 w-full"
					value={props.conditionalFieldValue}
					onChange={(e) =>
						props.setConditionalFieldValue(e.target.value)
					}></input>
			) : null}
			{conditionalField && conditionalField.field_type === "selection" ? (
				<select
					className="border border-rounded border-2 p-2 w-full"
					value={props.conditionalFieldValue}
					onChange={(e) => {
						props.setConditionalFieldValue(e.target.value);
					}}>
					<option value=""></option>
					{conditionalField.field_settings &&
					conditionalField.field_settings !== ""
						? conditionalField.field_settings
								.split("[break]")
								.map((value, index) => {
									return (
										<option
											key={index}
											value={value.trim()}>
											{value.trim()}
										</option>
									);
								})
						: null}
				</select>
			) : null}
			{conditionalField && conditionalField.field_type === "yes/no" ? (
				<select
					className="border border-rounded border-2 p-2 w-full"
					value={props.conditionalFieldValue}
					onChange={(e) => {
						props.setConditionalFieldValue(e.target.value);
					}}>
					<option value={-1}></option>
					<option value={1}>Yes</option>
					<option value={0}>No</option>
				</select>
			) : null}
			{conditionalField && conditionalField.field_type === "currency" ? (
				<input
					type="text"
					className="border border-rounded border-2 p-2 w-full"
					value={props.conditionalFieldValue}
					onChange={(e) =>
						props.setConditionalFieldValue(e.target.value)
					}></input>
			) : null}
		</>
	);
};

export default ConditionalRender;
