const YesNoPrompt = (props) => {
	const confirm = (e) => {
		e.preventDefault();
		props.deleteAction();
	};

	const deny = (e) => {
		e.preventDefault();
		props.cancelAction();
	};

	return (
		<div className="fixed top-0 left-0 w-full h-screen flex justify-center items-center bg-black/50 z-50" onClick={(e) => deny(e)}>
			<div className="bg-white m-2 rounded-lg w-full sm:w-auto" onClick={(e) => e.stopPropagation()}>
				<p className="p-4 text-3xl">
					Delete {props.type}: {props.name}?
				</p>
				<div className="flex flex-col items-center ">
					{props.hasImage ? <img src={props.imgSrc} alt="to delete" className="max-h-[80vh] w-auto"></img> : null}
				</div>
				<form className="p-4">
					<div className="flex gap-4 justify-center">
						<button type="button" onClick={(e) => deny(e)} className="rounded bg-gray-500 p-2 text-white">
							Cancel
						</button>
						<button type="button" onClick={(e) => confirm(e)} className="rounded bg-red-500 p-2 text-white">
							Delete
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default YesNoPrompt;
