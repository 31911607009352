import { useState } from "react";
import { BsExclamationCircleFill } from "react-icons/bs";
import ConditionalRender from "./ConditionalRenderer";

const ConditionalModal = ({ fields, selectedFieldName, setSelectedConditionals, selectedConditionals, setShowConditionalModal }) => {
	const [selectedConditionField, setSelectedConditionField] = useState("");
	const [conditionalFieldValue, setConditionalFieldValue] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	const addToConditionals = (e) => {
		for (let i = 0; i < selectedConditionals.length; i++) {
			if (
				selectedConditionals[i].selectedConditionField === selectedConditionField &&
				selectedConditionals[i].conditionalFieldValue === conditionalFieldValue
			) {
				return;
			}
		}

		let foundName = "";
		let foundType = "";
		for (let j = 0; j < fields.length; j++) {
			if (fields[j].id.toString() === selectedConditionField.toString()) {
				foundName = fields[j].name;
				foundType = fields[j].field_type;
				break;
			}
		}

		setSelectedConditionals((prev) => [
			...prev,
			{
				selectedConditionFieldName: foundName,
				selectedFieldType: foundType,
				selectedConditionField: selectedConditionField,
				conditionalFieldValue: conditionalFieldValue,
			},
		]);
	};

	return (
		<div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black/50 z-50">
			<div className="bg-white w-full md:w-1/2 m-2 rounded-lg ">
				<p className="p-4 text-left font-bold text-3xl">Add Conditional</p>
				<hr></hr>
				<div className="p-4">
					<p className="w-full">Display when:</p>
					<select
						className="border border-rounded border-2 p-2 w-full"
						value={selectedConditionField}
						onChange={(e) => {
							setSelectedConditionField(e.target.value);
							setConditionalFieldValue("");
						}}>
						<option value=""></option>
						{fields && fields.length > 0
							? fields.map((values) => {
									if (values.name !== selectedFieldName) {
										return (
											<option value={values.id} key={values.id}>
												{values.field_type} - {values.name}
											</option>
										);
									}
									return null;
							  })
							: null}
					</select>
					{selectedConditionField && selectedConditionField !== -1 ? (
						<ConditionalRender
							fields={fields}
							conditionalFieldId={selectedConditionField}
							conditionalFieldValue={conditionalFieldValue}
							setConditionalFieldValue={setConditionalFieldValue}
						/>
					) : null}
				</div>
				<hr />
				<div className="p-4">
					<div>
						{errorMessage && errorMessage !== "" ? (
							<div className="flex items-center gap-2 bg-rose-100 p-2 mt-2 rounded-lg">
								<BsExclamationCircleFill color="red" className="text-3xl" />
								<p>{errorMessage}</p>
							</div>
						) : null}
					</div>
					<div className="flex justify-end gap-3 pt-4">
						<button
							onClick={(e) => {
								e.preventDefault();
								setShowConditionalModal(false);
							}}
							className="rounded-none bg-gray-500 p-2 text-white">
							Cancel
						</button>
						{conditionalFieldValue !== "" && selectedConditionField !== "" ? (
							<button
								className="rounded-none bg-blue-500 p-2 text-white"
								onClick={(e) => {
									addToConditionals(e);
									setShowConditionalModal(false);
								}}>
								Add conditional
							</button>
						) : (
							<button
								className="rounded-none bg-gray-500 p-2 text-white cursor-default"
								onClick={(e) => {
									e.preventDefault();
								}}>
								Add conditional
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ConditionalModal;
