import React, { useState, useEffect } from "react";

const LoadingDots = ({ speed = 200 }) => {
	const [dotState, setDotState] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			setDotState((prevDotState) => (prevDotState + 1) % 4);
		}, speed);

		return () => clearInterval(interval);
	}, [speed]);

	return <div className="flex gap-2">{".".repeat(dotState)}</div>;
};

export default LoadingDots;
