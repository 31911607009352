import { useEffect } from "react";
import { AiFillAndroid, AiFillApple } from "react-icons/ai";

const AppDownloadPage = () => {
	useEffect(() => {
		document.title = "Mobile App Download";
	}, []);

	return (
		<div className="w-full flex justify-center min-h-[100vh] bg-gray-100">
			<div className="w-full md:w-2/5 m-4  p-6 rounded-lg">
				<div className="text-center">
					<h1 className="text-2xl font-bold">Krummy Capture Mobile App Download</h1>
					<h2 className="text-xl">Version 1.0.0</h2>
				</div>
				<div className="flex flex-col md:flex-row gap-4 md:gap-4 justify-between">
					<div className="mt-4 border border-1 border-black md:basis-1/2 p-4 bg-white rounded-lg">
						<div className="flex items-center">
							<AiFillAndroid color="green" size={30} />
							<p className="text-lg font-bold ">Android</p>
						</div>

						<div>
							<a
								href="https://krummycapturesdks.s3.us-west-1.amazonaws.com/app-release.apk"
								className="underline text-blue-500 hover:text-blue-700">
								Download APK
							</a>
						</div>
					</div>
					<div className="md:mt-4 md:basis-1/2 p-4 border border-1 border-black bg-white rounded-lg">
						<div className="flex items-center">
							<AiFillApple size={30} />
							<p className="text-lg font-bold">iOS</p>
						</div>
						<p>Currently unavailable</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AppDownloadPage;
