import { useEffect, useState } from "react";
import { IoAdd } from "react-icons/io5";
import LoadingCircle from "../utility/LoadingCircle";
import BankWord from "./BankWord";
import arrowUp from "../../assets/arrow-up-circle.png";

const WordBankList = ({ api, token, setWordList, wordList, isLoading, filterWord, showList, isField, listName, setErrorMessage }) => {
	const [newWord, setNewWord] = useState("");
	const [filteredWords, setFilteredWords] = useState([]);

	useEffect(() => {
		if (filterWord !== "") {
			setFilteredWords(wordList.filter((word) => word.name.toLowerCase().includes(filterWord.toLowerCase())));
		} else {
			setFilteredWords(wordList);
		}
	}, [filterWord, setWordList, wordList]);

	const checkWordAlreadyExists = () => {
		for (let i = 0; i < wordList.length; i++) {
			if (wordList[i].name.toLowerCase() === newWord.toLowerCase()) {
				setErrorMessage(`"${newWord}" already exists in the ${listName}`);
				return true;
			}
		}
		return false;
	};

	const addWord = (e) => {
		e.preventDefault();
		if (newWord === "") {
			setErrorMessage("Please enter a word or phrase");
			return;
		}
		if (checkWordAlreadyExists() === true) {
			return;
		}
		if (api === "" || token === "") {
			return false;
		}

		fetch(api + "/wordbank", {
			method: "POST",
			mode: "cors",
			headers: {
				Authorization: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				name: newWord,
				isField: isField ? 1 : 0,
			}),
		})
			.then((res) => {
				if (res.ok) {
					return res.json();
				} else {
					// setErrorMessage(
					// 	"Something went wrong when trying to add the phrase"
					// );
				}
			})
			.then((res) => {
				if (res) {
					setNewWord("");
					setWordList([
						{
							id: res.wordbank_id,
							name: newWord,
							isField: isField ? 1 : 0,
						},
						...wordList,
					]);
				}
			});
	};

	const toggleVisible = () => {
		if (document.getElementById("Field List").scrollTop > 400) {
			document.getElementById("Field List Button").style.display = "inline";
		} else if (document.getElementById("Field List").scrollTop <= 400) {
			document.getElementById("Field List Button").style.display = "none";
		}
		if (document.getElementById("Section List").scrollTop > 400) {
			document.getElementById("Section List Button").style.display = "inline";
		} else {
			document.getElementById("Section List Button").style.display = "none";
		}
	};

	const goToTop = (e) => {
		if (e.target.id === "Field List Button") {
			document.getElementById("Field List").scrollTo(0, 0);
		} else if (e.target.id === "Section List Button") {
			document.getElementById("Section List").scrollTo(0, 0);
		}
	};

	if (document.getElementById("Field List")) {
		document.getElementById("Field List").addEventListener("scroll", toggleVisible);
	}

	if (document.getElementById("Section List")) {
		document.getElementById("Section List").addEventListener("scroll", toggleVisible);
	}

	return (
		<div className={`w-full ${showList ? " " : " hidden"} `}>
			<form onSubmit={(e) => addWord(e)} className="w-full flex justify-center items-center mb-4">
				<input
					className="rounded-l-lg p-1 border border-black text-black w-full"
					type="text"
					id="newword"
					name="newword"
					placeholder={"Word to add to " + listName}
					value={newWord}
					onChange={(e) => {
						setNewWord(e.target.value);
						setErrorMessage("");
					}}
				/>
				<button className="rounded-r-lg p-2 border border-black text-black" type="submit">
					<IoAdd />
				</button>
			</form>
			<p className="text-xl md:text-2xl bg-gray-300 text-center p-3 border border-black p-4 rounded-t-lg sticky top-0">{listName}</p>
			<div className="overflow-y-scroll h-[60vh]" id={listName}>
				<button className="fixed bottom-10 md:pl-[24%] pl-[34%]">
					<img style={{ display: "none" }} src={arrowUp} className="w-auto h-9" id={listName + " Button"} onClick={(e) => goToTop(e)} />
				</button>

				{wordList && filteredWords.length > 0 ? (
					filteredWords.map((value, index) => {
						return (
							<BankWord
								key={value.id}
								displayColor={index % 2 === 0 ? "bg-gray-200" : "bg-white"}
								roundedCornerCss={`  ${index === filteredWords.length - 1 ? "rounded-b-lg" : ""}`}
								name={value.name}
								id={value.id}
								api={api}
								token={token}
								wordbank={wordList}
								setWordBank={setWordList}
							/>
						);
					})
				) : isLoading ? (
					<div className="flex flex-col items-center flex-center gap-3 bg-white p-4">
						<LoadingCircle />
						<p>Loading...</p>
					</div>
				) : (
					<p className="text-center text-xl">No results found</p>
				)}
			</div>
		</div>
	);
};

export default WordBankList;
