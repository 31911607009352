import { useEffect, useState } from "react";
import ImageViewer from "./ImageViewer";
import { BsTrash } from "react-icons/bs";
import YesNoPrompt from "../../utility/YesNoPrompt";
import LoadingOverlay from "../../utility/LoadingOverlay";

const UploadedImages = ({
	api,
	token,
	path,
	query,
	recentlyAddedPaths,
	setRecentlyAddedPaths,
	setInitialFieldData,
	setNumberOfUploaded,
	fieldName,
	stage,
	viewedThumbnailList,
	initialThumbnailPathes,
	projectId,
	setLastModifiedOn,
	uploadEditedImage
}) => {
	const [imagePaths, setImagePaths] = useState([]);
	const [thumbnailData, setThumbnailData] = useState([]);
	const [showImage, setShowImage] = useState(false);
	const [isLoading, setIsLoading] = useState();
	const [clickedImageIndex, setClickedImageIndex] = useState(0);
	const [showDeletePrompt, setShowDeletePrompt] = useState(false);
	const [deletePath, setDeletePath] = useState("");
	const [srcToDelete, setSrcToDelete] = useState("");
	const [srcOfImageToShow, setSrcOfImageToShow] = useState("");

	useEffect(() => {
		if (api === "" || token === "" || path === "" || query === "") {
			return;
		}

		if (initialThumbnailPathes) {
			return;
		}

		const controller = new AbortController();
		const signal = controller.signal;
		fetch(api + "/project/dropbox/images", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			signal,
			body: JSON.stringify({
				query: query
					.replaceAll("/", "_")
					.replaceAll("\\", "_")
					.replaceAll("<", "_")
					.replaceAll(">", "_")
					.replaceAll(":", "_")
					.replaceAll('"', "_")
					.replaceAll("|", "_")
					.replaceAll("?", "_")
					.replaceAll("*", "_")
					.replaceAll(".", "_"),
				path: path,
			}),
		})
			.then((res) => {
				if (res) {
					return res.json();
				}
			})
			.then((res) => {
				if (res && res.matches) {
					setImagePaths(res.matches.map((match) => match.metadata.metadata.path_lower));
				}
			}) 
			.catch((err) => {});

		return () => {
			controller.abort();
		};
	}, [api, token, path, query, initialThumbnailPathes]);

	useEffect(() => {
		if (initialThumbnailPathes && initialThumbnailPathes.length > thumbnailData.length) {
			setImagePaths(initialThumbnailPathes.map((mapData) => mapData.path_lower));
		}
	}, [initialThumbnailPathes, thumbnailData]);

	useEffect(() => {
		if (imagePaths) {
			setNumberOfUploaded(imagePaths.length);
		} else {
			setNumberOfUploaded(0);
		}
	}, [imagePaths, setNumberOfUploaded]);

	useEffect(() => {
		if (api === "" || token === "" || imagePaths.length <= 0 || !viewedThumbnailList) {
			return;
		}

		const controller = new AbortController();
		const signal = controller.signal;
		setIsLoading(true);
		fetch(api + "/project/dropbox/thumbnails", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			signal,
			body: JSON.stringify({ entries: [...imagePaths] }),
		})
			.then((res) => {
				setIsLoading(false);
				if (res) {
					return res.json();
				}
			})
			.then((res) => {
				if (res) {
					if (typeof setInitialFieldData !== "undefined") {
						setInitialFieldData((prev) => prev);
					}
					setThumbnailData(
						res.thumbnail.entries.map((thumbnail) => {
							let data = thumbnail.metadata;
							data.thumbnail = "data:image/jpeg;base64, " + thumbnail.thumbnail;
							return data;
						})
					);
				}
			})
			.catch((err) => {});

		return () => {
			controller.abort();
		};
	}, [imagePaths, api, token, setInitialFieldData, viewedThumbnailList]);

	useEffect(() => {
		if (recentlyAddedPaths && recentlyAddedPaths.length > 0) {
			setImagePaths([...new Set([...imagePaths, ...recentlyAddedPaths])]);
			setRecentlyAddedPaths([]);
		}
	}, [recentlyAddedPaths, imagePaths, setRecentlyAddedPaths]);

    useEffect(() => {
        if (clickedImageIndex !== null && clickedImageIndex >= 0 && clickedImageIndex < thumbnailData.length) {
            setSrcOfImageToShow(thumbnailData[clickedImageIndex].thumbnail);
        }
    }, [clickedImageIndex, thumbnailData]);

	const showImageWindow = (imagePath, index, theSrc) => {
		setShowImage(true);
		setClickedImageIndex(index);
		setSrcOfImageToShow(theSrc);
	};

	const prepDeletePrompt = (pathName, theImgSrc) => {
		setDeletePath(pathName);
		setShowDeletePrompt(true);
		setSrcToDelete(theImgSrc);
	};

	const deleteImage = () => {
		setShowDeletePrompt(false);
		setSrcOfImageToShow("");
		fetch(api + "/project/dropbox/image", {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			body: JSON.stringify({ path: deletePath, stage: stage, project_id: projectId }),
		}).then((res) => {
			if (res.ok) {
				setLastModifiedOn(new Date());
				setThumbnailData(thumbnailData.filter((thumbData) => thumbData.path_lower !== deletePath));
				setNumberOfUploaded((prev) => prev - 1);
			}
		});
	};

	return (
		<div>
			{showDeletePrompt ? (
				<YesNoPrompt
					type="image"
					name={deletePath.split("/")[deletePath.split("/").length - 1].split("-")[1]}
					hasImage={true}
					imgSrc={srcToDelete}
					cancelAction={() => setShowDeletePrompt(false)}
					deleteAction={() => deleteImage()}
				/>
			) : null}

			{showImage && srcOfImageToShow !== "" ? (
				<ImageViewer
					setShowImage={setShowImage}
					clickedImageIndex={clickedImageIndex}
					setClickedImageIndex={setClickedImageIndex}
					fieldName={fieldName}
					api={api}
					token={token}
					imgSrc={srcOfImageToShow}
					setSrcOfImageToShow={setSrcOfImageToShow}
					thumbnailData={thumbnailData}
					uploadEditedImage={uploadEditedImage}
					deleteImage={deleteImage}
					deletePath={deletePath}
					prepDeletePrompt={prepDeletePrompt}
					showDeletePrompt={showDeletePrompt}
					setShowDeletePrompt={setShowDeletePrompt}
				/>
			) : null}
			<div className="flex flex-wrap gap-2">
				{thumbnailData && thumbnailData.length > 0 ? (
					thumbnailData.map((value, index) => {
						return (
							<div className="border boder-2 border-black flex items-center" key={value.id}>
								<img
									src={value.thumbnail}
									alt={value.name}
									className="h-16 w-16 cursor-pointer"
									onClick={() => showImageWindow(value.path_lower, index, value.thumbnail)}
								/>
								<div
									className="bg-red-800 h-full flex justify-center items-center cursor-pointer"
									onClick={() => prepDeletePrompt(value.path_lower, value.thumbnail)}>
									<BsTrash color="white" className="text-2xl" />
								</div>
							</div>
						);
					})
				) : isLoading  ? (
					<div className="border boder-2 border-black flex items-center">
						<div className="h-16 w-20">
							<LoadingOverlay />
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default UploadedImages;
