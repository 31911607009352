const RadioGroup = ({ name, values, value, onChange, productId }) => (
	<div className="mt-1">
		<div className="flex items-center">
			<label className="font-bold text-lg mb-1">{name}</label>
			<div className="flex-grow border-b border-gray-400 ml-2"></div>
		</div>
		<div className="flex gap-4 flex-wrap">
			{values.map((val) => (
				<div key={val.id} className="flex gap-1 items-center">
					<input
						type="radio"
						id={`${name}-${val.id}-${productId}`}
						name={name + "-" + productId}
						value={val.id}
						checked={value.toString() === val.id.toString()}
						onChange={onChange}
					/>
					<label htmlFor={`${name}-${val.id}-${productId}`}>{val.name}</label>
				</div>
			))}
		</div>
	</div>
);

export default RadioGroup;
