import { useState } from "react";
import { formatTimeToMatchADP, getADPFormattedTimestamp } from "./ClockinUtils";
import ClockInModal from "./ClockinModal";
import { GiMeal } from "react-icons/gi";
import { BsHouseDoorFill } from "react-icons/bs";
import TimeSinceDate from "./TimeSinceDate";
import { IoMdWarning } from "react-icons/io";
import ClockingNotesModal from "./ClockingNotesModal";

const ClockOutButton = ({
	api,
	token,
	odooId,
	odooModel,
	projectsRoute,
	clockingData,
	setClockingData,
	clockedIntoThis,
	isFullWidth = false,
	showClock = false,
	clockBannerToShow,
	setClockBannerToShow,
}) => {
	const [conflictingProject, setConflictingProject] = useState(false);
	const [clockingOut, setClockingOut] = useState(false);
	const [responseCode, setResponseCode] = useState(-1);
	const [showModal, setShowModal] = useState(false);
	const [conflictIdMatchesClockInId, setConflictIdMatchesClockInId] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [overTimeIsMeal, setOverTimeIsMeal] = useState(false);
	const [showOvertimeModal, setShowOvertimeModal] = useState(false);
	const [lateReasonText, setLateReasonText] = useState("");
	const [note, setNote] = useState("");
	const [showNoteModalMeal, setShowNoteModalMeal] = useState(false);
	const [showNoteModalOut, setShowNoteModalOut] = useState(false);

	const clockOut = (isMeal = false) => {
		if (clockingData && clockingData.lastClocked && showOvertimeModal === false) {
			const currentDate = new Date();
			const differenceInMilliseconds = currentDate - clockingData.lastClocked;
			const differenceInMinutes = differenceInMilliseconds / 1000 / 60;

			if (differenceInMinutes >= 360) {
				setErrorMessage("You have been clocked in longer than 6 hours ");
				setResponseCode(400);
				setShowModal(true);
				return;
			}
		}
		setShowOvertimeModal(false);
		setClockingOut(true);
		setShowModal(true);
		let newClockOutTime = getADPFormattedTimestamp();

		fetch(api + "/clocking/out", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			body: JSON.stringify({
				odooId: odooId,
				odooModel: odooModel,
				ADPTimestamp: newClockOutTime,
				isMeal,
				lateReasonText,
				lastClockedInTime: formatTimeToMatchADP(clockingData.lastClocked),
				note: note,
			}),
		})
			.then((res) => {
				setResponseCode(res.status);
				if (res.ok || res.status === 409) {
					return res.json();
				} else {
					setErrorMessage("An Unexpected error occurred. Please try again. If this persists, please contact IT.");
					setClockingOut(false);
				}
			})
			.then((data) => {
				if (data) {
					if (Object.keys(data).includes("clockingInfo")) {
						setClockingData({
							clockingInfo: data.clockingInfo,
							specialCode: data.specialCode,
							lastClocked: data.lastClocked ? new Date(data.lastClocked) : "",
						});
						setClockBannerToShow("");
						setLateReasonText("");
						setNote("");
						setShowNoteModalMeal(false);
						setShowNoteModalOut(false);
					}
					if (data.error) {
						setErrorMessage(data.error);
						if (data.conflictedInstallation) {
							setConflictingProject(data.conflictedInstallation);
							setConflictIdMatchesClockInId(data.conflictIdMatchesClockInId);
						} else {
							//something else went wrong here
						}
					} else {
						setErrorMessage("");
					}
				}
				setClockingOut(false);
			})
			.catch((err) => {
				setResponseCode(400);
				setErrorMessage("An Unexpected error occurred. Please try again. If this persists, please contact IT.");
				setClockingOut(false);
			});
	};

	const showOvertimeWarning = (isMeal = false) => {
		setOverTimeIsMeal(isMeal);
		setShowOvertimeModal(true);
	};

	const closeNoteModal = () => {
		setShowNoteModalMeal(false);
		setShowNoteModalOut(false);
	};

	return (
		<div className={`flex ${isFullWidth ? "flex-col gap-1" : "gap-2"}`}>
			{showNoteModalMeal || showNoteModalOut ? (
				<ClockingNotesModal
					closeModal={closeNoteModal}
					note={note}
					setNote={setNote}
					buttonLogic={() => {
						if (showNoteModalMeal) {
							clockOut(true);
						} else if (showNoteModalOut) {
							clockOut(false);
						} else {
							alert("An invalid option was somehow selected. Please close try again");
							closeNoteModal();
						}
					}}
					buttonText={showNoteModalMeal ? "Meal Out" : showNoteModalOut ? "Clock Out" : "Invalid options. Please close and try again"}
				/>
			) : null}
			{showOvertimeModal ? (
				<div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black/[.3] z-[200]">
					<div className="p-4 bg-white rounded-lg w-full md:w-1/5">
						<div className="flex flex-col justify-center items-center">
							<div className="flex justify-center items-center">
								<IoMdWarning size={55} color="red" />
							</div>
							<p className="text-red-700">Warning</p>

							<p className="mt-1 text-center">You have been working for 6 or more hours. Please leave a reason in order to clock out.</p>

							<div className="mt-4 w-full">
								<label>
									Reason:
									<form onSubmit={(e) => e.preventDefault()} className="w-full">
										<textarea
											className="border border-black w-full p-2"
											rows={6}
											minLength={6}
											value={lateReasonText}
											onChange={(e) => setLateReasonText(e.target.value)}
										/>
									</form>
								</label>
								<p className="text-right">{`${lateReasonText.trim().length}/6`}</p>
							</div>
							{lateReasonText.trim().length >= 6 ? (
								<button
									className="bg-blue-500 hover:bg-blue-700 p-3 rounded-lg text-white mt-4 w-full"
									onClick={() => clockOut(overTimeIsMeal)}>
									I Understand and Have Written My Reason
								</button>
							) : (
								<button className="bg-gray-500  p-3 rounded-lg text-white mt-4 w-full" onClick={(e) => e.preventDefault()} disabled={true}>
									Please write at least 6 characters
								</button>
							)}

							<button className="bg-gray-500 hover:bg-gray-600 p-3 rounded-lg text-white mt-2 w-full" onClick={() => setShowOvertimeModal(false)}>
								Cancel
							</button>
						</div>
					</div>
				</div>
			) : null}

			{showModal ? (
				<ClockInModal
					isLoading={clockingOut}
					odooId={odooId}
					odooModel={odooModel}
					conflictingProject={conflictingProject}
					status={responseCode}
					cancel={() => setShowModal(false)}
					projectsRoute={""}
					conflictIdMatchesClockInId={conflictIdMatchesClockInId}
					loadingMessage={"Clocking out..."}
					successMessage={"You Clocked Out."}
					errorMessage={errorMessage}
				/>
			) : null}
			{clockedIntoThis && clockingData.specialCode === "clock-out" ? (
				<div className="flex flex-col gap-1">
					{clockingData && clockingData.lastClocked ? (
						showClock ? (
							<div className={`${showClock ? "" : "hidden"}`}>
								<TimeSinceDate
									date={clockingData.lastClocked}
									preText={"Started Work At"}
									setClockBannerToShow={setClockBannerToShow}
									specialCode={clockingData.specialCode}
								/>
							</div>
						) : !showClock && clockBannerToShow !== "over" ? (
							<div className={"hidden"}>
								<TimeSinceDate
									date={clockingData.lastClocked}
									preText={"Started Work At"}
									setClockBannerToShow={setClockBannerToShow}
									specialCode={clockingData.specialCode}
								/>
							</div>
						) : null
					) : null}
					<button
						className={`p-2 border border-blue-500 text-white ${
							showModal ? "bg-gray-500" : "bg-blue-600 hover:bg-blue-700"
						} w-full rounded-lg flex items-center justify-center gap-1`}
						onClick={() => {
							clockBannerToShow === "over" ? showOvertimeWarning(true) : setShowNoteModalMeal(true);
						}}>
						<GiMeal />
						Meal Out
					</button>
					<button
						className={`p-2 border border-blue-500 text-white ${
							showModal ? "bg-gray-500" : "bg-blue-600 hover:bg-blue-700"
						} w-full rounded-lg flex items-center justify-center gap-1`}
						onClick={() => {
							clockBannerToShow === "over" ? showOvertimeWarning(false) : setShowNoteModalOut(true);
						}}>
						<BsHouseDoorFill />
						Clock Out
					</button>
				</div>
			) : null}
		</div>
	);
};

export default ClockOutButton;
