const StagePrompt = (props) => {
	const confirm = async (e) => {
		e.preventDefault();
		props.confirmAction();
	};

	const deny = (e) => {
		e.preventDefault();
		props.cancelAction();
	};

	return (
		<div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-black/50 z-[500]">
			<div className="bg-white m-2 rounded-lg">
				<p className="p-5 text-3xl">{props.prompt}</p>
				<form className="p-8">
					<div className="flex gap-5 justify-center">
						{props.hasDownloadSegment ? (
							<p onClick={(e) => confirm(e)} className="cursor-pointer rounded-none bg-red-500 p-2 text-white">
								{props.yesText}
							</p>
						) : (
							<button type="button" onClick={(e) => confirm(e)} className="rounded-none bg-red-500 p-2 text-white">
								{props.yesText}
							</button>
						)}
						{props.noText ? (
							<button type="button" onClick={(e) => deny(e)} className="rounded-none bg-gray-500 p-2 text-white">
								{props.noText}
							</button>
						) : null}
					</div>
				</form>
			</div>
		</div>
	);
};

export default StagePrompt;
