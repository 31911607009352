import { useEffect, useState } from "react";
import TimeSince from "../../utility/TimeSince";
import { ImLoop2 } from "react-icons/im";
import { IoMdClose } from "react-icons/io";
import LoadingCircle from "../../utility/LoadingCircle";
import { IoIosMail } from "react-icons/io";

const MessageLogs = ({ api, token, userId, odoo_model, odoo_id, openChatLog, setOpenChatLog }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [messages, setMessages] = useState([]);

	useEffect(() => {
		if (!api || !token || !userId || !odoo_model || !odoo_id) {
			return;
		}
		setIsLoading(true);
		const controller = new AbortController();
		const signal = controller.signal;
		fetch(api + "/messages", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: token,
			},
			body: JSON.stringify({ odoo_model, odoo_id }),
			signal,
		})
			.then((res) => {
				setIsLoading(false);
				if (res.ok) {
					return res.json();
				}
			})
			.then((data) => {
				if (data) {
					setMessages(data.messages);
				}
			});

		return () => {
			controller.abort();
		};
	}, [api, odoo_id, odoo_model, token, userId]);

	const refreshMessages = () => {
		setIsLoading(true);
		fetch(api + "/messages", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: token,
			},
			body: JSON.stringify({ odoo_model, odoo_id }),
		})
			.then((res) => {
				setIsLoading(false);
				if (res.ok) {
					return res.json();
				}
			})
			.then((data) => {
				if (data) {
					setMessages(data.messages);
				}
			});
	};

	return (
		<div
			className={`text-black fixed top-0 left-0 w-full h-full flex justify-end ${openChatLog ? "" : "hidden"} bg-black/50 z-50`}
			onClick={() => setOpenChatLog(false)}>
			<div className="bg-white w-full lg:w-2/4 bg-gray-200" onClick={(e) => e.stopPropagation()}>
				<div className="flex flex-col h-full">
					<div className="flex-shrink-0 p-2 text-white flex justify-between items-center" style={{ backgroundColor: "#714B67" }}>
						<div className="flex gap-2 items-center">
							<p className="text-2xl font-bold ">Odoo Log</p>
							{isLoading ? null : (
								<button onClick={refreshMessages}>
									<ImLoop2 size={20} />
								</button>
							)}
						</div>
						<button onClick={() => setOpenChatLog(false)}>
							<IoMdClose size={30} />
						</button>
					</div>
					<div className="flex-grow overflow-y-scroll ">
						{isLoading ? (
							<div className="flex flex-col justify-center items-center h-full">
								<LoadingCircle />
								<p>Retrieving Messages...</p>
							</div>
						) : null}
						{messages && messages.length > 0 && !isLoading ? (
							messages.map((mapData) => {
								return (
									<div key={mapData.id} className="mx-2 mt-2 rounded-lg ">
										<div className="flex gap-1 items-center">
											<p className="font-bold">{mapData.author_id ? mapData.author_id[1] : null}</p>
											<p> </p>
											<TimeSince date={mapData.create_date + "Z"} />
										</div>
										<div className="bg-white p-2 rounded-lg mt-1">
											<div dangerouslySetInnerHTML={{ __html: mapData.body }} />
											<p>{mapData.subtype_id ? (mapData.body ? null : mapData.subtype_id[1]) : null}</p>
										</div>
									</div>
								);
							})
						) : (
							<div className="flex flex-col items-center justify-center h-full gap-1">
								<IoIosMail size={43} />
								<p className="text-lg font-bold">This log is empty</p>
							</div>
						)}
						<div className="mb-2" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default MessageLogs;
