import { Link } from "react-router-dom";

const TemplateSelectionModal = ({ templateData, setShowTemplateSelectionModal, templateName }) => {
	const closeModal = (e) => {
		e.preventDefault();
		setShowTemplateSelectionModal(false);
	};
	return (
		<div
			className="fixed top-0 left-0 w-full h-screen flex justify-center items-center bg-black/50 z-30"
			onClick={() => setShowTemplateSelectionModal(false)}>
			<div className="bg-white m-2 rounded-lg w-full md:w-auto min-w-[50%]" onClick={(e) => e.stopPropagation()}>
				<div className="border border-black border-2 rounded-lg w-full ">
					<div className="flex justify-between bg-gray-300 rounded-t-lg p-4 items-center">
						<p className="text-2xl font-bold text-center ">{templateName}</p>
					</div>
					<hr className="border-black" />
					<div className="p-4 flex flex-col gap-2 max-h-[40vh] overflow-y-scroll ">
						{templateData && templateData.length > 0
							? templateData.map((mapData) => {
									return (
										<div key={mapData.id} className="flex justify-between items-center">
											<div className="flex gap-3 items-center">
												<p>Version {mapData.version}</p>
												<p
													className={`font-normal text-base p-1 ${mapData.status_name === "archived" ? " bg-gray-300 " : ""} ${
														mapData.status_name === "draft" ? " bg-orange-300 " : ""
													} ${mapData.status_name === "published" ? " bg-green-300 " : ""}`}>
													{mapData.status_name}
												</p>
											</div>
											<Link to={`/template/${mapData.id}`} className="text-blue-600 hover:underline">
												View
											</Link>
										</div>
									);
							  })
							: null}
					</div>
					<hr className="border-black" />
					<div className="bg-gray-300 rounded-b-lg p-2 flex justify-center">
						<button className="bg-blue-600 text-white p-2 rounded-lg" onClick={(e) => closeModal(e)}>
							Close
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TemplateSelectionModal;
