import { useEffect, useState } from "react";
import { BiTrash } from "react-icons/bi";
import { AiOutlinePlus, AiFillEyeInvisible } from "react-icons/ai";
import LoadingCircle from "../utility/LoadingCircle";

const ReportImageModal = ({ setShowReportImageModal, logos, selectedImgName, setSelectedImgName, api, token, reportId, setLogos }) => {
	const [tempSelectedName, setTempSelectedName] = useState("");
	const [imgSrc, setImgSrc] = useState(null);
	const [uploadingImage, setUploadingImage] = useState(false);
	const [imgToDelete, setImgToDelete] = useState("");
	const [deletingImage, setDeletingImage] = useState(false);
	const closeModal = (e) => {
		setShowReportImageModal(false);
	};

	useEffect(() => {
		setTempSelectedName(selectedImgName);
	}, [selectedImgName]);

	const stopPropagation = (e) => {
		e.stopPropagation();
	};

	const changeTempSelected = (e, theName) => {
		e.preventDefault();
		setTempSelectedName(theName);
	};

	const saveSelection = (e) => {
		e.preventDefault();
		if (tempSelectedName === selectedImgName) {
			setShowReportImageModal(false);
			return;
		}
		setSelectedImgName(tempSelectedName);
		setShowReportImageModal(false);
	};

	const saveSelectionViaUpload = (theUploadedName) => {
		setSelectedImgName(theUploadedName);
		setShowReportImageModal(false);
	};

	const uploadImage = (e) => {
		e.preventDefault();
		if (uploadingImage) {
			return;
		}

		setUploadingImage(true);
		const formData = new FormData();

		fetch(imgSrc)
			.then((res) => {
				if (res) {
					return res.blob();
				}
			})
			.then((resBlob) => {
				if (resBlob) {
					formData.append("newImage", resBlob, "newImage");
					fetch(api + "/report/logos", {
						method: "post",
						headers: {
							enctype: "multipart/form-data",
							Authorization: token,
						},
						body: formData,
					})
						.then((res2) => {
							if (res2.ok) {
								return res2.json();
							} else {
								setUploadingImage(false);
							}
						})
						.then((imgName) => {
							let imageName = imgName.pathToImage.split("/").splice(-1).join("").trim();
							setLogos((prev) => [...prev, { name: imageName, thumbnail: imgSrc }]);
							saveSelectionViaUpload(imageName);
						});
				}
			});
	};

	const addImage = (e) => {
		if (e.target.files.length > 0) {
			setImgSrc(
				URL.createObjectURL(
					new Blob([e.target.files[0]], {
						type: "image/jpg",
						"Content-Disposition": "attachment",
					})
				)
			);
		}
	};

	const deleteImage = (e) => {
		if (deletingImage) {
			return;
		}
		setDeletingImage(true);
		fetch(api + "/report/logos", {
			method: "delete",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			body: JSON.stringify({ imageName: imgToDelete }),
		}).then((res) => {
			if (res.ok) {
				setLogos((prev) => [...prev.filter((filterData) => filterData.name !== imgToDelete)]);
				setImgToDelete("");
				setDeletingImage(false);
			} else {
				setDeletingImage(false);
			}
		});
	};

	const resetUploadImage = (e) => {
		URL.revokeObjectURL(imgSrc);
		setImgSrc(null);
	};

	const resetDeleteImage = (e) => {
		e.preventDefault();
		setImgToDelete("");
	};

	return (
		<div className="fixed top-0 left-0 w-full h-screen flex justify-center items-center bg-black/50 z-30" onClick={closeModal}>
			<div className="bg-white m-2 rounded-lg w-full md:w-auto" onClick={stopPropagation}>
				<div className="border border-black border-2 rounded-lg w-full  ">
					<div className="flex justify-between bg-gray-300 rounded-t-lg p-4 items-center">
						{imgSrc ? <p className="text-2xl font-bold text-center ">Upload Image?</p> : null}
						{!imgSrc && !imgToDelete ? <p className="text-2xl font-bold text-center ">Select Cover Image</p> : null}
						{imgToDelete !== "" ? <p className="text-2xl font-bold text-center ">Delete this image?</p> : null}
					</div>
					<div className="p-4">
						<div className="flex gap-2 mb-4 flex-wrap md:max-w-[50vw] max-h-[60vh] overflow-y-scroll justify-center items-center border border-black p-2  bg-gray-50">
							{!imgToDelete ? (
								!imgSrc ? (
									<form className="flex justify-center items-center text-center">
										<label
											htmlFor={"pictureButton"}
											className="flex flex-col select-none justify-center items-center cursor-pointer p-4 bg-white w-36 h-36 border border-black hover:bg-gray-100 hover:scale-105">
											<AiOutlinePlus size={32} />
											<p>Add new Image</p>
										</label>
										<input className="hidden" type="file" id={"pictureButton"} accept="image/*" onChange={addImage} />
									</form>
								) : !uploadingImage ? (
									<div className=" w-64 h-64 border border-black">
										<img
											src={imgSrc}
											alt="To add to logo list"
											className="absolute w-64 h-64 object-contain bg-white border cursor-pointer "
										/>
									</div>
								) : (
									<div className="flex flex-col justify-center items-center w-64 h-64 border border-black">
										<LoadingCircle />
										<p>Uploading image</p>
									</div>
								)
							) : null}
							{!imgSrc && !imgToDelete ? (
								<div
									onClick={(e) => changeTempSelected(e, "")}
									className={`flex select-none flex-col justify-center items-center cursor-pointer p-4 bg-white w-36 h-36 border hover:scale-105 hover:bg-gray-100 ${
										tempSelectedName === "" ? "border-blue-500 border-4" : "border-black"
									}`}>
									<AiFillEyeInvisible size={32} />
									<p className="text-center">Don't use an image</p>
								</div>
							) : null}
							{!imgSrc && logos && logos.length > 0 ? (
								!imgToDelete ? (
									logos.map((mapData) => {
										return (
											<div className="relative hover:scale-100" key={mapData.name}>
												<p className="absolute -top-1 -right-2 bg-rose-400 rounded-full border border-white">
													<BiTrash size={30} className="m-1 cursor-pointer" onClick={(e) => setImgToDelete(mapData.name)} />
												</p>
												<img
													alt={mapData.name}
													src={mapData.thumbnail}
													className={`w-36 h-36 object-contain bg-white border cursor-pointer  ${
														tempSelectedName === mapData.name ? "border-blue-500 border-4" : "border-black"
													}`}
													onClick={(e) => changeTempSelected(e, mapData.name)}
												/>
											</div>
										);
									})
								) : !deletingImage ? (
									logos
										.filter((filterData) => filterData.name === imgToDelete)
										.map((mapData) => {
											return (
												<div className="relative " key={mapData.name}>
													<img
														alt={mapData.name}
														src={mapData.thumbnail}
														className={`w-64 h-64 object-contain bg-white border `}
														onClick={(e) => changeTempSelected(e, mapData.name)}
													/>
												</div>
											);
										})
								) : (
									<div className="flex flex-col justify-center items-center w-64 h-64 border border-black">
										<LoadingCircle />
										<p>Deleting image...</p>
									</div>
								)
							) : null}
						</div>
						<div className="flex gap-4">
							{!imgSrc && !imgToDelete ? (
								<>
									<button className="basis-1/2 bg-blue-500 hover:bg-blue-700 p-2 text-white rounded-lg" onClick={saveSelection}>
										Save
									</button>
									<button className="basis-1/2 bg-gray-400 hover:bg-gray-500 p-2 text-white rounded-lg" onClick={closeModal}>
										Cancel
									</button>
								</>
							) : null}

							{imgSrc ? (
								<>
									<button className="basis-1/2 bg-blue-500 hover:bg-blue-700 p-2 text-white rounded-lg" onClick={uploadImage}>
										Upload Image
									</button>
									<button className="basis-1/2 bg-gray-400 hover:bg-gray-500 p-2 text-white rounded-lg" onClick={resetUploadImage}>
										Cancel
									</button>
								</>
							) : null}

							{imgToDelete ? (
								<>
									<button className="basis-1/2 bg-rose-500 hover:bg-rose-700 p-2 text-white rounded-lg" onClick={deleteImage}>
										Delete Image
									</button>
									<button className="basis-1/2 bg-gray-400 hover:bg-gray-500 p-2 text-white rounded-lg" onClick={resetDeleteImage}>
										Cancel
									</button>
								</>
							) : null}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReportImageModal;
