import { useEffect, useState } from "react";

const SelectionInput = ({ initialFieldData, fieldDataExists, postToDB, putToDB, fieldSettings, readOnly, hasOdooMapping, odooData }) => {
	const [currentSelection, setCurrentSelection] = useState("");
	const [sendToDbFailed, setSendToDbFailed] = useState(false);

	const setSelectionToDefault = (newInitialFieldData, newFieldDataExists, newHasOdooMapping, newOdooData) => {
		let newValue = "";
		if (newFieldDataExists) {
			newValue = newInitialFieldData.value;
		}
		if (newHasOdooMapping) {
			if (newOdooData && newOdooData.odoo_value !== "failedtogetdataforpmscode400") {
				newValue = newOdooData.odoo_value;
			} else if (newOdooData && newOdooData.odoo_value === "failedtogetdataforpmscode400") {
				if (newFieldDataExists) {
					newValue = newInitialFieldData.value;
				}
			} else {
				newValue = "";
			}
		}
		setCurrentSelection(newValue);
	};

	useEffect(() => {
		if (sendToDbFailed) {
			setSelectionToDefault(initialFieldData, fieldDataExists, hasOdooMapping, odooData);
			setSendToDbFailed(false);
		}
	}, [sendToDbFailed, initialFieldData, fieldDataExists, hasOdooMapping, odooData]);

	useEffect(() => {
		setSelectionToDefault(initialFieldData, fieldDataExists, hasOdooMapping, odooData);
	}, [initialFieldData, fieldDataExists, hasOdooMapping, odooData]);

	const sendToDb = () => {
		if (!fieldSettings.includes(currentSelection) && currentSelection !== "") {
			return;
		}
		if (fieldDataExists === false) {
			postToDB(currentSelection, setSendToDbFailed);
		} else if (fieldDataExists) {
			putToDB(currentSelection, setSendToDbFailed);
		}
	};

	return (
		<form>
			<select
				className="border border-2 p-2 border-gray w-full rounded-lg z-0"
				value={currentSelection}
				onBlur={() => sendToDb()}
				onChange={(e) => setCurrentSelection(e.target.value)}
				disabled={readOnly}>
				<option value=""></option>
				{fieldSettings && fieldSettings.length > 0
					? fieldSettings.map((value, index) => {
							return (
								<option key={index} value={value}>
									{value}
								</option>
							);
					  })
					: null}
			</select>
		</form>
	);
};

export default SelectionInput;
