import { Fragment, useEffect, useState } from "react";
import LoadingCircle from "../utility/LoadingCircle";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";
import ClockingExportModal from "./ClockingExportModal";

function formatCSV(data, startDate, endDate, employee) {
	let filteredData = data;

	if (startDate && endDate) {
		const start = new Date(startDate);
		const end = new Date(endDate);
		
		filteredData = data.filter(item => {
			const itemDate = new Date(item.time);
			const isWithinDateRange = itemDate >= start && itemDate <= end;
			const isEmployeeMatch = employee === "All" || item.name === employee;
			return isWithinDateRange && isEmployeeMatch;
		});
	} else if (employee !== "All") {
		filteredData = data.filter(item => item.name === employee);
	}

	const csvString = [
		["Employee", "Project Name", "Supervisor", "Clocking info", "Note"],
		...filteredData.map(item => [
			item.userPhone ? `${item.name} (${item.userPhone})` : item.name,
			item.project_name,
			item.supervisorPhone ? `${item.supervisorName} (${item.supervisorPhone})` : item.supervisorName,
			item.clock_type ? `${item.clock_type} (${item.time})` : `${item.time}`,
			`"${item.note.replace(/"/g, '""').replace(/\n/g, ' ')}"`
		])
	  ]
	  .map(row => row.join(","))
	  .join("\n")

	return "\uFEFF" + csvString;
}


const downloadCSV = (csvString, filename = "data.csv") => {
	const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
	const link = document.createElement("a");
	link.href = URL.createObjectURL(blob);
	link.setAttribute("download", filename);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}

const ClockingNotesAdminPage = ({ api, token }) => {
	const [loading, setLoading] = useState(false);
	const [clockingNotes, setClockingNotes] = useState([]);
	const [totalPages, setTotalPages] = useState(1);
	const [page, setPage] = useState(1);
	const [showExportModal, setShowExportModal] = useState(false);
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [employee, setEmployee] = useState("All");
	const [errorText, setErrorText] = useState("");
	const { user, loading: authLoading } = useAuth0();
	const navigate = useNavigate();
	const PER_PAGE = 100;

	const employeeNames = [...new Set(clockingNotes.map(note => note.name))];

	useEffect(() => {
		document.title = "Clocking Notes";
	}, []);

	useEffect(() => {
		if (api === "" || token === "" || authLoading || !user) {
			return;
		}
		if (!user["https://claim.krummycapture/claim"]["clocking_notes_permission"] && !user["https://claim.krummycapture/claim"]["admin"]) {
			navigate("/dashboard");
		}

		const removeAutonums = (s) => {
			const regex = /(-\s?\d+)+$/;
			return s.replace(regex, "");
		};

		setLoading(true);
		const controller = new AbortController();
		const signal = controller.signal;
		setLoading(true);
		fetch(`${api}/clocking/admin-notes`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			mode: "cors",
			signal,
		})
			.then((res) => {
				if (res.ok) {
					return res.json();
				} else if (res.status === 403) {
					alert("You do not have permission to view this page. Navigating to the dashboard.");
					navigate("/dashboard");
				}
			})
			.then((data) => {
				if (data) {
					setClockingNotes(
						data.records.map((mapData) => {
							return {
								...mapData,
								project_name: removeAutonums(mapData.project_name),
								time: mapData.time,
							};
						})
					);
					setTotalPages(Math.ceil(data.records.length / 100));
				}
				setLoading(false);
			});

		return () => {
			controller.abort();
		};
	}, [api, authLoading, navigate, page, token, user]); 

	const getNotesForCurrentPage = () => {
        const startIndex = (page - 1) * PER_PAGE;
        const endIndex = startIndex + 100;
        return clockingNotes.slice(startIndex, endIndex);
    };

    const currentPageNotes = getNotesForCurrentPage();

	const handlePrevious = () => {
		if (page > 0) {
			setPage((prevPage) => prevPage - 1);
		}
	};

	const handleNext = () => {
		if (page < totalPages) {
			setPage((prevPage) => prevPage + 1);
		}
	};

	const exportData = (startDate, endDate, employee) => {
		if (startDate && !endDate) {
			setErrorText("Please enter an end date");
			return;
		}
		if (endDate && !startDate) {
			setErrorText("Please enter a start date");
			return;
		}
		if (startDate > endDate) {
			setErrorText("Start date must be before end date");
			return;
		}
		if (startDate > new Date().toISOString().split("T")[0] || endDate > new Date().toISOString().split("T")[0]) {
			setErrorText("Dates must be before today");
			return;
		}
		setErrorText("");
		const csvString = formatCSV(clockingNotes, startDate, endDate, employee);
		downloadCSV(csvString, "clocking_notes.csv")
	}

	const closeExportModal = () => {
		setStartDate("");
		setEndDate("");
		setEmployee("All");
		setErrorText("");
		setShowExportModal(false);
	}

	const months = {
		1: "January",
		2: "February",
		3: "March",
		4: "April",
		5: "May",
		6: "June",
		7: "July",
		8: "August",
		9: "September",
		10: "October",
		11: "November",
		12: "December",
	};

	function format12HourTime(date) {
		if (!(date instanceof Date)) {
			date = new Date(date);
		}

		const day = date.getDate();
		const monthIndex = date.getMonth() + 1;

		let hours = date.getHours();
		const minutes = date.getMinutes();
		const ampm = hours >= 12 ? "PM" : "AM";
		hours = hours % 12;
		hours = hours || 12;
		const minutesStr = minutes < 10 ? "0" + minutes : minutes;

		return (
			`${months[monthIndex]} ${String(day).padStart(2, "0")}, ${hours}:${minutesStr} ${ampm}`
		);
	}

	if (loading || authLoading) {
		return (
			<div className="h-[40vh] flex flex-col justify-center items-center">
				<LoadingCircle />
				<p>Loading Notes...</p>
			</div>
		);
	}

	return (
		<div className="w-full flex justify-center items-center">
			<div className="w-full p-4 md:w-5/6 flex flex-col justify-center items-center">
				<div className="w-full flex justify-between items-center mb-4">
					<h1 className="text-2xl font-bold text-center flex-grow">Clocking Notes</h1>
					<button className="text-white bg-blue-500 p-3 rounded-lg ml-auto" onClick={() => setShowExportModal(true)}>Export</button>
				</div>
				{currentPageNotes && currentPageNotes.length > 0 ? (
					<div>
						<div className="grid grid-cols-6 border-t border-r border-black ">
							<div className="border-l border-b border-black p-2 bg-gray-100 font-bold">Employee</div>
							<div className="border-l border-b border-black p-2 bg-gray-100 font-bold">Project Name</div>
							<div className="border-l border-b border-black p-2 bg-gray-100 font-bold">Supervisor</div>
							<div className="border-l border-b border-black p-2 bg-gray-100 font-bold flex items-center gap-1">
								<p>Clocking info</p>
								<FaInfoCircle title="This is in your timezone" />
							</div>
							<div className="border-l border-b border-black p-2 bg-gray-100 font-bold col-span-2">Note</div>
							{currentPageNotes.map((mapData) => (
								<Fragment key={mapData.id}>
									<div className="border-l border-b border-black p-2 flex flex-col">
										<p>{mapData.name}</p>
										{mapData.userPhone ? <p>{mapData.userPhone}</p> : null}
									</div>
									<div className="border-l border-b border-black p-2">{mapData.project_name}</div>
									<div className="border-l border-b border-black p-2">
										{mapData.supervisorName || mapData.supervisorPhone ? (
											<div>
												<p>{mapData.supervisorName}</p>
												<p>{mapData.supervisorPhone ? mapData.supervisorPhone : null}</p>
											</div>
										) : (
											"-"
										)}
									</div>

									<div className="border-l border-b border-black p-2 flex flex-col">
										<p>{mapData.clock_type}</p>
										{mapData.time ? format12HourTime(mapData.time) : null}
									</div>
									<div className="border-l border-b border-black p-2 col-span-2">{mapData.note}</div>
								</Fragment>
							))}
						</div>
						<div className="flex justify-center items-center mt-4">
							<div className="flex">
								<button
									onClick={handlePrevious}
									disabled={page === 1}
									className={`${page === 1 ? "bg-gray-500 " : "bg-blue-600 "} text-white p-2`}>
									Prev
								</button>
								<div className="flex justify-center items-center border border-black px-2">
									<p>
										{page}/{totalPages}
									</p>
								</div>
								<button
									onClick={handleNext}
									disabled={page === totalPages || totalPages === 0}
									className={`${page === totalPages || totalPages === 0 ? "bg-gray-500 " : "bg-blue-600 "} text-white p-2`}>
									Next
								</button>
							</div>
						</div>
					</div>
				) : null}
				{showExportModal && (
					<ClockingExportModal 
						closeExportModal={closeExportModal}
						exportData={exportData}
						employeeNames={employeeNames}
						startDate={startDate}
						setStartDate={setStartDate}
						endDate={endDate}
						setEndDate={setEndDate}
						employee={employee}
						setEmployee={setEmployee}
						errorText={errorText}
					/>
				)}
			</div>
		</div>
	);
};

export default ClockingNotesAdminPage;
