const UpdateTemplateModal = ({ setShowUpdateModal, updateTemplateToLatestVersion }) => {
	const closeModal = (e) => {
		e.preventDefault();
		setShowUpdateModal(false);
	};

	const preventPropagation = (e) => {
		e.stopPropagation();
	};

	const updateTemplate = (e) => {
		e.preventDefault();
		updateTemplateToLatestVersion(e);
		setShowUpdateModal(false);
	};

	return (
		<div className="text-black fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black/50 z-50" onClick={closeModal}>
			<div className="bg-white w-full lg:w-1/4 m-2 rounded-lg " onClick={preventPropagation}>
				<div className="m-4 flex justify-between items-center">
					<p className="text-2xl">Update to the latest version of the template?</p>
				</div>
				<div className="m-4 flex justify-center items-center gap-4">
					<button className="bg-blue-500 hover:bg-blue-700 text-white p-2 rounded-lg" onClick={updateTemplate}>
						Update
					</button>
					<button type="button" className="bg-gray-500 hover:bg-gray-600 text-white p-2 rounded-lg" onClick={closeModal}>
						Cancel
					</button>
				</div>
			</div>
		</div>
	);
};

export default UpdateTemplateModal;
