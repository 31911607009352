import { ImImages } from "react-icons/im";
import RadioGroup from "./attributes/RadioGroup";
import SelectBox from "./attributes/SelectBox";
import ColorPicker from "./attributes/ColorPicker";
import PillsSelector from "./attributes/PillsSelector";
import { useEffect, useState } from "react";

const Product = ({ product, setSelectedProducts }) => {
	const [notes, setNotes] = useState(product.notes || "");
	const [debouncedNotes, setDebouncedNotes] = useState(notes);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedNotes(notes);
		}, 500);

		return () => {
			clearTimeout(handler);
		};
	}, [notes]);

	useEffect(() => {
		setSelectedProducts((prevProducts) => prevProducts.map((mapData) => (mapData.id === product.id ? { ...mapData, notes: debouncedNotes } : mapData)));
	}, [debouncedNotes, product.id, setSelectedProducts]);

	const decrementCount = () => {
		if (product.amount > 0) {
			setSelectedProducts((prevProducts) =>
				prevProducts.map((mapData) => (mapData.id === product.id ? { ...mapData, amount: mapData.amount - 1 } : mapData))
			);
		}
	};

	const incrementCount = () => {
		setSelectedProducts((prevProducts) =>
			prevProducts.map((mapData) => (mapData.id === product.id ? { ...mapData, amount: mapData.amount + 1 } : mapData))
		);
	};

	const addToOrder = () => {
		setSelectedProducts((prevProducts) => prevProducts.map((mapData) => (mapData.id === product.id ? { ...mapData, isOptional: false } : mapData)));
	};

	const removeFromOrder = () => {
		setSelectedProducts((prevProducts) =>
			prevProducts.map((mapData) => (mapData.id === product.id ? { ...mapData, isOptional: true, amount: 0 } : mapData))
		);
	};

	const handleCountChange = (e) => {
		let newCount = e.target.value;
		if (isNaN(newCount)) {
			setSelectedProducts((prevProducts) => prevProducts.map((mapData) => (mapData.id === product.id ? { ...mapData, amount: 1 } : mapData)));
		} else {
			setSelectedProducts((prevProducts) => prevProducts.map((mapData) => (mapData.id === product.id ? { ...mapData, amount: newCount } : mapData)));
		}
	};

	const renderAttributeInput = (attribute) => {
		const { id, name, display_type, values } = attribute;
		const currentAttribute = product.attributes.find((attr) => attr.id === id);
		const value = currentAttribute?.selectedValue?.id || "";

		const onChange = (e) => {
			const selectedOptionId = e.target.value;
			const selectedOption = values.find((val) => val.id === parseInt(selectedOptionId, 10));

			setSelectedProducts((prevProducts) =>
				prevProducts.map((mapData) =>
					mapData.id === product.id
						? {
								...mapData,
								attributes: mapData.attributes.map((attr) =>
									attr.id === id
										? {
												...attr,
												selectedValue: {
													id: selectedOption.id,
													name: selectedOption.name,
												},
										  }
										: attr
								),
						  }
						: mapData
				)
			);
		};

		switch (display_type) {
			case "radio":
				return <RadioGroup key={attribute.id} name={name} values={values} value={value} onChange={onChange} productId={product.id} />;
			case "select":
				return <SelectBox key={attribute.id} name={name} values={values} value={value} onChange={onChange} productId={product.id} />;
			case "color":
				return <ColorPicker key={attribute.id} name={name} values={values} value={value} onChange={onChange} productId={product.id} />;
			case "pills":
				return <PillsSelector key={attribute.id} name={name} values={values} value={value} onChange={onChange} productId={product.id} />;
			default:
				return <p key={attribute.id}>Unsupported type: {display_type}</p>;
		}
	};

	const handleNotesChange = (e) => {
		setNotes(e.target.value);
	};

	return (
		<div className="flex flex-col lg:flex-row w-full gap-4">
			<div>
				{product.image_1920 ? (
					<img src={`data:image/jpeg;base64,${product.image_1920}`} alt={product.display_name} className="w-[300px] w-[300px] object-contain" />
				) : (
					<div className="w-[300px] h-auto flex justify-center items-center">
						<ImImages className="w-auto h-[50%]" />
					</div>
				)}
			</div>

			<div className="flex flex-col justify-between w-full">
				<div>
					<div>
						<div className="flex items-center">
							<h2 className="text-xl font-bold mr-2">{product.name}</h2>
						</div>
					</div>
					<div className="flex flex-col gap-3">
						{product.attributes ? product.attributes.map((attribute) => renderAttributeInput(attribute)) : null}
					</div>
				</div>

				<div className="mt-1">
					<p className="font-bold">Notes:</p>
					<textarea value={notes} onChange={handleNotesChange} placeholder="Product notes..." rows="3" className="border border-black w-full p-1" />
				</div>

				<div className="flex w-full justify-end my-4 gap-2">
					{product.isOptional ? (
						<div className="flex justify-end">
							<button onClick={addToOrder} className="text-white p-2 rounded-lg bg-blue-600 hover:bg-blue-700">
								Add to Order
							</button>
						</div>
					) : !product.isOptional && product.canBeOptional ? (
						<button className="bg-red-600 hover:bg-red-700 p-2 rounded-lg text-white" onClick={removeFromOrder}>
							Remove From Order
						</button>
					) : null}

					<div className="flex items-center">
						<button className="p-2 px-3 border border-black rounded-l bg-gray-200" onClick={decrementCount}>
							-
						</button>
						<input
							type="text"
							className="p-2 border-t border-b border-black text-center w-16 bg-gray-100 text-center"
							value={product.amount}
							onChange={handleCountChange}
						/>
						<button className="p-2 px-3 border border-black rounded-r bg-gray-200" onClick={incrementCount}>
							+
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Product;
