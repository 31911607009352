import { useEffect, useState } from "react";

const NumberInput = ({ postToDB, putToDB, initialFieldData, fieldDataExists, data, readOnly, odooData, hasOdooMapping }) => {
	const [currentValue, setCurrentValue] = useState("");
	const [commaFormat, setCommaFormat] = useState("");

	useEffect(() => {
		let newValue = "";
		if (fieldDataExists) {
			newValue = initialFieldData.value;
		}

		if (hasOdooMapping) {
			if (odooData && odooData.odoo_value !== "failedtogetdataforpmscode400") {
				newValue = odooData.odoo_value;
			} else if (odooData && odooData.odoo_value === "failedtogetdataforpmscode400") {
				if (fieldDataExists) {
					newValue = initialFieldData.value;
				}
			} else {
				newValue = "";
			}
		}
		setCurrentValue(newValue);
	}, [initialFieldData, fieldDataExists, odooData, hasOdooMapping]);

	useEffect(() => {
		if (currentValue) {
			setCommaFormat(
				currentValue
					.toString()
					.replace(/[^0-9]/g, "")
					.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
			);
		} else {
			setCommaFormat("");
		}
	}, [currentValue]);

	const sendToDb = () => {
		if (initialFieldData && currentValue === initialFieldData.value) {
			return;
		}
		if (fieldDataExists === false && currentValue !== "") {
			postToDB(currentValue.replaceAll(/\$|,/g, ""));
		} else if (initialFieldData && currentValue !== initialFieldData.value) {
			putToDB(currentValue.replaceAll(/\$|,/g, ""));
		}
	};

	return (
		<form>
			<input
				type="text"
				value={commaFormat}
				onChange={(e) => setCurrentValue(e.target.value.toString().replace(/[^0-9]/g, ""))}
				onBlur={() => sendToDb()}
				className={"rounded-lg border border-2 p-2 w-full border-gray"}
				readOnly={readOnly}
			/>
		</form>
	);
};

export default NumberInput;
