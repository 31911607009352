import { useCallback, useEffect, useState } from "react";
import BooleanInput from "./BooleanInput";
import DateInput from "./DateInput";
import NumberInput from "./NumberInput";
import SelectionInput from "./SelectionInput";
import TextAreaInput from "./TextAreaInput";
import TextInput from "./TextInput";
import CurrencyInput from "./CurrencyInput";
import { BsExclamationCircleFill, BsFillCameraFill, BsShieldExclamation } from "react-icons/bs";
import { FcInfo } from "react-icons/fc";
import CameraContainer from "./../camera/cameracontainer";
import { AiFillLock } from "react-icons/ai";
import ProjectErrorMessage from "../ProjectErrorMessage";

const FieldConverter = ({
	conditionalData,
	setConditionalData,
	templateName,
	allFields,
	sectionId,
	projectId,
	templateId,
	fieldData,
	setFieldData,
	projectName,
	data,
	api,
	token,
	sectionName,
	stage,
	templateTypeId,
	odooMappings,
	odooId,
	setRequiredFieldsRemaining,
	showThisField,
	showThisSection,
	initialThumbnailPathes,
	setLastModifiedOn,
	selectedOdooModel,
	setLastUpdatedBy,
	odooInstallationName,
	odooParentName,
}) => {
	const [fieldSettings, setFieldSettings] = useState([]);
	const [initialFieldData, setInitialFieldData] = useState();
	const [fieldDataExists, setFieldDataExists] = useState(false);
	const [hasConditional, setHasConditional] = useState(false);
	const [viewConditionMet, setViewConditionMet] = useState(false);
	const [currentOdooMappings, setCurrentOdooMappings] = useState([]);
	const [odooData, setOdooData] = useState("");
	const [comment, setComment] = useState("");
	const [hasPhotos, setHasPhotos] = useState(false);
	const [metRequireCondition, setMetRequiredCondition] = useState(true);
	const [metRequireCommentCondition, setMetRequiredCommentCondition] = useState(true);
	const [odooUpdateFailed, setOdooUpdateFailed] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [postingData, setPostingData] = useState(false);

	useEffect(() => {
		let trimmedStrings = data.field_settings.split("[break]");
		for (let i = 0; i < trimmedStrings.length; i++) {
			trimmedStrings[i] = trimmedStrings[i].trim();
		}
		setFieldSettings(trimmedStrings);
	}, [data.field_settings]);

	const convertToYesNo = (theValue) => {
		let returnValue = "";
		if (theValue === 1 || theValue === "1" || (typeof theValue === "string" && theValue.trim().toLowerCase() === "yes")) {
			returnValue = "yes";
		} else if (theValue === 0 || theValue === "0" || (typeof theValue === "string" && theValue.trim().toLowerCase() === "no")) {
			returnValue = "no";
		}
		return returnValue;
	};

	useEffect(() => {
		setCurrentOdooMappings(odooMappings);

		if (fieldData) {
			for (let i = 0; i < fieldData.length; i++) {
				if (fieldData[i].field_id === data.id) {
					setInitialFieldData(fieldData[i]);
					setComment(fieldData[i].comment === null ? "" : fieldData[i].comment);
					break;
				}
			}
		}

		for (let i = 0; i < odooMappings.length; i++) {
			if (odooMappings[i].templatefieldid === data.id && odooMappings[i].odoo_value !== "") {
				setOdooData(odooMappings[i]);
			}
		}
	}, [fieldData, data, odooMappings]);

	useEffect(() => {
		if (typeof initialFieldData !== "undefined") {
			setFieldDataExists(true);
		}
	}, [initialFieldData]);

	const checkConditionalMet = useCallback(
		(idOfFieldToVerify) => {
			let conditionalList = "";
			for (let i = 0; i < allFields.length; i++) {
				if (allFields[i].id.toString() === idOfFieldToVerify.toString()) {
					if (allFields[i].conditional_list === "") {
						return true;
					} else {
						conditionalList = allFields[i].conditional_list.split(",");
						break;
					}
				}
			}

			for (let i = 0; i < conditionalList.length; i++) {
				for (let j = 0; j < fieldData.length; j++) {
					if (fieldData[j].field_id.toString() === conditionalList[i].toString()) {
						let valueOfFoundField = fieldData[j].value;
						let fieldType = fieldData[j].field_type;
						let conditionValueToCheck = conditionalList[i + 1].replaceAll("[comma1]", ",");
						if (odooMappings && odooMappings.length > 0) {
							let theCondField = odooMappings.filter((filterData) => filterData.templatefieldid.toString() === conditionalList[i].toString());
							if (theCondField.length > 0) {
								let foundFieldType = allFields.filter((filterData) => filterData.id.toString() === conditionalList[i].toString());
								if (foundFieldType[0].field_type === "yes/no") {
									valueOfFoundField = convertToYesNo(theCondField[0].odoo_value);
								} else {
									valueOfFoundField = theCondField[0].odoo_value;
								}
							}
						}
						if (fieldType === "yes/no") {
							conditionValueToCheck = convertToYesNo(conditionValueToCheck);
						}
						if (valueOfFoundField === conditionValueToCheck && checkConditionalMet(fieldData[j].field_id)) {
							return true;
						}
					}
				}
			}
			return false;
		},
		[allFields, fieldData, odooMappings]
	);

	useEffect(() => {
		if (typeof data.conditional_list !== "undefined" && data.conditional_list !== "") {
			setHasConditional(true);

			let conditionalListSplit = data.conditional_list.split(",");

			if (conditionalListSplit.length <= 1) {
				return;
			}

			let conditionMet = checkConditionalMet(data.id);
			if (conditionMet) {
				setViewConditionMet(true);
			} else {
				setViewConditionMet(false);
			}
		}
	}, [fieldData, data.id, checkConditionalMet, data.conditional_list]);

	useEffect(() => {
		if (((data.required || data.require_photos) && hasConditional && viewConditionMet) || (!hasConditional && (data.required || data.require_photos))) {
			let requiredConditionMet = true;
			let requiredPhotoConditionsMet = true;
			let requiredCommentConditionMet = true;

			if (data.required && data.field_type !== "photos") {
				if (data.odoo_mapping) {
					if (!odooData || odooData.odoo_value === "") {
						requiredConditionMet = false;
					} else {
						requiredConditionMet = true;
					}

					if (odooData && odooData.odoo_value === "failedtogetdataforpmscode400") {
						if (!initialFieldData || initialFieldData.value === "") {
							requiredConditionMet = false;
						} else {
							requiredConditionMet = true;
						}
					}
				} else {
					if (!initialFieldData || initialFieldData.value === "") {
						requiredConditionMet = false;
					} else {
						requiredConditionMet = true;
					}
				}
			}

			if (data.required && data.require_comment) {
				if (!initialFieldData || initialFieldData.comment === "") {
					requiredCommentConditionMet = false;
				} else {
					requiredCommentConditionMet = true;
				}
			}
			//if ((data.require_photos && !hasPhotos) || (data.required && data.field_type === "photos") || (data.allow_photos && !hasPhotos)) {
			if ((data.require_photos && !hasPhotos) || (data.required && data.field_type === "photos")) {
				if (!hasPhotos) {
					requiredPhotoConditionsMet = false;
				} else {
					requiredPhotoConditionsMet = true;
				}
			}

			if (!requiredConditionMet || !requiredPhotoConditionsMet || !requiredCommentConditionMet) {
				setRequiredFieldsRemaining((prev) => (prev.includes(data.id.toString()) === false ? [...prev, data.id.toString()] : prev));
				setMetRequiredCondition(requiredConditionMet);
				setMetRequiredCommentCondition(requiredCommentConditionMet);
			} else {
				setRequiredFieldsRemaining((prev) =>
					prev.includes(data.id.toString()) ? [...prev.filter((filterData) => filterData !== data.id.toString())] : prev
				);

				setMetRequiredCondition(requiredConditionMet);
				setMetRequiredCommentCondition(requiredCommentConditionMet);
			}
		} else {
			setRequiredFieldsRemaining((prev) =>
				prev.includes(data.id.toString()) ? [...prev.filter((filterData) => filterData !== data.id.toString())] : prev
			);
			setMetRequiredCondition(true);
			setMetRequiredCommentCondition(true);
		}
	}, [initialFieldData, data, hasConditional, hasPhotos, setRequiredFieldsRemaining, viewConditionMet, odooData]);

	const postToDB = (valueToPost, setSendToDbFailed = undefined) => {
		if (postingData) {
			return;
		}
		setPostingData(true);
		fetch(api + "/projectdata", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: token,
			},
			body: JSON.stringify({
				field_id: data.id,
				value: valueToPost,
				project_id: projectId,
				section_id: sectionId,
				odoo_mapping: data.odoo_mapping,
				comment,
				odooId: odooId,
				projectId: projectId,
				odoo_model: selectedOdooModel,
			}),
		})
			.then((res) => {
				if (!res.ok) {
					setPostingData(false);
					// put an error here
					setErrorMessage(`Failed to update on Odoo:\n${sectionName} - ${data.name}`);
					setOdooUpdateFailed(true);
				} else {
					return res.json();
				}
			})
			.then((res) => {
				if (res) {
					setPostingData(false);
					setLastModifiedOn(new Date());
					setLastUpdatedBy(localStorage.getItem("username") ? localStorage.getItem("username") : "You");
					if (res.odooUpdateFailed) {
						setErrorMessage(`Failed to update on Odoo:\n${sectionName} - ${data.name}`);
						setOdooUpdateFailed(true);
					} else {
						setOdooUpdateFailed(false);
					}
					let newData = {
						id: res.projectDataId,
						field_id: data.id,
						value: valueToPost,
						project_id: projectId,
						section_id: sectionId,
						comment,
						odooId: odooId,
					};
					setInitialFieldData(newData);
					setFieldData([...fieldData, newData]);
					if (data.odoo_mapping && data.odoo_mapping !== "") {
						let mappingsClone = [...odooMappings];
						for (let i = 0; i < mappingsClone.length; i++) {
							if (data.id === mappingsClone[i].templatefieldid) {
								mappingsClone[i].odoo_value = valueToPost;
								let newOdooData = { ...odooData };
								newOdooData["odoo_value"] = valueToPost;
								setOdooData(newOdooData);
								setCurrentOdooMappings([...mappingsClone]);
								break;
							}
						}
					}

					for (let i = 0; i < conditionalData.length; i++) {
						if (conditionalData[i].field_condition_id === newData.field_id) {
							let conditionMet = false;
							if (conditionalData[i].condition_type === ">=") {
								if (parseInt(newData.value) >= parseInt(conditionalData[i].condition_value)) {
									conditionMet = true;
								}
							} else if (conditionalData[i].condition_type === ">") {
								if (parseInt(newData.value) > parseInt(conditionalData[i].condition_value)) {
									conditionMet = true;
								}
							} else if (conditionalData[i].condition_type === "=") {
								if (newData.value == conditionalData[i].condition_value) {
									conditionMet = true;
								}
							} else if (conditionalData[i].condition_type === "<") {
								if (parseInt(newData.value) < parseInt(conditionalData[i].condition_value)) {
									conditionMet = true;
								}
							} else if (conditionalData[i].condition_type === "<=") {
								if (parseInt(newData.value) <= parseInt(conditionalData[i].condition_value)) {
									conditionMet = true;
								}
							}
							let copyOfData = conditionalData;
							copyOfData[i].conditionMet = conditionMet;
							setConditionalData([...copyOfData]);
						}
					}
				}
			});
	};

	const putToDB = (valueToPut, setSendToDbFailed = undefined) => {
		fetch(api + "/projectdata/" + initialFieldData.id, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: token,
			},
			body: JSON.stringify({
				value: valueToPut,
				comment,
				odoo_mapping: data.odoo_mapping,
				odooId: odooId,
				projectId: projectId,
				odoo_model: selectedOdooModel,
			}),
		})
			.then((res) => {
				if (!res.ok) {
					// put an error here
					setErrorMessage(`Failed to update on Odoo:\n${sectionName} - ${data.name}`);
					setOdooUpdateFailed(true);
				} else {
					return res.json();
				}
			})
			.then((res) => {
				if (res) {
					if (res.odooUpdateFailed) {
						setErrorMessage(`Failed to update on Odoo:\n${sectionName} - ${data.name}`);
						setOdooUpdateFailed(true);
					} else {
						setOdooUpdateFailed(false);
					}
					setLastUpdatedBy(localStorage.getItem("username") ? localStorage.getItem("username") : "You");
					setLastModifiedOn(new Date());
					let newFieldData = { ...initialFieldData };

					newFieldData.value = valueToPut;
					newFieldData.comment = comment;

					let fieldDataCopy = [...fieldData];
					setInitialFieldData(newFieldData);
					for (let i = 0; i < fieldDataCopy.length; i++) {
						if (fieldDataCopy[i].id === newFieldData.id) {
							fieldDataCopy[i] = newFieldData;
							setFieldData(fieldDataCopy);
							break;
						}
					}
					if (data.odoo_mapping && data.odoo_mapping !== "") {
						let mappingsClone = [...odooMappings];
						for (let i = 0; i < mappingsClone.length; i++) {
							if (data.id === mappingsClone[i].templatefieldid) {
								mappingsClone[i].odoo_value = valueToPut;
								mappingsClone[i].odoo_value = valueToPut;
								let newOdooData = { ...odooData };
								newOdooData["odoo_value"] = valueToPut;
								setOdooData(newOdooData);
								setCurrentOdooMappings([...mappingsClone]);
								break;
							}
						}
					}

					for (let i = 0; i < conditionalData.length; i++) {
						if (conditionalData[i].field_condition_id === newFieldData.field_id) {
							let conditionMet = false;
							if (conditionalData[i].condition_type === ">=") {
								if (parseInt(newFieldData.value) >= parseInt(conditionalData[i].condition_value)) {
									conditionMet = true;
								}
							} else if (conditionalData[i].condition_type === ">") {
								if (parseInt(newFieldData.value) > parseInt(conditionalData[i].condition_value)) {
									conditionMet = true;
								}
							} else if (conditionalData[i].condition_type === "=") {
								if (newFieldData.value == conditionalData[i].condition_value) {
									conditionMet = true;
								}
							} else if (conditionalData[i].condition_type === "<") {
								if (parseInt(newFieldData.value) < parseInt(conditionalData[i].condition_value)) {
									conditionMet = true;
								}
							} else if (conditionalData[i].condition_type === "<=") {
								if (parseInt(newFieldData.value) <= parseInt(conditionalData[i].condition_value)) {
									conditionMet = true;
								}
							}
							let copyOfData = conditionalData;
							copyOfData[i].conditionMet = conditionMet;
							setConditionalData([...copyOfData]);
						}
					}
				}
			});
	};

	const sendCommentToDb = () => {
		if (initialFieldData && comment === initialFieldData.comment) {
			return;
		}
		if (!fieldDataExists) {
			postToDB(data.odoo_mapping && !odooUpdateFailed ? odooData.odoo_value : initialFieldData ? initialFieldData.value : "");
		} else {
			putToDB(data.odoo_mapping && !odooUpdateFailed ? odooData.odoo_value : initialFieldData.value);
		}
	};

	return (hasConditional && viewConditionMet) || !hasConditional ? (
		<div className={`${!showThisField ? "hidden" : ""}`}>
			<div className={`bg-white rounded-lg p-3 break-words  ${hasConditional ? "border border-2 border-green-800 ml-3" : ""}`}>
				{errorMessage ? <ProjectErrorMessage message={errorMessage} setMessage={setErrorMessage} /> : null}
				<p className="flex items-center mb-1 font-bold">
					{data.read_only || stage === "completed" ? <AiFillLock className="text-2xl" /> : null} {data.name}
				</p>
				<p>{data.description}</p>

				{data.field_type === "text" ? (
					<TextInput
						postToDB={postToDB}
						putToDB={putToDB}
						initialFieldData={initialFieldData}
						fieldDataExists={fieldDataExists}
						data={data}
						readOnly={data.read_only || stage === "completed"}
						hasOdooMapping={data.odoo_mapping ? true : false}
						odooData={odooData}
					/>
				) : null}
				{data.field_type === "number" ? (
					<NumberInput
						postToDB={postToDB}
						putToDB={putToDB}
						initialFieldData={initialFieldData}
						fieldDataExists={fieldDataExists}
						readOnly={data.read_only || stage === "completed"}
						hasOdooMapping={data.odoo_mapping ? true : false}
						odooData={odooData}
					/>
				) : null}
				{data.field_type === "long text" ? (
					<TextAreaInput
						postToDB={postToDB}
						putToDB={putToDB}
						initialFieldData={initialFieldData}
						fieldDataExists={fieldDataExists}
						readOnly={data.read_only || stage === "completed"}
						hasOdooMapping={data.odoo_mapping ? true : false}
						odooData={odooData}
					/>
				) : null}
				{data.field_type === "date" ? (
					<DateInput
						postToDB={postToDB}
						putToDB={putToDB}
						initialFieldData={initialFieldData}
						fieldDataExists={fieldDataExists}
						readOnly={data.read_only || stage === "completed"}
						hasOdooMapping={data.odoo_mapping ? true : false}
						odooData={odooData}
					/>
				) : null}
				{data.field_type === "selection" ? (
					<SelectionInput
						postToDB={postToDB}
						putToDB={putToDB}
						initialFieldData={initialFieldData}
						fieldDataExists={fieldDataExists}
						fieldSettings={fieldSettings}
						readOnly={data.read_only || stage === "completed"}
						hasOdooMapping={data.odoo_mapping ? true : false}
						odooData={odooData}
					/>
				) : null}
				{data.field_type === "yes/no" ? (
					<BooleanInput
						postToDB={postToDB}
						putToDB={putToDB}
						initialFieldData={initialFieldData}
						fieldDataExists={fieldDataExists}
						fieldSettings={fieldSettings}
						readOnly={data.read_only || stage === "completed"}
						hasOdooMapping={data.odoo_mapping ? true : false}
						odooData={odooData}
					/>
				) : null}
				{data.field_type === "currency" ? (
					<CurrencyInput
						postToDB={postToDB}
						putToDB={putToDB}
						initialFieldData={initialFieldData}
						fieldDataExists={fieldDataExists}
						readOnly={data.read_only || stage === "completed"}
						hasOdooMapping={data.odoo_mapping ? true : false}
						odooData={odooData}
					/>
				) : null}

				{data.allow_comment ? (
					<form>
						<label>
							Comment:
							<textarea
								type="text"
								rows={4}
								value={comment}
								onChange={(e) => setComment(e.target.value)}
								onBlur={() => sendCommentToDb()}
								className="border border-2 p-2 border-gray w-full whitespace-pre-wrap rounded-lg"
								readOnly={stage === "completed"}
							/>
						</label>
					</form>
				) : null}
				{stage === "assigned" ? null : data.field_type === "photos" ? (
					<CameraContainer
						sectionName={sectionName}
						data={data}
						cameraid={data.id}
						api={api}
						token={token}
						setInitialFieldData={setInitialFieldData}
						fieldName={data.name}
						projectName={projectName}
						templateName={templateName}
						stage={stage}
						readOnly={stage === "completed"}
						templateTypeId={templateTypeId}
						setHasPhotos={setHasPhotos}
						initialThumbnailPathes={initialThumbnailPathes.filter((filterData) => {
							const duplicatePattern = / \(\d+\)$/;
							let thumbnailName = filterData.name.split(".").slice(0, -1).join(".").trim();
							if (duplicatePattern.test(thumbnailName)) {
								thumbnailName = thumbnailName.replace(duplicatePattern, "");
							}
							let testFieldThmbName =
								sectionName.replaceAll("/", "-") +
								" - " +
								data.name
									.replaceAll("/", "_")
									.replaceAll("\\", "_")
									.replaceAll("<", "_")
									.replaceAll(">", "_")
									.replaceAll(":", "_")
									.replaceAll('"', "_")
									.replaceAll("|", "_")
									.replaceAll("?", "_")
									.replaceAll("*", "_")
									.replaceAll(".", "_")
									.trim();
							return testFieldThmbName.trim() === thumbnailName.trim();
						})}
						setLastModifiedOn={setLastModifiedOn}
						projectId={projectId}
						odooId={odooId}
						selectedOdooModel={selectedOdooModel}
						odooInstallationName={odooInstallationName}
						odooParentName={odooParentName}
					/>
				) : data.field_type !== "photos" && data.allow_photos ? (
					<CameraContainer
						sectionName={sectionName}
						data={data}
						cameraid={data.id}
						api={api}
						token={token}
						fieldName={data.name}
						projectName={projectName}
						templateName={templateName}
						stage={stage}
						readOnly={stage === "completed"}
						templateTypeId={templateTypeId}
						setHasPhotos={setHasPhotos}
						initialThumbnailPathes={initialThumbnailPathes.filter((filterData) => {
							const duplicatePattern = / \(\d+\)$/;
							let thumbnailName = filterData.name.split(".").slice(0, -1).join(".").trim();
							if (duplicatePattern.test(thumbnailName)) {
								thumbnailName = thumbnailName.replace(duplicatePattern, "");
							}
							let testFieldThmbName =
								sectionName.replaceAll("/", "-") +
								" - " +
								data.name
									.trim()
									.replaceAll("/", "_")
									.replaceAll("\\", "_")
									.replaceAll("<", "_")
									.replaceAll(">", "_")
									.replaceAll(":", "_")
									.replaceAll('"', "_")
									.replaceAll("|", "_")
									.replaceAll("?", "_")
									.replaceAll("*", "_")
									.replaceAll(".", "_")
									.trim();
							return testFieldThmbName.trim() === thumbnailName.trim();
						})}
						setLastModifiedOn={setLastModifiedOn}
						projectId={projectId}
						odooId={odooId}
						selectedOdooModel={selectedOdooModel}
						odooInstallationName={odooInstallationName}
						odooParentName={odooParentName}
					/>
				) : null}
				{data.allow_comment && data.comment_has_validation && (!initialFieldData || (initialFieldData && initialFieldData.value !== comment)) ? (
					<div className="flex items-center gap-2 bg-blue-400 p-2 mt-2 rounded-lg">
						<BsShieldExclamation color="black" className="text-3xl" />
						<p>The comment and field data must match.</p>
					</div>
				) : null}
				{data.required && data.field_type !== "photos" && !metRequireCondition ? (
					<div className="flex items-center gap-2 bg-rose-100 p-2 mt-2 rounded-lg">
						<BsExclamationCircleFill color="red" className="text-3xl" />
						<p>This is a required field. Please enter a value.</p>
					</div>
				) : null}
				{/* {(data.require_photos && hasPhotos === false) || (data.required && data.field_type === "photos" && !hasPhotos)  || (data.allow_photos && hasPhotos === false) ? ( */}
				{(data.require_photos && hasPhotos === false) || (data.required && data.field_type === "photos" && !hasPhotos) ? (
					<div className="flex items-center gap-2 bg-yellow-300 p-2 mt-2 rounded-lg">
						<BsFillCameraFill color="black" className="text-3xl" />
						<p>Photos are required. Take at least one photo.</p>
					</div>
				) : null}
				{odooUpdateFailed ? (
					<div className="flex items-center gap-2 bg-blue-100 p-2 mt-2 rounded-lg">
						<FcInfo className="text-3xl" />
						<p>There is an error with the odoo mapping. Data will not be sent to Odoo.</p>
					</div>
				) : null}
				{data.required && data.require_comment && !metRequireCommentCondition ? (
					<div className="flex items-center gap-2 bg-rose-100 p-2 mt-2 rounded-lg">
						<BsExclamationCircleFill color="red" className="text-3xl" />
						<p>Comment is required for this section.</p>
					</div>
				) : null}
			</div>
		</div>
	) : null;
};

export default FieldConverter;
