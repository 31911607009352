const InventoryPagination = ({ page, totalPages, setPage }) => {
	const handlePrevious = () => {
		if (page > 1) {
			setPage((prev) => prev - 1);
		}
	};

	const handleNext = () => {
		if (page < totalPages) {
			setPage((prev) => prev + 1);
		}
	};

	return (
		<div className="flex">
			<button
				onClick={handlePrevious}
				disabled={parseInt(page) <= 1}
				className={`${parseInt(page) === 1 ? "bg-gray-500 " : "bg-blue-600 "} text-white p-2`}>
				Prev
			</button>
			<div className="flex justify-center items-center border border-black px-2">
				<p>
					{page}/{totalPages}
				</p>
			</div>
			<button
				onClick={handleNext}
				disabled={parseInt(page) >= totalPages || totalPages === 0}
				className={`${parseInt(page) >= totalPages || totalPages === 0 ? "bg-gray-500 " : "bg-blue-600 "} text-white p-2`}>
				Next
			</button>
		</div>
	);
};

export default InventoryPagination;
