import React, { useState, useEffect } from "react";
import { FaFilePdf } from "react-icons/fa";
import LoadingCircle from "../utility/LoadingCircle";
import { isMobile } from "react-device-detect";

const useFetchPdf = (url) => {
	const [pdfUrl, setPdfUrl] = useState(null);

	useEffect(() => {
		fetch(url)
			.then((response) => response.blob())
			.then((blob) => {
				const objectUrl = URL.createObjectURL(blob);
				setPdfUrl(objectUrl);
			})
			.catch((error) => {});
	}, [url]);

	return pdfUrl;
};

const PdfViewer = ({ dropboxUrl, title }) => {
	const [isOpen, setIsOpen] = useState(false);
	const pdfUrl = useFetchPdf(dropboxUrl);
	const googleDocsViewerUrl = `https://docs.google.com/gview?url=${encodeURIComponent(dropboxUrl)}&embedded=true`;

	const handleOpenClick = () => {
		setIsOpen(true);
	};

	const handleCloseClick = () => {
		setIsOpen(false);
	};

	return (
		<div className="flex gap-2 items-center">
			{pdfUrl ? (
				<button onClick={handleOpenClick}>
					<FaFilePdf className="h-10 w-10" color="red" />
				</button>
			) : (
				<LoadingCircle />
			)}
			<div>
				<button onClick={handleOpenClick}>{title}</button>
				{isOpen ? (
					<div className="fixed top-0 left-0 bottom-0 right-0 h-full w-full bg-black text-white overflow-auto">
						<div className={`fixed top-0 right-0 m-3  flex flex-col gap-2`} style={{ zIndex: 100 }}>
							<button onClick={handleCloseClick} className="bg-gray-100 p-2 rounded-sm text-black border shadow-lg hover:bg-gray-200">
								Close
							</button>
							<a href={dropboxUrl} download className="bg-gray-100 p-2 rounded-sm text-black border shadow-lg hover:bg-gray-200">
								Download
							</a>
						</div>
						<div className="w-full h-full overflow-auto">
							<iframe
								src={googleDocsViewerUrl}
								width="100%"
								height="100%"
								className="pdfModalContent"
								type="application/pdf"
								scrolling="auto"
								title={title}></iframe>
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default PdfViewer;
