import { useEffect, useState } from "react";
import LoadingCircle from "../utility/LoadingCircle";
import ReportImageModal from "./ReportImageModal";

const ReportImage = ({ api, token, reportId, setSelectedImgName, selectedImgName }) => {
	const [imgThumbnail, setImgThumbnail] = useState("");
	const [showReportImageModal, setShowReportImageModal] = useState(false);
	const [logos, setLogos] = useState([]);

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		getLogos(api, token, signal);

		return () => {
			controller.abort();
		};
	}, [api, token]);

	const getLogos = (theApi, theToken, abortSignal) => {
		fetch(theApi + "/report/logos", {
			method: "get",
			headers: {
				Authorization: theToken,
			},
			abortSignal,
		})
			.then((res) => {
				if (res.ok) {
					return res.json();
				}
			})
			.then((jsonData) => {
				if (jsonData) {
					setLogos(jsonData.logos);
				}
			});
	};

	useEffect(() => {
		const findImageThumbnailByName = (theNameToFind, theLogos) => {
			if (!theLogos || theLogos.length <= 0) {
				return;
			}
			for (let i = 0; i < theLogos.length; i++) {
				if (theNameToFind === theLogos[i].name) {
					setImgThumbnail(theLogos[i].thumbnail);
					return;
				}
			}
			setSelectedImgName("");
		};

		findImageThumbnailByName(selectedImgName, logos);
	}, [selectedImgName, logos, setSelectedImgName]);

	const openImageModal = () => {
		setShowReportImageModal(true);
	};

	return (
		<div>
			{showReportImageModal ? (
				<ReportImageModal
					logos={logos}
					setShowReportImageModal={setShowReportImageModal}
					selectedImgName={selectedImgName}
					setSelectedImgName={setSelectedImgName}
					reportId={reportId}
					api={api}
					token={token}
					setLogos={setLogos}
				/>
			) : null}
			<p className="font-bold">Cover Image</p>
			<p className="text-sm">Displays on the first page of the report</p>
			<div className="mt-1">
				{selectedImgName.trim() === "" ? (
					<div className="w-auto h-36 p-4 bg-stone-50 text-center flex items-center justify-center border border-black rounded-lg">
						<p>No Image Selected</p>
					</div>
				) : logos && logos.length > 0 ? (
					<img src={imgThumbnail} alt="the report cover" className="w-auto max-h-[30vh] object-contain select-none" />
				) : (
					<div>
						<LoadingCircle />
					</div>
				)}
				{logos && logos.length > 0 ? (
					<div className="mt-1">
						<button className="bg-blue-500 hover:bg-blue-700 p-2 text-white rounded-lg" onClick={openImageModal}>
							Change Image
						</button>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default ReportImage;
