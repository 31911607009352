import { Fragment, useEffect, useState } from "react";
import LoadingCircle from "../../utility/LoadingCircle";
const months = {
	1: "January",
	2: "February",
	3: "March",
	4: "April",
	5: "May",
	6: "June",
	7: "July",
	8: "August",
	9: "September",
	10: "October",
	11: "November",
	12: "December",
};
const Attendance = ({ api, token }) => {
	const [lateClockedOutEmployees, setLateClockedOutEmployees] = useState([]);
	const [loading, setLoading] = useState(false);
	const [updatingSavedNotes, setUpdatingSavedNotes] = useState(false);
	const [notes, setNotes] = useState([]);
	const viewOptions = ["late", "notes"];
	const [showView, setShowView] = useState("late");

	function format12HourTime(date) {
		if (!(date instanceof Date)) {
			date = new Date(date);
		}

		const day = date.getDate();
		const monthIndex = date.getMonth() + 1;

		let hours = date.getHours();
		const minutes = date.getMinutes();
		const ampm = hours >= 12 ? "PM" : "AM";
		hours = hours % 12;
		hours = hours || 12;
		const minutesStr = minutes < 10 ? "0" + minutes : minutes;

		return (
			<div>
				<p>{`${months[monthIndex]} ${String(day).padStart(2, "0")}, ${hours}:${minutesStr} ${ampm}`}</p>
			</div>
		);
	}

	const removeAutonums = (s) => {
		const regex = /(-\s?\d+)+$/;
		return s.replace(regex, "");
	};

	useEffect(() => {
		document.title = "Attendance";
	}, []);

	useEffect(() => {
		if (api === "" || token === "") {
			return;
		}
		const controller = new AbortController();
		const signal = controller.signal;
		setLoading(true);
		fetch(api + "/clocking/attendance", {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			mode: "cors",
			signal,
		})
			.then((res) => {
				if (res.ok) {
					return res.json();
				}
			})
			.then((data) => {
				if (data) {
					let records = data.lateRecords;
					records = records.map((mapData) => {
						return {
							...mapData,
							project_name: removeAutonums(mapData.project_name),
							in_time: format12HourTime(mapData.in_time),
							out_time: format12HourTime(mapData.out_time),
						};
					});
					records.sort((a, b) => new Date(b.time) - new Date(a.time));
					setLateClockedOutEmployees(records);

					let noteRecords = data.notes;
					noteRecords = noteRecords.map((mapData) => {
						return {
							...mapData,
							project_name: removeAutonums(mapData.project_name),
							time: format12HourTime(mapData.time),
						};
					});
					noteRecords.sort((a, b) => new Date(b.time) - new Date(a.time));
					setNotes(noteRecords);
				}
				setLoading(false);
			});

		return () => {
			controller.abort();
		};
	}, [api, token]);

	const handleCheckboxChange = (dbRowId) => {
		if (updatingSavedNotes) {
			return;
		}
		setUpdatingSavedNotes(true);
		fetch(api + "/clocking/toggle-note", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			mode: "cors",
			body: JSON.stringify({ rowId: dbRowId }),
		}).then((res) => {
			if (res.ok) {
				setUpdatingSavedNotes(false);
				setLateClockedOutEmployees((prevEmployees) =>
					prevEmployees.map((employee) => (employee.id === dbRowId ? { ...employee, saved_to_adp: !employee.saved_to_adp } : employee))
				);
			}
		});
	};

	return (
		<div className="py-4 flex flex-col justify-center items-center">
			<div className="w-full md:w-4/5   mb-2 bg-white">
				<div className="items-center p-2 text-lg ">
					<h1 className="text-2xl font-bold text-center">Attendance</h1>
					<div className="flex gap-4 mt-2 justify-center items-center">
						<button
							className={`${showView === "late" ? "bg-blue-500 hover:bg-blue-700" : "bg-gray-500"} text-white p-2 rounded-lg`}
							onClick={() => setShowView("late")}>
							Late Reasons
						</button>
						<button
							className={`${showView === "notes" ? "bg-blue-500 hover:bg-blue-700" : "bg-gray-500"} text-white p-2 rounded-lg`}
							onClick={() => setShowView("notes")}>
							Clocking notes
						</button>
					</div>
				</div>
				<div className="p-2">
					{loading ? (
						<div className="flex flex-col justify-center items-center">
							<LoadingCircle />
							<p>loading...</p>
						</div>
					) : null}
					{loading || showView !== "late" ? null : lateClockedOutEmployees.length > 0 ? (
						<>
							<p className="text-center">A list of subordinates who worked 6 hours or more straight without a break in the last 14 days.</p>
							<div className="grid grid-cols-7 border-t border-r border-black ">
								<div className="border-l border-b border-black p-2 bg-gray-100 font-bold">Employee</div>
								<div className="border-l border-b border-black p-2 bg-gray-100 font-bold">Project Name</div>
								<div className="border-l border-b border-black p-2 bg-gray-100 font-bold">Cost Number</div>
								<div className="border-l border-b border-black p-2 bg-gray-100 font-bold">In Time</div>
								<div className="border-l border-b border-black p-2 bg-gray-100 font-bold">Out Time</div>
								<div className="border-l border-b border-black p-2 bg-gray-100 font-bold">Reason</div>
								<div className="border-l border-b border-black p-2 bg-gray-100 font-bold">Saved Note in ADP</div>
								{lateClockedOutEmployees.map((mapData) => (
									<Fragment key={mapData.id}>
										<div className="border-l border-b border-black p-2">{mapData.name}</div>
										<div className="border-l border-b border-black p-2">{mapData.project_name}</div>
										<div className="border-l border-b border-black p-2">{mapData.cost_number}</div>
										<div className="border-l border-b border-black p-2">{mapData.in_time}</div>
										<div className="border-l border-b border-black p-2">{mapData.out_time}</div>
										<div className="border-l border-b border-black p-2">{mapData.late_reason}</div>
										<div className="border-l border-b border-black p-2">
											<label>
												<input
													type="checkbox"
													checked={mapData.saved_to_adp}
													onChange={() => handleCheckboxChange(mapData.id, mapData.saved_to_adp)}
												/>
											</label>
										</div>
									</Fragment>
								))}
							</div>
						</>
					) : (
						<p className="text-center">No time issues found.</p>
					)}
					{loading || showView !== "notes" ? null : notes.length > 0 ? (
						<>
							<p className="text-center">Notes from subordinates in the last 14 days.</p>

							<div className="grid grid-cols-7 border-t border-r border-black ">
								<div className="border-l border-b border-black p-2 bg-gray-100 font-bold">Employee</div>
								<div className="border-l border-b border-black p-2 bg-gray-100 font-bold">Project Name</div>
								<div className="border-l border-b border-black p-2 bg-gray-100 font-bold">Time</div>
								<div className="border-l border-b border-black p-2 bg-gray-100 font-bold">Clocking Type</div>
								<div className="border-l border-b border-black p-2 bg-gray-100 font-bold col-span-3">Note</div>
								{notes.map((mapData) => (
									<Fragment key={mapData.id}>
										<div className="border-l border-b border-black p-2">{mapData.name}</div>
										<div className="border-l border-b border-black p-2">{mapData.project_name}</div>
										<div className="border-l border-b border-black p-2">{mapData.time}</div>
										<div className="border-l border-b border-black p-2">{mapData.clock_type}</div>
										<div className="border-l border-b border-black p-2 col-span-3">{mapData.note}</div>
									</Fragment>
								))}
							</div>
						</>
					) : (
						<p className="text-center">No notes found.</p>
					)}
				</div>
			</div>
		</div>
	);
};

export default Attendance;
