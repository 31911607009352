const SaveMultiplePrompt = (props) => {
	const exitPrompt = () => {
		props.setUploadedImages([]);
		props.uploadMulitpleRef.current.value = null;
	};

	return (
		<div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-black/50 max-h-full overflow-y-scroll z-50">
			<div className="bg-white p-4 flex flex-col items-center justify-center gap-3 m-2 rounded-lg">
				<p className="font-bold text-2xl">Save these images? </p>
				<div className="flex flex-row flex-wrap gap-3 items-center justify-center">
					{props.uploadPreviews && props.uploadPreviews.length > 0
						? props.uploadPreviews.map((value, index) => {
								return (
									<img
										key={index}
										src={value}
										alt="upload preview"
										className="max-h-[40vh] w-auto"
									/>
								);
						  })
						: null}
				</div>

				<div className="flex gap-3">
					{" "}
					<p
						className="bg-green-300 p-4 cursor-pointer"
						onClick={(e) => props.uploadImages(e)}>
						Upload All
					</p>
					<p
						className="bg-gray-300 p-4 cursor-pointer"
						onClick={() => exitPrompt()}>
						Cancel
					</p>
				</div>
			</div>
		</div>
	);
};

export default SaveMultiplePrompt;
