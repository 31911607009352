import { useState, useEffect, useContext } from "react";
import Shop from "./Shop";
import { FaShoppingCart } from "react-icons/fa";
import { CartContext } from "./cart/CartProvider";
import { useNavigate } from "react-router-dom";

const ShopButton = ({ api, token, odooId, odooModel, projectName, openedFromCart = false, buttonText = "Open Shop" }) => {
	const [showModal, setShowModal] = useState(false);
	const { cart } = useContext(CartContext);
	const navigate = useNavigate();

	useEffect(() => {
		if (showModal) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}
		return () => {
			document.body.style.overflow = "auto";
		};
	}, [showModal]);

	const openModal = () => {
		setShowModal(true);
	};

	const closeModal = () => {
		setShowModal(false);
	};

	const navigateToCart = () => {
		if (openedFromCart) {
			setShowModal(false);
		} else {
			navigate("/cart");
		}
	};

	return (
		<>
			<div className="flex items-center gap-1 bg-blue-600 hover:bg-blue-700 p-2 rounded-lg cursor-pointer w-full select-none" onClick={openModal}>
				<FaShoppingCart color="white" />
				<p className="text-center text-white">{buttonText}</p>
			</div>
			{showModal ? (
				<div className="fixed top-0 bottom-0 left-0 right-0 z-[2000] overflow-y-scroll bg-gray-100 text-black">
					<div className="flex flex-col-reverse mb-4 md:mb-0 md:flex-row justify-between mx-4 mt-4 ">
						<div>
							<p className="font-bold text-3xl">
								Shop <span className="text-xs">{`${odooId} - ${odooModel} - ${projectName}`}</span>
							</p>
						</div>

						<div className="flex gap-4 justify-end">
							<div className="flex gap-2 items-center border border-black p-2 rounded bg-white cursor-pointer" onClick={navigateToCart}>
								<FaShoppingCart />
								<p>{`Cart (${cart.length})`}</p>
							</div>
							<button onClick={closeModal} className="bg-gray-500 text-white p-2 rounded">
								Close
							</button>
						</div>
					</div>
					<Shop api={api} token={token} odooId={odooId} odooModel={odooModel} projectName={projectName} />
				</div>
			) : null}
		</>
	);
};

export default ShopButton;
