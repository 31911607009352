export default function ClockingExportModal({ closeExportModal, exportData, employeeNames, startDate, setStartDate, endDate, setEndDate, employee, setEmployee, errorText }) {
    return (
        <div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-gray-700 bg-opacity-50 z-[100]" onClick={closeExportModal}>
            <div className="flex flex-col items-center justify-center bg-white p-4 m-2 rounded-lg" onClick={(e) => e.stopPropagation()}>
                <h2 className="text-2xl font-bold mb-4">
                    Please Select The Dates You Would Like To Export
                </h2>
                <div className="flex items-center mb-4">
                    <label htmlFor="start-date" className="text-lg font-bold mr-2 w-32">Start Date: </label>
                    <input type="date" id="start-date" className="p-2 border border-black rounded-lg flex-grow" onChange={(e) => setStartDate(e.target.value)} />
                </div>
                <div className="flex items-center mb-4">
                    <label htmlFor="end-date" className="text-lg font-bold mr-2 w-32">End Date: </label>
                    <input type="date" id="end-date" className="p-2 border border-black rounded-lg flex-grow" onChange={(e) => setEndDate(e.target.value)} />
                </div>
                <div className="flex items-center mb-4">
                    <label htmlFor="employee" className="text-lg font-bold mr-2 w-32">Employee: </label>
                    <select name="employee" id="employee" className="p-2 border border-black rounded-lg flex-grow" defaultValue="All" onChange={(e) => setEmployee(e.target.value)}>
                        <option value="All">All</option>
                        {employeeNames && employeeNames.map((employee, index) => (
                            <option key={index} value={employee}>{employee}</option>
                        ))}
                    </select>
                </div>
                {errorText ? <p className="text-red-500 text-sm mb-4">{errorText}</p> : null}
                <div className="flex items-center justify-center w-full gap-4">
                    <button onClick={closeExportModal} className="bg-gray-500 text-white px-4 py-2 rounded-lg">
                        Close
                    </button>
                    <button onClick={() => exportData(startDate, endDate, employee)} className="bg-blue-500 text-white px-4 py-2 rounded-lg">
                        Export
                    </button>
                </div>
            </div>
        </div>
    )
}