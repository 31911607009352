const ClockingNotesModal = ({ closeModal, note, setNote, buttonLogic, buttonText }) => {
	return (
		<div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black/[.3] z-[200]" onClick={closeModal}>
			<div
				className="p-4 bg-white rounded-lg w-full md:w-2/5"
				onClick={(e) => {
					e.stopPropagation();
				}}>
				<p className="font-bold text-center text-xl mb-4">Add Note (optional)</p>
				<div>
					<textarea
						value={note}
						onChange={(e) => setNote(e.target.value)}
						placeholder=""
						rows="4"
						className="w-full p-1 border border-black"
						maxLength={500}
					/>
					<div className="text-xs float-right">{`${note.length}/500`}</div>
				</div>
				<button type="button" className={`w-full p-2 mt-3 rounded bg-blue-500 text-white`} onClick={buttonLogic}>
					{buttonText}
				</button>
			</div>
		</div>
	);
};

export default ClockingNotesModal;
