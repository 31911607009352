import React, { useState } from "react";
import { FaFileWord } from "react-icons/fa";

const DocsViewer = ({ docUrl, title }) => {
	const [isOpen, setIsOpen] = useState(false);
	const googleDocsViewerUrl = `https://docs.google.com/gview?url=${encodeURIComponent(docUrl)}&embedded=true`;

	const handleOpenClick = () => {
		setIsOpen(true);
	};

	const handleCloseClick = () => {
		setIsOpen(false);
	};

	return (
		<div className="flex gap-2 items-center">
			<button onClick={handleOpenClick}>
				<FaFileWord className="h-10 w-10" color="blue" />
			</button>
			<button onClick={handleOpenClick}>{title}</button>
			{isOpen ? (
				<div className="fixed top-0 left-0 bottom-0 right-0 h-full w-full bg-black text-white">
					<iframe src={googleDocsViewerUrl} width="100%" height="100%" className="w-full h-full" title={title}></iframe>
					<div className="absolute top-0 right-0 m-3 flex flex-col gap-2" style={{ zIndex: 2 }}>
						<button onClick={handleCloseClick} className="bg-gray-100 p-2 rounded-sm text-black border shadow-lg hover:bg-gray-200">
							Close
						</button>
						<a href={docUrl} download className="bg-gray-100 p-2 rounded-sm text-black border shadow-lg hover:bg-gray-200">
							Download
						</a>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default DocsViewer;
