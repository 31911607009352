import { useEffect, useState } from "react";
import OrderCard from "./OrderCard";
import InventoryPagination from "../InventoryPagination";
import LoadingCircle from "../../utility/LoadingCircle";
import { useSearchParams } from "react-router-dom";

const Orders = ({ api, token }) => {
	const [loading, setLoading] = useState(false);
	const [orders, setOrders] = useState([]);
	const [totalPages, setTotalPages] = useState(1);
	const PER_PAGE = 10;

	const [searchParams, setSearchParams] = useSearchParams();
	const [page, setPage] = useState(parseInt(searchParams.get("page")) || 1);

	useEffect(() => {
		document.title = "Previous Orders";
	}, []);

	useEffect(() => {
		setSearchParams({ page });
	}, [page, setSearchParams]);

	useEffect(() => {
		if (api === "" || token === "") {
			return;
		}

		const controller = new AbortController();
		const signal = controller.signal;
		setLoading(true);
		fetch(`${api}/shop/orders?page=${page}&perpage=${PER_PAGE}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			mode: "cors",
			signal,
		})
			.then((res) => {
				if (res.ok) {
					return res.json();
				} else {
				}
			})
			.then((res) => {
				if (res) {
					setOrders(res.orders);
					setTotalPages(Math.ceil(res.totalOrders / PER_PAGE));
				}
				setLoading(false);
			})
			.catch((err) => {});

		return () => {
			controller.abort();
		};
	}, [api, page, token]);

	if (loading) {
		return (
			<div className="fixed top-0 right-0 left-0 bottom-0 bg-black/50 z-[100] flex justify-center items-center">
				<div className="flex flex-col items-center justify-center border border-black p-4 bg-white">
					<LoadingCircle />
					<p>Loading Orders...</p>
				</div>
			</div>
		);
	}

	return (
		<div className="w-full flex flex-col items-center">
			<h1 className="mt-3 font-bold text-3xl mb-3">Orders</h1>
			<div className="w-full md:w-3/5">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-3 m-2">
					{orders && orders.length > 0 ? (
						orders.map((mapData) => {
							return <OrderCard key={mapData.id} order={mapData} />;
						})
					) : (
						<div className="col-span-2 my-4">
							<p className="text-center">No orders found for this page.</p>
						</div>
					)}
				</div>
				<div className="mb-2 mt-2 flex justify-center items-center">
					<InventoryPagination page={page} setPage={setPage} totalPages={totalPages} />
				</div>
			</div>
		</div>
	);
};

export default Orders;
