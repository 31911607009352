exports.getIdAndModel = (project) => {
	if (project.x_studio_module === "Installation" && project.task_id) {
		return [project.task_id[0], "project.task"];
	} else if (project.x_studio_module === "Roofing" && project.x_studio_many2one_field_3UTue) {
		return [project.x_studio_many2one_field_3UTue[0], "x_roofing"];
	} else if (project.x_studio_module === "Helpdesk" && project.x_studio_many2one_field_8NwQn) {
		return [project.x_studio_many2one_field_8NwQn[0], "helpdesk.ticket"];
	} else if (project.x_studio_module === "Krummy Capture" && project.x_studio_many2one_field_JaG0B) {
		return [project.x_studio_many2one_field_JaG0B[0], "x_krummy_capture"];
	} else {
		return "-";
	}
};
exports.formatTimeToMatchADP = (inputDate) => {
	const date = new Date(inputDate);

	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const day = String(date.getDate()).padStart(2, "0");
	const hours = String(date.getHours()).padStart(2, "0");
	const minutes = String(date.getMinutes()).padStart(2, "0");
	const seconds = String(date.getSeconds()).padStart(2, "0");
	const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

	const offsetMinutes = date.getTimezoneOffset();
	const offsetSign = offsetMinutes <= 0 ? "+" : "-";
	const offsetHours = Math.abs(Math.floor(offsetMinutes / 60));
	const offsetRemainingMinutes = Math.abs(offsetMinutes % 60);

	const formattedOffset = `${offsetSign}${String(offsetHours).padStart(2, "0")}:${String(offsetRemainingMinutes).padStart(2, "0")}`;

	const dateTimeWithOffset = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${formattedOffset}`;

	return dateTimeWithOffset;
};

exports.getADPFormattedTimestamp = () => {
	const now = new Date();

	const year = now.getFullYear();
	const month = String(now.getMonth() + 1).padStart(2, "0");
	const day = String(now.getDate()).padStart(2, "0");
	const hours = String(now.getHours()).padStart(2, "0");
	const minutes = String(now.getMinutes()).padStart(2, "0");
	const seconds = String(now.getSeconds()).padStart(2, "0");
	const milliseconds = String(now.getMilliseconds()).padStart(3, "0");

	const offsetMinutes = now.getTimezoneOffset();
	const offsetSign = offsetMinutes <= 0 ? "+" : "-";
	const offsetHours = Math.abs(Math.floor(offsetMinutes / 60));
	const offsetRemainingMinutes = Math.abs(offsetMinutes % 60);

	const formattedOffset = `${offsetSign}${String(offsetHours).padStart(2, "0")}:${String(offsetRemainingMinutes).padStart(2, "0")}`;

	const dateTimeWithOffset = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${formattedOffset}`;

	return dateTimeWithOffset;
};
