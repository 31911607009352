import { useState } from "react";
import InventoryTree from "./InventoryTree";
import { FaSearch } from "react-icons/fa";

const FilterPanel = ({ categories, categoriesQuery, setCategoriesQuery, searchText, setSearchText }) => {
	const [tempSearchText, setTempSearchText] = useState("");
	const handleSearch = () => {
		setSearchText(tempSearchText);
	};
	return (
		<div className="p-4 sticky top-0 h-[100vh] flex flex-col ">
			<div className="flex items-center mb-2">
				<input
					type="text"
					value={tempSearchText}
					onChange={(e) => setTempSearchText(e.target.value)}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							handleSearch();
						}
					}}
					placeholder="Search..."
					className="border border-black w-full rounded-l px-4 py-1 focus:outline-none "
				/>
				<button onClick={handleSearch} className="border border-black bg-blue-500 text-white rounded-r px-2 py-1 flex items-center">
					<FaSearch className="mr-2" />
					Search
				</button>
			</div>
			<InventoryTree data={categories} categoriesQuery={categoriesQuery} setCategoriesQuery={setCategoriesQuery} />
		</div>
	);
};

export default FilterPanel;
