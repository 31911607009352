import { useEffect, useState, useMemo } from "react";

const FieldExclusionList = ({ api, token, reportId, dataToSave, setDataToSave, sections, selectedTemplate, templateData }) => {
	const [selectedSection, setSelectedSection] = useState("");
	const [fields, setFields] = useState([]);
	const collator = useMemo(() => {
		return new Intl.Collator(undefined, { numeric: true, sensitivity: "base" });
	}, []);
	const [selectAllConst, setSelectAllConst] = useState(false);

	const changeSectionSelection = (e) => {
		setSelectedSection(e.target.value);

		if (e.target.value === "") {
			setSelectAllConst(false);
			return;
		}

		let selectedFieldsLength = dataToSave.templateData[selectedTemplate]["includedFields"][e.target.value];

		if (typeof selectedFieldsLength === "undefined") {
			setSelectAllConst(false);
			return;
		}

		selectedFieldsLength = selectedFieldsLength.filter((filterData) => filterData.deleted === false).length;
		let totalFields = templateData[selectedTemplate].sections[e.target.value].length;
		if (selectedFieldsLength === totalFields) {
			setSelectAllConst(true);
		} else {
			setSelectAllConst(false);
		}
	};

	useEffect(() => {
		if (
			sections.length > 0 &&
			sections.includes(selectedSection) &&
			dataToSave.templateData[selectedTemplate]["includedSections"].map((mapData) => mapData.name).includes(selectedSection) &&
			dataToSave.templateData[selectedTemplate]["includedSections"][
				dataToSave.templateData[selectedTemplate]["includedSections"].map((mapData) => mapData.name).indexOf(selectedSection)
			].deleted === false
		) {
			let sortedFields = templateData[selectedTemplate].sections[selectedSection];
			sortedFields.sort(collator.compare);
			setFields(sortedFields);
		} else {
			setSelectedSection("");
			setFields([]);
		}
	}, [selectedSection, sections, selectedTemplate, dataToSave, templateData, collator.compare]);

	const addField = (e, theField) => {
		e.preventDefault();
		let newTemplateDataToSave = { ...dataToSave.templateData };
		let currentFields = newTemplateDataToSave[selectedTemplate]["includedFields"][selectedSection];
		let alreadyIncluded = false;
		if (currentFields && currentFields.length > 0) {
			alreadyIncluded = currentFields.map((mapData) => mapData.name).includes(theField);
		}
		if (alreadyIncluded) {
			newTemplateDataToSave[selectedTemplate]["includedFields"][selectedSection][
				currentFields.map((mapData) => mapData.name).indexOf(theField)
			].deleted = false;
		} else {
			if (!currentFields) {
				newTemplateDataToSave[selectedTemplate]["includedFields"][selectedSection] = [{ id: -1, name: theField, from_server: false, deleted: false }];
			} else {
				newTemplateDataToSave[selectedTemplate]["includedFields"][selectedSection].push({ id: -1, name: theField, from_server: false, deleted: false });
			}
		}

		let selectedSectionsLength = newTemplateDataToSave[selectedTemplate]["includedFields"][selectedSection].filter(
			(filterData) => filterData.deleted === false
		).length;
		let totalSections = templateData[selectedTemplate].sections[selectedSection].length;
		if (selectedSectionsLength === totalSections) {
			setSelectAllConst(true);
		} else {
			setSelectAllConst(false);
		}

		setDataToSave((prev) => {
			return { ...prev, templateData: newTemplateDataToSave };
		});
	};

	const removeField = (e, theField) => {
		e.preventDefault();
		let newTemplateDataToSave = { ...dataToSave.templateData };
		let currentFields = newTemplateDataToSave[selectedTemplate]["includedFields"][selectedSection];
		for (let i = currentFields.length - 1; i >= 0; i--) {
			if (currentFields[i].name === theField) {
				if (currentFields[i].from_server) {
					newTemplateDataToSave[selectedTemplate]["includedFields"][selectedSection][i].deleted = true;
				} else {
					newTemplateDataToSave[selectedTemplate]["includedFields"][selectedSection].splice(i, 1);
				}
				break;
			}
		}

		let selectedSectionsLength = newTemplateDataToSave[selectedTemplate]["includedFields"][selectedSection].filter(
			(filterData) => filterData.deleted === false
		).length;
		let totalSections = templateData[selectedTemplate].sections[selectedSection].length;
		if (selectedSectionsLength === totalSections) {
			setSelectAllConst(true);
		} else {
			setSelectAllConst(false);
		}

		setDataToSave((prev) => {
			return { ...prev, templateData: newTemplateDataToSave };
		});
	};

	const toggleAllFields = (e) => {
		setSelectAllConst(e.target.checked ? 1 : 0);
		if (!selectAllConst) {
			for (let i = 0; i < fields.length; i++) {
				setSelectAllConst(true);
				addField(e, fields[i]);
			}
		} else {
			for (let i = 0; i < fields.length; i++) {
				removeField(e, fields[i]);
			}
		}
	};

	return (
		<div className="border-2 border-black p-2 rounded-lg bg-gray-50">
			<h3 className="text-xl font-bold">Field Inclusions</h3>
			<form onSubmit={(e) => e.preventDefault()} className="flex w-full flex-col md:flex-row gap-3">
				<div className="w-full">
					<p className="font-bold">Section:</p>
					<p className="text-sm">Select section to modify included fields.</p>
					<select
						id="templateNames"
						value={selectedSection}
						className="border border-2 border-black rounded-lg p-1 w-full mt-2"
						onChange={changeSectionSelection}>
						<option value=""></option>
						{sections && sections.length > 0
							? sections.map((mapData, index) => {
									return (
										<option value={mapData} key={index}>
											{mapData}
										</option>
									);
							  })
							: null}
					</select>
				</div>
				<div className="w-full">
					<p className="font-bold">Fields:</p>
					<p className="text-sm">Click on fields to include or exclude them.</p>
					<div className="w-full border-2 border-black rounded-lg max-h-[33vh] h-[33vh] overflow-y-scroll mt-2 bg-white ">
						{fields && fields.length > 0 && dataToSave ? (
							<div className="p-4 flex items-center gap-2 border-b border-black select-none cursor-pointer" onClick={toggleAllFields}>
								<input type="checkbox" className="border border-black" readOnly checked={selectAllConst ? 1 : 0} />

								<p>Select All </p>
							</div>
						) : null}

						{fields && fields.length > 0 ? (
							fields.map((mapData, index) => {
								return dataToSave.templateData[selectedTemplate] &&
									dataToSave.templateData[selectedTemplate]["includedFields"][selectedSection] &&
									dataToSave.templateData[selectedTemplate]["includedFields"][selectedSection].map((mData) => mData.name).includes(mapData) &&
									dataToSave.templateData[selectedTemplate]["includedFields"][selectedSection][
										dataToSave.templateData[selectedTemplate]["includedFields"][selectedSection].map((mData) => mData.name).indexOf(mapData)
									].deleted === false ? (
									<div
										key={index}
										className={` cursor-pointer select-none ${index !== 0 ? "  border-t border-black" : ""}`}
										onClick={(e) => removeField(e, mapData)}>
										<div className="p-4 flex items-center gap-2">
											<input type="checkbox" readOnly checked={true} value={mapData} />
											<p>{mapData}</p>
										</div>
									</div>
								) : (
									<div
										key={index}
										className={`cursor-pointer select-none ${index !== 0 ? "  border-t border-black" : ""}`}
										onClick={(e) => addField(e, mapData)}>
										<div className="p-4 flex items-center gap-2">
											<input type="checkbox" readOnly checked={false} value={mapData} />
											<p>{mapData}</p>
										</div>
									</div>
								);
							})
						) : (
							<p className="text-center p-4">Select a section to get started</p>
						)}
					</div>
				</div>
			</form>
		</div>
	);
};

export default FieldExclusionList;
