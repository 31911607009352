import { useContext, useEffect, useState } from "react";
import LoadingCircle from "../utility/LoadingCircle";
import { CartContext } from "./cart/CartProvider";
import Product from "./Product";

const ProductModal = ({ api, token, selectedProduct, setViewProduct, odooId, odooModel, projectName }) => {
	const [loading, setLoading] = useState(false);
	const [selectedProducts, setSelectedProducts] = useState([]);
	const { addToCart } = useContext(CartContext);

	useEffect(() => {
		if (api === "" || token === "" || !selectedProduct) {
			return;
		}

		const controller = new AbortController();
		const signal = controller.signal;
		setLoading(true);
		fetch(api + `/shop/product/${selectedProduct.id}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			mode: "cors",
			signal,
		})
			.then((res) => {
				if (res.ok) {
					return res.json();
				} else {
				}
			})
			.then((res) => {
				if (res) {
					if (res.product.optionalProducts) {
						setSelectedProducts([
							{ ...res.product, isOptional: false, canBeOptional: false, odooId, odooModel, projectName, amount: 1, notes: "" },
							...res.product.optionalProducts.map((mapData) => {
								return {
									...mapData,
									isOptional: true,
									canBeOptional: true,
									odooId,
									odooModel,
									projectName,
									amount: 0,
									notes: "",
								};
							}),
						]);
					} else {
						setSelectedProducts([
							{ ...res.product, isOptional: false, canBeOptional: false, odooId, odooModel, projectName, amount: 1, notes: "" },
						]);
					}
				}
				setLoading(false);
			})
			.catch((err) => {});

		return () => {
			controller.abort();
		};
	}, [api, odooId, odooModel, projectName, selectedProduct, token]);

	const [isCartEnabled, setIsCartEnabled] = useState(false);

	useEffect(() => {
		const checkCartEnabled = () => {
			return selectedProducts.every((product) => {
				if (!product.isOptional) {
					if (product.amount <= 0) return false;

					return product.attributes.every((attr) => attr.selectedValue?.id && attr.selectedValue?.name);
				}
				return true;
			});
		};

		setIsCartEnabled(checkCartEnabled());
	}, [selectedProducts]);

	const handleAddToCartClick = () => {
		for (let i = 0; i < selectedProducts.length; i++) {
			let currentProduct = { ...selectedProducts[i] };

			if (!currentProduct.isOptional) {
				delete currentProduct.image_1920;
				delete currentProduct.optionalProducts;

				addToCart(currentProduct);
			}
		}
		alert("Items added to cart");
		setViewProduct(false);
	};

	if (loading) {
		return (
			<div className="fixed top-0 right-0 left-0 bottom-0 bg-black/50 z-[100] flex justify-center items-center">
				<div className="flex flex-col items-center justify-center border border-black p-4 bg-white">
					<LoadingCircle />
					<p>Loading Product...</p>
				</div>
			</div>
		);
	}

	return (
		<div className="fixed inset-0 bg-black/50 z-[100] flex justify-center items-center">
			<div className="bg-gray-100 w-full h-screen flex flex-col items-center">
				<div className="flex flex-col h-full w-full md:w-4/5 lg:w-3/5 bg-white">
					<div className="flex justify-between items-center w-full p-4 bg-slate-200">
						<h2 className="text-2xl font-bold ">Add To Cart</h2>
						<button onClick={() => setViewProduct(false)} className="p-2 text-sm text-white bg-gray-500 hover:bg-gray-600 rounded-lg">
							Close
						</button>
					</div>

					<div className="flex-grow overflow-y-scroll p-4">
						<div>
							{selectedProducts.some((product) => product.isOptional) && <p className="font-bold text-xl mb-2">Selected Products</p>}
							{selectedProducts.length > 0 &&
								selectedProducts.map(
									(mapData) => !mapData.isOptional && <Product product={mapData} key={mapData.id} setSelectedProducts={setSelectedProducts} />
								)}

							{selectedProducts.some((product) => product.isOptional) && (
								<div className=" p-2 mt-2">
									<p className="font-bold text-xl mb-2">Optional Products</p>
									<div className="flex flex-col gap-2">
										{selectedProducts.length > 0 &&
											selectedProducts.map(
												(mapData) =>
													mapData.isOptional && (
														<Product product={mapData} key={mapData.id} setSelectedProducts={setSelectedProducts} />
													)
											)}
									</div>
								</div>
							)}
						</div>
					</div>

					<div>
						<div className="flex justify-end p-2 bg-slate-200">
							{isCartEnabled ? (
								<button className="bg-green-600 hover:bg-green-700 rounded-lg p-3 text-white" onClick={handleAddToCartClick}>
									{`Add ${selectedProducts.filter((filterData) => filterData.isOptional === false).length} ${
										selectedProducts.filter((filterData) => filterData.isOptional === false).length === 1 ? "Item" : "Items"
									} to Cart`}
								</button>
							) : (
								<button
									className="bg-gray-600 hover:bg-gray-700 rounded-lg p-3 text-white"
									onClick={() => alert("Please make sure all selected options have attributes set and an amount > 0")}>
									{`Add ${selectedProducts.filter((filterData) => filterData.isOptional === false).length} ${
										selectedProducts.filter((filterData) => filterData.isOptional === false).length === 1 ? "Item" : "Items"
									} to Cart`}
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductModal;
