const SelectBox = ({ name, values, value, onChange, productId }) => (
	<div className="mt-1 flex flex-col">
		<div className="flex items-center mb-1">
			<label className="font-bold text-lg mb-1">{name}</label>
			<div className="flex-grow border-b border-gray-400 ml-2"></div>
		</div>
		<select name={name + "-" + productId} value={value} onChange={onChange} className="w-full border border-gray-500 p-1">
			<option value={""} />

			{values.map((val) => (
				<option key={val.id} value={val.id}>
					{val.name}
				</option>
			))}
		</select>
	</div>
);

export default SelectBox;
