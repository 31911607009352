const DownloadModal = (props) => {
	return (
		<div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-black/50 z-30">
			<div className="bg-white m-2 rounded-lg">
				<div className="p-5 text-3xl">{props.messages}</div>
				<form className="p-8">
					<div className="flex gap-5 justify-center">
						<p
							onClick={(e) => props.onDownloadAction(e)}
							className="cursor-pointer text-blue-600 underline text-xl">
							{props.downloadText}
						</p>
					</div>
				</form>
			</div>
		</div>
	);
};

export default DownloadModal;
