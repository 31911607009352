import { useEffect, useState } from "react";
import { BsExclamationCircleFill } from "react-icons/bs";
import LoadingCircle from "../utility/LoadingCircle";
import { AiFillCheckCircle } from "react-icons/ai";

const DeleteProjectModal = ({
	setShowDeleteProjectModal,
	projectName,
	deleteFunction,
	actionVerb,
	isClearing,
	isLoading,
	deleteFunctionCompleted,
	completedMessage,
}) => {
	const [confirmationText, setConfirmationText] = useState("");
	const [deleteRequirementsMet, setDeleteRequirementsMet] = useState(false);
	const [userDidntFollowInstructions, setUserDidntFollowInstructions] = useState(false);

	const [clearData, setClearData] = useState(true);
	const [clearPhotos, setClearPhotos] = useState(true);

	const changeConfirmationText = (e) => {
		setConfirmationText(e.target.value);
		if (userDidntFollowInstructions) {
			setUserDidntFollowInstructions(false);
		}
	};

	useEffect(() => {
		if (confirmationText === projectName) {
			setDeleteRequirementsMet(true);
		} else {
			setDeleteRequirementsMet(false);
		}
	}, [confirmationText, projectName, userDidntFollowInstructions]);

	const showRequirementsNotMetMessage = (e) => {
		e.preventDefault();
		setUserDidntFollowInstructions(true);
	};

	const deleteProject = (e) => {
		e.preventDefault();
		if (isClearing) {
			deleteFunction(clearData, clearPhotos);
		} else {
			deleteFunction(e);
		}
	};
	return (
		<div
			className="text-black fixed top-0 left-0 w-full h-screen flex justify-center items-center bg-black/50 z-30"
			onClick={() => setShowDeleteProjectModal(false)}>
			<div className="bg-gray-300 m-2 rounded-lg w-full md:w-auto min-w-[50%]" onClick={(e) => e.stopPropagation()}>
				<div className="border border-black border-2 rounded-lg w-full ">
					<div className="flex justify-between  rounded-t-lg p-4 items-center">
						<p className="text-2xl font-bold text-center capitalize">{`${actionVerb} ${projectName}?`}</p>
						<p
							className="bg-white text-black cursor-pointer p-2 border border-black rounded-lg hover:bg-slate-100"
							onClick={() => setShowDeleteProjectModal(false)}>
							Close
						</p>
					</div>
					<hr className="border-black" />
					<div className="p-4 bg-white rounded-b-lg">
						<p>
							To {actionVerb} this project, type the project name <span className="font-bold">{` ${projectName} `}</span> into the box below and
							press {actionVerb}.
						</p>
						<p className="text-red-600 font-bold">This decision is permanent.</p>
						<form onSubmit={(e) => e.preventDefault()} className="mt-4">
							<input
								type="text"
								placeholder="Project Name"
								value={confirmationText}
								onChange={(e) => changeConfirmationText(e)}
								className=" border border-black w-full p-2"
							/>
							{isClearing ? (
								<form className="mt-4">
									<div className="flex gap-2 items-center">
										<label>
											<input type="checkbox" checked={clearData} onChange={(e) => setClearData(e.target.checked)} />
											Clear Data
										</label>
									</div>
									<div className="flex gap-2 items-center">
										<label>
											<input type="checkbox" checked={clearPhotos} onChange={(e) => setClearPhotos(e.target.checked)} />
											Clear Photos
										</label>
									</div>
								</form>
							) : null}
							{isLoading ? (
								<div className="mt-4 flex justify-center items-center">
									<LoadingCircle />
								</div>
							) : deleteRequirementsMet ? (
								<button className="bg-red-600 text-white w-full mt-4 p-2 rounded-lg capitalize" onClick={(e) => deleteProject(e)}>
									I Agree to {actionVerb.toLowerCase()} This Project
								</button>
							) : (
								<button
									className="bg-gray-400 text-white w-full mt-4 p-2 rounded-lg capitalize"
									onClick={(e) => showRequirementsNotMetMessage(e)}>
									I Agree to {actionVerb.toLowerCase()} This Project
								</button>
							)}
							{userDidntFollowInstructions ? (
								<div className="flex items-center gap-2 bg-rose-100 p-2 mt-2 rounded-lg">
									<BsExclamationCircleFill color="red" className="text-3xl" />
									<p>Please make sure you typed the project name correctly.</p>
								</div>
							) : null}
							{deleteFunctionCompleted ? (
								<div className="mt-2 flex  bg-green-200 p-2 rounded-lg">
									<div className="flex gap-2 ">
										<AiFillCheckCircle color="green" size={24} />
										<p>{completedMessage}</p>
									</div>
								</div>
							) : null}
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DeleteProjectModal;
