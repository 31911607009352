import { useState } from "react";
import { MdDeleteForever } from "react-icons/md";
const BankWord = (props) => {
	const [showDeletePrompt, setShowDeletePrompt] = useState(false);
	const deleteWord = (e) => {
		e.preventDefault();
		if (props.api === "" || props.token === "") {
			return;
		}
		setShowDeletePrompt(true);
		fetch(props.api + "/wordbank/" + props.id, {
			method: "DELETE",
			mode: "cors",
			headers: {
				Authorization: props.token,
				"Content-Type": "application/json",
			},
		}).then((res) => {
			if (res.ok) {
				props.setWordBank(
					props.wordbank.filter((word) => word.id !== props.id)
				);
			}
		});
	};

	const makeDeletePromptVisible = (e) => {
		e.preventDefault();
		setShowDeletePrompt(true);
	};

	return (
		<div
			className={
				"border border-black p-2 w-full  " +
				props.displayColor +
				props.roundedCornerCss
			}>
			{showDeletePrompt ? (
				<div className="fixed top-0 left-0 w-screen h-screen bg-black/50 flex justify-center items-center z-50">
					<div className="bg-white p-4 outline outline-2 outline-black ">
						<p className="text-3xl">Delete term: {props.name}?</p>
						<div className="flex justify-center mt-4 gap-2">
							<p
								className="bg-rose-600 text-white p-2 cursor-pointer"
								onClick={(e) => deleteWord(e)}>
								DELETE
							</p>
							<p
								className="bg-gray-500 text-white p-2 cursor-pointer"
								onClick={() => setShowDeletePrompt(false)}>
								Cancel
							</p>
						</div>
					</div>
				</div>
			) : null}

			<div className="flex gap-3">
				<span
					className="cursor-pointer text-rose-600"
					onClick={(e) => makeDeletePromptVisible(e)}>
					<MdDeleteForever className="text-2xl" />
				</span>
				<p className="break-all ">{props.name} </p>
			</div>
		</div>
	);
};

export default BankWord;
