import { useEffect, useState } from "react";
import InventoryGrid from "./InventoryGrid";
import LoadingCircle from "../utility/LoadingCircle";
import FilterPanel from "./FilterPanel";
import ProductModal from "./ProductModal";
import InventoryPagination from "./InventoryPagination";

const Shop = ({ api, token, odooId, odooModel, projectName }) => {
	const [loading, setLoading] = useState(false);
	const [inventory, setInventory] = useState([]);
	const MAX_PER_PAGE = 20;
	const [totalPages, setTotalPages] = useState(0);
	const [categories, setCategories] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [viewProduct, setViewProduct] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState({});
	const [showFilters, setShowFilters] = useState(false);
	const [page, setPage] = useState(1);
	const [categoriesQuery, setCategoriesQuery] = useState("all");
	const [searchText, setSearchText] = useState("");
	// useEffect(() => {
	// 	document.title = "Shop";
	// }, []);

	useEffect(() => {
		if (api === "" || token === "") {
			return;
		}

		const controller = new AbortController();
		const signal = controller.signal;
		setLoading(true);
		fetch(api + `/shop/inventory?page=${page}&perpage=${MAX_PER_PAGE}&categories=${categoriesQuery}&search=${encodeURIComponent(searchText)}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			mode: "cors",
			signal,
		})
			.then((res) => {
				if (res.ok) {
					return res.json();
				} else {
				}
			})
			.then((res) => {
				if (res) {
					setInventory(res.records);
					if (res.totalCount === 0) {
						setTotalPages(0);
					} else {
						setTotalPages(Math.ceil(res.totalCount / MAX_PER_PAGE));
					}
					setTotalCount(res.totalCount);
					setCategories(res.categories);
					setShowFilters(false);
				}
				setLoading(false);
			})
			.catch((err) => {});

		return () => {
			controller.abort();
		};
	}, [api, page, token, categoriesQuery, searchText]);

	const paginationComponent = (
		<div className="flex flex-col items-end justify-center px-4 pb-4">
			<InventoryPagination totalPages={totalPages} page={page} setPage={setPage} />

			<p className="text-sm">{`Results: ${totalCount ? totalCount : 0}`}</p>
		</div>
	);

	return (
		<div>
			{loading ? (
				<div className="fixed top-0 right-0 left-0 bottom-0 bg-black/50 z-[100] flex justify-center items-center">
					<div className="flex flex-col items-center justify-center border border-black p-4 bg-white">
						<LoadingCircle />
						<p>Loading Shop...</p>
					</div>
				</div>
			) : null}
			{viewProduct && selectedProduct ? (
				<ProductModal
					selectedProduct={selectedProduct}
					setViewProduct={setViewProduct}
					api={api}
					token={token}
					odooId={odooId}
					odooModel={odooModel}
					projectName={projectName}
				/>
			) : null}
			<div className="flex flex-col lg:flex-row">
				{/* Filter Button for Small Screens */}
				<div className="block lg:hidden w-full">
					<button className="btn btn-primary w-full mb-4 bg-blue-500 hover:bg-blue-600 text-white p-4" onClick={() => setShowFilters(true)}>
						Filter
					</button>
				</div>

				<div className="hidden lg:flex w-1/4">
					<div className="w-full">
						<FilterPanel
							totalPages={totalPages}
							categories={categories}
							categoriesQuery={categoriesQuery}
							setCategoriesQuery={setCategoriesQuery}
							paginationComponent={paginationComponent}
							searchText={searchText}
							setSearchText={setSearchText}
						/>
					</div>
				</div>

				<div className="w-full lg:w-3/4">
					<InventoryGrid
						inventory={inventory}
						setSelectedProduct={setSelectedProduct}
						setViewProduct={setViewProduct}
						loading={loading}
						paginationComponent={paginationComponent}
					/>
					{paginationComponent}
				</div>

				<div className={`fixed inset-0 z-50 bg-white flex flex-col ${showFilters ? "" : "hidden"}`}>
					<div className="flex items-center justify-between p-4 border-b bg-gray-200">
						<h2 className="text-lg font-semibold">Filters</h2>
						<button className="text-gray-500" onClick={() => setShowFilters(false)}>
							Close
						</button>
					</div>
					<div className="flex-1 overflow-auto">
						<FilterPanel
							totalPages={totalPages}
							categories={categories}
							categoriesQuery={categoriesQuery}
							setCategoriesQuery={setCategoriesQuery}
							paginationComponent={paginationComponent}
							searchText={searchText}
							setSearchText={setSearchText}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Shop;
