import {useCallback, useEffect, useState} from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

export let globalPageIndex = "0";

const Button2= ({ content, onClick, active, disabled }) => {
    return (
        <button
            className={`flex flex-col cursor-pointer items-center justify-center w-9 h-9 shadow-[0_4px_10px_rgba(0,0,0,0.03)] text-sm font-normal transition-colors rounded-lg
      ${active ? "bg-blue-500 text-white" : "text-blue-500"}
      ${
                !disabled
                    ? "bg-white hover:bg-blue-500 hover:text-white"
                    : "text-blue-300 bg-white cursor-not-allowed"
            }
      `}
            onClick={onClick}
            disabled={disabled}
        >
            {content}
        </button>
    );
}

const PaginationNav1 = ({
                            gotoPage,
                            canPreviousPage,
                            canNextPage,
                            pageCount,
                            pageIndex,
                        }) => {

    const renderPageLinks = useCallback(() => {
        if (pageCount === 0) return null;
        const visiblePageButtonCount = 3;
        let numberOfButtons =
            pageCount < visiblePageButtonCount ? pageCount : visiblePageButtonCount;
        const pageIndices = [pageIndex];
        numberOfButtons--;
        [...Array(numberOfButtons)].forEach((_item, itemIndex) => {
            const pageNumberBefore = pageIndices[0] - 1;
            const pageNumberAfter = pageIndices[pageIndices.length - 1] + 1;

            if (
                pageNumberBefore >= 0 &&
                (itemIndex < numberOfButtons / 2 || pageNumberAfter > pageCount - 1)
            ) {
                pageIndices.unshift(pageNumberBefore);
            } else {
                pageIndices.push(pageNumberAfter);
            }
        });

        return pageIndices.map((pageIndexToMap) => (
            <li key={pageIndexToMap}>
                <Button2
                    content={pageIndexToMap + 1}
                    onClick={() => gotoPage(pageIndexToMap)}
                    active={pageIndex === pageIndexToMap}
                />
            </li>
        ));
    }, [pageCount, pageIndex]);
    return (
        <ul className="flex gap-2">
            <li>
                <Button2
                    content={
                        <div className="flex ml-1 text-center">
                            <FaChevronLeft size="0.6rem" />
                            <FaChevronLeft size="0.6rem" className="-translate-x-1/2" />
                        </div>
                    }
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                />
            </li>
            {renderPageLinks()}
            <li>
                <Button2
                    content={
                        <div className="flex ml-1 text-center">
                            <FaChevronRight size="0.6rem" />
                            <FaChevronRight size="0.6rem" className="-translate-x-1/2" />
                        </div>
                    }
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                />
            </li>
        </ul>
    );
}

const PaginationNav1Presentation = (pageCountUpdate, searchQuery, globalPage, setGlobalPage) => {

    const [oldSearch, setOldSearch] = useState(" ");
    const [newSearch, setNewSearch] = useState(0);
    const [pageIndex, setPageIndex] = useState(0);
    const pageCount = pageCountUpdate;

    useEffect(() => {
        if (newSearch === 1){
            setPageIndex(0);
            setNewSearch(0);
            setOldSearch(searchQuery);
        }

    }, [newSearch]);

    useEffect(() => {
        if (searchQuery !== oldSearch) {
            setNewSearch(1);
            globalPageIndex = 0;
            setGlobalPage(0);
        }else{
            setNewSearch(0);
        }

    }, [searchQuery]);


    useEffect(() => {
        setGlobalPage(pageIndex);
    }, [pageIndex]);


    return (
        <div className="flex gap-3 flex-wrap p-6 py-12 text-center">
            <PaginationNav1
                gotoPage={setPageIndex}
                canPreviousPage={pageIndex > 0}
                canNextPage={pageIndex < pageCount - 1}
                pageCount={pageCount}
                pageIndex={pageIndex}
            />
        </div>
    );
}

export { PaginationNav1Presentation };