import { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import LoadingCircle from "../utility/LoadingCircle";
import { PaginationNav1Presentation } from "../project/Pagination";

const TemplateSelection = ({ api, token, isAdmin }) => {
	const params = useParams();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [usedTemplates, setUsedTemplates] = useState([]);
	const [unusedTemplates, setUnusedTemplates] = useState([]);
	const [displayedTemplates, setDisplayedTemplates] = useState([]);
	const [odooData, setOdooData] = useState();
	const [creatingProject, setCreatingProject] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const [filterText, setFilterText] = useState("");
	const [modelLabel, setModelLabel] = useState("");
	const [pageCount, setPageCount] = useState(0);
	const [firstIndex, setFirstIndex] = useState(0);
	const [lastIndex, setLastIndex] = useState(0);
	const [globalPage, setGlobalPage] = useState(0);
	const [schedulingId, setSchedulingId] = useState(-1);

	useEffect(() => {
		document.title = "Template Selection";
	}, []);

	useEffect(() => {
		if (searchParams.get("schedulingid") && !isNaN(searchParams.get("schedulingid"))) {
			setSchedulingId(searchParams.get("schedulingid"));
		}
	}, [searchParams]);

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		setLoading(true);
		let portfolioParam = searchParams.get("portfolio") ? searchParams.get("portfolio") : "";
		fetch(
			api +
				"/project/odoo/template-select/" +
				params.odooid +
				"?model=" +
				searchParams.get("model") +
				"&contract=" +
				searchParams.get("contract") +
				"&portfolio=" +
				portfolioParam,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: token,
				},
				signal,
			}
		)
			.then((res) => {
				setLoading(false);
				if (res.ok) {
					return res.json();
				}
				// need to add error message here
			})
			.then((res) => {
				if (res) {
					// need to add validation here
					if (res.odooData.length === 0) {
					} else {
						setOdooData(res.odooData[0]);
						setUsedTemplates(res.projects);
						setUnusedTemplates(res.templates);
						setDisplayedTemplates([...res.projects, ...res.templates]);
						setModelLabel(res.label);
					}
				}
			});

		return () => {
			controller.abort();
		};
	}, [api, token, params.odooid, searchParams, navigate]);

	const createProjectFromTemplate = (e, theTemplateId) => {
		if (e) {
			e.preventDefault();
		}
		if (creatingProject) {
			return;
		}
		setCreatingProject(true);
		fetch(api + "/project", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: token,
			},
			body: JSON.stringify({
				odoo_id: odooData.id,
				template_version_id: theTemplateId,
				name: odooData.name ? odooData.name : odooData.x_name,
				model: searchParams.get("model"),
			}),
		})
			.then((res) => {
				if (res.ok) {
					return res.json();
				} else {
					setCreatingProject(false);
				}
			})
			.then((res) => {
				if (res) {
					updateOdooSchedulingRecord(res.id);
					navigate("/project/" + res.id);
				}
			});
	};

	useEffect(() => {
		let copyArray = [...usedTemplates, ...unusedTemplates];
		if (filterText === "") {
			setDisplayedTemplates(copyArray);
		} else {
			copyArray = copyArray.filter((templateData) => templateData.name.toLowerCase().includes(filterText));
			setDisplayedTemplates(copyArray);
		}
	}, [filterText, usedTemplates, unusedTemplates]);

	useEffect(() => {
		setPageCount(Math.ceil(displayedTemplates.length / 10));
		setFirstIndex(globalPage * 10);
		setLastIndex(10 * (globalPage + 1) < displayedTemplates.length ? 10 * (globalPage + 1) - 1 : displayedTemplates.length - 1);
	}, [displayedTemplates, globalPage, firstIndex, lastIndex]);

	const updateOdooSchedulingRecord = async (projectId) => {
		if (schedulingId && !isNaN(schedulingId) && schedulingId !== -1) {
			fetch(api + "/scheduling/" + schedulingId, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: token,
				},
				body: JSON.stringify({
					projectId,
					name: odooData.name ? odooData.name : odooData.x_name,
					model: searchParams.get("model"),
				}),
			});
		}
	};

	return (
		<div className="p-4 flex flex-col items-center">
			{creatingProject ? (
				<div className="text-black fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black/50 z-50">
					<div className="bg-white flex flex-col items-center justify-center p-4 rounded-lg ">
						<LoadingCircle />
						<p>Creating project...</p>
					</div>
				</div>
			) : null}
			{odooData ? (
				<div className="text-center text-3xl p-4 sticky top-0 bg-white w-full">
					<p>{odooData.name ? odooData.name : odooData.x_name}</p>
				</div>
			) : null}
			<div className="flex flex-col gap-4 w-full md:w-3/5">
				<form onSubmit={(e) => e.preventDefault()} className="flex w-full justify-center items-center">
					<input
						type="text"
						className="border rounded-lg border-black p-2 w-full "
						placeholder="Filter by template name"
						value={filterText}
						onChange={(e) => setFilterText(e.target.value)}
						required
					/>
				</form>
				<div>
					<p className="text-2xl bg-gray-300 text-center p-3 border border-black p-4 rounded-t-lg">{`${modelLabel} Templates`}</p>
					<div className="gap-3 w-full border border-black p-2 hidden md:flex justify-between bg-black text-white md:text-center">
						<p className="md:basis-1/2">Name</p>
						<p className="md:basis-1/2">Actions</p>
					</div>
					{displayedTemplates.length > 0 ? (
						displayedTemplates.map((templateData, index) => {
							if (index <= lastIndex && index >= firstIndex) {
								return (
									<div
										key={templateData.id ? templateData.id : templateData.template_id}
										className={
											"gap-3 border border-black p-3 flex flex-col md:flex-row md:text-center justify-between items-center " +
											(index % 2 === 0 ? "bg-white" : "bg-gray-200") +
											(index === displayedTemplates.length - 1 ? " rounded-b-lg " : "")
										}>
										<p className="text-l md:basis-1/2">
											{templateData.name}
											{" - v" + templateData.version}
										</p>
										{templateData.project_id ? (
											<div className="md:basis-1/2">
												<button
													onClick={async () => {
														updateOdooSchedulingRecord(templateData.project_id);
														navigate("/project/" + templateData.project_id);
													}}
													className="text-blue-600 underline">
													Use
												</button>
											</div>
										) : (
											<div className="md:basis-1/2">
												<button
													onClick={(e) => createProjectFromTemplate(e, templateData.id)}
													className=" bg-blue-600 text-white rounded-lg p-2">
													Create
												</button>
											</div>
										)}
									</div>
								);
							}
						})
					) : loading ? (
						<div className="flex flex-col items-center justify-center w-full my-2">
							<LoadingCircle />
							<p>Loading Templates</p>
						</div>
					) : (
						<div className="border border-black p-3  bg-white rounded-b-lg">
							<p className="text-center">0 results found</p>
						</div>
					)}
				</div>
			</div>
			<div className="text-center">
				<span className="text-sm text-gray-700">
					Showing
					<span className="font-semibold text-gray-900"> {globalPage * 10 + 1}</span> to
					<span className="font-semibold text-gray-900">
						{" "}
						{10 * (globalPage + 1) < displayedTemplates.length ? 10 * (globalPage + 1) : displayedTemplates.length}
					</span>{" "}
					of
					<span className="font-semibold text-gray-900"> {displayedTemplates.length}</span> Entries
				</span>

				<nav aria-label="Template Select Navigation">
					<ul className="inline-flex items-center -space-x-px h-16">{PaginationNav1Presentation(pageCount, "", globalPage, setGlobalPage)}</ul>
				</nav>
			</div>
		</div>
	);
};

export default TemplateSelection;
