import { useEffect, useState } from "react";

const DateInput = ({ postToDB, putToDB, initialFieldData, fieldDataExists, readOnly, odooData, hasOdooMapping }) => {
	const [currentDate, setCurrentDate] = useState("");

	useEffect(() => {
		let newValue = "";
		if (fieldDataExists) {
			newValue = initialFieldData.value;
		}

		if (hasOdooMapping) {
			if (odooData && odooData.odoo_value !== "failedtogetdataforpmscode400") {
				newValue = odooData.odoo_value;
			} else if (odooData && odooData.odoo_value === "failedtogetdataforpmscode400") {
				if (fieldDataExists) {
					newValue = initialFieldData.value;
				}
			} else {
				newValue = "";
			}
		}
		setCurrentDate(newValue);
	}, [initialFieldData, fieldDataExists, odooData, hasOdooMapping]);

	const sendToDb = () => {
		if (fieldDataExists === false && currentDate !== "") {
			postToDB(currentDate);
		} else if (fieldDataExists) {
			putToDB(currentDate);
		}
	};

	return (
		<form>
			<input
				type="date"
				value={currentDate}
				onChange={(e) => setCurrentDate(e.target.value)}
				onBlur={() => sendToDb()}
				className="rounded-lg border border-2 p-2 border-gray w-full"
				readOnly={readOnly}
			/>
		</form>
	);
};

export default DateInput;
