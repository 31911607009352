import React from "react";

const TimeSince = ({ date }) => {
	const calculateTimeSince = () => {
		const now = new Date();
		const past = new Date(date);
		const seconds = Math.floor((now - past) / 1000);

		let interval = Math.floor(seconds / 31536000); // seconds in a year

		if (interval > 1) {
			return interval + " years";
		} else if (interval === 1) {
			return "1 year";
		}
		interval = Math.floor(seconds / 2592000); // seconds in a month
		if (interval > 1) {
			return interval + " months";
		} else if (interval === 1) {
			return "1 month";
		}
		interval = Math.floor(seconds / 86400); // seconds in a day
		if (interval > 1) {
			return interval + " days";
		} else if (interval === 1) {
			return "1 day";
		}
		interval = Math.floor(seconds / 3600); // seconds in an hour
		if (interval > 1) {
			return interval + " hours";
		} else if (interval === 1) {
			return "1 hour";
		}
		interval = Math.floor(seconds / 60); // seconds in a minute
		if (interval > 1) {
			return interval + " minutes";
		} else if (interval === 1) {
			return "1 minute";
		}
		return seconds === 1 ? "1 second" : seconds + " seconds";
	};

	const timeSince = calculateTimeSince();

	return <p className="text-sm">{timeSince + " ago"}</p>;
};

export default TimeSince;
