import { useEffect, useState } from "react";

const TextAreaInput = ({ initialFieldData, fieldDataExists, postToDB, putToDB, readOnly, odooData, hasOdooMapping }) => {
	const [currentValue, setCurrentValue] = useState("");

	useEffect(() => {
		let newValue = "";
		if (fieldDataExists) {
			newValue = initialFieldData.value;
		}

		if (hasOdooMapping) {
			if (odooData && odooData.odoo_value !== "failedtogetdataforpmscode400") {
				newValue = odooData.odoo_value;
			} else if (odooData && odooData.odoo_value === "failedtogetdataforpmscode400") {
				if (fieldDataExists) {
					newValue = initialFieldData.value;
				}
			} else {
				newValue = "";
			}
		}
		setCurrentValue(newValue);
	}, [initialFieldData, fieldDataExists, odooData, hasOdooMapping]);

	const sendToDb = () => {
		if (fieldDataExists === false && currentValue !== "") {
			postToDB(currentValue);
		} else if (fieldDataExists) {
			putToDB(currentValue);
		}
	};

	return (
		<form>
			<textarea
				type="text"
				rows={4}
				value={currentValue}
				onChange={(e) => setCurrentValue(e.target.value)}
				onBlur={() => sendToDb()}
				className="rounded-lg border border-2 p-2 border-gray w-full whitespace-pre-wrap"
				readOnly={readOnly}
			/>
		</form>
	);
};

export default TextAreaInput;
