import { useEffect, useState } from "react";
import { BsTrash } from "react-icons/bs";
import { IoAdd } from "react-icons/io5";

const SelectedTemplateList = ({ templateData, dataToSave, setDataToSave, reportId, api, token }) => {
	const [templateToAdd, setTemplateToAdd] = useState("");
	const [allTemplateNames, setAllTemplateNames] = useState({});
	const [selectedTemplateNames, setSelectedTemplateNames] = useState([]);

	useEffect(() => {
		setAllTemplateNames(Object.keys(templateData));
	}, [templateData]);

	useEffect(() => {
		if (typeof dataToSave === "undefined" || dataToSave === null || dataToSave.templateData === null || typeof dataToSave.templateData === "undefined") {
			return;
		}
		let potentiallySelectedTemplates = Object.keys(dataToSave.templateData);
		let newSelectedTemplateNames = [];
		for (let i = 0; i < potentiallySelectedTemplates.length; i++) {
			if (dataToSave.templateData[potentiallySelectedTemplates[i]].deleted === false) {
				newSelectedTemplateNames.push(potentiallySelectedTemplates[i]);
			}
		}
		setSelectedTemplateNames(newSelectedTemplateNames);
	}, [dataToSave]);

	const addTemplate = (e) => {
		e.preventDefault();
		let selectedTemplates = Object.keys(dataToSave.templateData);
		let alreadyIncluded = selectedTemplates.includes(templateToAdd);
		if (!alreadyIncluded) {
			let newSaveTemplateData = { ...dataToSave.templateData };
			newSaveTemplateData[templateToAdd] = {
				deleted: false,
				from_server: false,
				includedFields: {},
				includedSections: [],
				id: -1,
			};
			setDataToSave((prev) => {
				return { ...prev, templateData: newSaveTemplateData };
			});
		} else {
			let newSaveTemplateData = { ...dataToSave.templateData };
			newSaveTemplateData[templateToAdd].deleted = false;
			setDataToSave((prev) => {
				return {
					...prev,
					templateData: newSaveTemplateData,
				};
			});
		}
		setTemplateToAdd("");
	};

	const removeTemplate = (e, templateToRemove) => {
		e.preventDefault();
		let fromServer = dataToSave.templateData[templateToRemove].from_server;
		let newTemplateDataToSave = { ...dataToSave.templateData };

		if (fromServer) {
			newTemplateDataToSave[templateToRemove].deleted = true;
			let includedSections = [...newTemplateDataToSave[templateToRemove].includedSections];
			for (let i = includedSections.length - 1; i >= 0; i--) {
				if (includedSections[i].from_server === true) {
					includedSections[i].deleted = true;
				} else {
					includedSections.splice(i, 1);
				}
			}

			let fieldKeys = Object.keys(newTemplateDataToSave[templateToRemove].includedFields);
			let includedFields = { ...newTemplateDataToSave[templateToRemove].includedFields };
			for (let i = 0; i < fieldKeys.length; i++) {
				for (let j = includedFields[fieldKeys[i]].length - 1; j >= 0; j--) {
					if (includedFields[fieldKeys[i]][j].from_server) {
						includedFields[fieldKeys[i]][j].deleted = true;
					} else {
						includedFields[fieldKeys[i]].splice(j, 1);
					}
				}
			}
			newTemplateDataToSave[templateToRemove].includedSections = includedSections;
			newTemplateDataToSave[templateToRemove].includedFields = includedFields;
		} else {
			delete newTemplateDataToSave[templateToRemove];
		}

		setDataToSave((prev) => {
			return {
				...prev,
				templateData: newTemplateDataToSave,
			};
		});
	};

	return (
		<>
			<div className="mb-4">
				<h3 className="text-xl font-bold">Select Templates</h3>
				<p className="text-sm">Choose templates to add to report.</p>
			</div>
			<form onSubmit={(e) => addTemplate(e)} className="flex">
				<input
					list="templateNames"
					className="border border-rounded border-2 border-black p-1 w-full rounded-l-lg"
					value={templateToAdd}
					onChange={(e) => {
						setTemplateToAdd(e.target.value);
					}}
					required
				/>
				<datalist id="templateNames">
					{allTemplateNames && allTemplateNames.length > 0
						? allTemplateNames.map((mapData, index) => {
								return (
									<option value={mapData} key={index}>
										{mapData}
									</option>
								);
						  })
						: null}
				</datalist>
				<button className="p-2 border-2 border-black rounded-r-lg">
					<IoAdd />
				</button>
			</form>
			<div className="border-2 border-black mt-4 max-h-[33vh] h-[33vh] overflow-y-scroll rounded-lg">
				{selectedTemplateNames && selectedTemplateNames.length > 0 ? (
					selectedTemplateNames.map((mapData, index) => {
						return (
							<div key={index}>
								<div className={`p-4 flex items-center gap-2 ${index !== 0 ? "  border-t border-black" : ""}`}>
									<BsTrash className="cursor-pointer" color="red" onClick={(e) => removeTemplate(e, mapData)} />
									<p>{mapData}</p>
								</div>
							</div>
						);
					})
				) : (
					<p className="text-center p-4">Add templates to get started</p>
				)}
			</div>
		</>
	);
};

export default SelectedTemplateList;
