import React, { useEffect, useRef, useState } from "react";
import { FiUpload } from "react-icons/fi";
import { FcCancel } from "react-icons/fc";
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import LoadingCircle from "../utility/LoadingCircle";

function ImageDropArea({ api, id, setUploadedImages, setLoadingFiles, type, servicePath, setServiceBasePath, setValid404Triggered, serverKey }) {
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const [currentUploading, setCurrentUploading] = useState(null);
	const [recentlyUploaded, setRecentlyUploaded] = useState([]);
	const [showHistory, setShowHistory] = useState(false);
	const [isHovering, setIsHovering] = useState(false);
	const fileInputRef = useRef(null);

	const preventDefaults = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	useEffect(() => {
		setLoadingFiles(true);
		const controller = new AbortController();
		fetch(`${api}/dropbox/${type}/${id}?key=${serverKey}${type === "service" ? "&path=" + encodeURIComponent(servicePath) : ""}`, {
			method: "GET",
			signal: controller.signal,
		})
			.then((res) => {
				if (res.ok) {
					return res.json();
				}
				if (res.status === 409) {
					setValid404Triggered(true);
				}
			})
			.then((data) => {
				if (data) {
					if (data.entries) {
						setUploadedImages(data.entries);
					}
					if (type === "service") {
						setServiceBasePath(data.basePath);
					}
					setLoadingFiles(false);
				}
			});

		return () => {
			controller.abort();
		};
	}, [api, id, setUploadedImages, setLoadingFiles, type, servicePath, setServiceBasePath, setValid404Triggered, serverKey]);

	useEffect(() => {
		function isImageFile(filename) {
			const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp", ".tiff", ".webp"];

			let extension = filename.split(".");
			extension = extension[extension.length - 1];

			return imageExtensions.includes("." + extension.toLowerCase());
		}

		const uploadImage = async () => {
			if (currentUploading) {
				const formData = new FormData();
				formData.append("image", currentUploading);
				let name = new Date().toISOString();
				formData.append("timestamp", name);
				formData.append("key", serverKey);

				const imageUrl = URL.createObjectURL(currentUploading);
				let modifiedRecentlyUploaded = [...recentlyUploaded];
				try {
					const response = await fetch(`${api}/dropbox/upload/${type}/${id}`, {
						method: "POST",
						body: formData,
					});

					modifiedRecentlyUploaded.pop();
					if (response.ok) {
						const result = await response.json();
						if (result.type && result.type === "file") {
							setUploadedImages((prev) => [...prev, result]);
							modifiedRecentlyUploaded.push({
								thumbnail: imageUrl,
								metadata: { name: result.name },
								local: true,
								status: `Upload successful! File Path: ${result.pathToImage}`,
								failed: false,
							});
						} else {
							let splitData = result.pathToImage.split("/");
							if (!isImageFile(result.pathToImage)) {
								setUploadedImages((prev) => [
									...prev,
									{ type: "file", thumbnail: imageUrl, metadata: { name: splitData[splitData.length - 1] }, local: true },
								]);
							} else {
								setUploadedImages((prev) => [
									...prev,
									{ thumbnail: imageUrl, metadata: { name: splitData[splitData.length - 1] }, local: true },
								]);
							}
							modifiedRecentlyUploaded.push({
								thumbnail: imageUrl,
								metadata: { name: splitData[splitData.length - 1] },
								local: true,
								status: `Upload successful! File Path: ${result.pathToImage}`,
								failed: false,
							});
						}
					} else {
						modifiedRecentlyUploaded.push({
							thumbnail: imageUrl,
							metadata: { name: name },
							local: true,
							status: `Error: upload failed`,
							failed: true,
						});
					}
				} catch (error) {
					modifiedRecentlyUploaded.push({
						thumbnail: imageUrl,
						metadata: { name: name },
						local: true,
						status: `Error: upload failed`,
						failed: true,
					});
				} finally {
					setRecentlyUploaded(modifiedRecentlyUploaded);
					setCurrentUploading(null);
				}
			}
		};

		if (currentUploading) {
			uploadImage();
			setShowHistory(true);
		}
	}, [api, currentUploading, id, recentlyUploaded, serverKey, setUploadedImages, type]);

	useEffect(() => {
		if (uploadedFiles.length > 0 && !currentUploading) {
			setCurrentUploading(uploadedFiles[0]);
			setRecentlyUploaded((prev) => [
				...prev,
				{
					thumbnail: "",
					metadata: { name: "uploading..." },
					local: true,
					status: `loading`,
					failed: false,
				},
			]);
			setUploadedFiles((prevImages) => prevImages.slice(1));
		}
	}, [uploadedFiles, currentUploading]);

	const handleDrop = (e) => {
		preventDefaults(e);
		const files = e.dataTransfer.files;
		setIsHovering(false);
		if (files && files.length > 0) {
			const imageFiles = Array.from(files).filter((file) => file.type.startsWith("image/"));
			const nonImageFiles = Array.from(files).filter((file) => file.type.startsWith("image/") === false);
			let toShow = [...Array.from(imageFiles)];
			if (type !== "fleet") {
				toShow.push(...nonImageFiles);
			}
			setUploadedFiles((prevImages) => [...prevImages, ...Array.from(toShow)]);
			if (nonImageFiles.length > 0 && type === "fleet") {
				alert("Only images are allowed");
			}
		}
	};

	const handleFileSelect = (e) => {
		preventDefaults(e);
		let files = e.target.files;

		if (files && files.length > 0) {
			const imageFiles = Array.from(files).filter((file) => file.type.startsWith("image/"));
			const nonImageFiles = Array.from(files).filter((file) => file.type.startsWith("image/") === false);

			let toShow = [...Array.from(imageFiles)];
			if (type !== "fleet") {
				toShow.push(...nonImageFiles);
			}
			setUploadedFiles((prevImages) => [...prevImages, ...Array.from(toShow)]);
			if (nonImageFiles.length > 0 && type === "fleet") {
				alert("Only images are allowed");
			}
		}
	};

	const handleDragEnter = (e) => {
		preventDefaults(e);
		setIsHovering(true);
	};
	const handleDragOver = (e) => {
		preventDefaults(e);
		setIsHovering(true);
	};
	const handleDragLeave = (e) => {
		preventDefaults(e);
		setIsHovering(false);
	};
	const handleClick = () => {
		fileInputRef.current.click();
	};

	if (type === "service") {
		return;
	}

	return (
		<>
			<div
				id="drop-area"
				className={`flex justify-center items-center flex-col gap-2 border cursor-pointer select-none ${
					isHovering ? "border-black bg-gray-100" : "border-dashed border-gray-400 bg-gray-50"
				} `}
				onDragEnter={handleDragEnter}
				onDragOver={handleDragOver}
				onDragLeave={handleDragLeave}
				onDrop={handleDrop}
				onClick={handleClick}
				style={{ height: "200px", textAlign: "center", padding: "10px" }}>
				<input type="file" ref={fileInputRef} style={{ display: "none" }} multiple onChange={handleFileSelect} accept="image/*" />
				<FiUpload size={isHovering ? 75 : 70} color={isHovering ? "#0061FE" : "gray"} />
				<p className={`${isHovering ? "text-lg" : "text-base"}`}>Drag and drop images here</p>
			</div>
			{showHistory ? (
				<div className="fixed bottom-0 text-white right-0  bg-white bg-opacity-90 border border-black p-1 shadow-md bg-black">
					<div className="mb-2 flex justify-between items-center">
						<p className="text-lg  text-start">Upload History</p>
						<div className="flex justify-center items-center cursor-pointer mr-2">
							<ImCross
								onClick={() => {
									setShowHistory(false);
								}}
								size={20}
							/>
						</div>
					</div>
					{recentlyUploaded && recentlyUploaded.length > 0 ? (
						<div className="h-72 overflow-y-auto min-w-[40vh] max-w-[100vh] md:max-w-[50vw]">
							<div className="flex flex-col gap-1">
								{recentlyUploaded.map((img, index) => (
									<div className="flex gap-2  items-center  p-1" key={index}>
										<p>
											{img.status === "loading" ? (
												<LoadingCircle />
											) : img.failed ? (
												<FcCancel size={25} />
											) : (
												<FaCheck size={25} color="green" />
											)}
										</p>
										{/* 
								<img key={index} src={img.thumbnail} alt={`Uploaded-${index}`} className="w-10 h-10 border border-black" /> */}
										<div className="text-start">
											{img.status === "loading" ? null : <p>{img.metadata.name}</p>}
											<p>Status: {img.status === "loading" ? "Uploading..." : img.failed ? "Failed" : "Uploaded"}</p>
										</div>
									</div>
								))}
							</div>
						</div>
					) : (
						<div className="h-72 overflow-y-auto min-w-[40vh] max-w-[100vh] md:max-w-[50vw] flex justify-center items-center">
							<p>Upload a photo to get started</p>
						</div>
					)}
				</div>
			) : (
				<div
					className="fixed bottom-0 text-white right-0 w-96 bg-white bg-opacity-90 border border-black p-1 shadow-md bg-black cursor-pointer"
					onClick={() => setShowHistory(true)}>
					Show Upload History
				</div>
			)}
		</>
	);
}

export default ImageDropArea;
