import { useEffect, useState } from "react";
import { BsExclamationCircleFill, BsSearch } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import WordBankList from "./WordBankList";

const WordBank = (props) => {
	const navigate = useNavigate();
	const [filterWord, setFilterWord] = useState("");
	const [fieldWords, setFieldWords] = useState([]);
	const [sectionWords, setSectionWords] = useState([]);
	const [errorMessage, setErrorMessage] = useState("");
	const [showFields, setShowFields] = useState(true);
	const [showSections, setShowSections] = useState(true);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		document.title = "Word Bank";
	}, []);

	useEffect(() => {
		if (props.api === "" || props.token === "") {
			return;
		}
		const controller = new AbortController();
		const signal = controller.signal;
		setIsLoading(true);
		fetch(props.api + "/wordbank", {
			method: "GET",
			mode: "cors",
			headers: {
				Authorization: props.token,
				"Content-Type": "application/json",
			},
			signal,
		})
			.then((res) => {
				if (res.ok) {
					return res.json();
				} else {
					if (props.isPublicStorage && res.status === 403) {
						navigate("/dashboard");
					}
					setIsLoading(false);
				}
			})
			.then((res) => {
				if (res) {
					setIsLoading(false);
					setFieldWords(res.fieldWords);
					setSectionWords(res.sectionWords);
				}
			})
			.catch((err) => {});

		return () => {
			controller.abort();
		};
	}, [navigate, props.api, props.isPublicStorage, props.token, props.url]);

	return (
		<div className="p-4">
			<h1 className="text-3xl text-center mb-4">Word Bank</h1>
			<form onSubmit={(e) => e.preventDefault()} className="w-full flex justify-center items-center mb-4 ">
				<input
					className="rounded-l-lg p-1 border border-black text-black w-full md:w-3/5"
					type="text"
					id="newword"
					name="newword"
					placeholder="Look for word"
					value={filterWord}
					onChange={(e) => {
						setFilterWord(e.target.value);
					}}
				/>
				<label htmlFor="newword">
					<button className="border border-black p-2" onClick={() => setFilterWord("")}>
						<MdClose color="red" />
					</button>
				</label>
				<label htmlFor="newword">
					<button className="border rounded-r-lg border-black p-2">
						<BsSearch className="" />
					</button>
				</label>
			</form>
			<div className="flex flex-col gap-4 justify-center items-center  w-full">
				{errorMessage !== "" ? (
					<div className="flex items-center gap-2 bg-rose-100 p-2 mt-2 rounded-lg">
						<BsExclamationCircleFill color="red" className="text-3xl" />
						<p>{errorMessage}</p>
					</div>
				) : null}
				<div className="flex gap-3 justify-center w-full md:w-3/5">
					<div className="w-full flex justify-end">
						<button
							className={`${showFields ? "bg-gray-600" : "bg-blue-600"} text-white rounded-lg p-3`}
							onClick={() => setShowFields((prevState) => !prevState)}>
							{showFields ? "Hide Fields" : "Show Fields"}
						</button>
					</div>
					<div className="w-full">
						<button
							className={` ${showSections ? "bg-gray-600" : "bg-blue-600"} text-white rounded-lg p-3`}
							onClick={() => setShowSections((prevState) => !prevState)}>
							{showSections ? "Hide Sections" : "Show Sections"}
						</button>
					</div>
				</div>
				<div className="flex gap-3 w-full md:w-3/5">
					<WordBankList
						wordList={fieldWords}
						api={props.api}
						token={props.token}
						setWordList={setFieldWords}
						isLoading={isLoading}
						filterWord={filterWord}
						showList={showFields}
						isField={true}
						listName={"Field List"}
						setErrorMessage={setErrorMessage}
					/>
					<WordBankList
						wordList={sectionWords}
						api={props.api}
						token={props.token}
						setWordList={setSectionWords}
						isLoading={isLoading}
						filterWord={filterWord}
						showList={showSections}
						isField={false}
						listName={"Section List"}
						setErrorMessage={setErrorMessage}
					/>
					{showFields === false && showSections === false ? (
						<div className="w-full">
							<p className="text-center">- No option selected -</p>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default WordBank;
