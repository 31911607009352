const PillsSelector = ({ name, values, value, onChange, productId }) => (
	<div className="mt-1">
		<div className="flex items-center">
			<label className="font-bold text-lg mb-1">{name}</label>
			<div className="flex-grow border-b border-gray-400 ml-2"></div>
		</div>

		<div className="flex gap-2 mt-2 flex-wrap">
			{values.map((val) => (
				<div key={val.id}>
					<input
						type="radio"
						id={`${name}-${val.id}-${productId}`}
						name={name + "-" + productId}
						value={val.id}
						checked={value.toString() === val.id.toString()}
						onChange={onChange}
						className="hidden"
					/>
					<label
						htmlFor={`${name}-${val.id}-${productId}`}
						className={`px-4 py-2 border rounded-lg cursor-pointer ${
							value.toString() === val.id.toString() ? "bg-blue-500 text-white" : "bg-gray-200"
						}`}>
						{val.name}
					</label>
				</div>
			))}
		</div>
	</div>
);

export default PillsSelector;
