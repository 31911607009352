import { useEffect, useState } from "react";

const CloneSectionModal = (props) => {
	const [newName, setNewName] = useState("");
	const [sectionNameNotInList, setSectionNameNotInList] = useState(false);
	const [filteredWordBank, setFilteredWordBank] = useState([]);

	useEffect(() => {
		let tempFilteredBank = [];
		for (let i = 0; i < props.sectionWordBank.length; i++) {
			let foundDupe = false;
			for (let j = 0; j < props.sections.length; j++) {
				if (props.sections[j].name === props.sectionWordBank[i].name) {
					foundDupe = true;
				}
			}
			if (foundDupe === false) {
				tempFilteredBank.push(props.sectionWordBank[i]);
			}
		}
		setFilteredWordBank(tempFilteredBank);
	}, [props.sections, props.sectionWordBank]);

	const cloneSection = (e) => {
		e.preventDefault();
		if (props.api === "" || props.token === "" || newName === "") {
			return;
		}

		if (sectionNameNotInList) {
			return;
		}

		let newPosition = props.sections.length > 0 ? props.sections[props.sections.length - 1].position + 1 : 1;

		fetch(props.api + "/section/clone/" + props.section_id, {
			method: "post",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: props.token,
			},
			body: JSON.stringify({
				name: newName,
				position: newPosition,
				template_id: props.template_id,
			}),
		})
			.then((res) => {
				if (res.ok) {
					return res.json();
				} else {
					// put an error here
				}
			})
			.then((res) => {
				if (res) {
					props.setSections([
						...props.sections,
						{
							id: res.section_id,
							name: newName,
							position: newPosition,
							template_id: props.template_id,
						},
					]);

					props.setShowCloneModal(false);
				}
			});
	};

	const closeModal = (e) => {
		e.preventDefault();
		props.setShowCloneModal(false);
	};

	const checkNameInList = (e, name) => {
		e.preventDefault();
		for (let i = 0; i < filteredWordBank.length; i++) {
			if (filteredWordBank[i].name === name) {
				setSectionNameNotInList(false);
				return;
			}
		}
		setSectionNameNotInList(true);
	};

	return (
		<div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-black/50 z-50">
			<div className="bg-white  m-2 rounded-lg">
				<p className="p-5 text-3xl">Clone Section: {props.sectionName}?</p>
				<hr></hr>
				<form className="p-8" onSubmit={(e) => e.preventDefault()}>
					<label>
						Name:
						<input
							list="nameWordBank"
							className="border border-rounded border-2 p-2 w-full mb-4"
							value={newName}
							onChange={(e) => {
								setNewName(e.target.value);
								checkNameInList(e, e.target.value);
							}}
							required
						/>
						{props.sectionWordBank && filteredWordBank.length > 0 ? (
							<datalist id="nameWordBank">
								{props.sectionWordBank && filteredWordBank.length > 0
									? filteredWordBank.map((value) => {
											return (
												<option key={value.id} value={value.name}>
													{value.name}
												</option>
											);
									  })
									: null}
							</datalist>
						) : (
							<p className="text-red-500 mb-4">This template has used all available section names. Please add more to the word bank.</p>
						)}
						{sectionNameNotInList ? <p className="text-red-500 mb-4">Please select a value from the list</p> : null}
					</label>
					<div className="flex gap-2 justify-end">
						<button type="button" onClick={(e) => closeModal(e)} className="rounded-none bg-gray-500 p-2 text-white">
							Cancel
						</button>
						<button
							type="button"
							onClick={(e) => cloneSection(e)}
							className={`rounded-none ${filteredWordBank.length > 0 ? " bg-blue-500 text-white " : " bg-gray-700 text-white "}  p-2`}>
							Clone
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default CloneSectionModal;
