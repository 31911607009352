import { useEffect, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";

const ProjectErrorMessage = ({ message, setMessage }) => {
	const defaultTime = 2;
	const [timeRemaining, setTimeRemaining] = useState(2);

	useEffect(() => {
		if (!message || timeRemaining <= -1) {
			setMessage("");
			setTimeRemaining(defaultTime);
			return;
		}
		if (!message && timeRemaining > 0) {
			return;
		}
		const intervalId = setInterval(() => {
			setTimeRemaining(timeRemaining - 1);
		}, 1000);

		return () => {
			clearInterval(intervalId);
		};
	}, [message, setMessage, timeRemaining]);

	const preventPropagation = (e) => {
		e.preventPropagation();
	};

	const closeModal = (e) => {
		setMessage("");
	};

	return message !== "" ? (
		<div className="text-black fixed bottom-0 right-0 flex justify-center items-center z-50" onClick={closeModal}>
			<div className="bg-yellow-300 w-full m-2 rounded-lg border border-black" onClick={preventPropagation}>
				<div className="p-3 flex items-center gap-2">
					<AiOutlineInfoCircle size={30} />
					<p className="whitespace-pre-wrap">{message}</p>
				</div>
				<div className="relative h-2 w-full overflow-x-hidden bg-gray-300 rounded-b-lg">
					<div
						className={`rounded-b-lg  absolute h-2 w-full bg-black transition-all duration-500 ${timeRemaining < 1 ? "opacity-0" : ""}`}
						style={{ width: `${(timeRemaining / defaultTime) * 100}%` }}></div>
				</div>
			</div>
		</div>
	) : null;
};

export default ProjectErrorMessage;
