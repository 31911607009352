import { useState } from "react";
import ConditionalRender from "./ConditionalRenderer";
import { BsExclamationCircleFill } from "react-icons/bs";

const UpdateConditionalModal = ({ fields, selectedFieldName, setSelectedConditionals, selectedConditionals, setShowUpdateConditionalModal }) => {
    const [selectedConditionField, setSelectedConditionField] = useState("");
    const [conditionalFieldValue, setConditionalFieldValue] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const addToConditionals = (e) => {
        for (let i = 0; i < selectedConditionals.length; i++) {
            if (
                selectedConditionals[i].selectedConditionField === selectedConditionField &&
                selectedConditionals[i].conditionalFieldValue === conditionalFieldValue
                ) {
                    return;
                }
            }
            
            let foundName = "";
            let foundType = "";
            for (let j = 0; j < fields.length; j++) {
                if (fields[j].id.toString() === selectedConditionField.toString()) {
                    foundName = fields[j].name
                    foundType = fields[j].field_type;
                    break;
            }
        }

        if (selectedConditionals.conditionalFieldValue !== conditionalFieldValue
            ) {
           setSelectedConditionals(() => [
            {
                selectedConditionFieldName: foundName,
                selectedFieldType: foundType,
                selectedConditionField: selectedConditionField,
                conditionalFieldValue: conditionalFieldValue
            }
           ])
        }
    }

    return (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black/50 z-50">
            <div className="bg-white w-full md:w-1/2 m-2 rounded-lg">
                <p className="p-4 text-left font-bold text-3xl">Update Conditional</p>
                <hr></hr>
                <div className="p-4">
                    <p className="w-full">Display when:</p>
                    <select
                        className="border border-rounded border-2 p-2 w-full"
                        value={selectedConditionField}
                        onChange={(e) => {
                            setSelectedConditionField(e.target.value);
                            setConditionalFieldValue("");
                        }}>
                        <option value=""></option>  
                        {fields && fields.length > 0
                            ? fields.map((values) => {
                                for (let x = 0; x < selectedConditionals.length; x++) {
                                    if (values.name === selectedConditionals[x].selectedConditionFieldName) {
                                        return (
                                            <option value={values.id} key={values.id}>
                                            {values.field_type} - {values.name}
                                        </option>
                                    );
                                }
                            }
                                return null;
                            })
                        : null} 
                    </select>
                    {selectedConditionField && selectedConditionField !== -1 ? (
                        <ConditionalRender 
                            fields={fields}
                            conditionalFieldId={selectedConditionField}
                            conditionalFieldValue={conditionalFieldValue}
                            setConditionalFieldValue={setConditionalFieldValue}
                        />
                    ) : null}
                </div>
                <hr />
                <div className="p-4">
					<div>
						{errorMessage && errorMessage !== "" ? (
							<div className="flex items-center gap-2 bg-rose-100 p-2 mt-2 rounded-lg">
								<BsExclamationCircleFill color="red" className="text-3xl" />
								<p>{errorMessage}</p>
							</div>
						) : null}
					</div>
					<div className="flex justify-end gap-3 pt-4">
						<button
							onClick={(e) => {
								e.preventDefault();
								setShowUpdateConditionalModal(false);
							}}
							className="rounded-none bg-gray-500 p-2 text-white">
							Cancel
						</button>
						{conditionalFieldValue !== "" && selectedConditionField !== "" ? (
							<button
								className="rounded-none bg-blue-500 p-2 text-white"
								onClick={(e) => {
									addToConditionals(e);
									setShowUpdateConditionalModal(false);
                                    
								}}>
								Update conditional
							</button>
						) : (
							<button
								className="rounded-none bg-gray-500 p-2 text-white cursor-default"
								onClick={(e) => {
									e.preventDefault();
								}}>
								Update conditional
							</button>
						)}
                    </div>
                </div>
            </div>
        </div>
    );
};


export default UpdateConditionalModal;