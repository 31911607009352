import { useEffect, useRef, useState } from "react";
import ProjectSection from "./ProjectSection";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import LoadingCircle from "../utility/LoadingCircle";
import StagePrompt from "./StagePrompt";
import segmentImage from "../../assets/segmentimage.png";
import FileSaver from "file-saver";
import { BsArrowLeftRight, BsArrowUpCircleFill } from "react-icons/bs";
import { AiOutlineExport, AiFillWarning } from "react-icons/ai";
import { ImArrowLeft } from "react-icons/im";
import { ImArrowRight } from "react-icons/im";
import { RiCameraSwitchFill } from "react-icons/ri";
import ExportModal from "./ExportModal";
import ReportButton from "./buttons/ReportButton";
import DeleteProjectButton from "./buttons/DeleteProjectButton";
import UpdateTemplateButton from "./buttons/UpdateTemplateButton";
import ScrollToTop from "react-scroll-to-top";
import NavigationArea from "./NavigationArea";
import ClearRecordButton from "./buttons/ClearRecordButton";
import MessageLogs from "./Messages/MessageLogs";
import { IoChatbubbleEllipsesSharp, IoChatbubbles } from "react-icons/io5";
import { FaDropbox } from "react-icons/fa";
import ClockOutButton from "../Clocking/ClockOutButton";
import ClockInButton from "../Clocking/ClockInButton";
import NearingLateClockoutBanner from "../Clocking/NearingLateClockoutBanner";
import LateClockOutBanner from "../Clocking/LateClockoutBanner";
import ShopButton from "../shop/ShopButton";

const Project = (props) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const ppc = searchParams.get("ppc");
	const params = useParams();
	const DELETE_ID = 1; // hardcoded odoo user id that allows deleting 2 for user admin 1 for employee admin
	const [sections, setSections] = useState([]);
	const [projectName, setProjectName] = useState("");
	const [odooId, setOdooId] = useState();
	const [templateName, setTemplateName] = useState("");
	const [templateId, setTemplateId] = useState("");
	const [templateVersion, setTemplateVersion] = useState("");
	const [conditionalData, setConditionalData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [stage, setStage] = useState("");
	const [showStagePrompt, setShowStagePrompt] = useState(false);
	const [templateTypeId, setTemplateTypeId] = useState(-1);
	const [showExportMenu, setShowExportMenu] = useState(false);
	const [googleMapLink, setGoogleMapLink] = useState("");
	const [requiredFieldsBySection, setRequiredFieldsBySection] = useState({});
	const [allInitialThumbnailPathes, setAllInitialThumbnailPathes] = useState([]);
	const [lastUpdatedBy, setLastUpdatedBy] = useState("");
	const [lastModifiedOn, setLastModifiedOn] = useState("");
	const [updatingTemplateVersion, setUpdatingTemplateVersion] = useState(false);
	const [templateStatus, setTemplateStatus] = useState(-1);
	const [loading, setLoading] = useState(false);
	const [showElement, setShowElement] = useState(false);
	const [selectedOdooModel, setSelectedOdooModel] = useState("");
	const [templateNavMappings, setTemplateNavMappings] = useState("");
	const [contractType, setContractType] = useState("all");
	const [portfolioType, setPortfolioType] = useState("all");
	const [parentRecord, setParentRecord] = useState(false);
	const [childRecords, setChildRecords] = useState([]);
	const [tickets, setTickets] = useState([]);
	const [odooInstallationName, setOdooInstallationName] = useState("");
	const [odooParentName, setOdooParentName] = useState("");
	const [helpdeskStage, setHelpdeskStage] = useState("");
	const [installationRecord, setInstallationRecord] = useState([]);
	const [openChatLog, setOpenChatLog] = useState(false);
	const [movingToSIQ, setMovingToSIQ] = useState(false);
	const [dropboxLinkPath, setDropboxLinkPath] = useState("");
	const [showInvalidDropboxModal, setShowInvalidDropboxModal] = useState(false);
	const [clockedIntoThis, setClockedIntoThis] = useState(false);
	const [clockingData, setClockingData] = useState({});
	const [clockBannerToShow, setClockBannerToShow] = useState("");
	const [hasPreProjectCompleteTicket, setHasPreProjectCompleteTicket] = useState(false);

	useEffect(() => {
		setTimeout(function () {
			setShowElement(true);
		}, 5000);
	}, []);

	const deleteThisProject = (e) => {
		e.preventDefault();
		fetch(props.api + "/project/" + params.id, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: props.token,
			},
		}).then((res) => {
			if (res.ok) {
				navigate("/dashboard");
			}
		});
	};

	useEffect(() => {
		let foundId = false;
		let clockingInfo = clockingData.clockingInfo;
		if (!clockingInfo) {
			return;
		}
		if (selectedOdooModel === "project.task") {
			if (clockingInfo.installationIds && clockingInfo.installationIds.includes(odooId)) {
				foundId = true;
			}
		} else if (selectedOdooModel === "x_roofing") {
			if (clockingInfo.roofing && clockingInfo.roofing[0] === odooId) {
				foundId = true;
			}
		} else if (selectedOdooModel === "helpdesk.ticket") {
			if (clockingInfo.tickets && clockingInfo.tickets.includes(odooId)) {
				foundId = true;
			}
		} else if (selectedOdooModel === "x_krummy_capture") {
			if (clockingInfo.krummy && clockingInfo.krummy[0] === odooId) {
				foundId = true;
			}
		}
		setClockedIntoThis(foundId);
	}, [clockingData, odooId, selectedOdooModel]);

	useEffect(() => {
		if (props.api === "" || props.token === "") {
			return;
		}
		const controller = new AbortController();
		const signal = controller.signal;

		setIsLoading(true);
		fetch(props.api + "/project/" + params.id, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: props.token,
			},
			signal,
		})
			.then((res) => {
				if (!res.ok) {
					setIsLoading(false);
					if (props.isPublicStorage) {
						navigate("/dashboard");
					}
					// put an error here
				} else {
					return res.json();
				}
			})
			.then((res) => {
				if (res) {
					setClockedIntoThis(res.clockedIntoThis);
					setTemplateTypeId(res.templateType[0].id);
					if (res.project[0].contract_type.toLowerCase() === "residential") {
						setContractType("residential");
					} else if (res.project[0].contract_type.toLowerCase() === "commercial") {
						setContractType("commercial");
					} else {
						setContractType("all");
					}
					setIsLoading(false);
					let unsortedSections = res.sections;
					let sortedSections = unsortedSections.sort((a, b) => a.position - b.position);
					document.title = res.project[0].name;

					let tempRequiredFieldsBySection = {};
					for (let i = 0; i < sortedSections.length; i++) {
						tempRequiredFieldsBySection[sortedSections[i].id] = [];
					}

					setRequiredFieldsBySection(tempRequiredFieldsBySection);

					if (res.project[0].stage === "assigned") {
						setShowStagePrompt(true);
					}

					setAllInitialThumbnailPathes(res.dbxImages);
					setStage(res.project[0].stage);
					setProjectName(res.project[0].name);
					setOdooId(res.project[0].odoo_id);
					setSections(sortedSections);
					setTemplateName(res.project[0].template_name);
					setTemplateVersion(res.project[0].version);
					setTemplateId(res.project[0].template_id);
					setLastUpdatedBy(res.project[0].last_updated_by);
					setLastModifiedOn(res.project[0].last_modified_on);
					setTemplateStatus(res.project[0].template_status);
					setSelectedOdooModel(res.project[0].odoo_model);
					setOdooInstallationName(res.project[0].odoo_installation_name);
					setOdooParentName(res.project[0].odoo_parent_name);
					setTemplateNavMappings(res.navMappings);
					setHelpdeskStage(res.helpdeskStage);
					setParentRecord(res.parentRecord);
					setChildRecords(res.childRecords);
					setInstallationRecord(res.installationRecord);
					setDropboxLinkPath(res.dropboxLinkPath);
					setTickets(res.tickets);

					if (ppc) {
						const preCompleteTicket = res.tickets.find((ticket) => {
							let teamIsProjectComplete = false;
							if (Array.isArray(ticket.team_id) && ticket.team_id.length > 1) {
								const teamName = ticket.team_id[1];
								teamIsProjectComplete = teamName.toLowerCase() === "Pre-Project Complete".toLowerCase();
							}

							let stageIsScheduled = false;
							if (Array.isArray(ticket.stage_id) && ticket.stage_id.length > 1) {
								const stageName = ticket.stage_id[1];
								stageIsScheduled = stageName.toLowerCase() === "Scheduled".toLowerCase();
							}

							let foundId = ticket.id.toString() === ppc.toString();

							return teamIsProjectComplete && stageIsScheduled && foundId;
						});

						setHasPreProjectCompleteTicket(preCompleteTicket ? true : false);
					}
					let tempPortfolioType = "all";
					let projectPortfolioType = res.project[0].portfolio_type.toLowerCase();
					if (projectPortfolioType === "public storage") {
						tempPortfolioType = "ps";
					} else if (projectPortfolioType === "extra space storage") {
						tempPortfolioType = "ess";
					} else if (projectPortfolioType !== "") {
						tempPortfolioType = "misc";
					}
					setPortfolioType(tempPortfolioType);
					let gmapLink = res.project[0].google_map_link;
					if (gmapLink) {
						let splitLink = gmapLink.split("href");
						if (splitLink.length === 2) {
							gmapLink = `${splitLink[0]} rel="noopener noreferrer" target='_blank' href${splitLink[1]}`;
						} else if (splitLink.length >= 3) {
							gmapLink = `${splitLink[0]} rel="noopener noreferrer" target='_blank' href${splitLink.slice().join("href")}`;
						}
					}
					setGoogleMapLink(gmapLink);

					let conditionsArray = [];
					for (let i = 0; i < sortedSections.length; i++) {
						if (sortedSections[i].section_condition_id !== 0) {
							conditionsArray.push({
								id_of_conditioned: sortedSections[i].id,
								conditionMet: false,
								condition_type: sortedSections[i].condition_type,
								condition_value: sortedSections[i].condition_value,
								field_condition_id: sortedSections[i].field_condition_id,
								section_condition_id: sortedSections[i].section_condition_id,
							});
						}
					}
					setConditionalData(conditionsArray);
				}
			})
			.catch((err) => {});

		return () => {
			controller.abort();
		};
	}, [props.api, props.token, params.id, props.isPublicStorage, navigate, ppc]);

	useEffect(() => {
		if (props.api === "" || props.token === "") {
			return;
		}
		const controller = new AbortController();
		const signal = controller.signal;

		const currentDate = new Date();

		const year = currentDate.getFullYear();
		const month = String(currentDate.getMonth() + 1).padStart(2, "0");
		const day = String(currentDate.getDate()).padStart(2, "0");
		const formattedDate = `${year}-${month}-${day}`;
		setIsLoading(true);
		fetch(props.api + "/project/clocking?date=" + formattedDate, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: props.token,
			},
			signal,
		})
			.then((res) => {
				if (!res.ok) {
				} else {
					return res.json();
				}
			})
			.then((res) => {
				if (res) {
					setClockedIntoThis(res.clockedIntoThis);
					setClockingData({
						clockingInfo: res.clockingInfo,
						specialCode: res.clockingSpecialCode,
						lastClocked: res.lastClocked ? new Date(res.lastClocked) : null,
					});
				}
			})
			.catch((err) => {});

		return () => {
			controller.abort();
		};
	}, [props.api, props.token]);

	const changeStage = (newStage, hasDownload, downloadName) => {
		fetch(props.api + "/project/stage", {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: props.token,
			},
			body: JSON.stringify({ id: params.id, stage: newStage, odoo_id: odooId }),
		}).then((res) => {
			if (res.ok) {
				setStage(newStage);
				setShowStagePrompt(false);
				if (hasDownload) {
					FileSaver.saveAs(segmentImage, downloadName);
				}
				setLastModifiedOn(new Date());
			}
		});
	};

	const openExportMenu = (e) => {
		e.preventDefault();
		setShowExportMenu(true);
	};

	const updateTemplateToLatestVersion = (e) => {
		e.preventDefault();
		if (updatingTemplateVersion) {
			return;
		}

		setUpdatingTemplateVersion(true);
		fetch(props.api + "/project/update-template/" + params.id, {
			method: "post",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: props.token,
			},
		}).then((res) => {
			setUpdatingTemplateVersion(false);
			if (res.ok) {
				window.location.reload(true);
			} else {
			}
		});
	};

	const moveToSIQ = (ticketOdooId) => {
		setMovingToSIQ(true);
		fetch(props.api + "/projectdata/siq/" + ticketOdooId, {
			method: "put",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: props.token,
			},
		}).then((res) => {
			if (res.ok) {
				setMovingToSIQ(false);
				if (hasPreProjectCompleteTicket && ticketOdooId === ppc) {
					setHasPreProjectCompleteTicket(false);
				} else {
					setHelpdeskStage("SIQ Review");
				}
			}
		});
	};

	const styleByItemCount = () => {
		let count = 1;
		if (props.reportAccess || props.isAdmin) {
			count++;
		}
		if (selectedOdooModel === "helpdesk.ticket" && helpdeskStage !== "SIQ Review") {
			count++;
		}
		if (odooId && (props.templateAccess || props.isAdmin)) {
			count++;
		}
		if (props.exportAccess || props.isAdmin) {
			count++;
		}
		if (props.isAdmin && templateStatus !== 2) {
			count++;
		}
		if (count === 1) {
			return "";
		} else if (count <= 4) {
			return "grid grid-cols-2 md:grid-rows-2 grid-rows-auto";
		} else {
			return "grid grid-cols-2 md:grid-rows-3 grid-rows-auto";
		}
	};

	useEffect(() => {
		if (openChatLog) {
			document.body.classList.add("overflow-hidden");
		} else {
			document.body.classList.remove("overflow-hidden");
		}

		return () => {
			document.body.classList.remove("overflow-hidden");
		};
	}, [openChatLog]);

	useEffect(() => {
		if (showStagePrompt && stage === "assigned") {
			document.body.classList.add("overflow-hidden");
		} else {
			document.body.classList.remove("overflow-hidden");
		}

		return () => {
			document.body.classList.remove("overflow-hidden");
		};
	}, [showStagePrompt, stage]);

	return (
		<div className="">
			{clockBannerToShow === "warning" ? <NearingLateClockoutBanner /> : null}

			{clockBannerToShow === "over" ? <LateClockOutBanner /> : null}

			{props.isPublicStorage ? null : selectedOdooModel !== "helpdesk.ticket" ? null : openChatLog ? null : (
				<div className="relative z-[150]">
					<div className="fixed bottom-5 right-5 ">
						<button onClick={() => setOpenChatLog(true)} className="bg-black p-2 text-white rounded-full">
							<IoChatbubbleEllipsesSharp size={24} />
						</button>
					</div>
				</div>
			)}
			{showInvalidDropboxModal ? (
				<div
					className="text-black fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black/50 z-50"
					onClick={() => setShowInvalidDropboxModal(false)}>
					<div className="bg-white w-full lg:w-1/4 m-2 rounded-lg " onClick={(e) => e.stopPropagation()}>
						<div className="m-4">
							<p className="text-3xl font-bold text-center">Invalid Dropbox Link</p>
						</div>
						<div className="px-4 pb-4">
							<p>This project has not been properly setup in Dropbox. Please contact IT and ask them to migrate the folder.</p>
							<div className="flex justify-center w-full mt-4">
								<button type="button" className="rounded-lg bg-gray-500 p-2 text-white " onClick={() => setShowInvalidDropboxModal(false)}>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
			) : null}
			<ScrollToTop
				smooth
				top={500}
				width="1"
				height="1"
				style={{ background: (76, 175, 80, 0.3), right: 70, bottom: 20 }}
				component={<BsArrowUpCircleFill className="w-full h-full" />}
				className="bg-opacity-0 #{!important}"
			/>
			{/*<ScrollButton />*/}
			{updatingTemplateVersion ? (
				<div className="text-black fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black/50 z-50">
					<div className="bg-white flex flex-col items-center justify-center p-4 rounded-lg ">
						<LoadingCircle />
						<p>Updating template version...</p>
					</div>
				</div>
			) : null}
			{showExportMenu ? (
				<ExportModal
					setShowExportMenu={setShowExportMenu}
					projectid={params.id}
					api={props.api}
					token={props.token}
					projectName={projectName}
					templateName={templateName}
				/>
			) : null}
			{showStagePrompt && stage === "assigned" ? (
				<StagePrompt
					prompt={"This is a new project"}
					yesText="Start project"
					noText="Go Back"
					confirmAction={() => {
						changeStage("in-progress", true, "pms-start-segment-" + projectName);
					}}
					cancelAction={() => {
						navigate(-1);
					}}
					hasDownloadSegment={true}
					downloadName={"pms-start-segment-" + projectName}
				/>
			) : null}

			{showStagePrompt && stage === "in-progress" ? (
				<StagePrompt
					prompt={"Move to finalize?"}
					yesText="Finalize Project"
					noText="Cancel"
					confirmAction={() => {
						changeStage("finalize");
					}}
					cancelAction={() => {
						setShowStagePrompt(false);
					}}
				/>
			) : null}

			{showStagePrompt && stage === "finalize" ? (
				<StagePrompt
					prompt={"Move to completed?"}
					yesText="Complete Project"
					noText="Cancel"
					confirmAction={() => {
						changeStage("completed", true, "pms-end-segment-" + projectName);
					}}
					cancelAction={() => {
						setShowStagePrompt(false);
					}}
					hasDownloadSegment={true}
					downloadName={"pms-end-segment-" + projectName}
				/>
			) : null}
			{showStagePrompt && stage === "completed" ? (
				<StagePrompt
					prompt={"Move to finalize?"}
					yesText="Back to finalize"
					noText="Cancel"
					confirmAction={() => {
						changeStage("finalize");
					}}
					cancelAction={() => {
						setShowStagePrompt(false);
					}}
				/>
			) : null}
			{props.isPublicStorage ? null : (
				<MessageLogs
					api={props.api}
					token={props.token}
					userId={props.userId}
					odoo_model={selectedOdooModel}
					odoo_id={odooId}
					openChatLog={openChatLog}
					setOpenChatLog={setOpenChatLog}
				/>
			)}

			<div className="p-5 bg-gray-100 flex flex-col lg:flex-row justify-between gap-4 w-full relative">
				<div>
					<p className="text-2xl font-bold">Project: {projectName}</p>
					<p className="text-xl ">Template: {templateName + " - v" + templateVersion} </p>
					<p className="text-lg ">Stage: {stage}</p>
					<div className="flex gap-2 text-lg">
						<p>Location: </p>
						{googleMapLink === "" || googleMapLink === "failedtogetdataforpmscode400" ? (
							<p>-</p>
						) : (
							<div className="text-blue-400 hover:text-blue-600 underline" dangerouslySetInnerHTML={{ __html: googleMapLink }}></div>
						)}
					</div>
					{lastUpdatedBy ? (
						<div className="flex gap-2 text-lg">
							<p>Last updated by: {`${lastUpdatedBy} ${lastModifiedOn ? `@ ${new Date(lastModifiedOn).toLocaleString("en-US")}` : ""}`}</p>
						</div>
					) : null}
					{props.isPublicStorage || isLoading ? null : dropboxLinkPath ? (
						<div className="flex gap-2 items-center  ">
							<FaDropbox size={20} color="#0061FE" />
							<Link to={dropboxLinkPath} className="text-blue-400 hover:text-blue-600 underline">
								Dropbox Access
							</Link>
							<p></p>
						</div>
					) : (
						<div className="flex gap-2 items-center text-blue-400 hover:text-blue-600 underline ">
							<button
								className="flex gap-2 items-center"
								onClick={() => {
									setShowInvalidDropboxModal(true);
								}}>
								<FaDropbox size={20} color="#0061FE" />
								<p>Dropbox Access</p>
							</button>
						</div>
					)}
				</div>
				<div>
					<div className="mb-1">
						{isLoading ? null : (
							<>
								<ClockOutButton
									api={props.api}
									token={props.token}
									projectsRoute={""}
									odooId={odooId}
									odooModel={selectedOdooModel}
									clockingData={clockingData}
									setClockingData={setClockingData}
									isFullWidth={true}
									clockedIntoThis={clockedIntoThis}
									showClock={true}
									clockBannerToShow={clockBannerToShow}
									setClockBannerToShow={setClockBannerToShow}
								/>
								<ClockInButton
									api={props.api}
									token={props.token}
									projectsRoute={""}
									odooId={odooId}
									odooModel={selectedOdooModel}
									clockingData={clockingData}
									setClockingData={setClockingData}
									isFullWidth={true}
									clockedIntoThis={clockedIntoThis}
									showClock={true}
									clockBannerToShow={clockBannerToShow}
									setClockBannerToShow={setClockBannerToShow}
								/>
							</>
						)}
					</div>
					<div className={`gap-1 bg-gray-100 text-white select-none ${styleByItemCount()}`}>
						{props.isPublicStorage ? null : (
							<>
								{props.clearRecordAccess ? (
									<ClearRecordButton projectName={projectName} projectid={params.id} api={props.api} token={props.token} />
								) : null}
								{/* {props.isAdmin && props.isAdmin === true && parseInt(props.userId) === DELETE_ID ? (
								<DeleteProjectButton projectName={projectName} deleteThisProject={deleteThisProject} />
							) : null}{" "} */}
								{props.reportAccess || props.isAdmin ? (
									<ReportButton
										api={props.api}
										token={props.token}
										odooId={odooId}
										selectedOdooModel={selectedOdooModel}
										templateNavMappings={templateNavMappings}
										parentRecord={parentRecord}
										childrenRecords={childRecords}
										tickets={tickets}
										projectName={projectName}
										templateName={templateName}
									/>
								) : null}
								{stage && stage !== "assigned" ? (
									<div
										className="flex items-center gap-1 cursor-pointer bg-blue-600 hover:bg-blue-700 p-2 rounded-lg w-full select-none"
										onClick={() => setShowStagePrompt(true)}>
										{stage !== "completed" ? <ImArrowRight /> : <ImArrowLeft />}
										<p>
											{stage === "assigned" ? "Move to in-progress" : null}
											{stage === "in-progress" ? "Move to finalize" : null}
											{stage === "finalize" ? "Move to complete" : null}
											{stage === "completed" ? "Move to finalize" : null}
										</p>
									</div>
								) : null}
								{hasPreProjectCompleteTicket && selectedOdooModel !== "helpdesk.ticket" ? (
									<div
										className={`flex items-center gap-1 cursor-pointer ${
											movingToSIQ ? "bg-gray-700 hover:bg-gray-800" : "bg-blue-600 hover:bg-blue-700"
										} p-2 rounded-lg w-full select-none`}
										onClick={() => {
											if (!movingToSIQ) {
												moveToSIQ(ppc);
											}
										}}>
										{movingToSIQ ? (
											<>
												<LoadingCircle />
												<p>Moving to SIQ...</p>
											</>
										) : (
											<>
												<ImArrowRight />
												<p>Move to SIQ</p>
											</>
										)}
									</div>
								) : null}
								{selectedOdooModel === "helpdesk.ticket" && helpdeskStage === "Scheduled" ? (
									<div
										className={`flex items-center gap-1 cursor-pointer ${
											movingToSIQ ? "bg-gray-700 hover:bg-gray-800" : "bg-blue-600 hover:bg-blue-700"
										} p-2 rounded-lg w-full select-none`}
										onClick={() => {
											if (!movingToSIQ) {
												moveToSIQ(odooId);
											}
										}}>
										{movingToSIQ ? (
											<>
												<LoadingCircle />
												<p>Moving to SIQ...</p>
											</>
										) : (
											<>
												<ImArrowRight />
												<p>Move to SIQ</p>
											</>
										)}
									</div>
								) : null}
								{odooId && (props.templateAccess || props.isAdmin) ? (
									<div className="flex items-center cursor-pointer ">
										<Link
											to={
												"/odoo-project/" +
												odooId +
												"/template-select?model=" +
												selectedOdooModel +
												`&contract=${contractType}&portfolio=${portfolioType}`
											}
											className="w-full select-none h-full">
											<div className="flex items-center gap-1 bg-blue-600 hover:bg-blue-700 p-2 rounded-lg select-none h-full">
												<BsArrowLeftRight />
												<p className="">Switch Template</p>
											</div>
										</Link>
									</div>
								) : null}
								{/* {props.isAdmin === true || props.requestAccess ? (
								<div className="flex items-center cursor-pointer  select-none">
									<Link to={"/request/create/" + params.id} className="w-full h-full">
										<div className="flex items-center gap-1 bg-blue-600 p-2 rounded-lg h-full">
											<RiCameraSwitchFill />
											<p>Make Request</p>
										</div>
									</Link>
								</div>
							) : null} */}
								{odooId && selectedOdooModel && projectName ? (
									<ShopButton api={props.api} token={props.token} odooId={odooId} odooModel={selectedOdooModel} projectName={projectName} />
								) : null}
								{props.exportAccess || props.isAdmin ? (
									<div
										className="flex items-center gap-1  bg-blue-600 hover:bg-blue-700 p-2 rounded-lg w-full cursor-pointer select-none"
										onClick={(e) => {
											openExportMenu(e);
										}}>
										<AiOutlineExport />
										<p>Export</p>
									</div>
								) : null}
								{props.isAdmin === true && templateStatus !== 2 ? (
									<UpdateTemplateButton updateTemplateToLatestVersion={updateTemplateToLatestVersion} />
								) : null}
							</>
						)}
					</div>
				</div>
			</div>
			<div className="flex flex-col md:flex-row ">
				<div className="basis-1/4">
					<div className="md:sticky md:top-0  ">
						{props.isPublicStorage ? null : (
							<NavigationArea
								api={props.api}
								token={props.token}
								templateNavMappings={templateNavMappings}
								selectedOdooModel={selectedOdooModel}
								odooId={odooId}
								contractType={contractType}
								portfolioType={portfolioType}
								parentRecord={parentRecord}
								childRecords={childRecords}
								tickets={tickets}
								installationRecord={installationRecord}
								isLoading={isLoading}
							/>
						)}
						<div className="flex justify-between items-center bg-gray-200 px-4 py-1">
							<p className="font-bold text-xl">Section List</p>
						</div>
						<div className="max-h-[60vh] overflow-y-scroll  px-4">
							{sections && sections.length > 0 ? (
								sections.map((value) => {
									for (let i = 0; i < conditionalData.length; i++) {
										if (conditionalData[i].id_of_conditioned === value.id && conditionalData[i].conditionMet === false) {
											return null;
										}
									}

									return (
										<div key={value.id} className="border-l-4 p-1 pl-2 flex justify-between items-center">
											<a className="text-blue-500 hover:text-blue-700 underline" href={"#" + value.name.replaceAll(" ", "-")}>
												{`${value.name}`}
											</a>

											{showElement ? (
												requiredFieldsBySection[value.id].length > 0 ? (
													<div
														className="flex items-center text-sm bg-yellow-300 p-1 rounded-lg gap-1 opacity-0"
														style={{ opacity: showElement ? 1 : 0 }}>
														<AiFillWarning />
														<p>{requiredFieldsBySection[value.id].length}</p>
													</div>
												) : null
											) : null}
										</div>
									);
								})
							) : isLoading ? (
								<div className="flex flex-col items-center justify-center w-full my-2">
									<LoadingCircle />
									<p>Loading Sections...</p>
								</div>
							) : (
								<p>This is an empty template</p>
							)}
						</div>
					</div>
				</div>

				<div className="basis-3/4 p-2  bg-gray-200" id="project-data">
					{sections && sections.length > 0 ? (
						sections.map((value, index) => {
							let showThisSection = true;
							for (let i = 0; i < conditionalData.length; i++) {
								if (conditionalData[i].id_of_conditioned === value.id && conditionalData[i].conditionMet === false) {
									showThisSection = false;
									break;
								}
							}

							return (
								<ProjectSection
									conditionalData={conditionalData}
									sectionId={value.id}
									setConditionalData={setConditionalData}
									templateName={templateName}
									templateId={templateId}
									projectName={projectName}
									key={value.id}
									projectId={params.id}
									api={props.api}
									data={value}
									token={props.token}
									stage={stage}
									requestForm={false}
									templateTypeId={templateTypeId}
									projectHtmlId={"section-" + index}
									requiredFieldsBySection={requiredFieldsBySection}
									setRequiredFieldsBySection={setRequiredFieldsBySection}
									showThisSection={showThisSection}
									setLastModifiedOn={setLastModifiedOn}
									initialThumbnailPathes={allInitialThumbnailPathes.filter((filterData) =>
										filterData.name.includes(value.name.replaceAll("/", "-") + " - ")
									)}
									showElement={showElement}
									selectedOdooModel={selectedOdooModel}
									setLastUpdatedBy={setLastUpdatedBy}
									odooInstallationName={odooInstallationName}
									odooParentName={odooParentName}
								/>
							);
						})
					) : isLoading ? (
						<div className="flex flex-col items-center justify-center w-full my-2">
							<LoadingCircle />
							<p>Loading Sections</p>
						</div>
					) : (
						<p>This is an empty template</p>
					)}
				</div>
			</div>
		</div>
	);
};

export default Project;
