import { useState } from "react";
import { useNavigate } from "react-router-dom";

const DeleteReportModal = ({ setShowDeleteReportModal, api, token, id, filteredReports, setFilteredReports }) => {
    const navigate = useNavigate();
    const [reportName, setReportName] = useState("");
    const [saveName, setSaveName] = useState("");
    const [deleting, setDeleting] = useState(false);
    const [error, setError] = useState("");

    const changeReportNameViaEvent = (e) => {
        setReportName(e.target.value);
    };

    const changeSaveNameViaEvent = (e) => {
        setSaveName(e.target.value);
    };

    const deleteReport = (e) => {
        e.preventDefault();

        if (deleting) {
            return;
        }
        setDeleting(true);
        filteredReports = filteredReports.filter(obj => !id.includes(obj.id));
        setFilteredReports([...filteredReports]);

        fetch(api + "/report/delete-report", {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: token,
            },
            body: JSON.stringify({ reportId: id }),
            mode: "cors",
        })
            .then((res) => {
                if (res) {
                    setShowDeleteReportModal(false);
                    return res.json();

                }else{
                    setDeleting(false);
                }
            })

    };

    const closeModal = (e) => {
        e.preventDefault();
        setShowDeleteReportModal(false);
    };

    return (
        <div className="fixed top-0 left-0 w-full h-screen flex justify-center items-center bg-black/[.06] z-30" onClick={closeModal}>
            <div className="bg-white m-2 rounded-lg w-full md:w-auto" onClick={(e) => e.stopPropagation()}>
                <div className="border border-black border-2 rounded-lg w-full md:basis-1/4 ">
                    <div className="flex justify-between bg-gray-300 rounded-t-lg p-4 items-center">
                        <p className="text-2xl font-bold text-center ">Delete Report</p>
                    </div>
                    <form className="flex flex-col gap-4 p-4" onSubmit={deleteReport}>
                        <p>Are you sure you want to delete this report?</p>
                        <div className="flex gap-4 justify-center">
                            <button type="submit" className="p-2 bg-blue-600 text-white rounded-lg">
                                Delete
                            </button>
                            <button type="button" onClick={closeModal} className="p-2 bg-gray-500 text-white rounded-lg">
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default DeleteReportModal;
