import { useEffect, useState } from "react";
import { BsTrash } from "react-icons/bs";
import { Link } from "react-router-dom";
import LoadingCircle from "../utility/LoadingCircle";
import CreateReportModal from "./CreateReportModal";
import DeleteReportModal from "./DeleteReportModal";

const Reports = ({ api, token, isAdmin, reportAccess }) => {
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [reports, setReports] = useState([]);
	const [filteredReports, setFilteredReports] = useState([]);
	const [filterString, setFilterString] = useState("");
	const [finishedInitialLoad, setFinishedInitialLoad] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [idToDelete, setIdToDelete] = useState("");

	useEffect(() => {
		document.title = "Reports";
	}, []);

	const openCreateModal = (e) => {
		e.preventDefault();
		setShowCreateModal(true);
	};

	useEffect(() => {
		if (filterString === "") {
			setFilteredReports(reports);
		} else {
			setFilteredReports(reports.filter((filterData) => filterData.name.includes(filterString)));
		}
	}, [filterString, reports]);

	useEffect(() => {
		fetch(api + "/report/all", {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: token,
			},
			mode: "cors",
		})
			.then((res) => {
				setFinishedInitialLoad(true);
				if (res) {
					return res.json();
				}
			})
			.then((data) => {
				if (data) {
					setReports(data.reports);
				}
			});
	}, [api, token]);

	const preventEventDefault = (e) => {
		e.preventDefault();
	};

	const removeReport = (e) => {
		e.preventDefault();
		setIdToDelete(e.target.id);
		setShowDeleteModal(true);
	};

	return (
		<div className="flex flex-col">
			{showCreateModal ? <CreateReportModal setShowCreateReportModal={setShowCreateModal} api={api} token={token} /> : null}
			<div className="bg-gray-100 p-4 text-lg md:text-2xl font-bold">
				<p>Reports</p>
			</div>
			<div className="flex justify-center p-4">
				<div className="flex flex-col items-center justify-center md:w-3/5 w-full">
					<button className="bg-blue-600 text-white p-4 rounded-lg mb-4" onClick={openCreateModal}>
						Add Report
					</button>
					<form onSubmit={preventEventDefault} className="w-full mb-4 ">
						<input
							className="rounded-lg p-1 border border-black text-black w-full"
							type="text"
							placeholder="Word to filter by"
							value={filterString}
							onChange={(e) => {
								setFilterString(e.target.value);
							}}
						/>
					</form>

					<div className="w-full">
						<div className="hidden md:flex">
							{isAdmin !== true ? (
								<div className="flex justify-between items-center border w-full outline outline-1 p-4 bg-black outline-black border-black text-white rounded-t-lg">
									<p className="basis-3/6">Report Name</p>
									<p className="basis-1/6 text-center">Last Updated By</p>
									<p className="basis-1/6 text-center">Last Updated On</p>
									<p className="basis-1/6 text-center">Options</p>
								</div>
							) : (
								<div className="flex justify-start items-center border w-full outline outline-1 p-4 bg-black outline-black border-black text-white rounded-t-lg">
									<p className="basis-3/6 ">Report Name</p>
									<p className="basis-1/6 text-center">Last Updated By</p>
									<p className="basis-1/6 text-center">Last Updated On</p>
									<p className="basis-1/6 text-center">Options</p>
								</div>
							)}
						</div>

						<div className="flex md:hidden">
							<div className="flex justify-center items-center border w-full outline outline-1 p-4 bg-black outline-black border-black text-white rounded-t-lg">
								<p className="text-center">Reports</p>
							</div>
						</div>

						{filteredReports && filteredReports.length > 0 ? (
							filteredReports.map((mapData, index) => {
								return (
									<div
										key={mapData.id}
										className={`flex flex-col md:flex-row justify-between border w-full outline outline-1 p-4 ${
											index % 2 === 0 ? "bg-gray-200" : "bg-white"
										}  ${index === filteredReports.length - 1 ? "rounded-b-lg" : ""}`}>
										{showDeleteModal ? (
											<DeleteReportModal
												setShowDeleteReportModal={setShowDeleteModal}
												api={api}
												token={token}
												id={idToDelete}
												filteredReports={filteredReports}
												setFilteredReports={setFilteredReports}
											/>
										) : null}
										<p className="text-xl basis-3/5 text-center md:text-left">{mapData.name}</p>
										<p className="basis-1/5 text-center">
											<span className="display md:hidden">Last Updated By: </span>
											{mapData.last_updated_by ? mapData.last_updated_by : "-"}
										</p>
										<p className="basis-1/5 text-center">
											<span className="display md:hidden">@ </span>
											{new Date(mapData.last_modified_on).toLocaleString("en-US")}
										</p>
										<div className="text-end basis-1/5 row">
											{isAdmin === true || reportAccess === true ? (
												<div className="flex flex-row justify-around ">
													<div className=" text-center ">
														<Link to={"/report/" + mapData.id} className="underline text-blue-600">
															Edit
														</Link>
													</div>
													<div className=" justify-center items-center">
														<BsTrash
															id={mapData.id}
															className="cursor-pointer w-5 h-5 mt-1.5 text-red-600"
															onClick={removeReport}
														/>
													</div>
												</div>
											) : (
												<div className="text-center">
													<div className="col-md-8 text-center mt-0.5">
														<p>-</p>
													</div>
												</div>
											)}
										</div>
									</div>
								);
							})
						) : finishedInitialLoad ? (
							filterString !== "" ? (
								<p className="text-center p-4">No reports found with value {filterString}</p>
							) : (
								<p className="p-4 text-center">Add a report to get started</p>
							)
						) : (
							<div className="flex flex-col justify-center items-center p-4">
								<LoadingCircle />
								<p className="text-black">Loading reports...</p>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Reports;
