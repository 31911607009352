import { useEffect, useState } from "react";
import LoadingCircle from "../utility/LoadingCircle";
import PDFMerger from "pdf-merger-js";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";

const ReportModal = ({
	setShowReportModal,
	reports,
	api,
	odooId,
	token,
	requests,
	selectedOdooModel,
	templateNavMappings,
	relatedOdooRecords,
	projectName,
	loading,
}) => {
	const [selectedReportId, setSelectedReportId] = useState("-1");
	const [saveName, setSaveName] = useState("");
	const [reportName, setReportName] = useState("");
	const [generatingReport, setGeneratingReport] = useState(false);
	const [hidePhotos, setHidePhotos] = useState(false);
	const [hideData, setHideData] = useState(false);
	const [requestIds, setRequestIds] = useState([]);
	const [hasError, setHasError] = useState(false);
	const [formattedOdooRecords, setFormattedOdooRecords] = useState([]);
	const [allTicketIds, setAllTicketIds] = useState([]);
	const [selectedTickets, setSelectedTickets] = useState([]);
	const [missingTickets, setMissingTickets] = useState(false);
	const [includeParentChild, setIncludeParentchild] = useState(false);
	const [fetchMessage, setFetchMessage] = useState("Creating the report");
	const [parentChildRecords, setParentChildRecords] = useState([]);
	const [selectedParentChildRecords, setSelectedParentChildrecords] = useState([]);
	const [missingParentChildRecord, setMissingParentChildRecord] = useState(false);
	const modalTypes = ["start", "parentchild", "tickets"];
	const [showModalType, setShowModalType] = useState("start");

	const closeModal = (e) => {
		e.preventDefault();
		setShowReportModal(false);
		setAllTicketIds([]);
	};

	useEffect(() => {
		if (selectedOdooModel === "helpdesk.ticket") {
			setSelectedTickets([parseInt(odooId)]);
		}
	}, [odooId, selectedOdooModel]);

	useEffect(() => {
		if (!templateNavMappings || !relatedOdooRecords) {
			return;
		}
		let keys = templateNavMappings.map((mapData) => mapData.mapping);
		let tempRecords = [];
		for (let i = 0; i < keys.length; i++) {
			if (Object.keys(relatedOdooRecords).includes(keys[i])) {
				let records = relatedOdooRecords[keys[i]].records;
				let type = relatedOdooRecords[keys[i]].type;
				if (type === "many2one") {
					if (records.length > 0) {
						tempRecords.push({
							odoo_id: records[0],
							name: records[1],
							selectedReport: "",
							model: relatedOdooRecords[keys[i]].model,
						});
					}
				} else if (type === "one2many") {
					if (records.length > 0) {
						for (let j = 0; j < records.length; j++) {
							tempRecords.push({
								odoo_id: records[j].id,
								name: records[j].name,
								selectedReport: "",
								model: relatedOdooRecords[keys[i]].model,
							});
						}
					}
				}
			}
		}
		setFormattedOdooRecords(tempRecords);
	}, [templateNavMappings, relatedOdooRecords]);

	useEffect(() => {
		if (reports.length > 0 && selectedReportId === "-1") {
			setSelectedReportId(reports[0].id);
			setSaveName(reports[0].save_name);
			setReportName(reports[0].name);
		}
	}, [reports, selectedReportId]);

	const changeSelectedTemplate = (e, theId) => {
		e.preventDefault();
		if (selectedReportId !== theId) {
			for (let i = 0; i < reports.length; i++) {
				if (`${reports[i].id}` === `${theId}`) {
					setReportName(reports[i].name);
					setSaveName(reports[i].save_name);
					setSelectedReportId(theId);
				}
			}
		}
	};

	const generateCover = async (model) => {
		try {
			const response = await fetch(api + "/report/generate-cover/" + odooId, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: token,
				},
				body: JSON.stringify({ reportId: selectedReportId, hideData, hidePhotos, requestIds, odoo_model: model }),
				mode: "cors",
			});

			const blobData = await response.blob();

			const coverBlob = new Blob([blobData], {
				type: "application/octet-stream",
			});
			const fileURL = window.URL.createObjectURL(coverBlob, { type: "application/octet-stream" });
			return fileURL;
		} catch (err) {
			throw new Error();
		}
	};

	const generatePartsByModel = async (model, recordId, reportIdToUse) => {
		try {
			const response2 = await fetch(api + "/report/generate/" + recordId, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: token,
				},
				body: JSON.stringify({ reportId: reportIdToUse, hideData, hidePhotos, requestIds, filterModel: model }),
				mode: "cors",
			});

			const blobData2 = await response2.blob();

			const actualPDF = new Blob([blobData2], {
				type: "application/octet-stream",
			});
			const fileURL2 = window.URL.createObjectURL(actualPDF, { type: "application/octet-stream" });
			return { statusCode: response2.status, file: fileURL2 };
		} catch (err) {
			throw new Error();
		}
	};

	async function addPageNumbersToMergedPDF(mergedPdfBytes) {
		const pdfDoc = await PDFDocument.load(mergedPdfBytes);
		const pageCount = pdfDoc.getPageCount();

		for (let i = 0; i < pageCount; i++) {
			if (i === 0) {
				continue;
			}
			const page = pdfDoc.getPages()[i];
			const pageNumber = i;

			const font = await pdfDoc.embedFont(StandardFonts.TimesRoman);
			const fontSize = 11;
			const textColor = rgb(0, 0, 0);
			const xPosition = 10;
			const yPosition = 10;

			page.drawText(`Page ${pageNumber} of ${pageCount - 1}`, {
				x: xPosition,
				y: yPosition,
				size: fontSize,
				font: font,
				color: textColor,
			});
		}

		return await pdfDoc.save();
	}

	async function createEmptyPdf() {
		const pdfDoc = await PDFDocument.create();
		const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
		const page = pdfDoc.addPage();
		const { width, height } = page.getSize();
		const fontSize = 24;
		const text = "This project doesn't have any data for this report template.";
		page.drawText(text, {
			x: 10,
			y: height - 30,
			size: fontSize,
			font: timesRomanFont,
			color: rgb(0, 0, 0),
		});

		return await pdfDoc.save();
	}

	function removeAutoNums(input) {
		const parts = input.split(" - ");

		if (!isNaN(parts[parts.length - 1])) {
			parts.pop();
		}

		return parts.join(" - ");
	}

	async function createTitlePage(titleText) {
		const pdfDoc = await PDFDocument.create();
		const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);

		const page = pdfDoc.addPage();
		const { width, height } = page.getSize();

		const fontSize = 24;
		let formattedText = removeAutoNums(titleText);
		const textWidth = timesRomanFont.widthOfTextAtSize(formattedText, fontSize);

		const textX = (width - textWidth) / 2;
		const textY = height * (2 / 3);

		page.drawText(formattedText, {
			x: textX,
			y: textY,
			size: fontSize,
			font: timesRomanFont,
			color: rgb(0, 0, 0),
		});

		return await pdfDoc.save();
	}

	const getTicketAndParentChildData = async (e) => {
		if (generatingReport) {
			return;
		}
		setHasError(false);
		setGeneratingReport(true);
		try {
			const response = await fetch(
				api + "/report/models/" + odooId + "?reportid=" + selectedReportId + "&model=" + selectedOdooModel + `&hierarchy=${includeParentChild ? 1 : 0}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						Authorization: token,
					},
					mode: "cors",
				}
			);

			const { ticketIds, parentInstallation, childInstallations } = await response.json();
			setGeneratingReport(false);
			let newParentChildRecords = [];

			if (includeParentChild) {
				if (parentInstallation && parentInstallation !== -1) {
					newParentChildRecords.push({ id: parentInstallation[0], name: parentInstallation[1], isParent: true });
				}
				if (childInstallations && childInstallations.length > 0) {
					for (let i = 0; i < childInstallations.length; i++) {
						newParentChildRecords.push({ id: childInstallations[i].id, name: childInstallations[i].name, isParent: false });
					}
				}
				setParentChildRecords(newParentChildRecords);
			}

			if (ticketIds && ticketIds.length > 0) {
				if (ticketIds.length === 1) {
					setAllTicketIds(ticketIds);
					if (!includeParentChild) {
						generateReport(ticketIds);
					} else {
						setShowModalType("parentchild");
					}
				} else {
					setAllTicketIds([...new Set(ticketIds)]);
					if (includeParentChild) {
						setShowModalType("parentchild");
					} else {
						setShowModalType("tickets");
					}
				}
			} else {
				setAllTicketIds([]);
				if (!includeParentChild) {
					generateReport([]);
				} else {
					setShowModalType("parentchild");
				}
			}
		} catch (err) {
			setGeneratingReport(false);
			setHasError(true);
		}
	};

	const generateReport = async (ticketIds) => {
		if (allTicketIds.length > 1 && selectedTickets.length === 0) {
			setMissingTickets(true);
			return;
		}
		setMissingTickets(false);
		if (generatingReport) {
			return;
		}
		setHasError(false);
		setGeneratingReport(true);
		try {
			const response4 = await fetch(
				api + "/report/models/" + odooId + "?reportid=" + selectedReportId + "&model=" + selectedOdooModel + `&hierarchy=${includeParentChild ? 1 : 0}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						Authorization: token,
					},
					mode: "cors",
				}
			);
			let results = await response4.json();
			let {
				installationId: originalInstallation,
				roofingId,
				krummyCaptureId,
				parentInstallation: originalParent,
				childInstallations: originalChildren,
			} = results;

			let files = [await generateCover(selectedOdooModel)];
			let filesByCode = {
				204: [],
				200: [],
				400: [],
			};

			if (includeParentChild) {
				if (originalParent && originalParent !== -1) {
					if (selectedParentChildRecords.includes(originalParent[0]) === false) {
					} else {
						const response4 = await fetch(
							api + "/report/models/" + originalParent[0] + "?reportid=" + selectedReportId + "&model=project.task&hierarchy=0",
							{
								method: "GET",
								headers: {
									"Content-Type": "application/json",
									Accept: "application/json",
									Authorization: token,
								},
								mode: "cors",
							}
						);

						let results = await response4.json();
						let { installationId, roofingId, krummyCaptureId } = results;
						let addedPage = false;
						if (installationId !== -1) {
							let { statusCode, file } = await generatePartsByModel("project.task", installationId, selectedReportId);
							if (statusCode === 200 && addedPage === false) {
								let parentPage = await createTitlePage(originalParent[1]);
								filesByCode[200].push(parentPage);
								addedPage = true;
							}
							if (file) filesByCode[statusCode].push(file);
						}
						if (krummyCaptureId !== -1) {
							let { statusCode, file } = await generatePartsByModel("x_krummy_capture", krummyCaptureId, selectedReportId);
							if (statusCode === 200 && addedPage === false) {
								let parentPage = await createTitlePage(originalParent[1]);
								filesByCode[200].push(parentPage);
								addedPage = true;
							}
							if (file) filesByCode[statusCode].push(file);
						}
						if (roofingId !== -1) {
							let { statusCode, file } = await generatePartsByModel("x_roofing", roofingId, selectedReportId);
							if (statusCode === 200 && addedPage === false) {
								let parentPage = await createTitlePage(originalParent[1]);
								filesByCode[200].push(parentPage);
								addedPage = true;
							}
							if (file) filesByCode[statusCode].push(file);
						}

						if (formattedOdooRecords.length > 0) {
							for (let i = 0; i < formattedOdooRecords.length; i++) {
								if (formattedOdooRecords[i].selectedReport !== "") {
									let { statusCode, file } = await generatePartsByModel(
										formattedOdooRecords[i].model,
										formattedOdooRecords[i].odoo_id,
										formattedOdooRecords[i].selectedReport
									);
									filesByCode[statusCode].push(file);
								}
							}
						}
					}
				} else if (originalInstallation !== -1) {
					if (selectedParentChildRecords.includes(originalInstallation) === false) {
					} else {
						let parentPage = await createTitlePage(projectName);
						filesByCode[200].push(parentPage);
						const response4 = await fetch(
							api + "/report/models/" + originalInstallation + "?reportid=" + selectedReportId + "&model=project.task&hierarchy=0",
							{
								method: "GET",
								headers: {
									"Content-Type": "application/json",
									Accept: "application/json",
									Authorization: token,
								},
								mode: "cors",
							}
						);

						let results = await response4.json();
						let { installationId, roofingId, krummyCaptureId } = results;
						if (installationId !== -1) {
							let { statusCode, file } = await generatePartsByModel("project.task", installationId, selectedReportId);
							if (file) filesByCode[statusCode].push(file);
						}
						if (krummyCaptureId !== -1) {
							let { statusCode, file } = await generatePartsByModel("x_krummy_capture", krummyCaptureId, selectedReportId);
							if (file) filesByCode[statusCode].push(file);
						}
						if (roofingId !== -1) {
							let { statusCode, file } = await generatePartsByModel("x_roofing", roofingId, selectedReportId);
							if (file) filesByCode[statusCode].push(file);
						}

						if (formattedOdooRecords.length > 0) {
							for (let i = 0; i < formattedOdooRecords.length; i++) {
								if (formattedOdooRecords[i].selectedReport !== "") {
									let { statusCode, file } = await generatePartsByModel(
										formattedOdooRecords[i].model,
										formattedOdooRecords[i].odoo_id,
										formattedOdooRecords[i].selectedReport
									);
									if (file) filesByCode[statusCode].push(file);
								}
							}
						}
					}
				}
				for (let i = 0; i < originalChildren.length; i++) {
					if (selectedParentChildRecords.includes(originalChildren[i].id) === false) {
						continue;
					}

					const response4 = await fetch(
						api + "/report/models/" + originalChildren[i].id + "?reportid=" + selectedReportId + "&model=project.task&hierarchy=0",
						{
							method: "GET",
							headers: {
								"Content-Type": "application/json",
								Accept: "application/json",
								Authorization: token,
							},
							mode: "cors",
						}
					);

					let results = await response4.json();
					let { installationId, roofingId, krummyCaptureId } = results;
					let addedPage = false;
					if (installationId !== -1) {
						let { statusCode, file } = await generatePartsByModel("project.task", installationId, selectedReportId);
						if (statusCode === 200 && addedPage === false) {
							let childPage = await createTitlePage(originalChildren[i].name);
							filesByCode[200].push(childPage);
							addedPage = true;
						}
						if (file) filesByCode[statusCode].push(file);
					}
					if (krummyCaptureId !== -1) {
						let { statusCode, file } = await generatePartsByModel("x_krummy_capture", krummyCaptureId, selectedReportId);
						if (statusCode === 200 && addedPage === false) {
							let childPage = await createTitlePage(originalChildren[i].name);
							filesByCode[200].push(childPage);
							addedPage = true;
						}
						if (file) filesByCode[statusCode].push(file);
					}
					if (roofingId !== -1) {
						let { statusCode, file } = await generatePartsByModel("x_roofing", roofingId, selectedReportId);
						if (statusCode === 200 && addedPage === false) {
							let childPage = await createTitlePage(originalChildren[i].name);
							filesByCode[200].push(childPage);
							addedPage = true;
						}
						if (file) filesByCode[statusCode].push(file);
					}

					if (formattedOdooRecords.length > 0) {
						for (let i = 0; i < formattedOdooRecords.length; i++) {
							if (formattedOdooRecords[i].selectedReport !== "") {
								let { statusCode, file } = await generatePartsByModel(
									formattedOdooRecords[i].model,
									formattedOdooRecords[i].odoo_id,
									formattedOdooRecords[i].selectedReport
								);
								if (file) filesByCode[statusCode].push(file);
							}
						}
					}
				}

				if (ticketIds.length > 0) {
					let ticketsUsed = 0;
					for (let i = 0; i < ticketIds.length; i++) {
						let { statusCode, file } = await generatePartsByModel("helpdesk.ticket", ticketIds[i], selectedReportId);
						if (ticketsUsed === 0 && statusCode === 200) {
							let ticketPage = await createTitlePage("Tickets");
							filesByCode[200].push(ticketPage);
							ticketsUsed++;
						}
						if (file) filesByCode[statusCode].push(file);
					}
				}
			} else {
				let titlePage = await createTitlePage(projectName);
				if (originalInstallation !== -1) {
					let { statusCode, file } = await generatePartsByModel("project.task", originalInstallation, selectedReportId);
					if (statusCode !== 400) {
						filesByCode[200].push(titlePage);
					}
					filesByCode[statusCode].push(file);
				}
				if (krummyCaptureId !== -1) {
					let { statusCode, file } = await generatePartsByModel("x_krummy_capture", krummyCaptureId, selectedReportId);
					if (statusCode !== 400) {
						filesByCode[200].push(titlePage);
					}
					filesByCode[statusCode].push(file);
				}
				if (roofingId !== -1) {
					let { statusCode, file } = await generatePartsByModel("x_roofing", roofingId, selectedReportId);
					if (statusCode !== 400) {
						filesByCode[200].push(titlePage);
					}
					filesByCode[statusCode].push(file);
				}
				if (ticketIds.length > 0) {
					let ticketsUsed = 0;
					for (let i = 0; i < ticketIds.length; i++) {
						let { statusCode, file } = await generatePartsByModel("helpdesk.ticket", ticketIds[i], selectedReportId);
						if (ticketsUsed === 0 && statusCode === 200) {
							let ticketPage = await createTitlePage("Tickets");
							filesByCode[200].push(ticketPage);
							ticketsUsed++;
						}
						filesByCode[statusCode].push(file);
					}
				}

				if (formattedOdooRecords.length > 0) {
					for (let i = 0; i < formattedOdooRecords.length; i++) {
						if (formattedOdooRecords[i].selectedReport !== "") {
							let { statusCode, file } = await generatePartsByModel(
								formattedOdooRecords[i].model,
								formattedOdooRecords[i].odoo_id,
								formattedOdooRecords[i].selectedReport
							);
							filesByCode[statusCode].push(file);
						}
					}
				}
			}
			if (filesByCode[200].length > 0) {
				files.push(...filesByCode[200]);
			} else if (filesByCode[204].length > 0) {
				files.push(await createEmptyPdf());
			} else if (filesByCode[400].length > 0) {
				files.push(await createEmptyPdf());
			} else {
				throw new Error();
			}

			const merger = new PDFMerger();
			for (const file of files) {
				await merger.add(file);
			}
			const mergedPDF = await merger.saveAsBuffer();
			const numberedPdfBytes = await addPageNumbersToMergedPDF(mergedPDF);
			const pdfBlob = new Blob([numberedPdfBytes], { type: "application/pdf" });
			const url = URL.createObjectURL(pdfBlob);
			const link = document.createElement("a");
			link.download = saveName + ".pdf";
			link.href = url;
			link.click();
			for (const file of files) {
				URL.revokeObjectURL(file);
			}
			setGeneratingReport(false);
		} catch (err) {
			setGeneratingReport(false);
			setHasError(true);
		}
	};
	const preventPropagation = (e) => {
		e.stopPropagation();
	};
	const changeHidePhotos = (e) => {
		setHidePhotos(e.target.checked);
	};

	const changeHideData = (e) => {
		setHideData(e.target.checked);
	};

	const changeParentChildSelection = (e) => {
		setIncludeParentchild(e.target.checked);
	};

	const toggleRequest = (e, requestId) => {
		if (requestIds.includes(requestId)) {
			setRequestIds((prev) => prev.filter((filterData) => filterData !== requestId));
		} else {
			setRequestIds([...requestIds, requestId]);
		}
	};

	const toggleSelectedTicket = (ticketId) => {
		if (selectedTickets.includes(ticketId)) {
			setSelectedTickets((prev) => [...prev.filter((filterData) => filterData !== ticketId)]);
		} else {
			setSelectedTickets([...selectedTickets, ticketId]);
		}
	};

	const toggleSelectedParentChildRecord = (recordId) => {
		setMissingParentChildRecord(false);
		if (selectedParentChildRecords.includes(recordId)) {
			setSelectedParentChildrecords((prev) => [...prev.filter((filterData) => filterData.toString() !== recordId.toString())]);
		} else {
			setSelectedParentChildrecords([...selectedParentChildRecords, recordId]);
		}
	};

	const validateParentChildSelections = () => {
		if (selectedParentChildRecords.length > 0) {
			setMissingParentChildRecord(false);
			if (allTicketIds.length > 1) {
				setShowModalType("tickets");
			} else {
				generateReport(allTicketIds);
			}
		} else {
			setMissingParentChildRecord(true);
		}
	};

	const processStartCreation = async () => {
		await getTicketAndParentChildData();
	};

	if (parentChildRecords.length > 0 && showModalType !== "tickets") {
		return (
			<div className="text-black fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black/50 z-50" onClick={closeModal}>
				<div className="bg-white w-full lg:w-1/4 m-2 rounded-lg " onClick={preventPropagation}>
					<div className="m-4 flex justify-between items-center">
						<p className="text-3xl">Parent/Child Records</p>
						<div>
							<button type="button" className="rounded-lg bg-gray-500 p-2 text-white " onClick={closeModal}>
								Close
							</button>
						</div>
					</div>
					<div className="mx-4">
						<p>Select the records you want to include.</p>
					</div>
					<div className="m-4 flex flex-col gap-4">
						<form>
							<p>Records:</p>
							{parentChildRecords.map((mapData) => (
								<div key={mapData.id} className="flex gap-1 items-center">
									<input
										id={"parentchild" + mapData.id}
										type="checkbox"
										checked={selectedParentChildRecords.includes(mapData.id)}
										onChange={() => toggleSelectedParentChildRecord(mapData.id)}></input>
									<label htmlFor={"parentchild" + mapData.id}>
										{mapData.name}
										{mapData.isParent ? " (Parent)" : null}
										{mapData.id === odooId ? " (Current)" : null}
									</label>
								</div>
							))}
						</form>
						<div className=" flex flex-col justify-center items-center gap-2">
							{generatingReport ? (
								<div className="flex flex-col justify-center items-center">
									<LoadingCircle />
									<p>Creating the report...</p>
								</div>
							) : selectedReportId !== "-1" ? (
								<div className="flex gap-4 justify-center items-center">
									<button
										className={`bg-gray-500 hover:bg-gray-600 text-white p-3 rounded-lg`}
										onClick={() => {
											setParentChildRecords([]);
											setAllTicketIds([]);
											setSelectedTickets([parseInt(odooId)]);
										}}>
										Go Back
									</button>
									<button
										className={`${
											selectedReportId !== "-1" ? "bg-blue-500 hover:bg-blue-700 " : "bg-gray-500 hover:bg-gray-600 "
										} text-white p-3 rounded-lg`}
										onClick={() => validateParentChildSelections()}>
										{allTicketIds.length > 1 ? "Select Tickets" : "Create Report"}
									</button>
								</div>
							) : (
								<button className={`bg-gray-500 hover:bg-gray-600 text-white p-3 rounded-lg`}>Create Report</button>
							)}
							{missingParentChildRecord ? <p className="text-red-500">Please include at least one record.</p> : null}
							{selectedReportId === "-1" ? <p>Select a Report</p> : null}
							{hasError ? (
								<p className="text-red-500">Something went wrong. Please try again. If this keeps happening, please contact IT.</p>
							) : null}
						</div>
					</div>
				</div>
			</div>
		);
	}

	if (allTicketIds.length > 1 && showModalType === "tickets") {
		return (
			<div className="text-black fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black/50 z-50" onClick={closeModal}>
				<div className="bg-white w-full lg:w-1/4 m-2 rounded-lg " onClick={preventPropagation}>
					<div className="m-4 flex justify-between items-center">
						<p className="text-3xl">Tickets</p>
						<div>
							<button type="button" className="rounded-lg bg-gray-500 p-2 text-white " onClick={closeModal}>
								Close
							</button>
						</div>
					</div>
					<div className="mx-4">
						<p>This project has multiple tickets, select the ones you want to include.</p>
					</div>
					<div className="m-4 flex flex-col gap-4">
						<form>
							<p>Odoo Ids:</p>
							{allTicketIds.map((mapData) => (
								<div key={mapData} className="flex gap-1 items-center">
									<input
										id={"ticket" + mapData}
										type="checkbox"
										checked={selectedTickets.includes(mapData)}
										onChange={() => toggleSelectedTicket(mapData)}></input>
									<label htmlFor={"ticket" + mapData}>
										{mapData}
										{selectedOdooModel === "helpdesk.ticket" && parseInt(odooId) === mapData ? " (Current Project)" : null}
									</label>
								</div>
							))}
						</form>
						<div className=" flex flex-col justify-center items-center gap-2">
							{generatingReport ? (
								<div className="flex flex-col justify-center items-center">
									<LoadingCircle />
									<p>Creating the report...</p>
								</div>
							) : selectedReportId !== "-1" ? (
								<div className="flex gap-4 justify-center items-center">
									<button
										className={`bg-gray-500 hover:bg-gray-600 text-white p-3 rounded-lg`}
										onClick={() => {
											if (includeParentChild) {
												setShowModalType("parentchild");
											} else {
												setAllTicketIds([]);
												setSelectedTickets([parseInt(odooId)]);
											}
										}}>
										Go Back
									</button>
									<button
										className={`${
											selectedReportId !== "-1" ? "bg-blue-500 hover:bg-blue-700 " : "bg-gray-500 hover:bg-gray-600 "
										} text-white p-3 rounded-lg`}
										onClick={() => {
											if (selectedTickets.length === 0) {
												setMissingTickets(true);
											} else {
												setMissingTickets(false);
												generateReport(selectedTickets);
											}
										}}>
										Create Report
									</button>
								</div>
							) : (
								<button className={`bg-gray-500 hover:bg-gray-600 text-white p-3 rounded-lg`}>Create Report</button>
							)}
							{missingTickets ? <p className="text-red-500">Please include at least one ticket.</p> : null}
							{selectedReportId === "-1" ? <p>Select a Report</p> : null}
							{hasError ? (
								<p className="text-red-500">Something went wrong. Please try again. If this keeps happening, please contact IT.</p>
							) : null}
						</div>
					</div>
				</div>
			</div>
		);
	}

	if (!loading && reports && reports.length === 0) {
		return (
			<div className="text-black fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black/50 z-50" onClick={closeModal}>
				<div className="bg-white w-full lg:w-1/4 m-2 rounded-lg " onClick={preventPropagation}>
					<div className="m-4 flex flex-col justify-between items-center">
						<p className="font-bold text-2xl mb-2">Not Found</p>
						<p className="text-red-500">This template is not used in any reports.</p>
					</div>
				</div>
			</div>
		);
	}
	return (
		<div className="text-black fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black/50 z-50" onClick={closeModal}>
			<div className="bg-white w-full lg:w-2/4 m-2 rounded-lg " onClick={preventPropagation}>
				<div className="m-4 flex justify-between items-center">
					<p className="text-3xl">Create Report</p>
					<div>
						<button type="button" className="rounded-lg bg-gray-500 p-2 text-white " onClick={closeModal}>
							Close
						</button>
					</div>
				</div>
				<div className="m-4 flex flex-col gap-4">
					<div>
						<p className="text-lg">Report</p>
						<form className="flex flex-col gap-2">
							<select
								id="templateNames"
								value={selectedReportId}
								className="border border-black rounded-lg p-1 w-full mt-2"
								onChange={(e) => changeSelectedTemplate(e, e.target.value)}>
								{reports && reports.length > 0
									? reports.map((mapData) => {
											return (
												<option key={mapData.id} value={mapData.id}>
													{mapData.name}
												</option>
											);
									  })
									: null}
							</select>
						</form>
					</div>
					<form>
						<div className="flex gap-2  items-center">
							<input id="hide-photos" type="checkbox" checked={hidePhotos} onChange={changeHidePhotos}></input>
							<label htmlFor="hide-photos">Hide Photos</label>
						</div>
						<div className="flex gap-2  items-center">
							<input id="hide-data" type="checkbox" checked={hideData} onChange={changeHideData}></input>
							<label htmlFor="hide-data">Hide Data</label>
						</div>
						<div className="flex gap-2  items-center">
							<input id="include-parentchild" type="checkbox" checked={includeParentChild} onChange={changeParentChildSelection}></input>
							<label htmlFor="include-parentchild">Include Parent/Child Records</label>
						</div>
					</form>
					<div className=" flex flex-col justify-center items-center gap-2">
						{generatingReport ? (
							<div className="flex flex-col justify-center items-center">
								<LoadingCircle />
								<p>Creating the report...</p>
							</div>
						) : selectedReportId !== "-1" ? (
							<button
								className={`${
									selectedReportId !== "-1" ? "bg-blue-500 hover:bg-blue-700 " : "bg-gray-500 hover:bg-gray-600 "
								} text-white p-3 rounded-lg`}
								onClick={processStartCreation}>
								{includeParentChild ? "Select Records" : "Create Report"}
							</button>
						) : (
							<button className={`bg-gray-500 hover:bg-gray-600 text-white p-3 rounded-lg`}>Create Report</button>
						)}
						{selectedReportId === "-1" ? <p>Select a Report</p> : null}

						{hasError ? <p className="text-red-500">Something went wrong. Please try again. If this keeps happening, please contact IT.</p> : null}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReportModal;
