import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import LoadingCircle from "../utility/LoadingCircle";
import RequestField from "./RequestField";
import RequestStatus from "./RequestStatus";

const Request = ({ api, token, isAdmin, isPublicStorage }) => {
	const navigate = useNavigate();
	const [request, setRequest] = useState();
	const [requestFields, setRequestFields] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [status, setStatus] = useState("active");
	const [assigneeName, setAssigneeName] = useState("");
	const [isAssignee, setIsAssignee] = useState(false);
	const [isRequester, setIsRequester] = useState(false);
	const [requestMessage, setRequestMessage] = useState("");
	let statusMessages = {
		incomplete: "Please fill out the entire request before submitting",
		submitted: "Request submitted for approval",
		reset: "",
		complete: "Request is now set to complete",
		rejected: "Request is now set to requesting retakes",
	};
	const params = useParams();

	useEffect(() => {
		document.title = "Request";
	}, []);

	useEffect(() => {
		if (request) {
			setStatus(request.status);
		}
	}, [request]);

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		setIsLoading(true);
		fetch(api + "/request/" + params.requestid, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: token,
			},
			signal,
		})
			.then((res) => {
				setIsLoading(false);
				if (res.ok) {
					return res.json();
				} else {
					if (isPublicStorage) {
						navigate("/dashboard");
					}
				}
			})
			.then((res) => {
				if (res) {
					setRequest(res.request);
					document.title = "Request for: " + res.request.name;
					setStatus(res.request.status);
					let processedFields = res.requestFields.sort((a, b) => {
						if (a.position > b.position) {
							return 1;
						} else if (a.position < b.position) {
							return -1;
						} else {
							return 0;
						}
					});
					processedFields = processedFields.map((mapData) => {
						mapData["hasPhotos"] = false;
						return mapData;
					});
					setRequestFields(processedFields);
					setAssigneeName(res.assigneeName);
					setIsAssignee(res.isAssignee);
					setIsRequester(res.isRequester);
				}
			})
			.catch((err) => {});

		return () => {
			controller.abort();
		};
	}, [api, token, params.requestid, isPublicStorage, navigate]);

	const changeStatus = (newStatus) => {
		window.scrollTo(0, 0);
		fetch(api + "/request/status/" + params.requestid, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: token,
			},
			body: JSON.stringify({
				status: newStatus,
			}),
		}).then((res) => {
			if (res.ok) {
				setStatus(newStatus);
			}
		});
	};

	const submitForApproval = (e) => {
		e.preventDefault();
		for (let i = 0; i < requestFields.length; i++) {
			if (requestFields[i].data === "" || requestFields[i].hasPhotos === false) {
				setRequestMessage(statusMessages.incomplete);
				return;
			}
		}
		changeStatus("awaiting approval");
		setRequestMessage(statusMessages.submitted);
	};

	const updateLastModified = () => {
		fetch(api + "/request/modified/" + params.requestid, {
			method: "get",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: token,
			},
		});
	};

	const moveToCompleted = () => {
		setRequestMessage(statusMessages.complete);
		changeStatus("completed");
	};

	const moveToRejected = () => {
		setRequestMessage(statusMessages.rejected);
		changeStatus("requesting retakes");
	};

	const renderRequestStatus = () => {
		if (request) {
			return (
				<div className="text-base flex items-center gap-2">
					<p>Status:</p>
					<RequestStatus status={status} />
				</div>
			);
		}

		return <p>Loading status...</p>;
	};

	return (
		<div>
			{requestMessage !== statusMessages.reset ? (
				<div
					className="fixed top-0 left-0 w-full h-screen flex justify-center items-center bg-black/70 z-30"
					onClick={() => setRequestMessage(statusMessages.reset)}>
					<div className="p-4 bg-white m-2 rounded-lg w-full md:w-auto max-h-[80vh] flex flex-col items-center justify-center">
						{requestMessage === statusMessages.submitted || requestMessage === statusMessages.complete ? (
							<AiOutlineCheckCircle className="text-4xl" color="green" />
						) : null}
						{requestMessage === statusMessages.rejected || requestMessage === statusMessages.incomplete ? (
							<AiOutlineCloseCircle className="text-4xl" color="red" />
						) : null}
						<p className="font-bold text-center">{requestMessage}</p>
					</div>
				</div>
			) : null}
			<div className="bg-gray-100 p-4 flex flex-col md:flex-row justify-between items-center text-xl">
				<div>
					{request ? (
						<>
							<p className="font-bold">
								Request for:{" "}
								<Link to={"/project/" + request.project_id} className="text-blue-600 underline">
									{request.name}
								</Link>
							</p>
							<p className="whitespace-pre-wrap">{request.description}</p>
						</>
					) : null}
				</div>
				{renderRequestStatus()}
			</div>
			<div className="md:w-full md:flex md:justify-center">
				<div className="md:w-3/5 p-4">
					<div className="flex flex-col gap-4">
						{requestFields && requestFields.length > 0 ? (
							requestFields.map((mapData) => {
								return (
									<div key={mapData.id} className="flex flex-col gap-1 border border-black rounded-lg p-2">
										<RequestField
											api={api}
											token={token}
											requestFieldData={mapData}
											requestData={request}
											assigneeName={assigneeName}
											isAssignee={isAssignee}
											updateLastModified={updateLastModified}
											setRequestFields={setRequestFields}
											requestFields={requestFields}
											isRequester={isRequester}
											status={status}
										/>
									</div>
								);
							})
						) : isLoading ? (
							<div className="flex justify-center flex-col items-center">
								<LoadingCircle />
								<p>Loading request...</p>
							</div>
						) : (
							<p>Request not found</p>
						)}
						{(status === "active" || status === "requesting retakes") && isAssignee ? (
							<button type="button" className="text-base bg-blue-600 text-white p-3 rounded-lg" onClick={(e) => submitForApproval(e)}>
								Submit for approval
							</button>
						) : null}
						{isRequester && status === "awaiting approval" ? (
							<>
								<button type="button" className="text-base bg-green-600 text-white p-3 rounded-lg" onClick={() => moveToCompleted()}>
									Move Request to Complete
								</button>
								<button type="button" className="text-base bg-red-600 text-white p-3 rounded-lg" onClick={() => moveToRejected()}>
									Request Retakes
								</button>
							</>
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Request;
