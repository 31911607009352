import { useEffect, useState } from "react";
import { BiDownArrow, BiRightArrow } from "react-icons/bi";
import { GrAdd } from "react-icons/gr";
import { FiEdit3 } from "react-icons/fi";
import { BsTrash } from "react-icons/bs";
import YesNoPrompt from "../../utility/YesNoPrompt";
import SectionModal from "./SectionModal";
import FieldModal from "../field/FieldModal";
import Field from "../field/Field";
import CloneSectionModal from "./CloneSectionModal";
import { ImCopy } from "react-icons/im";
import LoadingCircle from "../../utility/LoadingCircle";
import { AiOutlineInfoCircle } from "react-icons/ai";
import SectionInfoModal from "./SectionInfoModal";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";

const Section = (props) => {
	const [fields, setFields] = useState([]);
	const [showSection, setShowSection] = useState(false);
	const [showDeletePrompt, setShowDeletePrompt] = useState(false);
	const [showUpdateModal, setShowUpdateModal] = useState(false);
	const [showCreateFieldModal, setShowCreateFieldModal] = useState(false);
	const [showCloneModal, setShowCloneModal] = useState(false);
	const [loadingFields, setLoadingFields] = useState(false);
	const [showSectionInfoModal, setShowSectionInfoModal] = useState(false);

	useEffect(() => {
		if (props.idsOfOpenSections.includes(props.id)) {
			setShowSection(true);
		} else {
			setShowSection(false);
		}
	}, [props.id, props.idsOfOpenSections]);

	useEffect(() => {
		if (props.api === "" || props.token === "") {
			return;
		}

		const controller = new AbortController();
		const signal = controller.signal;
		setLoadingFields(true);
		fetch(props.api + "/section/" + props.id, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: props.token,
			},
			signal,
		})
			.then((res) => {
				if (!res.ok) {
					setLoadingFields(false);
					// put an error here
				} else {
					return res.json();
				}
			})
			.then((res) => {
				if (res) {
					setLoadingFields(false);
					const unsortedFields = res.fields;
					const sortedFields = unsortedFields.sort((a, b) => a.position - b.position);
					setFields(sortedFields);
				} else {
					setLoadingFields(false);
				}
			})
			.catch((err) => {});

		return () => {
			controller.abort();
		};
	}, [props.api, props.token, props.id]);

	const updateFieldInDB = (theField, newPosition) => {
		fetch(props.api + "/field/move/" + theField.id, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: props.token,
			},
			body: JSON.stringify({ position: newPosition }),
		}).then((res) => {
			if (!res.ok) {
				// put an error here
			} else {
			}
		});
	};

	const updateMultipleFieldsInDB = (updatedFields) => {
		fetch(props.api + "/field/move-multiple", {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: props.token,
			},
			body: JSON.stringify({ updatedFields }),
		}).then((res) => {
			if (!res.ok) {
				// put an error here
			} else {
			}
		});
	};

	const handleOnDragEnd = (result) => {
		if (!result.destination) return;
		const items = Array.from(fields);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		items.forEach((item, index) => {
			item.position = index;
		});
		updateMultipleFieldsInDB(items);
		setFields(items);
	};

	return (
		<>
			<div className="z-50">
				{showCreateFieldModal ? (
					<FieldModal
						isCreatingField={true}
						section_id={props.id}
						fieldTypes={props.fieldTypes}
						fieldWordBank={props.fieldWordBank}
						fields={fields}
						setFields={setFields}
						setShowCreateFieldModal={setShowCreateFieldModal}
						api={props.api}
						token={props.token}
					/>
				) : null}
				{showSectionInfoModal ? (
					<SectionInfoModal
						sectionName={props.name}
						setShowSectionInfoModal={setShowSectionInfoModal}
						condition_type={props.allSectionData.condition_type}
						condition_value={props.allSectionData.condition_value}
						field_condition_id={props.allSectionData.field_condition_id}
						section_condition_id={props.allSectionData.section_condition_id}
						sections={props.sections}
						fields={fields}
						api={props.api}
						token={props.token}
					/>
				) : null}
				{showCloneModal ? (
					<CloneSectionModal
						template_id={props.template_id}
						sectionName={props.name}
						setShowCloneModal={setShowCloneModal}
						sectionWordBank={props.sectionWordBank}
						api={props.api}
						id={props.id}
						section_id={props.id}
						token={props.token}
						sections={props.sections}
						setSections={props.setSections}
					/>
				) : null}
				{showUpdateModal ? (
					<SectionModal
						position={props.position}
						isCreatingSection={false}
						currentName={props.name}
						setShowSectionModal={setShowUpdateModal}
						sectionWordBank={props.sectionWordBank}
						api={props.api}
						section_id={props.id}
						token={props.token}
						sections={props.sections}
						setSections={props.setSections}
						condition_type={props.allSectionData.condition_type}
						condition_value={props.allSectionData.condition_value}
						field_condition_id={props.allSectionData.field_condition_id}
						section_condition_id={props.allSectionData.section_condition_id}
					/>
				) : null}
				{showDeletePrompt ? (
					<YesNoPrompt type="Section" name={props.name} deleteAction={props.deleteSection} cancelAction={() => setShowDeletePrompt(false)} />
				) : null}
			</div>
			<div className="mb-2 relative">
				<div className="flex justify-between bg-gray-300 border border-black sticky -top-1">
					<div className="text-xl font-bold p-3 flex items-center">
						<p>
							{showSection ? (
								<span
									className="cursor-pointer select-none"
									onClick={() => {
										setShowSection(false);
										props.setDisableSectionDrag(false);
										props.setIdsOfOpenSections((prev) => [...prev.filter((filterData) => filterData !== props.id)]);
									}}>
									<BiDownArrow />
								</span>
							) : (
								<span
									className="cursor-pointer select-none"
									onClick={() => {
										setShowSection(true);
										props.setDisableSectionDrag(true);
										if (props.idsOfOpenSections.includes(props.id) === false) {
											props.setIdsOfOpenSections((prev) => [...prev, props.id]);
										}
									}}>
									<BiRightArrow />
								</span>
							)}
						</p>
						<p id={props.name.replaceAll(" ", "-")}>Section: {props.name}</p>
					</div>

					{props.isDrafting ? (
						<div className="flex justify-center items-center text-2xl p-4 gap-4 ">
							<BsTrash className="cursor-pointer" color="red" onClick={() => setShowDeletePrompt(true)} />
							<FiEdit3 className="cursor-pointer" onClick={() => setShowUpdateModal(true)} />
							<GrAdd className="cursor-pointer" onClick={() => setShowCreateFieldModal(true)} />
							<ImCopy className="cursor-pointer" onClick={() => setShowCloneModal(true)} />
						</div>
					) : (
						<div className="flex flex-row-reverse md:flex-row md:justify-center items-center text-2xl p-3">
							<AiOutlineInfoCircle className="cursor-pointer select-none" onClick={() => setShowSectionInfoModal(true)} />
						</div>
					)}
				</div>
				<DragDropContext onDragEnd={handleOnDragEnd}>
					<Droppable droppableId="fields">
						{(provided) => (
							<ul className="fields" {...provided.droppableProps} ref={provided.innerRef}>
								{fields && fields.length > 0 && showSection ? (
									fields.map((values, index) => {
										return (
											<Draggable key={values.id} draggableId={values.id.toString()} index={index} isDragDisabled={!props.isDrafting}>
												{(provided) => (
													<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
														<Field
															key={values.id}
															arrayIndex={index}
															section_id={props.id}
															fieldId={values.id}
															values={values}
															className="p-4"
															api={props.api}
															fieldTypes={props.fieldTypes}
															token={props.token}
															fields={fields}
															setFields={setFields}
															fieldWordBank={props.fieldWordBank}
															isDrafting={props.isDrafting}
															updateFieldInDB={updateFieldInDB}></Field>
													</div>
												)}
											</Draggable>
										);
									})
								) : showSection ? (
									loadingFields ? (
										<div className="flex flex-col items-center justify-center w-full my-2">
											<LoadingCircle />
											<p>Loading Fields</p>
										</div>
									) : (
										<p className="p-2">This section is empty</p>
									)
								) : null}
								{provided.placeholder}
							</ul>
						)}
					</Droppable>
				</DragDropContext>
			</div>
		</>
	);
};

export default Section;
