import { useState, useEffect } from "react";
import { GrAdd } from "react-icons/gr";
import { useNavigate, useSearchParams } from "react-router-dom";
import TemplateModal from "./TemplateModal";
import { BsSearch } from "react-icons/bs";
import TemplateList from "./TemplateList";
import LoadingCircle from "../utility/LoadingCircle";
import TemplateDesktopFilter from "./filter/TemplateDesktopFilter";
import TemplateMobileFilter from "./filter/TemplateMobileFilter";

const Templates = (props) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [templates, setTemplates] = useState([]);
	const [searchQuery, setSearchQuery] = useState([]);
	const [showTemplateModal, setShowTemplateModal] = useState(false);
	const [showArchived, setShowArchived] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [templateTypes, setTemplateTypes] = useState([]);
	const [odooModels, setOdooModels] = useState([]);
	const [odooModelToFilterBy, setOdooModelToFilterBy] = useState("all");
	const [selectedTemplateType, setSelectedTemplateType] = useState("all");
	const [portfolioType, setPortfolioType] = useState("all");

	useEffect(() => {
		document.title = "Templates";
	}, []);

	useEffect(() => {
		if (props.api === "" || props.token === "") {
			return;
		}
		const controller = new AbortController();
		const signal = controller.signal;
		let fetchUrl = props.api + "/template?page=" + searchParams.get("page");
		if (searchParams.get("archived")) {
			setShowArchived(searchParams.get("archived"));
			fetchUrl += ` &archived=${searchParams.get("archived")}`;
		} else {
			setShowArchived(1);
			fetchUrl += `&archived=1`;
		}
		if (searchParams.get("query")) {
			fetchUrl += `&query=${searchParams.get("query")}`;
		} else {
			fetchUrl += `&query= `;
		}
		if (searchParams.get("type")) {
			setSelectedTemplateType(searchParams.get("type"));
			fetchUrl += `&type=${searchParams.get("type")}`;
		} else {
			setSelectedTemplateType("all");
			fetchUrl += `&type=all`;
		}

		if (searchParams.get("model")) {
			setOdooModelToFilterBy(searchParams.get("model"));
			fetchUrl += `&model=${searchParams.get("model")}`;
		} else {
			setOdooModelToFilterBy("all");
			fetchUrl += `&model=all`;
		}

		if (searchParams.get("portfolio")) {
			setPortfolioType(searchParams.get("portfolio"));
			fetchUrl += `&portfolio=${searchParams.get("portfolio")}`;
		} else {
			setPortfolioType("all");
			fetchUrl += `&portfolio=all`;
		}

		setIsLoading(true);
		fetch(fetchUrl, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: props.token,
			},
			mode: "cors",
			signal,
		})
			.then((res) => {
				setIsLoading(false);
				if (res.ok) {
					return res.json();
				} else {
				}
			})
			.then((res) => {
				if (res && typeof res.templates != "undefined") {
					setTemplates(res.templates);
					setTemplateTypes(res.templateTypes);
					setOdooModels(res.odooModels);
				}
			})
			.catch((err) => {});
		return () => {
			controller.abort();
		};
	}, [props.api, props.token, searchParams]);

	const startSearch = (e) => {
		e.preventDefault();
		const uriEncodedString = encodeURIComponent(encodeURIComponent(searchQuery));
		navigate(
			`/templates?page=1&archived=${showArchived}&query=${uriEncodedString}&type=${selectedTemplateType}&model=${odooModelToFilterBy}&portfolio=${portfolioType}`
		);
	};

	return (
		<div className="flex flex-col items-center p-4">
			{showTemplateModal ? (
				<TemplateModal
					isCreating={true}
					api={props.api}
					token={props.token}
					isAdmin={props.isAdmin}
					closeModal={() => setShowTemplateModal(false)}
					templateTypes={templateTypes}
					odooModels={odooModels}
				/>
			) : null}
			<h1 className="text-3xl flex items-center gap-3">
				Templates
				<span>
					<GrAdd className="cursor-pointer" onClick={() => setShowTemplateModal(true)} />
				</span>
			</h1>
			<form onSubmit={(e) => startSearch(e)} className="my-4 flex flex-col gap-3 w-full justify-center items-center md:w-3/5">
				<div className="flex w-full">
					<input
						type="text"
						className="border rounded-l-lg border-black p-2 w-full "
						placeholder="Search for a template..."
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
					/>
					<button type="submit" className="border rounded-r-lg border-black p-3">
						<BsSearch className="" />
					</button>
				</div>
				<div className="hidden md:flex w-full">
					<TemplateDesktopFilter
						showArchived={showArchived}
						setShowArchived={setShowArchived}
						odooModelToFilterBy={odooModelToFilterBy}
						setOdooModelToFilterBy={setOdooModelToFilterBy}
						odooModels={odooModels}
						selectedTemplateType={selectedTemplateType}
						setSelectedTemplateType={setSelectedTemplateType}
						portfolioType={portfolioType}
						setPortfolioType={setPortfolioType}
					/>
				</div>
				<div className="md:hidden w-full">
					<TemplateMobileFilter
						showArchived={showArchived}
						setShowArchived={setShowArchived}
						odooModelToFilterBy={odooModelToFilterBy}
						setOdooModelToFilterBy={setOdooModelToFilterBy}
						odooModels={odooModels}
						selectedTemplateType={selectedTemplateType}
						setSelectedTemplateType={setSelectedTemplateType}
						portfolioType={portfolioType}
						setPortfolioType={setPortfolioType}
					/>
				</div>
			</form>
			{isLoading ? (
				<div className="flex flex-col items-center flex-center gap-3 bg-white p-4">
					<LoadingCircle />
					<p>Loading...</p>
				</div>
			) : (
				<TemplateList templates={templates} isLoading={isLoading} />
			)}
		</div>
	);
};

export default Templates;
